export interface IrregularTaskEntityData {
  id: string;
  orderId: string;
  name: string;
  durationAtGenerationSite: number;
  durationAtDisposalSite: number;
  skipDisposalSite: boolean;
}

/**
 * こいつは扱いが特殊で、Order の現場タスクを更新すると毎回新しい ID が振られる。
 * なので ID が同じでも集約の内容が更新される事はあり得ず、トラックする必要はない。
 */
export class IrregularTaskEntity {
  readonly id: string;
  readonly persistentId: string;
  orderId: string;
  name: string;
  durationAtGenerationSite: number;
  durationAtDisposalSite: number;
  skipDisposalSite: boolean;

  constructor(
    id: string,
    orderId: string,
    name: string,
    durationAtGenerationSite: number,
    durationAtDisposalSite: number,
    skipDisposalSite: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.orderId = orderId;
    this.name = name;
    this.durationAtGenerationSite = durationAtGenerationSite;
    this.durationAtDisposalSite = durationAtDisposalSite;
    this.skipDisposalSite = skipDisposalSite;
  }
}
