import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  car$getAllSymbol,
  car$createSymbol,
  ICreateData,
  IUpdateData,
  car$updateSymbol,
  ICarMutationError,
} from '~/framework/server-api/masters/car';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';

import { IDisposable } from '~/framework/core/disposable';
import { Ports } from '~/framework/core/ports';
import { containerType$getAllSymbol } from '~/framework/server-api/masters/containerType';
import { mapData } from '~/framework/core/mapper';

export interface ICreatePresenter {
  create(entity: AggregatedCarEntity): void;
  errorOnCreate(error: ICarMutationError): void;
}

export interface IUpdatePresenter {
  update(entity: AggregatedCarEntity): void;
  errorOnUpdate(error: ICarMutationError): void;
}

export const carSymbol = Symbol('car');

export class CarApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly createPort: Ports<ICreatePresenter>;
  private readonly updatePort: Ports<IUpdatePresenter>;
  private carMapper: AggregatedCarMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
    this.createPort = new Ports<ICreatePresenter>();
    this.updatePort = new Ports<IUpdatePresenter>();
    this.carMapper = new AggregatedCarMapper(
      store.masters.aggregatedCar,
      store.masters.aggregatedCarType,
      store.masters.orderGroup,
      store.masters.aggregatedCarTypeContainerType,
      store.masters.aggregatedBaseSite,
      store.masters.user
    );
  }

  addCreatePresenter(presenter: ICreatePresenter): IDisposable {
    return this.createPort.add(presenter);
  }

  addUpdatePresenter(presenter: IUpdatePresenter): IDisposable {
    return this.updatePort.add(presenter);
  }

  async getAll(): Promise<AggregatedCarEntity[]> {
    const car$getAllApi = this.serverApis.get(car$getAllSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const [carsData, containerTypesData] = await Promise.all([
      car$getAllApi.getAll(),
      containerType$getAllApi.getAll(),
    ]);
    const containerTypeDataMap = mapData(containerTypesData, 'id');

    const carsEntityData = carsData.map((car) => {
      const carTypeEntityData = {
        ...car.carType,
        orderGroupId: car.carType.orderGroup.id,
        loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      return { ...car, carType: carTypeEntityData };
    });

    const entities: Array<AggregatedCarEntity> = this.carMapper.map(carsEntityData);
    return entities;
  }

  async create(createData: ICreateData): Promise<void> {
    const car$createApi = this.serverApis.get(car$createSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);
    const [createResult, containerTypesData] = await Promise.all([
      car$createApi.create(createData),
      containerType$getAllApi.getAll(),
    ]);

    if (createResult.__typename === 'Car') {
      const containerTypeDataMap = mapData(containerTypesData, 'id');
      const carTypeEntityData = {
        ...createResult.carType,
        orderGroupId: createResult.carType.orderGroup.id,
        loadableContainerTypes: createResult.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      const createdCarEntity: AggregatedCarEntity = this.carMapper.mapSingle({
        ...createResult,
        carType: carTypeEntityData,
      });
      this.createPort.output('create', createdCarEntity);
    } else if (createResult.__typename === 'CarMutationError') {
      this.createPort.output('errorOnCreate', createResult);
    } else {
      throw new Error('AbnormalResultException to create car mutation');
    }
  }

  async update(updateData: IUpdateData): Promise<void> {
    const car$updateApi = this.serverApis.get(car$updateSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);
    const [updateResult, containerTypesData] = await Promise.all([
      car$updateApi.update(updateData),
      containerType$getAllApi.getAll(),
    ]);

    if (updateResult.__typename === 'Car') {
      const containerTypeDataMap = mapData(containerTypesData, 'id');
      const carTypeEntityData = {
        ...updateResult.carType,
        orderGroupId: updateResult.carType.orderGroup.id,
        loadableContainerTypes: updateResult.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      const updatedCarEntity: AggregatedCarEntity = this.carMapper.mapSingle({
        ...updateResult,
        carType: carTypeEntityData,
      });
      this.updatePort.output('update', updatedCarEntity);
    } else if (updateResult.__typename === 'CarMutationError') {
      this.createPort.output('errorOnCreate', updateResult);
    } else {
      throw new Error('AbnormalResultException to update car mutation');
    }
  }
}
