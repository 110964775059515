import { MapperBase } from '~/framework/core/mapper';
import { DriverEntity, DriverEntityData } from '~/framework/domain/masters/driver/driverEntity';

export class DriverMapper extends MapperBase<DriverEntityData, DriverEntity> {
  protected instantiateWithData(data: DriverEntityData): DriverEntity {
    return new DriverEntity(
      data.id,
      data.name,
      data.nameRuby,
      data.employmentStatus,
      data.defaultPrimaryCarId,
      data.licensedCarTypeIds,
      data.defaultAttendanceTemplateId,
      data.status,
      data.signInUrl
    );
  }
}
