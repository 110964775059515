import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  disposalSite$createSymbol,
  IDisposalSiteCreateData,
  disposalSite$updateSymbol,
  IDisposalSiteUpdateData,
  disposalSite$getAllSymbol,
  ClientForDisposalSite,
} from '~/framework/server-api/masters/disposalSite';

import { AggregatedDisposalSiteMapper } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteMapper';

import { mapData } from '~/framework/core/mapper';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { Maybe } from '~/framework/typeAliases';

export const disposalSiteSymbol = Symbol('disposalSite');

export class DisposalSiteApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  // region list
  async getAll(): Promise<AggregatedDisposalSiteEntity[]> {
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const disposalSiteMapper: AggregatedDisposalSiteMapper = new AggregatedDisposalSiteMapper(
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.user
    );
    const result = await disposalSite$getAllApi.getAll();
    const entities = disposalSiteMapper.map(result);
    return entities;
  }
  // endregion

  async create(
    data: IDisposalSiteCreateData,
    options?: { client?: ClientForDisposalSite }
  ): Promise<AggregatedDisposalSiteEntity> {
    const disposalSite$createApi = this.serverApis.get(disposalSite$createSymbol);
    const disposalSiteMapper: AggregatedDisposalSiteMapper = new AggregatedDisposalSiteMapper(
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.user
    );
    const [result] = await disposalSite$createApi.create([data]);
    const entity = disposalSiteMapper.mapSingle({
      id: result.id,
      code: undefined, // 新規作成時はコード設定できないので undefined を入れる
      createdAt: result.createdAt,
      createdBy: result.createdBy,
      ...data,
      client: options?.client,
    });
    return entity;
  }

  async update(
    data: IDisposalSiteUpdateData,
    options?: { client: Maybe<ClientForDisposalSite> }
  ): Promise<AggregatedDisposalSiteEntity> {
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const disposalSite$updateApi = this.serverApis.get(disposalSite$updateSymbol);
    const disposalSiteMapper: AggregatedDisposalSiteMapper = new AggregatedDisposalSiteMapper(
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.user
    );
    // このアプリケーションサービスのインプットのデータとしては data でしかない、
    // プレゼンターが必要としているデータの形は集約したエンティティのため、情報をいくつか補完する必要があり、
    // getAll して情報を抜き出すという事をやっている。インプットのデータの型を変えてもよいが持っている事が
    // 前提となるのも微妙だったのでこの形にしている
    // TODO ここは getById に書き換えたい、全部取る必要はない
    const result = await Promise.all([disposalSite$getAllApi.getAll(), disposalSite$updateApi.update([data])]);
    const dataMap = mapData(result[0], 'id');
    const deficientData = dataMap.getOrError(data.id);
    const entity = disposalSiteMapper.mapSingle({
      ...data,
      code: undefined, // コードが設定されている可能性はあるが、返却値を利用しないため undefined を入れておく。本来的には update では Entity を返却しないようにしたい
      client: options?.client,
      createdAt: deficientData.createdAt,
      createdBy: deficientData.createdBy,
    });
    return entity;
  }
  // endregion

  // region import
  async createMultiple(data: IDisposalSiteCreateData[]): Promise<AggregatedDisposalSiteEntity[]> {
    const disposalSite$createApi = this.serverApis.get(disposalSite$createSymbol);
    const disposalSiteMapper: AggregatedDisposalSiteMapper = new AggregatedDisposalSiteMapper(
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.user
    );
    const result = await disposalSite$createApi.create(data);
    const entities = disposalSiteMapper.map(
      data.map((aData, index) => {
        return {
          id: result[index].id,
          code: undefined, // 新規作成時はコード設定できないので undefined を入れる
          client: undefined,
          createdAt: result[index].createdAt,
          createdBy: result[index].createdBy,
          ...aData,
        };
      })
    );
    return entities;
  }

  async updateMultiple(updateData: IDisposalSiteUpdateData[]): Promise<AggregatedDisposalSiteEntity[]> {
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const disposalSite$updateApi = this.serverApis.get(disposalSite$updateSymbol);
    const disposalSiteMapper: AggregatedDisposalSiteMapper = new AggregatedDisposalSiteMapper(
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.user
    );
    // このアプリケーションサービスのインプットのデータとしては data でしかない、
    // プレゼンターが必要としているデータの形は集約したエンティティのため、情報をいくつか補完する必要があり、
    // getAll して情報を抜き出すという事をやっている。インプットのデータの型を変えてもよいが持っている事が
    // 前提となるのも微妙だったのでこの形にしている
    // TODO ここは getById に書き換えたい、全部取る必要はない
    const [data] = await Promise.all([disposalSite$getAllApi.getAll(), disposalSite$updateApi.update(updateData)]);

    const dataMap = mapData(data, 'id');
    const entities = disposalSiteMapper.map(
      updateData.map((aData) => {
        const deficientData = dataMap.getOrError(aData.id);
        return {
          ...aData,
          code: deficientData.code,
          client: undefined,
          createdAt: deficientData.createdAt,
          createdBy: deficientData.createdBy,
        };
      })
    );
    return entities;
  }
  // endregion

  async getByIds(ids: string[]): Promise<AggregatedDisposalSiteEntity[]> {
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const disposalSiteMapper: AggregatedDisposalSiteMapper = new AggregatedDisposalSiteMapper(
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.user
    );
    // TODO ここは getById に書き換えたい、全部取る必要はない
    const disposalSites = await disposalSite$getAllApi.getAll();
    const result = disposalSites.filter((disposalSite) => ids.includes(disposalSite.id));
    const entities = disposalSiteMapper.map(result);
    return entities;
  }
}
