import { v4 as uuidv4 } from 'uuid';
import { EphemeralId, Id, Maybe } from '~/framework/typeAliases';
import { AggregatedPackingPlacementEntity } from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementEntity';

/**
 * 荷姿の残存数のフォーム上のデータ
 */
export interface IPackingPlacementFormValues {
  id: EphemeralId;
  containerTypeId: string;
  num: number;
  lastAllocatedAt: Maybe<Date>;

  correctData(): void;
  isEqualTo(another: IPackingPlacementFormValues): boolean;
}

export class PackingPlacementFormValues implements IPackingPlacementFormValues {
  private static readonly numMax: number = 999999;
  id: EphemeralId;
  containerTypeId: string;
  num: number;
  lastAllocatedAt: Maybe<Date>;

  constructor(id: EphemeralId, containerTypeId: string, num: number, lastAllocatedAt: Maybe<Date>) {
    this.id = id;
    this.containerTypeId = containerTypeId;
    this.num = num;
    this.lastAllocatedAt = lastAllocatedAt;
  }

  correctData(): void {
    if ((this.num as any) === '' || this.num === undefined) this.num = 0;
    else if (PackingPlacementFormValues.numMax < this.num) this.num = PackingPlacementFormValues.numMax;
  }

  isEqualTo(another: IPackingPlacementFormValues): boolean {
    return this.containerTypeId === another.containerTypeId && this.num === another.num;
  }

  static clone(original: IPackingPlacementFormValues): PackingPlacementFormValues {
    const id = uuidv4();
    return new PackingPlacementFormValues(id, original.containerTypeId, original.num, original.lastAllocatedAt);
  }
}

export class PackingPlacementFormValuesFactory {
  static buildByContainerTypeId(containerTypeId: Id): IPackingPlacementFormValues {
    const id = uuidv4();
    return new PackingPlacementFormValues(id, containerTypeId, 0, undefined);
  }

  static buildByPackingPlacement(packingPlacement: AggregatedPackingPlacementEntity): IPackingPlacementFormValues {
    const id = uuidv4();
    return new PackingPlacementFormValues(
      id,
      packingPlacement.containerTypeId,
      packingPlacement.num,
      packingPlacement.lastAllocatedAt
    );
  }
}
