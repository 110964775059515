import { CollectablePeriodTemplateMapper } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateMapper';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { CollectablePeriodTemplateStore } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateStore';
import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedGenerationSiteEntity,
  AggregatedGenerationSiteEntityData,
} from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { AggregatedGenerationSiteStore } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteStore';
import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { DriverMapper } from '~/framework/domain/masters/driver/driverMapper';
import { AggregatedDisposalSiteMapper } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteMapper';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';
import { CarTypeMapper } from '~/framework/domain/masters/car-type/carTypeMapper';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';

export class AggregatedGenerationSiteMapper extends StoredMapperBase<
  AggregatedGenerationSiteEntityData,
  AggregatedGenerationSiteEntity
> {
  protected store: AggregatedGenerationSiteStore;
  private clientMapper: AggregatedClientMapper;
  private driverMapper: DriverMapper;
  private disposalSiteMapper: AggregatedDisposalSiteMapper;
  private carTypeMapper: CarTypeMapper;
  private carMapper: AggregatedCarMapper;
  private userMapper: UserMapper;
  private collectablePeriodTemplateMapper: CollectablePeriodTemplateMapper;

  constructor(
    store: AggregatedGenerationSiteStore,
    clientStore: AggregatedClientStore,
    userStore: UserStore,
    disposalSiteStore: AggregatedDisposalSiteStore,
    collectablePeriodTemplateStore: CollectablePeriodTemplateStore,
    carStore: AggregatedCarStore,
    carTypeStore: AggregatedCarTypeStore,
    orderGroupStore: OrderGroupStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore,
    baseSiteStore: AggregatedBaseSiteStore
  ) {
    super();
    this.store = store;
    this.clientMapper = new AggregatedClientMapper(clientStore, userStore);
    this.driverMapper = new DriverMapper();
    this.disposalSiteMapper = new AggregatedDisposalSiteMapper(disposalSiteStore, userStore);
    this.carTypeMapper = new CarTypeMapper();
    this.carMapper = new AggregatedCarMapper(
      carStore,
      carTypeStore,
      orderGroupStore,
      carTypeContainerTypeStore,
      baseSiteStore,
      userStore
    );
    this.userMapper = new UserMapper(userStore);
    this.collectablePeriodTemplateMapper = new CollectablePeriodTemplateMapper(collectablePeriodTemplateStore);
  }

  protected instantiateWithData(data: AggregatedGenerationSiteEntityData): AggregatedGenerationSiteEntity {
    const client = this.clientMapper.mapSingle(data.client);
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    const defaultAssignedDriver = data.defaultAssignedDriver
      ? this.driverMapper.mapSingle(data.defaultAssignedDriver)
      : undefined;
    const defaultAssignedDisposalSite = data.defaultAssignedDisposalSite
      ? this.disposalSiteMapper.mapSingle(data.defaultAssignedDisposalSite)
      : undefined;

    const defaultAssignableCarTypes = this.carTypeMapper.map(data.defaultAssignableCarTypes);
    const defaultAssignedCar = data.defaultAssignedCar ? this.carMapper.mapSingle(data.defaultAssignedCar) : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const defaultCollectablePeriodTemplate = data.defaultCollectablePeriodTemplate
      ? this.collectablePeriodTemplateMapper.mapSingle(data.defaultCollectablePeriodTemplate)
      : undefined;

    return new AggregatedGenerationSiteEntity(
      data.id,
      data.clientId,
      data.code,
      data.name,
      data.nameRuby,
      data.zipCode,
      data.address1,
      data.address2,
      data.address3,
      data.address4,
      data.latitude,
      data.longitude,
      data.bannedDriverIds,
      data.defaultAssignedDriverId,
      data.defaultAssignedDisposalSiteId,
      data.defaultAssignableCarTypeIds,
      data.defaultAssignedCarId,
      data.defaultAvoidHighways,
      data.defaultDurationAtEntrance,
      data.restPeriodStart,
      data.restPeriodEnd,
      data.note,
      data.attachments ?? [],
      data.isAddressComplete,
      data.createdAt,
      data.status,
      data.defaultCollectablePeriodTemplateId,
      data.defaultCollectablePeriodStart,
      data.defaultCollectablePeriodEnd,
      data.defaultRouteCollectionAllowed,
      data.defaultPreloadStatus,
      data.defaultIsFixedArrivalTimeReportNeeded,
      data.defaultMarginTypeOfFixedArrivalTime,
      data.defaultMarginOfFixedArrivalTime,
      data.noteForOffice,
      client,
      bannedDrivers,
      defaultAssignedDriver,
      defaultAssignedDisposalSite,
      defaultAssignableCarTypes,
      defaultAssignedCar,
      createdBy,
      defaultCollectablePeriodTemplate
    );
  }

  protected updateWithData(data: AggregatedGenerationSiteEntityData, entity: AggregatedGenerationSiteEntity): void {
    const client = this.clientMapper.mapSingle(data.client);
    const bannedDrivers = this.driverMapper.map(data.bannedDrivers);
    const defaultAssignedDriver = data.defaultAssignedDriver
      ? this.driverMapper.mapSingle(data.defaultAssignedDriver)
      : undefined;
    const defaultAssignedDisposalSite = data.defaultAssignedDisposalSite
      ? this.disposalSiteMapper.mapSingle(data.defaultAssignedDisposalSite)
      : undefined;
    const defaultAssignableCarTypes = this.carTypeMapper.map(data.defaultAssignableCarTypes);
    const defaultAssignedCar = data.defaultAssignedCar ? this.carMapper.mapSingle(data.defaultAssignedCar) : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const defaultCollectablePeriodTemplate = data.defaultCollectablePeriodTemplate
      ? this.collectablePeriodTemplateMapper.mapSingle(data.defaultCollectablePeriodTemplate)
      : undefined;

    entity.clientId = data.clientId;
    entity.code = data.code;
    entity.name = data.name;
    entity.nameRuby = data.nameRuby;
    entity.zipCode = data.zipCode;
    entity.address1 = data.address1;
    entity.address2 = data.address2;
    entity.address3 = data.address3;
    entity.address4 = data.address4;
    entity.latitude = data.latitude;
    entity.longitude = data.longitude;
    entity.bannedDriverIds = data.bannedDriverIds;
    entity.defaultAssignedDriverId = data.defaultAssignedDriverId;
    entity.defaultAssignedDisposalSiteId = data.defaultAssignedDisposalSiteId;
    entity.defaultAssignableCarTypeIds = data.defaultAssignableCarTypeIds;
    entity.defaultAssignedCarId = data.defaultAssignedCarId;
    entity.defaultAvoidHighways = data.defaultAvoidHighways;
    entity.defaultDurationAtEntrance = data.defaultDurationAtEntrance;
    entity.restPeriodStart = data.restPeriodStart;
    entity.restPeriodEnd = data.restPeriodEnd;
    entity.note = data.note;
    entity.attachments = data.attachments ?? [];
    entity.isAddressComplete = data.isAddressComplete;
    entity.createdAt = data.createdAt;
    entity.status = data.status;
    entity.defaultCollectablePeriodTemplateId = data.defaultCollectablePeriodTemplateId;
    entity.defaultCollectablePeriodStart = data.defaultCollectablePeriodStart;
    entity.defaultCollectablePeriodEnd = data.defaultCollectablePeriodEnd;
    entity.defaultRouteCollectionAllowed = data.defaultRouteCollectionAllowed;
    entity.defaultPreloadStatus = data.defaultPreloadStatus;
    entity.defaultIsFixedArrivalTimeReportNeeded = data.defaultIsFixedArrivalTimeReportNeeded;
    entity.defaultMarginTypeOfFixedArrivalTime = data.defaultMarginTypeOfFixedArrivalTime;
    entity.defaultMarginOfFixedArrivalTime = data.defaultMarginOfFixedArrivalTime;
    entity.noteForOffice = data.noteForOffice;
    entity.client = client;
    entity.bannedDrivers = bannedDrivers;
    entity.defaultAssignedDriver = defaultAssignedDriver;
    entity.defaultAssignedDisposalSite = defaultAssignedDisposalSite;
    entity.defaultAssignableCarTypes = defaultAssignableCarTypes;
    entity.defaultAssignedCar = defaultAssignedCar;
    entity.createdBy = createdBy;
    entity.defaultCollectablePeriodTemplate = defaultCollectablePeriodTemplate;
  }
}
