import { MarginType, OrderDisposalSiteAssignmentType, DriverAssignmentType } from '~/framework/domain/typeAliases';
import { CandidateDate } from '~/framework/domain/reservation/reservation/candidateDate';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { IOrderAssignableDriver } from '~/framework/server-api/schedule/order/driver/assignableDriver';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';

export interface ReservationEntityData {
  id: PersistentId;
  candidateDates: CandidateDate[];
  note: Maybe<string>;
  sourceOrderNote: Maybe<string>;
  clientId: string;
  orderGroupId: string;
  generationSiteId: string;
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  // TODO: 処分場の入退場時間の後続リリースで削除
  assignedDisposalSiteIds: string[];
  orderAssignedDisposalSites: IOrderAssignedDisposalSite[];
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: IOrderAssignableDriver[];
  assignedCarId: Maybe<string>;
  assignableCarTypeIds: string[];
  carNum: number;
  minAssignedDriverNum: number;
  maxAssignedDriverNum: number;
  avoidHighways: boolean;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  isCancelled: boolean;
}

export class ReservationEntity implements ReservationEntity {
  readonly id: PersistentId;
  readonly persistentId: PersistentId;
  candidateDates: CandidateDate[];
  note: Maybe<string>;
  sourceOrderNote: Maybe<string>;
  clientId: string;
  orderGroupId: string;
  generationSiteId: string;
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  // TODO: 処分場の入退場時間の後続リリースで削除
  assignedDisposalSiteIds: string[];
  orderAssignedDisposalSites: IOrderAssignedDisposalSite[];
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: IOrderAssignableDriver[];
  assignedCarId: Maybe<string>;
  assignableCarTypeIds: string[];
  carNum: number;
  minAssignedDriverNum: number;
  maxAssignedDriverNum: number;
  avoidHighways: boolean;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  isCancelled: boolean;

  constructor(
    id: PersistentId,
    candidateDates: CandidateDate[],
    note: Maybe<string>,
    sourceOrderNote: Maybe<string>,
    clientId: string,
    orderGroupId: string,
    generationSiteId: string,
    durationAtGenerationSite: number,
    routeCollectionAllowed: boolean,
    // TODO: 処分場の入退場時間の後続リリースで削除
    assignedDisposalSiteIds: string[],
    orderAssignedDisposalSites: IOrderAssignedDisposalSite[],
    disposalSiteAssignmentType: OrderDisposalSiteAssignmentType,
    driverAssignmentType: DriverAssignmentType,
    assignableDrivers: IOrderAssignableDriver[],
    assignedCarId: Maybe<string>,
    assignableCarTypeIds: string[],
    carNum: number,
    minAssignedDriverNum: number,
    maxAssignedDriverNum: number,
    avoidHighways: boolean,
    isFixedArrivalTimeReportNeeded: boolean,
    marginTypeOfFixedArrivalTime: MarginType,
    marginOfFixedArrivalTime: number,
    isCancelled: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.candidateDates = candidateDates;
    this.note = note;
    this.sourceOrderNote = sourceOrderNote;
    this.clientId = clientId;
    this.orderGroupId = orderGroupId;
    this.generationSiteId = generationSiteId;
    this.durationAtGenerationSite = durationAtGenerationSite;
    this.routeCollectionAllowed = routeCollectionAllowed;
    // TODO: 処分場の入退場時間の後続リリースで削除
    this.assignedDisposalSiteIds = assignedDisposalSiteIds;
    this.orderAssignedDisposalSites = orderAssignedDisposalSites;
    this.disposalSiteAssignmentType = disposalSiteAssignmentType;
    this.driverAssignmentType = driverAssignmentType;
    this.assignableDrivers = assignableDrivers;
    this.assignedCarId = assignedCarId;
    this.assignableCarTypeIds = assignableCarTypeIds;
    this.carNum = carNum;
    this.minAssignedDriverNum = minAssignedDriverNum;
    this.maxAssignedDriverNum = maxAssignedDriverNum;
    this.avoidHighways = avoidHighways;
    this.isFixedArrivalTimeReportNeeded = isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = marginTypeOfFixedArrivalTime;
    this.marginOfFixedArrivalTime = marginOfFixedArrivalTime;
    this.isCancelled = isCancelled;
  }
}
