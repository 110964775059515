import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { orderGroup$getAllSymbol } from '~/framework/server-api/masters/orderGroup';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';

export const orderGroupSymbol = Symbol('orderGroup');

export class OrderGroupApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async getAll(): Promise<OrderGroupEntity[]> {
    const getAllApi = this.serverApis.get(orderGroup$getAllSymbol);
    const mapper: OrderGroupMapper = new OrderGroupMapper(this.store.masters.orderGroup);
    const result = await getAllApi.getAll();
    const entities = mapper.map(result);
    return entities;
  }
}
