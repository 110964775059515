import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedCarTypeEntity,
  AggregatedCarTypeEntityData,
} from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { AggregatedCarTypeContainerTypeMapper } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeMapper';

export class AggregatedCarTypeMapper extends StoredMapperBase<AggregatedCarTypeEntityData, AggregatedCarTypeEntity> {
  protected store: AggregatedCarTypeStore;
  private orderGroupMapper: OrderGroupMapper;
  private carTypeContainerTypeMapper: AggregatedCarTypeContainerTypeMapper;

  constructor(
    store: AggregatedCarTypeStore,
    orderGroupStore: OrderGroupStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore
  ) {
    super();
    this.store = store;
    this.orderGroupMapper = new OrderGroupMapper(orderGroupStore);
    this.carTypeContainerTypeMapper = new AggregatedCarTypeContainerTypeMapper(carTypeContainerTypeStore);
  }

  protected instantiateWithData(data: AggregatedCarTypeEntityData): AggregatedCarTypeEntity {
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const loadableContainerTypes = this.carTypeContainerTypeMapper.map(data.loadableContainerTypes);
    return new AggregatedCarTypeEntity(
      data.id,
      data.name,
      data.drivingSpeedCoefficient,
      data.durationOfBoarding,
      data.durationOfAlighting,
      orderGroup,
      loadableContainerTypes
    );
  }

  protected updateWithData(data: AggregatedCarTypeEntityData, entity: AggregatedCarTypeEntity): void {
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const loadableContainerTypes = this.carTypeContainerTypeMapper.map(data.loadableContainerTypes);
    entity.name = data.name;
    entity.drivingSpeedCoefficient = data.drivingSpeedCoefficient;
    entity.durationOfBoarding = data.durationOfBoarding;
    entity.durationOfAlighting = data.durationOfAlighting;
    entity.orderGroupId = data.orderGroup.id;
    entity.orderGroup = orderGroup;
    entity.loadableContainerTypes = loadableContainerTypes;
  }
}
