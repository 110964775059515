
import Vue, { PropType } from 'vue';
import {
  isValidFormatHhMm,
  secsToHhMm,
  strToHHMMorBlank,
  getHoursOf,
  getTimeRangeName,
} from '~/framework/services/date-time/date-time';
import { required } from '~/framework/view-models/rules';
import { HardLimitTime } from '~/framework/constants';
import { CssStyles, ValidationRule } from '~/framework/typeAliases';

const selectableItem = [...Array(getHoursOf(HardLimitTime)).keys()]
  .map((hh) => {
    const mm = ['00', '30'];
    return mm.map((mm) => {
      return {
        text: `${getTimeRangeName(hh)} ${hh}:${mm}`,
        value: `${hh.toString().padStart(2, '0')}:${mm}`,
      };
    });
  })
  .flat(2);

export default Vue.extend({
  props: {
    // v-model したときに受け取るのはこの値
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    outputMode: {
      type: String as PropType<'secs' | 'hhmm'>,
      default: 'hhmm',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    width: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    hideDetails: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectableItem,
      isShowMousePicker: false,
      mousePickerHeight: 300,
      mousePickerWidth: 100,
      displayValue:
        typeof this.value === 'number'
          ? secsToHhMm(this.value)
          : typeof this.value === 'string'
          ? strToHHMMorBlank(this.value)
          : '',
    };
  },
  computed: {
    isValid(): boolean {
      // 必須ではない場合は値が入力されていなくても通す
      // 例：入力の片側のみでも大丈夫な場合等
      return this.displayValue !== '' || !this.required || this.disabled;
    },
    rules(): ValidationRule[] {
      // disabledの場合は必須ではない
      if (this.required && !this.disabled) {
        return [required];
      }
      return [];
    },
    pickerStyles(): CssStyles {
      return {
        ...(this.width ? { width: this.width } : {}),
      };
    },
  },
  watch: {
    // props経由で value が変更されたら補正して changeイベントを投げる
    value(value) {
      this.displayValue = this.convertValidValue(value);
    },
  },
  methods: {
    /**
     * 空文字かHH:MMかを確定してくれる
     * @param value
     */
    convertValidValue(value: string | number | null | undefined): string {
      if (value === null || value === undefined) return '';
      if (typeof value === 'number') return secsToHhMm(value);
      return strToHHMMorBlank(value);
    },
    emitChange() {
      if (this.displayValue === null || this.displayValue === undefined) {
        return;
      }

      // 必須でない場合は未入力状態での emit を許容する
      if (!(this.isValid || this.required)) {
        this.$emit('change', undefined);
        return;
      }

      if (this.outputMode === 'hhmm') {
        if (isValidFormatHhMm(this.displayValue)) this.$emit('change', this.displayValue);
        return;
      }
      if (this.outputMode === 'secs') {
        this.$emit('change', this.displayValue.toSecs());
      }
    },
  },
});
