import { StoredMapperBase } from '~/framework/core/mapper';
import { AttendanceEntity, AttendanceEntityData } from '~/framework/domain/masters/attendance/attendanceEntity';
import { IAttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';

export class AttendanceMapper extends StoredMapperBase<AttendanceEntityData, AttendanceEntity> {
  protected store: IAttendanceStore;

  constructor(store: IAttendanceStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: AttendanceEntityData): AttendanceEntity {
    return new AttendanceEntity(data.id, data.date);
  }

  protected updateWithData(data: AttendanceEntityData, entity: AttendanceEntity): void {
    entity.date = data.date;
  }
}
