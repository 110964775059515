import { INamespace, NamespaceBase } from '~/framework/core/namespace';
import { IAggregatedBEntity } from '~/framework/domain/schedule/b/aggregatedBEntity';

export interface IAggregatedBStore extends INamespace<IAggregatedBEntity> {
  //
}

export class AggregatedBStore extends NamespaceBase<IAggregatedBEntity> implements IAggregatedBStore {
  //
}
