import { OrderGroupColor } from '~/framework/domain/typeAliases';

export interface OrderGroupEntityData {
  id: string;
  name: string;
  colorName: OrderGroupColor;
}

export class OrderGroupEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  colorName: OrderGroupColor;

  constructor(id: string, name: string, colorName: OrderGroupColor) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.colorName = colorName;
  }
}
