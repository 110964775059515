import _ from 'lodash';
import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { IDriverAttendanceData } from '~/framework/server-api/masters/driverAttendance';
import { car$getAllSymbol, ICarData } from '~/framework/server-api/masters/car';
import { carType$getAllSymbol, ICarTypeData } from '~/framework/server-api/masters/carType';
import {
  driverAttendanceTemplate$getAllSymbol,
  IDriverAttendanceTemplateData,
} from '~/framework/server-api/masters/driverAttendanceTemplate';
import { attendance$getByIdsSymbol } from '~/framework/server-api/masters/attendance';
import { driver$getByIdsSymbol, IDriverData } from '~/framework/server-api/masters/driver';
import { containerType$getAllSymbol, IContainerTypeData } from '~/framework/server-api/masters/containerType';
import { AggregatedDriverAttendanceMapper } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceMapper';
import { mapData } from '~/framework/core/mapper';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';

import { Maybe } from '~/framework/typeAliases';

export const driverAttendanceSymbol = Symbol('driverAttendance');

/**
 * ビルドするのに必要なデータ
 * 外側で既に取得していて使い回せるのであれば使う
 */
export interface IRequiredResources {
  driverData: IDriverData[];
  carData: ICarData[];
  carTypeData: ICarTypeData[];
  driverAttendanceTemplateData: IDriverAttendanceTemplateData[];
  containerTypesData: IContainerTypeData[];
}

export interface IDriverAttendanceFactory {
  [driverAttendanceSymbol]: void;
  buildByData(
    data: IDriverAttendanceData[],
    prefetchedData?: IRequiredResources
  ): Promise<AggregatedDriverAttendanceEntity[]>;
}

export class DriverAttendanceFactory implements IDriverAttendanceFactory {
  [driverAttendanceSymbol] = undefined;

  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async buildByData(
    driverAttendanceData: IDriverAttendanceData[],
    prefetchedData: Maybe<IRequiredResources> = undefined
  ): Promise<AggregatedDriverAttendanceEntity[]> {
    const car$getAllApi = this.serverApis.get(car$getAllSymbol);
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const driverAttendanceTemplate$getAllApi = this.serverApis.get(driverAttendanceTemplate$getAllSymbol);
    const attendance$getByIdsApi = this.serverApis.get(attendance$getByIdsSymbol);
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const driverAttendanceMapper = new AggregatedDriverAttendanceMapper(
      this.store.masters.aggregatedDriverAttendance,
      this.store.masters.attendance,
      this.store.masters.aggregatedDriver,
      this.store.masters.aggregatedCar,
      this.store.masters.driverAttendanceTemplate,
      this.store.masters.aggregatedCarType,
      this.store.masters.orderGroup,
      this.store.masters.aggregatedCarTypeContainerType,
      this.store.masters.aggregatedBaseSite,
      this.store.masters.user
    );

    const driverIds = _.uniq(driverAttendanceData.map((driverAttendance) => driverAttendance.driverId));
    const [driverData, carData, carTypeData, driverAttendanceTemplateData, containerTypesData] = prefetchedData
      ? [
          prefetchedData.driverData,
          prefetchedData.carData,
          prefetchedData.carTypeData,
          prefetchedData.driverAttendanceTemplateData,
          prefetchedData.containerTypesData,
        ]
      : await Promise.all([
          driver$getByIdsApi.getByIds(driverIds),
          car$getAllApi.getAll(),
          carType$getAllApi.getAll(),
          driverAttendanceTemplate$getAllApi.getAll(),
          containerType$getAllApi.getAll(),
        ]);
    const attendanceIds = _.uniq(driverAttendanceData.map((driverAttendance) => driverAttendance.attendanceId));
    const [attendanceData] = await Promise.all([attendance$getByIdsApi.getByIds(attendanceIds)]);
    const attendanceDataMap = mapData(attendanceData, 'id');
    const carTypeDataMap = mapData(
      carTypeData.map((carType) => {
        return {
          ...carType,
          orderGroupId: carType.orderGroup.id,
        };
      }),
      'id'
    );

    const containerTypeDataMap = mapData(containerTypesData, 'id');
    const carsEntityData = carData.map((car) => {
      const carTypeEntityData = {
        ...car.carType,
        orderGroupId: car.carType.orderGroup.id,
        loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      return { ...car, carType: carTypeEntityData };
    });
    const carEntityDataMap = mapData(carsEntityData, 'id');
    const driverAttendanceTemplateDataMap = mapData(driverAttendanceTemplateData, 'id');
    const driverDataMap = mapData(
      driverData.map((driver) => {
        return {
          ...driver,
          defaultPrimaryCar: driver.defaultPrimaryCarId?.map(carEntityDataMap),
          licensedCarTypes: driver.licensedCarTypeIds.mapValues(carTypeDataMap),
          defaultAttendanceTemplate: driver.defaultAttendanceTemplateId?.map(driverAttendanceTemplateDataMap),
        };
      }),
      'id'
    );
    return driverAttendanceMapper.map(
      driverAttendanceData.map((driverAttendance) => {
        return {
          ...driverAttendance,
          attendance: attendanceDataMap.getOrError(driverAttendance.attendanceId),
          driver: driverDataMap.getOrError(driverAttendance.driverId),
          primaryCar: carEntityDataMap.getOrError(driverAttendance.primaryCarId),
        };
      })
    );
  }
}
