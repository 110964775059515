import { InconsistencyEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistencyEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { IInconsistency as IErrorInconsistency } from '~/components/pages/schedule/r-schedule-errors/inconsistency';
import { InconsistencyReasons } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { Maybe } from '~/framework/typeAliases';
import { AggregatedOrderEntity as IOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';

export interface IInconsistency extends InconsistencyEntity, IErrorInconsistency {
  /**
   * 最適化から返ってきた時の配列の中での順番
   */
  index: number;
  /**
   * クライアントサイドでひとまず解決したものとして扱うかどうか
   */
  deemedAsSolved: boolean;
}

export class Inconsistency extends InconsistencyEntity implements IInconsistency {
  index: number;
  deemedAsSolved: boolean;
  driver: DriverEntity;
  orders: Maybe<IOrderEntity[]>;

  constructor(
    id: string,
    index: number,
    deemedAsSolved: boolean,
    driverId: PseudoId,
    indexes: number[],
    reason: InconsistencyReasons,
    driver: DriverEntity,
    orders: Maybe<IOrderEntity[]>
  ) {
    super(id, driverId, indexes, reason);
    this.deemedAsSolved = deemedAsSolved;
    this.index = index;
    this.driver = driver;
    this.orders = orders;
  }
}
