
import Vue from 'vue';
import { Context } from '@nuxt/types';
import _ from 'lodash';
import { isBefore, isSameDay, startOfToday } from 'date-fns';
import {
  ClientsByKeywordsCondition,
  ClientsByKeywordsOrder,
  GenerationSitesByKeywordsCondition,
  GenerationSitesByKeywordsOrder,
} from '~/framework/server-api/typeAliases';
import {
  SiteType,
  DriverType,
  OrderDisposalSiteAssignmentType,
  DriverAssignmentType,
  OrderStatus,
  PreloadStatus,
  EmploymentStatus,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import { ExtractVue, Maybe, PersistentId } from '~/framework/typeAliases';
import { AggregatedDisposalSiteEntity as IDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';

import { mapEntity } from '~/framework/core/mapper';
import { GenerationSiteTaskCategory } from '~/framework/view-models/generationSiteTaskCategory';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import {
  IReservationFormPanelOption,
  IFormValues,
  getInitialFormValuesByReservation,
  ICloseReservationFormArgs,
  IOpenReservationFormArgs,
} from '~/framework/view-models/panels/reservationFormPanel';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { IOrderValidation, OrderValidation } from '~/framework/view-models/orderValidation';
import { Client } from '~/components/common/r-lazy-searchable-pulldown/client';
import { GenerationSite } from '~/components/common/r-lazy-searchable-pulldown/generationSite';
import { arrayFromMaybe } from '~/framework/array';
import { Features } from '~/framework/featureManager';
import { dateToMd, dateToYyMdDaysOfWeek, formatDateForField } from '~/framework/services/date/date';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { WasteTypeEntity } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity as ICarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { generationSiteSymbol } from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import {
  IOrderApplicationService,
  IValidateOrderData,
  orderSymbol,
} from '~/framework/application/schedule/order/orderApplicationService';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { getHoursAndMinutesOf } from '~/framework/services/date-time/date-time';
import { IOrderDefaultService, OrderDefaultService } from '~/framework/services/order-default/orderDefaultService';
import ROrderAcceptanceCheck from '~/components/panels/schedule/r-order-form/r-order-acceptance-check/ROrderAcceptanceCheck.vue';
import RDisposalSites from '~/components/panels/schedule/r-order-form/RDisposalSites.vue';
import DisabledReason from '~/components/panels/schedule/r-order-form/disabledReasonEnum';
import RTaskTypeSelect from '~/components/panels/schedule/r-order-form/RTaskTypeSelect.vue';
import RDriverAssignment from '~/components/panels/schedule/r-order-form/RDriverAssignment.vue';
import RGenerationSiteOptions from '~/components/panels/schedule/r-order-form/RGenerationSiteOptions.vue';
import { SearchOrdersConditions } from '~/pages/schedule/orders/searchOrders';
import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import LazySearchablePullDownVue from '~/components/common/r-lazy-searchable-pulldown/RLazySearchablePulldown.vue';
import { ensure, unwrap } from '~/framework/core/value';
import { OrderFormBase } from '~/framework/view-models/order-form-base/orderFormBase';
import {
  DesignatedTimePeriodOption,
  DistinctTimeOption,
  ICollectablePeriodTemplateOption,
} from '~/framework/view-models/collectablePeriodTemplateOption';
import { routeCollectionOptions, IRouteCollectionOption } from '~/framework/view-models/routeCollectionOption';
import { IOrderAcceptanceCheckData } from '~/framework/application/schedule/order/order-acceptance-check/orderAcceptanceCheckApplicationService';
import { ContainerTypeTaskDuration } from '~/components/panels/schedule/r-order-form/containerTypeTaskDuration';
import { AggregatedReservationEntity as IReservationEntity } from '~/framework/domain/reservation/reservation/aggregatedReservationEntity';
import { IOrderDefault } from '~/framework/server-api/schedule/order/order-default/orderDefault';
import { ICandidateDateCollectablePeriodItem } from '~/components/panels/reservation/r-reservation-from/candidateDateCollectablePeriod';
import RCandidateDateCollectablePeriod from '~/components/panels/reservation/r-reservation-from/RCandidateDateCollectablePeriod.vue';
import {
  ReservationApplicationService,
  reservationSymbol,
} from '~/framework/application/reservation/reservationApplicationService';
import {
  IOrderAssignableDriver,
  getAssignableDriverIdsByDriverType,
} from '~/framework/server-api/schedule/order/driver/assignableDriver';
import RScheduleCancelReservationConfirmDialog from '~/components/pages/schedule/r-schedule-order/RScheduleCancelReservationConfirmDialog.vue';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { sanitizeNaturalNumber } from '~/framework/view-models/ruleLogics';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  PageNames,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import {
  DriverApplicationService,
  driverSymbol,
} from '~/framework/application/masters/driver/driverApplicationService';
import { getOrderFormData } from '../../schedule/r-order-form/getOrderFromData';
import { driverAttendanceSymbol } from '~/framework/application/masters/driver-attendance/driverAttendanceApplicationService';
import { clientSymbol } from '~/framework/application/masters/client/clientApplicationService';
import { sanitizeHours, sanitizeMinutes } from '~/framework/view-models/ruleLogics';

type LazySearchablePullDownType = ExtractVue<typeof LazySearchablePullDownVue>;

export enum EnteredGenerationSiteNameState {
  Exist,
  New,
  Searching,
}

/**
 * 排出場からデフォルトの値を取ってくるフィールドがデフォルト値のままかどうか
 * デフォルト値そのままであれば true
 * ユーザーがデフォルト値以外に変更していれば false
 */
type DefaultFormValueStatuses = {
  assignableCarTypeIds: boolean;
  assignedCarId: boolean;
  driverAssignmentType: boolean;
  assignableDrivers: boolean;
  disposalSiteIds: boolean;
  avoidHighways: boolean;
  routeCollectionAllowed: boolean;
  collectablePeriodTemplate: boolean;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: boolean;
  marginOfFixedArrivalTime: boolean;
};

class ReservationForm extends OrderFormBase<IFormValues> {
  readonly title: string;
  initialFormValues!: IFormValues; // initializeFormValues によって設定される
  /**
   * v-model for v-form
   */
  isFormValid: boolean = false;

  // date-time
  readonly scheduleDate: Maybe<Date>;
  readonly collectablePeriodTemplateEntities: CollectablePeriodTemplateEntity[];
  readonly collectablePeriodTemplates: ICollectablePeriodTemplateOption[];
  readonly collectablePeriodTemplateMap: Map<PersistentId, ICollectablePeriodTemplateOption>;
  /**
   * 予約で指定されている複数候補日
   */
  candidateDateCollectablePeriodItems!: ICandidateDateCollectablePeriodItem[]; // initializeFormValues によって設定される
  /**
   * 複数候補日の中で選択されている日付の情報
   */
  selectedCandidateDateCollectablePeriodItem!: ICandidateDateCollectablePeriodItem; // initializeFormValues によって設定される

  readonly routeCollectionOptions: IRouteCollectionOption[];

  // entities
  readonly reservation: IReservationEntity;

  constructor(
    clientDefaultCondition: ClientsByKeywordsCondition,
    clientLoader: Client,
    generationSiteDefaultCondition: GenerationSitesByKeywordsCondition,
    generationSiteLoader: GenerationSite,
    client: Maybe<ClientEntity>,
    officeSetting: OfficeSettingEntity,
    generationSite: Maybe<GenerationSiteEntity>,
    orderGroups: OrderGroupEntity[],
    disposalSites: IDisposalSiteEntity[],
    wasteTypes: WasteTypeEntity[],
    containerTypes: ContainerTypeEntity[],
    containerTypeTaskTypes: ContainerTypeTaskTypeEntity[],
    packingStyles: PackingStyleEntity[],
    collectablePeriodTemplates: CollectablePeriodTemplateEntity[],
    drivers: DriverEntity[],
    carTypes: AggregatedCarTypeEntity[],
    cars: ICarEntity[],
    assignableDriverAttendances: Maybe<DriverAttendanceEntity[]>,
    taskTypes: TaskTypeEntity[],
    reservation: IReservationEntity,
    orderValidation: IOrderValidation,
    initialFormValues?: Maybe<IFormValues>,
    title?: Maybe<string>,
    scheduleDate?: Maybe<Date>
  ) {
    super(
      clientDefaultCondition,
      clientLoader,
      generationSiteDefaultCondition,
      generationSiteLoader,
      officeSetting,
      orderGroups,
      disposalSites,
      wasteTypes,
      containerTypes,
      containerTypeTaskTypes,
      packingStyles,
      drivers,
      carTypes,
      cars,
      assignableDriverAttendances,
      orderValidation,
      taskTypes
    );

    // Form general
    this.reservation = reservation;
    this.title = title ?? '受注の確定';

    this.routeCollectionOptions = routeCollectionOptions;

    // Master data
    this.collectablePeriodTemplateEntities = collectablePeriodTemplates;
    this.collectablePeriodTemplates = [DistinctTimeOption, DesignatedTimePeriodOption, ...collectablePeriodTemplates];
    this.collectablePeriodTemplateMap = mapEntity(this.collectablePeriodTemplates);

    // date-time
    this.scheduleDate = scheduleDate;

    this.initializeFormValues(initialFormValues ?? this.getInitialFormValues(), client, generationSite);
  }

  setFormValuesWithOrderDefaults(orderDefaultValues: IOrderDefault): void {
    const formValues = this.getFormValues();
    const orderDefaultService: IOrderDefaultService = new OrderDefaultService(orderDefaultValues);

    const defaultRouteCollectionAllowed = orderDefaultService.defaultRouteCollectionAllowed();
    const defaultIsFixedArrivalTimeReportNeeded = orderDefaultService.defaultIsFixedArrivalTimeReportNeeded();
    const defaultMarginTypeOfFixedArrivalTime = orderDefaultService.defaultMarginTypeOfFixedArrivalTime();
    const defaultMarginOfFixedArrivalTime = orderDefaultService.defaultMarginOfFixedArrivalTime();

    orderDefaultService.updateReservationFormValuesWithDefaults(
      formValues,
      defaultRouteCollectionAllowed,
      defaultIsFixedArrivalTimeReportNeeded,
      defaultMarginTypeOfFixedArrivalTime,
      defaultMarginOfFixedArrivalTime
    );

    this.routeCollectionAllowed = formValues.routeCollectionAllowed;
    this.isFixedArrivalTimeReportNeeded = formValues.isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = formValues.marginTypeOfFixedArrivalTime;
    const [marginOfFixedArrivalTimeHours, marginOfFixedArrivalTimeMinutes] = getHoursAndMinutesOf(
      formValues.marginOfFixedArrivalTime
    );
    this.marginOfFixedArrivalTimeHours = marginOfFixedArrivalTimeHours;
    this.marginOfFixedArrivalTimeMinutes = marginOfFixedArrivalTimeMinutes;
  }

  get isDirty(): boolean {
    const isIrregularTaskDirty =
      this.irregularTask.name !== this.initialFormValues.irregularTask.name ||
      this.irregularTask.skipDisposalSite !== this.initialFormValues.irregularTask.skipDisposalSite ||
      this.irregularTask.durationAtGenerationSite !== this.initialFormValues.irregularTask.durationAtGenerationSite ||
      this.irregularTask.hoursAtGenerationSite !== this.initialFormValues.irregularTask.hoursAtGenerationSite ||
      this.irregularTask.minutesAtGenerationSite !== this.initialFormValues.irregularTask.minutesAtGenerationSite ||
      this.irregularTask.durationAtDisposalSite !== this.initialFormValues.irregularTask.durationAtDisposalSite ||
      this.irregularTask.hoursAtDisposalSite !== this.initialFormValues.irregularTask.hoursAtDisposalSite ||
      this.irregularTask.minutesAtDisposalSite !== this.initialFormValues.irregularTask.minutesAtDisposalSite;

    const isGenerationSiteTaskDirty =
      this.generationSiteTaskItems.length !== this.initialFormValues.generationSiteTasks.length ||
      this.generationSiteTaskItems.some((item, index) => {
        const initialItem = this.initialFormValues.generationSiteTasks[index];
        return (
          item.taskType !== initialItem.taskType ||
          item.wasteTypeId !== initialItem.wasteTypeId ||
          item.containerTypeId !== initialItem.containerTypeId ||
          item.containerNum !== initialItem.containerNum
        );
      });

    const isDurationAtGenerationSiteDirty =
      (this.generationSiteTaskCategory === GenerationSiteTaskCategory.TaskWithContainer &&
        this.generationSiteTaskDuration.durationAtGenerationSite !== this.initialFormValues.durationAtGenerationSite) ||
      (this.generationSiteTaskCategory === GenerationSiteTaskCategory.Irregular &&
        this.irregularTask.durationAtGenerationSite !== this.initialFormValues.durationAtGenerationSite);

    const isSelectedCandidateDateCollectablePeriodDirty =
      !isSameDay(
        this.selectedCandidateDateCollectablePeriodItem.getDate(),
        this.initialFormValues.selectedCandidateDateCollectablePeriodItem.getDate()
      ) ||
      this.selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName !==
        this.initialFormValues.selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName ||
      this.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime() !==
        this.initialFormValues.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime() ||
      this.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime() !==
        this.initialFormValues.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime();

    const dirtyFlags = new Map<string, boolean>();
    dirtyFlags.set('orderGroupId', this.orderGroupId !== this.initialFormValues.orderGroupId);
    dirtyFlags.set('selectedCandidateDateCollectableItem', isSelectedCandidateDateCollectablePeriodDirty);
    dirtyFlags.set('clientId', this.clientId !== this.initialFormValues.clientId);
    dirtyFlags.set('generationSiteId', this.generationSiteId !== this.initialFormValues.generationSiteId);
    dirtyFlags.set(
      'generationSiteTaskCategory',
      this.generationSiteTaskCategory !== this.initialFormValues.generationSiteTaskCategory
    );
    dirtyFlags.set('generationSiteTask', isGenerationSiteTaskDirty);
    dirtyFlags.set('irregularTask', isIrregularTaskDirty);
    dirtyFlags.set(
      'assignedDisposalSiteIds',
      _.isEqual(_.sortBy(this.disposalSiteIds), _.sortBy(this.initialFormValues.disposalSiteIds)) === false
    );
    dirtyFlags.set(
      'disposalSiteAssignmentType',
      this.disposalSiteAssignmentType !== this.initialFormValues.disposalSiteAssignmentType
    );

    const sortedOrderAssignedDisposalSites = _.sortBy(
      this.orderAssignedDisposalSites,
      (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId
    );

    const sortedInitialOrderAssignedDisposalSites = _.sortBy(
      this.initialFormValues.orderAssignedDisposalSites,
      (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId
    );

    const isOrderAssignedDisposalSitesDirty =
      sortedOrderAssignedDisposalSites.length === sortedInitialOrderAssignedDisposalSites.length
        ? sortedOrderAssignedDisposalSites.some((orderAssignedDisposalSite, index) => {
            return (
              orderAssignedDisposalSite.disposalSiteId !==
                sortedInitialOrderAssignedDisposalSites[index].disposalSiteId ||
              orderAssignedDisposalSite.durationAtEntrance !==
                sortedInitialOrderAssignedDisposalSites[index].durationAtEntrance
            );
          })
        : true;

    dirtyFlags.set('orderAssignedDisposalSites', isOrderAssignedDisposalSitesDirty);
    dirtyFlags.set('durationAtGenerationSite', isDurationAtGenerationSiteDirty);
    dirtyFlags.set('note', this.note !== this.initialFormValues.orderNote);
    dirtyFlags.set('driverNum', this.driverNum !== this.initialFormValues.driverNum);
    dirtyFlags.set('carNum', this.carNum !== this.initialFormValues.carNum);
    dirtyFlags.set('avoidHighways', this.avoidHighways !== this.initialFormValues.avoidHighways);
    dirtyFlags.set('driverAssignmentType', this.driverAssignmentType !== this.initialFormValues.driverAssignmentType);

    dirtyFlags.set(
      'isAssignableDriversCandidate',
      this.isAssignableDriversCandidate !== this.initialFormValues.isAssignableDriversCandidate
    );

    // NOTE: AssignableDrivers はそれぞれ Driver / Operator / Helper になり得るため、同じ乗務員でも編集されている可能性があるためそれぞれ分けてチェックしている
    const initialDrivers = _.sortBy(
      getAssignableDriverIdsByDriverType(DriverType.Driver, this.initialFormValues.assignableDrivers)
    );
    const initialOperators = _.sortBy(
      getAssignableDriverIdsByDriverType(DriverType.Operator, this.initialFormValues.assignableDrivers)
    );
    const initialHelpers = _.sortBy(
      getAssignableDriverIdsByDriverType(DriverType.Helper, this.initialFormValues.assignableDrivers)
    );

    const drivers = _.sortBy(getAssignableDriverIdsByDriverType(DriverType.Driver, this.assignableDrivers));
    const operators = _.sortBy(getAssignableDriverIdsByDriverType(DriverType.Operator, this.assignableDrivers));
    const helpers = _.sortBy(getAssignableDriverIdsByDriverType(DriverType.Helper, this.assignableDrivers));

    const isDriversDirty = _.isEqual(drivers, initialDrivers) === false;
    const isOperatorsDirty = _.isEqual(operators, initialOperators) === false;
    const isHelpersDirty = _.isEqual(helpers, initialHelpers) === false;

    dirtyFlags.set('assignableDrivers', isDriversDirty || isOperatorsDirty || isHelpersDirty);
    dirtyFlags.set(
      'assignableCarTypeIds',
      _.isEqual(_.sortBy(this.assignableCarTypeIds), _.sortBy(this.initialFormValues.assignableCarTypeIds)) === false
    );
    dirtyFlags.set('assignedCarId', this.assignedCarId !== this.initialFormValues.assignedCarId);
    dirtyFlags.set(
      'routeCollectionAllowed',
      this.routeCollectionAllowed !== this.initialFormValues.routeCollectionAllowed
    );
    dirtyFlags.set(
      'isFixedArrivalTimeReportNeeded',
      this.isFixedArrivalTimeReportNeeded !== this.initialFormValues.isFixedArrivalTimeReportNeeded
    );
    dirtyFlags.set(
      'marginTypeOfFixedArrivalTime',
      this.marginTypeOfFixedArrivalTime !== this.initialFormValues.marginTypeOfFixedArrivalTime
    );
    dirtyFlags.set(
      'marginOfFixedArrivalTime',
      this.marginOfFixedArrivalTime !== this.initialFormValues.marginOfFixedArrivalTime
    );

    const isDirty = Array.from(dirtyFlags.values()).some((value) => value);
    return isDirty;
  }

  getCollectablePeriodTemplateNameFromId(collectablePeriodTemplateId: Maybe<string>): Maybe<string> {
    if (collectablePeriodTemplateId === undefined) return undefined;
    return this.collectablePeriodTemplateMap.get(collectablePeriodTemplateId)?.name;
  }

  onChangeSelectedCandidateDateCollectablePeriodItem(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  onChangeCollectablePeriodTemplate(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  onChangeCollectableDistinctTime(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  onChangeCollectablePeriodStart(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  onChangeCollectablePeriodEnd(): void {
    this.orderValidation.resetErrorByField('collectablePeriod');
  }

  getValidateOrderData(): IValidateOrderData {
    const selectedCandidateDateCollectablePeriodItem = this.selectedCandidateDateCollectablePeriodItem;

    const collectablePeriodStart = selectedCandidateDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime();
    const collectablePeriodEnd = selectedCandidateDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime();

    const durationAtGenerationSite = this.getDurationAtGenerationSite();
    const generationSiteTasks = this.getCreateGenerationSiteTaskInput();
    const irregularTasks = this.getCreateIrregularTaskInput();

    const validateOrderData: IValidateOrderData = {
      date: selectedCandidateDateCollectablePeriodItem.getDate(),
      // 単数の入力をfixedに入れる
      plan: {
        fixed: {
          date: selectedCandidateDateCollectablePeriodItem.getDate(),
          collectablePeriodTemplateName: selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName,
          collectablePeriodStart,
          collectablePeriodEnd,
          unloadDate: selectedCandidateDateCollectablePeriodItem.unloadDate,
        },
      },
      collectablePeriodTemplateName: selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName,
      collectablePeriodStart,
      collectablePeriodEnd,
      unloadDate: selectedCandidateDateCollectablePeriodItem.unloadDate,
      orderGroupId: this.orderGroupId!,
      generationSiteId: this.generationSiteId!,
      durationAtGenerationSite: durationAtGenerationSite!,
      routeCollectionAllowed: this.routeCollectionAllowed,
      preloadStatus: PreloadStatus.NotAllowed,
      // TODO: 処分場の入退場時間の後続リリースで削除
      assignedDisposalSiteIds: this.disposalSiteIds!,
      // TODO: 処分場の入退場時間の後続リリースで削除
      disposalSiteAssignmentType: this.disposalSiteAssignmentType!,
      assignedDisposalSitesAndType: {
        orderDisposalSites: this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
          return {
            disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
            durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
            priority: orderAssignedDisposalSite.priority,
          };
        }),
        disposalSiteAssignmentType: this.disposalSiteAssignmentType,
      },
      assignableDriversAndNum: {
        driverAssignmentType: this.driverAssignmentType,
        assignableDrivers: this.assignableDrivers,
        minAssignedDriverNum: this.driverNum,
        maxAssignedDriverNum: this.driverNum,
      },
      assignedCarId: this.assignedCarId,
      assignableCarTypeIds: this.assignableCarTypeIds,
      assignedBaseSiteId: undefined,
      minAssignedCarNum: this.carNum,
      maxAssignedCarNum: this.carNum,
      carNum: this.carNum,
      note: this.note,
      noteForAssignedDriver: '',
      avoidHighways: this.avoidHighways,
      fixedArrivalTime: this.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: this.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: this.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: this.marginOfFixedArrivalTime,
      routingGroup: undefined,
      fixedDisplayOnReservation: this.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: this.fixedDisplayOnReservationName,
      schedulingPriority: this.schedulingPriority,
      generationSiteTasks,
      irregularTasks,
      recurringSettings: undefined,
      status: OrderStatus.Active,
    };
    return validateOrderData;
  }

  getOrderAcceptanceCheckData(): IOrderAcceptanceCheckData {
    const selectedCandidateDateCollectablePeriodItem = this.selectedCandidateDateCollectablePeriodItem;
    const collectablePeriodStart = selectedCandidateDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime();
    const collectablePeriodEnd = selectedCandidateDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime();

    const durationAtGenerationSite = this.getDurationAtGenerationSite();
    const generationSiteTasks = this.getCreateGenerationSiteTaskInput();
    const irregularTasks = this.getCreateIrregularTaskInput();

    const orderAcceptanceCheckData: IOrderAcceptanceCheckData = {
      // 予約の時点では受注が作られていないので、こちらは必ず undefined になる
      id: undefined,
      date: selectedCandidateDateCollectablePeriodItem.getDate(),
      // planの中身はoneOfでfixedかcandidateDatesのどちらかになる、ウェブ依頼はfixedのみ
      plan: {
        fixed: {
          date: selectedCandidateDateCollectablePeriodItem.getDate(),
          collectablePeriodTemplateName: selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName,
          collectablePeriodStart,
          collectablePeriodEnd,
          unloadDate: selectedCandidateDateCollectablePeriodItem.unloadDate,
        },
      },
      collectablePeriodTemplateName: selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName,
      collectablePeriodStart,
      collectablePeriodEnd,
      unloadDate: selectedCandidateDateCollectablePeriodItem.unloadDate,
      clientId: this.clientId!,
      orderGroupId: this.orderGroupId!,
      generationSiteId: this.generationSiteId!,
      durationAtGenerationSite: durationAtGenerationSite!,
      routeCollectionAllowed: this.routeCollectionAllowed,
      preloadStatus: PreloadStatus.NotAllowed,
      // TODO: 処分場の入退場時間の後続リリースで削除
      assignedDisposalSiteIds: this.disposalSiteIds!,
      // TODO: 処分場の入退場時間の後続リリースで削除
      disposalSiteAssignmentType: this.disposalSiteAssignmentType!,
      assignedDisposalSitesAndType: {
        orderDisposalSites: this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
          return {
            disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
            durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
            priority: orderAssignedDisposalSite.priority,
          };
        }),
        disposalSiteAssignmentType: this.disposalSiteAssignmentType,
      },
      assignableDriversAndNum: {
        driverAssignmentType: this.driverAssignmentType,
        assignableDrivers: this.assignableDrivers,
        minAssignedDriverNum: this.driverNum,
        maxAssignedDriverNum: this.driverNum,
      },
      assignedCarId: this.assignedCarId,
      assignedBaseSiteId: undefined,
      assignableCarTypeIds: this.assignableCarTypeIds,
      minAssignedCarNum: this.carNum,
      maxAssignedCarNum: this.carNum,
      carNum: this.carNum,
      note: this.note,
      noteForAssignedDriver: '',
      avoidHighways: this.avoidHighways,
      fixedArrivalTime: this.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: this.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: this.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: this.marginOfFixedArrivalTime,
      routingGroup: undefined,
      fixedDisplayOnReservation: this.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: this.fixedDisplayOnReservationName,
      schedulingPriority: this.schedulingPriority,
      generationSiteTasks,
      irregularTasks,
      includeFollowingRecurringOrders: undefined,
      status: OrderStatus.Active,
      reservationId: this.reservation.persistentId,
    };
    return orderAcceptanceCheckData;
  }

  getFormValues(): IFormValues {
    return {
      reservationId: this.reservation.persistentId,
      reservationNote: this.reservation.note,
      orderGroupId: this.orderGroupId,
      candidateDateCollectablePeriodItems: this.candidateDateCollectablePeriodItems,
      selectedCandidateDateCollectablePeriodItem: this.selectedCandidateDateCollectablePeriodItem,
      clientId: this.clientId,
      generationSiteId: this.generationSiteId,
      generationSiteTaskCategory: this.generationSiteTaskCategory,
      irregularTask: this.irregularTask.clone(),
      carNum: this.carNum,
      disposalSiteIds: _.clone(this.disposalSiteIds),
      disposalSiteAssignmentType: this.disposalSiteAssignmentType,
      orderAssignedDisposalSites: this.orderAssignedDisposalSites,
      generationSiteTasks: this.generationSiteTaskItems.map((item) => item.clone()),
      durationAtGenerationSite: this.getDurationAtGenerationSite(),
      orderNote: this.note,
      driverNum: this.driverNum,
      driverAssignmentType: this.driverAssignmentType,
      isAssignableDriversCandidate: this.isAssignableDriversCandidate,
      assignableDrivers: this.assignableDrivers,
      avoidHighways: this.avoidHighways,
      assignableCarTypeIds: _.clone(this.assignableCarTypeIds),
      assignedCarId: this.assignedCarId,
      routeCollectionAllowed: this.routeCollectionAllowed,
      fixedArrivalTime: this.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: this.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: this.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: this.marginOfFixedArrivalTime,
    };
  }

  initializeFormValues(
    formValues: IFormValues,
    client: Maybe<ClientEntity>,
    generationSite: Maybe<GenerationSiteEntity>
  ) {
    this.generationSiteTaskDuration = new ContainerTypeTaskDuration(this.containerTypes, this.containerTypeTaskTypes);
    this.selectedDisposalSites = [];
    this.orderAssignedDisposalSites = [];
    this.client = client;
    this.generationSite = generationSite;
    this.setFormValues(formValues);

    // フォームに値が設定されているにも関わらず preloaded でない場合は何かが間違っているので落としておく
    if (this.clientId !== undefined && client === undefined) {
      throw new Error(`clientId(${this.clientId}) is set, but pre-loaded client was not found!`);
    }
    if (this.generationSiteId !== undefined && generationSite === undefined) {
      throw new Error(
        `generationSiteId(${this.generationSiteId}) is set, but pre-loaded generationSite was not found!`
      );
    }

    // 会社や排出場が削除されていたらそれは空にする
    // ここで値をクリアする事が正しいのかという事について考えたが、Composite な Entity の値をクリアすると
    // dirty になる事になってしまい、いつコミットされるのか分からない中途半端なデータができあがる事になって
    // しまうので、そうなるくらいであればパネルの中でクリアした方がマシという理由でここでクリアする事にした。
    // この値が undefined になる事によってフォームが valid でなくなるが、バリデーションはフォームが valid
    // な時だけ走る様にしてあるので問題ない。
    if (this.clientId !== undefined && this.client !== undefined && this.client.isDeleted) {
      this.clientId = undefined;
      this.client = undefined;
    }
    if (this.generationSiteId !== undefined && this.generationSite !== undefined && this.generationSite.isDeleted) {
      this.generationSiteId = undefined;
      this.generationSite = undefined;
    }

    // 指定車種の orderGroupId と オーダーの orderGroupId が別だった場合、グループと車種が矛盾する事になるので
    // assignableCarTypeIds から違うものは消しておく
    this.assignableCarTypeIds = this.assignableCarTypeIds.filter((id) => {
      return this.carTypeMap.getOrError(id).orderGroupId === this.orderGroupId;
    });

    // 指定車番から推測した車種の orderGroupId とオーダーの orderGroupId が別だった場合、グループと車番が矛盾する事に
    // なるので assignedCarId は空にする
    if (
      this.assignedCarId !== undefined &&
      this.carTypeMap.getOrError(this.carMap.getOrError(this.assignedCarId).carType.id).orderGroupId !==
        this.orderGroupId
    ) {
      this.assignedCarId = undefined;
    }

    if (this.reservation.sourceOrderNote) {
      this.note =
        this.note && this.note.length !== 0
          ? this.note + '\n\n' + this.reservation.sourceOrderNote
          : this.reservation.sourceOrderNote;
    }

    // ここで on〜 を呼ぶのが気持ち悪い様な気もするが、変更したと言えばしたのでよしとする
    this.onClientChange(true);
    this.onChangeOrderGroup(true);
    this.onChangeGenerationSiteTaskCategory(true);
    this.onGenerationSiteTaskChange(true, this.generationSiteTaskItems);
    this.onChangeSkipDisposalSite(true);
    this.onChangeAssignedDisposalSite(true);
    this.onChangeAssignableCarTypes(true);
    this.onChangeAssignedCar(true);

    // NOTE1: ここで初期化が終わるので、フォームの初期値が固定される。今後のフォームに変化があるかどうかはこのタイミングでの値との比較となる。
    // そのために、今の状態で initialFormValue を設定する必要がある。
    // NOTE2: getFormValues の値をそのまま使うとオブジェクトの参照を持つことになり、入力が変わったときに initialFormValues の中身も
    // 変わってしまうため cloneDeep してから initialFormValues に持たせる。
    this.initialFormValues = _.cloneDeep(this.getFormValues());
  }

  private setFormValues(formValues: IFormValues): void {
    this.orderGroupId = formValues.orderGroupId;
    this.candidateDateCollectablePeriodItems = formValues.candidateDateCollectablePeriodItems;
    this.selectedCandidateDateCollectablePeriodItem = formValues.selectedCandidateDateCollectablePeriodItem;
    this.clientId = formValues.clientId;
    this.generationSiteId = formValues.generationSiteId;
    this.generationSiteTaskCategory = formValues.generationSiteTaskCategory;
    this.irregularTask = formValues.irregularTask.clone();
    this.carNum = formValues.carNum;
    this.disposalSiteIds = formValues.disposalSiteIds;
    this.disposalSiteAssignmentType = formValues.disposalSiteAssignmentType;
    this.generationSiteTaskItems = formValues.generationSiteTasks.map((item) => item.clone());
    this.generationSiteTaskDuration.setDurationAtGenerationSite(formValues.durationAtGenerationSite);
    this.note = formValues.orderNote;
    this.driverNum = formValues.driverNum;
    this.avoidHighways = formValues.avoidHighways;
    this.driverAssignmentType = formValues.driverAssignmentType;
    this.isAssignableDriversCandidate = formValues.isAssignableDriversCandidate;
    this.assignableDrivers = formValues.assignableDrivers;
    this.assignableCarTypeIds = formValues.assignableCarTypeIds;
    this.assignedCarId = formValues.assignedCarId;
    this.routeCollectionAllowed = formValues.routeCollectionAllowed;
    this.fixedArrivalTime = formValues.fixedArrivalTime;
    this.isFixedArrivalTimeReportNeeded = formValues.isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = formValues.marginTypeOfFixedArrivalTime;
    const [marginOfFixedArrivalTimeHours, marginOfFixedArrivalTimeMinutes] = getHoursAndMinutesOf(
      formValues.marginOfFixedArrivalTime
    );
    this.marginOfFixedArrivalTimeHours = marginOfFixedArrivalTimeHours;
    this.marginOfFixedArrivalTimeMinutes = marginOfFixedArrivalTimeMinutes;
  }

  private getInitialFormValues(): IFormValues {
    return getInitialFormValuesByReservation(this.reservation, {
      scheduleDate: this.scheduleDate,
      collectablePeriodTemplates: this.collectablePeriodTemplates,
    });
  }
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isCloseConfirmDialogActive: boolean;
  isDeleteConfirmDialogActive: boolean;
  isLessThanDurationOfTasksMessageActive: boolean;
  DisabledReason: typeof DisabledReason;
  EnteredGenerationSiteNameState: typeof EnteredGenerationSiteNameState;
  OrderStatus: typeof OrderStatus;
  GenerationSiteTaskCategory: typeof GenerationSiteTaskCategory;
  OrderSchedulingPriority: typeof OrderSchedulingPriority;
  masters: Maybe<Masters>;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  orderApplicationService: IOrderApplicationService;
  reservationApplicationService: ReservationApplicationService;
  driverApplicationService: DriverApplicationService;
  OrderDisposalSiteAssignmentType: typeof OrderDisposalSiteAssignmentType;
  isConfirmAcceptReservationDialogActive: boolean;
  confirmAcceptReservationDialogResolver: Maybe<(value: boolean) => void>;
  isCancelConfirmDialogActive: boolean;
};

type AsyncDataType = {
  viewModel: Maybe<ReservationForm>;
  isOrderAcceptanceCheckAvailable: boolean;
};

type Masters = {
  officeSetting: OfficeSettingEntity;
  client: Maybe<ClientEntity>;
  generationSite: Maybe<GenerationSiteEntity>;
  orderGroups: OrderGroupEntity[];
  disposalSites: IDisposalSiteEntity[];
  wasteTypes: WasteTypeEntity[];
  containerTypes: ContainerTypeEntity[];
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  collectablePeriodTemplates: CollectablePeriodTemplateEntity[];
  drivers: DriverEntity[];
  carTypes: AggregatedCarTypeEntity[];
  cars: ICarEntity[];
  assignableDriverAttendances: Maybe<DriverAttendanceEntity[]>;
  holidayRules: HolidayRuleEntity;
  packingStyles: PackingStyleEntity[];
  taskTypes: TaskTypeEntity[];
};

// 続けて登録する時に初期化する
type InitialMaster = {
  client: Maybe<ClientEntity>;
  generationSite: Maybe<GenerationSiteEntity>;
};

// フォームに必要なマスタをロードするために必要な値
interface IPreloadableFormObject {
  assignableDrivers: IOrderAssignableDriver[];
  date: Date;
  clientId: Maybe<PersistentId>;
  generationSiteId: Maybe<PersistentId>;
}

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RReservationForm',
  components: {
    ROrderAcceptanceCheck,
    RDisposalSites,
    RTaskTypeSelect,
    RDriverAssignment,
    RGenerationSiteOptions,
    RCandidateDateCollectablePeriod,
    RScheduleCancelReservationConfirmDialog,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const orderApplicationService = this.$context.applications.get(orderSymbol);
    const reservationApplicationService = this.$context.applications.get(reservationSymbol);
    const driverApplicationService = this.$context.applications.get(driverSymbol);
    return {
      isActive: false,
      isCloseConfirmDialogActive: false,
      isDeleteConfirmDialogActive: false,
      isLessThanDurationOfTasksMessageActive: false,
      viewModel: undefined as Maybe<ReservationForm>,
      DisabledReason,
      EnteredGenerationSiteNameState,
      OrderStatus,
      GenerationSiteTaskCategory,
      OrderSchedulingPriority,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      masters: undefined,
      isOrderAcceptanceCheckAvailable: false,
      orderApplicationService,
      reservationApplicationService,
      driverApplicationService,
      OrderDisposalSiteAssignmentType,
      isConfirmAcceptReservationDialogActive: false,
      confirmAcceptReservationDialogResolver: undefined,
      isCancelConfirmDialogActive: false,
    };
  },
  computed: {
    showRegisteredNotes(): boolean {
      if (!this.viewModel) return false;

      return !!(
        (this.viewModel.client && this.viewModel.client.note) ||
        (this.viewModel.generationSite && this.viewModel.generationSite.note) ||
        (this.viewModel.generationSite && this.viewModel.generationSite.noteForOffice) ||
        (this.viewModel.generationSite && this.viewModel.generationSite.attachments.length > 0) ||
        (this.viewModel.reservation.note && this.viewModel.reservation.note.length > 0) ||
        this.viewModel.selectedDisposalSites.some((disposalSite) => {
          return disposalSite.note !== undefined && disposalSite.note !== '';
        })
      );
    },
    isCollectablePeriodDisabled(): boolean {
      return this.viewModel?.orderGroupId === undefined;
    },
    isRegisterButtonDisabled(): boolean {
      if (!this.viewModel) return true;
      return this.isCancelled || this.viewModel.isRegisterButtonDisabled;
    },
    isCancelled(): boolean {
      if (!this.viewModel) return true;
      return this.viewModel.reservation.isCancelled;
    },
    // NOTE: 排出場作業で選択された荷姿を処分する作業合計時間
    // 全ての処分場に対して同じ作業時間とする
    disposalSiteTotalDurationOfTasks(): number {
      if (this.viewModel === undefined) return 0;
      if (this.masters === undefined) return 0;

      const masters = this.masters;

      // NOTE: 処分場作業の合計
      let totalDurationOfTask = 0;

      const containerTypeAndDurations: {
        containerTypeId: string;
        containerNum: number;
      }[] = [];

      // NOTE: 作業時間の合計を計算するために containerTypeId と containerNum を持つ array を作る
      this.viewModel.generationSiteTaskItems.forEach((item) => {
        if (item.containerTypeId === undefined || item.containerNum === undefined) {
          return;
        }

        // NOTE: 同じ containerid がすでに存在するか確認する
        const findIndex = containerTypeAndDurations.findIndex(
          (containerTypeAndDuration) => containerTypeAndDuration.containerTypeId === item.containerTypeId
        );

        // NOTE: 同じ containerId がない場合は追加する
        if (findIndex === -1) {
          containerTypeAndDurations.push({
            containerTypeId: item.containerTypeId,
            containerNum: item.containerNum,
          });

          return;
        }
        const containerNum = containerTypeAndDurations[findIndex].containerNum + item.containerNum;

        // NOTE: 同じ containerId がある場合は要素を追加せず、 containerNum を加算する
        containerTypeAndDurations[findIndex] = {
          containerTypeId: item.containerTypeId,
          containerNum,
        };
      });

      // NOTE: containerTypeId と taskType が '処分' である containerTypeTaskType を取得する
      // SiteType で判定すると今後処分場作業の種類が増えた場合に対応できないが、 '処分' 作業の taskTypeId を定数で埋め込む必要があるので暫定的に SiteType で判定する
      containerTypeAndDurations.forEach((containerTypeAndDuration) => {
        if (
          containerTypeAndDuration.containerTypeId === undefined ||
          containerTypeAndDuration.containerNum === undefined
        ) {
          return;
        }
        const containerTypeTaskType = masters.containerTypeTaskTypes.find(
          (containerTypeTaskType) =>
            containerTypeTaskType.containerTypeId === containerTypeAndDuration.containerTypeId &&
            containerTypeTaskType.taskType.baseTaskType.siteType === SiteType.DisposalSite
        );
        if (containerTypeTaskType === undefined) {
          return;
        }
        // NOTE: 数で時間増する場合は containerNum の個数だけ乗算する
        if (containerTypeTaskType.isProportionalToCount.value) {
          totalDurationOfTask += containerTypeTaskType.duration.value * containerTypeAndDuration.containerNum;
        } else {
          totalDurationOfTask += containerTypeTaskType.duration.value;
        }
      });

      return totalDurationOfTask;
    },
    isSchedulingPriorityDisabled(): boolean {
      return this.viewModel === undefined || this.viewModel.generationSiteId === undefined;
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.RESERVATION;
    },
    hasMultipleCarNum(): boolean {
      if (this.viewModel === undefined) return false;
      if (this.viewModel.carNum > 1) return true;

      return false;
    },
    isCarNumDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (this.viewModel.isFixedArrivalTimeReportNeeded) return true;

      return false;
    },
    carNumDisabledReason(): string {
      if (this.viewModel === undefined) return '';
      if (this.viewModel.generationSiteId === undefined) return '排出場を選択してください';
      if (this.viewModel.orderGroupId === undefined) return '担当グループを選択してください';
      if (this.viewModel.isFixedArrivalTimeReportNeeded)
        return '到着予定時刻の報告をする場合は車台数を複数にできません';
      return '';
    },
    isAssignedCarDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (this.viewModel.assignableCarTypeIds.length > 0) return true;

      return false;
    },
    assignedCarDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.viewModel.orderGroupId === undefined) {
        return '担当グループを選択してください';
      }
      if (this.viewModel.assignableCarTypeIds.length > 0) {
        return '車種か車番どちらかしか選択できません';
      }

      return '';
    },
    isAssignableCarTypesDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;
      if (this.viewModel.assignedCarId !== undefined) return true;

      return false;
    },
    assignableCarTypeDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.viewModel.orderGroupId === undefined) {
        return '担当グループを選択してください';
      }
      if (this.viewModel.assignedCarId !== undefined) {
        return '車種か車番どちらかしか選択できません';
      }

      return '';
    },
    isDriverNumDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;

      return false;
    },
    isDriverAssignmentDisabled(): boolean {
      if (this.viewModel === undefined) return true;
      if (this.viewModel.generationSiteId === undefined) return true;
      if (this.viewModel.orderGroupId === undefined) return true;

      return false;
    },
    driverAssignmentDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.viewModel.orderGroupId === undefined) {
        return '担当グループを選択してください';
      }

      return '';
    },
    isIsFixedArrivalTimeReportNeededDisabled(): boolean {
      return this.viewModel === undefined || this.viewModel.generationSiteId === undefined || this.hasMultipleCarNum;
    },
    isFixedArrivalTimeReportNeededDisabledReason(): string {
      if (!this.viewModel) return '';

      if (this.viewModel.generationSiteId === undefined) {
        return '排出場を選択してください';
      }
      if (this.hasMultipleCarNum) {
        return '車台数が複数の場合は、到着予定時刻の報告の指定はできません';
      }

      return '';
    },
  },
  watch: {
    /**
     * メッセージが表示されてから5秒後に閉じる
     */
    isLessThanDurationOfTasksMessageActive(value) {
      if (value === true) {
        setTimeout(() => {
          this.isLessThanDurationOfTasksMessageActive = false;
        }, 5000);
      }
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.reservationFormPanel.openFormEvent.on(this.onOpenForm);
    this.$context.panels.reservationFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    formatDateForField,
    dateToMd,
    dateToYyMdDaysOfWeek,
    sanitizeHours,
    sanitizeMinutes,
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @public
     */
    async open(reservation: IReservationEntity, option?: IReservationFormPanelOption): Promise<void> {
      if (this.isActive) return;
      const initialLoadMasterValues =
        option?.initialFormValues !== undefined
          ? this.getPreloadableFormObjectFromFormValues(option.initialFormValues)
          : this.getPreloadableFormObjectFromFormValues(getInitialFormValuesByReservation(reservation, option));
      if (this.masters === undefined || option?.useCache !== true) {
        this.masters = await this.loadAsyncData(this.$nuxt.context, initialLoadMasterValues);
      }
      const orderValidation = new OrderValidation();
      const clientDefaultCondition = {
        keywords: undefined,
        since: undefined,
        orderBy: ClientsByKeywordsOrder.CreatedAtDesc,
      };
      const clientLoader = new Client(this.$context, clientDefaultCondition);
      const generationSiteDefaultCondition: GenerationSitesByKeywordsCondition = {
        clientId: undefined,
        keywords: undefined,
        since: undefined,
        orderBy: GenerationSitesByKeywordsOrder.CreatedAtDesc,
      };

      const generationSiteLoader = new GenerationSite(this.$context, generationSiteDefaultCondition);
      this.viewModel = new ReservationForm(
        clientDefaultCondition,
        clientLoader,
        generationSiteDefaultCondition,
        generationSiteLoader,
        this.masters.client,
        this.masters.officeSetting,
        this.masters.generationSite,
        this.masters.orderGroups,
        this.masters.disposalSites,
        this.masters.wasteTypes,
        this.masters.containerTypes,
        this.masters.containerTypeTaskTypes,
        this.masters.packingStyles,
        this.masters.collectablePeriodTemplates,
        this.masters.drivers,
        this.masters.carTypes,
        this.masters.cars,
        this.masters.assignableDriverAttendances,
        this.masters.taskTypes,
        reservation,
        orderValidation,
        option?.initialFormValues,
        option?.formTitle,
        option?.scheduleDate
      );

      // 機能を開放していいかどうかチェック
      const [isOrderAcceptanceCheckAvailable] = await this.loadFeatureAvailabilities();
      this.isOrderAcceptanceCheckAvailable = isOrderAcceptanceCheckAvailable;

      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);

      // 会社や排出場が削除されていた場合、viewModel の中で勝手に会社と排出場が undefined に設定される事がある。
      // この状態でそのまま validateOrderForm を走らすと本来 null になるべきではないフィールドが null になって
      // しまいエラーになる。そこで、isFormValid な時だけ validateOrderForm を走らせる。会社や排出場が
      // undefined になっている場合はそもそも isFormValid が true にならないため、これで通らなくなる。
      if (this.viewModel.isFormValid) {
        if (await this.validateOrderForm()) this.onValidationError();
      }
    },
    /**
     * public ではあるが基本外からは呼ばず、PanelManager を通して操作される
     * @param createdOrderId 受注確定をした際に、確定によって新規登録された受注の Id。確定しないまま閉じた場合は undefined。
     * @param isCancelled 辞退した場合はtrue, それ以外の場合はfalse
     * @public
     */
    async close(createdOrderId?: Maybe<PersistentId>, isCancelled: boolean = false): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      ensure(this.viewModel);
      const closeFormArgs: ICloseReservationFormArgs = {
        entity: this.viewModel.reservation,
        createdOrderId,
        isCancelled,
      };
      this.$context.panels.reservationFormPanel.closeFormEvent.emit(closeFormArgs);
    },
    /**
     * https://github.com/FanfareInc/rin/pull/1396#discussion_r798198747
     * 「受注パネル」は会社や排出場など数が大量（数万オーダー）になる様な選択肢を表示させているが、
     * この様な選択肢はデータ量の問題で一度に読み込むことが出来ない。
     * そのため遅延処理を行い、パネルが表示された後に少しずつサーバーからデータを取得している。
     *
     * 一方、受注の新規登録や、（既存の受注の）登録内容の編集（や排出場の編集へ移動して戻ってきた場合）を行う時に、
     * 既に指定されている会社や排出場を選択肢として表示しないと、会社や排出場に関するデータが空の状態で受注を登録することになり、
     * 矛盾した（会社や排出場に関するデータは存在しないが、時間や作業内容などが登録された）受注が登録されることになる。
     * その矛盾を回避するため、会社や排出場のデータは受注を登録する前に先に読み込んでおく必要がある。
     * これが、this.masters.client(会社) と this.masters.generationSite(排出場)の役目である。
     *
     * 「続けて登録」の場合は、useCache をオンにして、既にサーバーから取得したデータを再利用し、
     * サーバーから新規に（もしくは再度）取得するデータの量を最低限に抑えている。
     * それにより、this.mastersの内容が次に開かれたパネルでも再利用される。
     *
     * しかし、this.masters.clientやthis.masters.generationSiteが次に開いたパネルにも反映される場合、
     * 次のパネルから登録する受注の内容に前回開いたパネルの受注の内容を使用するものと判断される。
     * そのため、前回の受注登録固有のデータ（「登録済みの備考欄」の内容）が表示されてしまう問題があった。
     *
     * その問題を解決するため、続けて登録を行う事は新たにフォームの内容を設定する事と等価であるという思想の元、
     * this.masters.clientとthis.masters.generationSiteをリセットしている。
     * （this.masters.clientなどはデータ量がパネルの挙動に大きく影響する程大きなデータ量ではないため、
     * キャッシュされるべきマスターの内容ではなく、各受注登録固有の内容（フォームの内容）と判断した）
     */
    initValueOfMasters(masters: Maybe<Masters>): Maybe<Masters> {
      const initialMaster = this.getInitialMaster();
      return _.assign(masters, initialMaster);
    },
    getInitialMaster(): InitialMaster {
      return {
        client: undefined,
        generationSite: undefined,
      };
    },
    async onOpenForm(args: IOpenReservationFormArgs): Promise<void> {
      await this.open(args.entity, args.option);
    },
    async onCloseForm(forceClose: boolean = false): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (forceClose === false && this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    getPreloadableFormObjectFromFormValues(formValues: IFormValues): IPreloadableFormObject {
      // loadAsyncDataに引数で渡されて、driverAttendancesByDateRangeでdateが使われる。
      // 複数日の一括処理の場合、dateCollectablePeriodItemsを複数持っている可能性があるが、
      // 最初の1つだけのチェックで問題なし
      return {
        assignableDrivers: formValues.assignableDrivers,
        date: formValues.selectedCandidateDateCollectablePeriodItem.getDate(),
        clientId: formValues.clientId,
        generationSiteId: formValues.generationSiteId,
      };
    },
    async loadAsyncData(_context: Context, initialLoadMasterValues: Maybe<IPreloadableFormObject>): Promise<Masters> {
      // 乗務員のリストは all で取りたいものの過去に作成されたオーダーの乗務員は論理削除されている可能性があり、
      // この様な乗務員を取得するために別途 ID を指定して取得しており、そのための小細工
      const driverIdSet: Set<PersistentId> = new Set<PersistentId>();
      if (initialLoadMasterValues?.assignableDrivers !== undefined)
        initialLoadMasterValues.assignableDrivers.forEach((assignableDriver) => {
          driverIdSet.add(assignableDriver.driverId);
        });
      const formData = await getOrderFormData(
        this.$context,
        initialLoadMasterValues?.date,
        initialLoadMasterValues?.clientId,
        initialLoadMasterValues?.generationSiteId,
        driverIdSet.toArray()
      );
      return {
        officeSetting: formData.officeSetting,
        client: formData.client,
        generationSite: formData.generationSite,
        orderGroups: formData.orderGroups,
        disposalSites: formData.disposalSites,
        wasteTypes: formData.wasteTypes,
        containerTypes: formData.containerTypes,
        containerTypeTaskTypes: formData.containerTypeTaskTypes,
        collectablePeriodTemplates: formData.collectablePeriodTemplates,
        drivers: formData.drivers,
        carTypes: formData.carTypes,
        cars: formData.cars,
        assignableDriverAttendances: formData.assignableDriverAttendances,
        holidayRules: formData.holidayRule,
        packingStyles: formData.packingStyles,
        taskTypes: formData.taskTypes,
      };
    },
    async loadFeatureAvailabilities(): Promise<boolean[]> {
      return await this.$context.features.getFeatureAvailabilities([Features.OrderAcceptanceCheck]);
    },
    async validateOrderForm(): Promise<boolean> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;

      const validateOrder = await this.reservationApplicationService.validateOrder(viewModel.getValidateOrderData());
      viewModel.orderValidation.setErrorsByInfeasibilities(
        viewModel.assignableDrivers.map((assignableDriver) => assignableDriver.driverId),
        viewModel.assignedCarId,
        validateOrder.infeasibilities
      );
      return viewModel.orderValidation.hasErrors;
    },
    checkClientAndGenerationSiteConsistency(): boolean {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`Impossible!`);

      // NOTE 受注登録後に排出場の会社を変更すると会社と排出場の組が不正になってしまう事があり、
      //  そのような状態になってしまっている場合には登録ができないので弾いておく。本来はこのような状態で
      //  登録ボタンを押せる様になってはいけない。
      if (viewModel.client && viewModel.clientId && viewModel.generationSite && viewModel.generationSiteId) {
        const clientIsBroken = viewModel.client.persistentId !== viewModel.clientId;
        const generationSiteIsBroken = viewModel.generationSite.persistentId !== viewModel.generationSiteId;
        const clientIdDoesntMatch = viewModel.clientId !== viewModel.generationSite.clientId;
        if (clientIsBroken || generationSiteIsBroken || clientIdDoesntMatch) {
          viewModel.generationSiteId = undefined;
          viewModel.generationSite = undefined;

          this.$context.snackbar.warning('会社と排出場の組が不正です。お手数ですがもう一度排出場を選択して下さい。', {
            timeout: 0,
            described: true,
          });
          return false;
        }
      }
      return true;
    },
    async onClickAcceptReservation(): Promise<void> {
      /**
       * Steps
       * 1. validate form data
       * 2. check client and generation site consistency
       * 3. show confirmation dialog
       * 4. create payload
       * 5. accept reservation API call
       * 6. close panel
       */

      const viewModel = unwrap(this.viewModel);
      viewModel.isRegistering = true;

      // まず登録しても大丈夫かどうかを確認する
      // エラーがあれば登録はできない
      if (await this.validateOrderForm()) {
        this.onValidationError();
        viewModel.isRegistering = false;
        return;
      }

      if (this.checkClientAndGenerationSiteConsistency() === false) {
        viewModel.isRegistering = false;
        return;
      }

      const confirmAcceptReservationDialogPromise = new Promise<boolean>((resolve) => {
        // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
        this.isConfirmAcceptReservationDialogActive = true;
        this.confirmAcceptReservationDialogResolver = resolve;
      });
      const confirmAcceptReservation = await confirmAcceptReservationDialogPromise;
      if (confirmAcceptReservation === false) {
        viewModel.isRegistering = false;
        return;
      }

      const durationAtGenerationSite = viewModel.getDurationAtGenerationSite();
      const generationSiteTasks = viewModel.getCreateGenerationSiteTaskInput();
      const irregularTasks = viewModel.getCreateIrregularTaskInput();

      const result = await this.reservationApplicationService.acceptReservation(viewModel.reservation.id, {
        date: viewModel.selectedCandidateDateCollectablePeriodItem.getDate(),
        plan: {
          fixed: {
            date: viewModel.selectedCandidateDateCollectablePeriodItem.getDate(),
            collectablePeriodTemplateName:
              viewModel.selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName,
            collectablePeriodStart:
              viewModel.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime(),
            collectablePeriodEnd:
              viewModel.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime(),
            unloadDate: undefined,
          },
        },
        collectablePeriodTemplateName:
          viewModel.selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateName,
        collectablePeriodStart:
          viewModel.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodStartOrDistinctTime(),
        collectablePeriodEnd:
          viewModel.selectedCandidateDateCollectablePeriodItem.getCollectablePeriodEndOrDistinctTime(),
        unloadDate: undefined, // NOTE: 宵積みの場合のみ指定される。予約の仕様上宵積み不可
        orderGroupId: viewModel.orderGroupId!,
        generationSiteId: viewModel.generationSiteId!,
        durationAtGenerationSite: durationAtGenerationSite!,
        routeCollectionAllowed: viewModel.routeCollectionAllowed,
        preloadStatus: PreloadStatus.NotAllowed, // NOTE: 要件上予約の確定の場合は宵積み不可
        // TODO: 処分場の入退場時間の後続リリースで削除
        assignedDisposalSiteIds: viewModel.disposalSiteIds!,
        // TODO: 処分場の入退場時間の後続リリースで削除
        disposalSiteAssignmentType: viewModel.disposalSiteAssignmentType!,
        assignedDisposalSitesAndType: {
          orderDisposalSites: viewModel.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
            return {
              disposalSiteId: orderAssignedDisposalSite.disposalSiteId,
              durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance,
              priority: orderAssignedDisposalSite.priority,
            };
          }),
          disposalSiteAssignmentType: viewModel.disposalSiteAssignmentType,
        },
        driverAssignmentType: viewModel.driverAssignmentType,
        assignableDrivers: viewModel.assignableDrivers,
        assignedCarId: viewModel.assignedCarId,
        assignableCarTypeIds: viewModel.assignableCarTypeIds,
        assignedBaseSiteId: undefined, // FIXME
        minAssignedCarNum: viewModel.carNum,
        maxAssignedCarNum: viewModel.carNum,
        carNum: viewModel.carNum,
        minAssignedDriverNum: viewModel.driverNum,
        maxAssignedDriverNum: viewModel.driverNum,
        note: viewModel.note,
        noteForAssignedDriver: '',
        avoidHighways: viewModel.avoidHighways,
        fixedArrivalTime: viewModel.fixedArrivalTime,
        isFixedArrivalTimeReportNeeded: viewModel.isFixedArrivalTimeReportNeeded,
        marginTypeOfFixedArrivalTime: viewModel.marginTypeOfFixedArrivalTime,
        marginOfFixedArrivalTime: viewModel.marginOfFixedArrivalTime,
        routingGroup: undefined,
        fixedDisplayOnReservation: viewModel.fixedDisplayOnReservation,
        fixedDisplayOnReservationName: viewModel.fixedDisplayOnReservationName,
        schedulingPriority: viewModel.schedulingPriority,
        recurringSettings: undefined, // NOTE: 要件上予約の確定の場合は繰り返し受注の登録は不可
        status: OrderStatus.Active,
        generationSiteTasks,
        irregularTasks,
      });

      viewModel.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.RESERVATION,
        [AdditionalInfoKeys.MODE]: FormModeParams.EDIT,
      });

      this.$context.snackbar.success('受注を確定しました');
      await this.close(result.orderId);
    },
    onConfirmAcceptReservation(value: boolean): void {
      this.isConfirmAcceptReservationDialogActive = false;
      if (this.confirmAcceptReservationDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.confirmAcceptReservationDialogResolver(value);
    },
    async onHoursAtGenerationSiteTaskChange(value: number): Promise<void> {
      if (this.viewModel === undefined) return;

      const hours: number = sanitizeNaturalNumber(value);
      await Vue.nextTick();
      this.isLessThanDurationOfTasksMessageActive =
        this.viewModel.generationSiteTaskDuration.setHoursAtGenerationSite(hours);
    },
    async onMinutesAtGenerationSiteTaskChange(value: number): Promise<void> {
      if (this.viewModel === undefined) return;

      const minutes: number = sanitizeNaturalNumber(value);
      await Vue.nextTick();
      this.isLessThanDurationOfTasksMessageActive =
        this.viewModel.generationSiteTaskDuration.setMinutesAtGenerationSite(minutes);
    },
    async onIrregularTaskDurationChange(): Promise<void> {
      // その他のタスクはコンテナありの車と違って IGenerationSiteTaskDuration を継承したクラスを
      // 通して管理していないので、若干気持ち悪いがここで調整している
      if (this.viewModel === undefined) return;
      await Vue.nextTick();
      if (
        !this.viewModel.irregularTask.hoursAtGenerationSite ||
        this.viewModel.irregularTask.hoursAtGenerationSite < 0
      ) {
        this.viewModel.irregularTask.hoursAtGenerationSite = 0;
      }
      if (
        !this.viewModel.irregularTask.minutesAtGenerationSite ||
        this.viewModel.irregularTask.minutesAtGenerationSite < 0
      ) {
        this.viewModel.irregularTask.minutesAtGenerationSite = 0;
      }
      const secsOfHours = this.viewModel.irregularTask.hoursAtGenerationSite * 60 * 60;
      const secsOfMinutes = this.viewModel.irregularTask.minutesAtGenerationSite * 60;
      const [hoursValue, minutesValue] = getHoursAndMinutesOf(secsOfHours + secsOfMinutes);
      this.viewModel.irregularTask.hoursAtGenerationSite = hoursValue;
      this.viewModel.irregularTask.minutesAtGenerationSite = minutesValue;
      this.viewModel.onIrregularTaskChange();
    },
    async onChangeGenerationSite(): Promise<void> {
      const viewModel = this.viewModel;
      ensure(viewModel);

      // Selecting client.
      // Only when generation site was selected without selecting client before.
      if (viewModel.clientId === undefined && viewModel.generationSite) {
        const clientApplicationService = this.$context.applications.get(clientSymbol);
        const client = await clientApplicationService.getById(viewModel.generationSite.clientId);
        viewModel.setClientOnGenerationSiteChange(client);
      }

      if (viewModel.generationSiteId) {
        const orderDefaultValues = await this.orderApplicationService.getOrderDefaultByGenerationSiteId(
          viewModel.generationSiteId
        );
        viewModel.setFormValuesWithOrderDefaults(orderDefaultValues);
      }

      viewModel.onChangeGenerationSite(false);
      await this.updateAssignableDriverAttendances();
    },
    onChangeHelperEnabled(value: boolean): void {
      if (!this.viewModel) return;
      this.viewModel.driverAssignmentType = value
        ? DriverAssignmentType.Distinguished
        : DriverAssignmentType.NotDistinguished;
    },
    async onChangeAssignableDrivers(driverNum: number, assignableDrivers: IOrderAssignableDriver[]): Promise<void> {
      if (this.viewModel === undefined) return;
      this.viewModel.driverNum = driverNum;
      this.viewModel.assignableDrivers = [...assignableDrivers];

      this.viewModel.onChangeAssignableDrivers();
      await this.updateAssignableDriverAttendances();
    },
    onChangeIsAssignableDriversCandidate(value: boolean): void {
      if (this.viewModel === undefined) return;
      this.viewModel.isAssignableDriversCandidate = value;
    },
    async onClickSetAllFieldWorkersToHelper() {
      if (!this.viewModel) return;

      const firstDriver = this.viewModel.assignableDrivers.find((driver) => driver.driverType === DriverType.Driver);

      // 補助員指定がない場合は自動で指定し、最初のドライバーを運転手として設定しておく
      if (this.viewModel.driverAssignmentType === DriverAssignmentType.NotDistinguished) {
        this.viewModel.driverAssignmentType = DriverAssignmentType.Distinguished;
        this.viewModel.assignableDrivers = firstDriver
          ? [{ driverId: firstDriver.driverId, driverType: DriverType.Operator }]
          : [];
      }

      const drivers = await this.driverApplicationService.getAll();
      const currentDriverIdSet = new Set(this.viewModel.assignableDrivers.map((d) => d.driverId));
      const fieldWorkers = drivers
        .filter((driver) => driver.employmentStatus === EmploymentStatus.FieldWorker)
        .filter((driver) => !currentDriverIdSet.has(driver.id))
        .map((driver) => {
          return { driverId: driver.id, driverType: DriverType.Helper };
        });
      const uniqueHelpers = _.uniqBy([...this.viewModel.assignableDrivers, ...fieldWorkers], 'driverId');

      // 補助員の人数以上になる場合は候補扱いにする
      if (uniqueHelpers.length >= this.viewModel.driverNum) {
        this.onChangeIsAssignableDriversCandidate(true);
      }
      this.onChangeAssignableDrivers(this.viewModel.driverNum, uniqueHelpers);
    },
    async updateAssignableDriverAttendances(): Promise<void> {
      const viewModel = this.viewModel;
      if (viewModel === undefined) return;

      if (viewModel.assignableDrivers.length === 0) {
        viewModel.assignableDriverAttendances = [];
        return;
      }

      const assignableDriverIds = viewModel.assignableDrivers.map((assignableDriver) => assignableDriver.driverId);

      const driverAttendanceApplicationService = this.$context.applications.get(driverAttendanceSymbol);
      const date = viewModel.selectedCandidateDateCollectablePeriodItem.getDate();
      const driverAttendances = await Promise.all(
        assignableDriverIds.map((driverId) => driverAttendanceApplicationService.getListOfDriver(driverId, date, date))
      );

      // TODO: applicationServiceをリファクタリングする際にviewModelまでは修正しなかったため、
      // flatをする非直感的な実装になっている。
      // データの持ち方をリファクタリングする際に修正を検討する
      viewModel.assignableDriverAttendances = driverAttendances.flat();
      viewModel.validateCarUsage();
    },
    async onChangeDate(date: Date): Promise<void> {
      const isPastDate = isBefore(date, startOfToday());
      if (isPastDate) {
        this.$context.snackbar.warning('過去の日付が選択されました。\n入力に間違いがないかご確認ください。');
      }

      await this.updateAssignableDriverAttendances();
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    getEnteredGenerationSiteNameState(): EnteredGenerationSiteNameState {
      const lazySearchablePullDown = this.$refs.searchablePulldownGenerationSite as LazySearchablePullDownType;
      if (
        lazySearchablePullDown === undefined ||
        lazySearchablePullDown.isLoading ||
        lazySearchablePullDown.searchScheduledAt !== undefined
      )
        return EnteredGenerationSiteNameState.Searching;
      return this.viewModel?.generationSite?.name !== lazySearchablePullDown.searchInput
        ? EnteredGenerationSiteNameState.New
        : EnteredGenerationSiteNameState.Exist;
    },
    async onClickCreateGenerationSiteButton(): Promise<void> {
      const searchInput: Maybe<string> = (this.$refs.searchablePulldownGenerationSite as LazySearchablePullDownType)
        .searchInput;
      await this.openGenerationSitePanelForCreate(searchInput);
    },
    async onClickCreateGenerationSitePopup(): Promise<void> {
      await this.openGenerationSitePanelForCreate();
    },
    async openGenerationSitePanelForCreate(initialGenerationSiteName?: string): Promise<void> {
      const viewModel = this.viewModel;
      ensure(viewModel);
      const systemContext = this.$context;
      const reservation = viewModel.reservation;
      const formValues = viewModel.getFormValues();
      const formTitle = viewModel.title;

      const callbackPromise = async (createdGenerationSite: Maybe<AggregatedGenerationSiteEntity>) => {
        if (createdGenerationSite) {
          formValues.clientId = createdGenerationSite.clientId;
          formValues.generationSiteId = createdGenerationSite.id;
        }

        await systemContext.panels.reservationFormPanel.open(reservation, {
          formTitle,
          initialFormValues: formValues,
          scheduleDate: viewModel.scheduleDate,
        });

        this.onChangeGenerationSite();
      };

      await this.$context.panels.generationSiteFormPanel.open(undefined, {
        forceClose: true,
        registerButtonLabel: `受注の確定を続ける`,
        disableContinueRegistrationButton: true,
        initialFormValues: {
          clientId: formValues.clientId,
          name: initialGenerationSiteName,
        },
        closeCallback: callbackPromise,
      });
    },
    async onClickEditGenerationSite(): Promise<void> {
      const viewModel = this.viewModel;
      ensure(viewModel);
      ensure(viewModel.generationSiteId);
      const service = this.$context.applications.get(generationSiteSymbol);
      const [generationSite] = await service.getByIds([viewModel.generationSiteId]);
      const systemContext = this.$context;
      const reservation = viewModel.reservation;
      const formValues = viewModel.getFormValues();
      const formTitle = viewModel.title;
      const defaultDurationAtEntrance = generationSite.defaultDurationAtEntrance;
      const carTypeMap = mapEntity(viewModel.carTypes);
      const carMap = mapEntity(viewModel.cars);
      const defaultFieldEdited = this.getDefaultFieldEditStatus(formValues, generationSite, carTypeMap, carMap);

      // callbackPromise は実行コンテキストが変わるため `this` を使ってはいけない。参照を変数として持って使うようにする。
      const orderApplicationService = this.orderApplicationService;

      // TODO: 排出場パネルを閉じた時にcallbackを呼ばないように修正する
      const callbackPromise = async (
        callbackedGenerationSite: Maybe<AggregatedGenerationSiteEntity> = generationSite
      ) => {
        // 排出場で設定しているデフォルトの値を持ってくるフィールドがユーザーによって変更されていなかった場合、
        // それはデフォルトの値を設定したいものだと解釈して、排出場のパネルで何か値を変更していた場合に
        // その値を受注パネルに再度設定し直すという事をしている

        // 受注に設定されている値を優先する
        // 車種・車番はどちらか一方しか設定できないため、受注に排出場の設定と異なる車種車番情報が設定されていない場合のみ排出場デフォルト値を反映する
        // 例: 受注に車番設定あり, 排出場に車種設定し更新 -> 受注上は車番設定が残り、車種は設定なし
        if (
          defaultFieldEdited.assignableCarTypeIds &&
          callbackedGenerationSite.defaultAssignableCarTypeIds.length !== 0 &&
          formValues.assignedCarId === undefined
        ) {
          // 指定車種の orderGroupId と オーダーの orderGroupId が別だった場合、グループと車種が矛盾する事になるので
          // assignableCarTypeIds から違うものは消しておく
          formValues.assignableCarTypeIds = callbackedGenerationSite.defaultAssignableCarTypeIds.filter(
            (assignableCarTypeId) => carTypeMap.getOrError(assignableCarTypeId).orderGroupId === formValues.orderGroupId
          );
        }
        if (
          defaultFieldEdited.assignedCarId &&
          callbackedGenerationSite.defaultAssignedCarId &&
          formValues.assignableCarTypeIds.length === 0
        ) {
          // 指定車番から推測した車種の orderGroupId とオーダーの orderGroupId が別だった場合、グループと車番が矛盾する事に
          // なるので assignedCarId は空になるようにする
          const assignedCar = callbackedGenerationSite.defaultAssignedCarId
            ? carMap.getOrError(callbackedGenerationSite.defaultAssignedCarId)
            : undefined;
          const assignableCarType = assignedCar ? carTypeMap.getOrError(assignedCar.carType.id) : undefined;
          formValues.assignedCarId =
            assignableCarType && assignableCarType.orderGroupId === formValues.orderGroupId
              ? callbackedGenerationSite.defaultAssignedCarId
              : undefined;
        }

        if (defaultFieldEdited.assignableDrivers && callbackedGenerationSite.defaultAssignedDriverId) {
          formValues.driverAssignmentType = DriverAssignmentType.NotDistinguished;
          formValues.assignableDrivers = [
            {
              driverType: DriverType.Driver,
              driverId: callbackedGenerationSite.defaultAssignedDriverId,
            },
          ];
        }
        if (defaultFieldEdited.disposalSiteIds && callbackedGenerationSite.defaultAssignedDisposalSiteId) {
          formValues.disposalSiteIds = arrayFromMaybe(callbackedGenerationSite.defaultAssignedDisposalSiteId);
        }
        if (defaultFieldEdited.avoidHighways) {
          formValues.avoidHighways = callbackedGenerationSite.defaultAvoidHighways;
        }
        if (
          formValues.generationSiteTaskCategory !== GenerationSiteTaskCategory.Irregular &&
          formValues.durationAtGenerationSite !== undefined
        ) {
          // この処理は賛否両論ありそうだが、durationAtEntrance を単品で保持していない以上、
          // どうしてもいい加減な処理になってしまう。以前、排出場のデフォルト入退場時間をいじって
          // 戻ってきた時に即座に反映されないのかという意見をもらった事があったので、若干いい加減になる
          // 事を覚悟でこの仕様にしている。
          const diff = defaultDurationAtEntrance - callbackedGenerationSite.defaultDurationAtEntrance;
          formValues.durationAtGenerationSite = Math.max(formValues.durationAtGenerationSite - diff, 0);
        }

        // Get order default values again after generation site is updated.
        const orderDefault = await orderApplicationService.getOrderDefaultByGenerationSiteId(
          callbackedGenerationSite.id
        );
        viewModel.setFormValuesWithOrderDefaults(orderDefault);

        formValues.clientId = callbackedGenerationSite.clientId;

        await systemContext.panels.reservationFormPanel.open(reservation, {
          formTitle,
          initialFormValues: formValues,
          scheduleDate: viewModel.scheduleDate,
        });
      };
      await this.$context.panels.generationSiteFormPanel.open(generationSite, {
        forceClose: true,
        registerButtonLabel: `受注の確定を続ける`,
        closeCallback: callbackPromise,
      });
    },
    async onClickEditDisposalSite(disposalSite: IDisposalSiteEntity): Promise<void> {
      const viewModel = this.viewModel;
      if (viewModel === undefined) throw new Error(`viewModel is undefined`);
      const systemContext = this.$context;
      const reservation = viewModel.reservation;
      const formValues = viewModel.getFormValues();
      const formTitle = viewModel.title;
      const callbackPromise = async () => {
        await systemContext.panels.reservationFormPanel.open(reservation, {
          formTitle,
          initialFormValues: formValues,
          scheduleDate: viewModel.scheduleDate,
        });
      };
      await this.$context.panels.disposalSiteFormPanel.open(disposalSite, {
        forceClose: true,
        registerButtonLabel: `受注の確定を続ける`,
        closeCallback: callbackPromise,
      });
    },
    onOrderAssignedDisposalSitesChange(orderAssignedDisposalSites: IOrderAssignedDisposalSite[]): void {
      if (this.viewModel === undefined) throw new Error(`viewModel is undefined`);
      this.viewModel.orderAssignedDisposalSites = orderAssignedDisposalSites;
    },
    getDefaultFieldEditStatus(
      formValues: IFormValues,
      generationSite: GenerationSiteEntity,
      carTypeMap: Map<string, CarTypeEntity>,
      carMap: Map<string, ICarEntity>
    ): DefaultFormValueStatuses {
      // 車種と車番は受注グループによってフォームに設定されるデフォルト値が変わるため、
      // 以下では、受注グループを考慮した比較を行っている
      const generationSiteDefaultAssignableCarTypeIds = generationSite.defaultAssignableCarTypeIds.filter(
        (defaultAssignableCarTypeId) =>
          carTypeMap.getOrError(defaultAssignableCarTypeId).orderGroupId === formValues.orderGroupId
      );
      const assignableCarTypeIds = _.isEqual(
        formValues.assignableCarTypeIds,
        generationSiteDefaultAssignableCarTypeIds
      );

      const generationSiteDefaultAssignedCar = generationSite.defaultAssignedCarId
        ? carMap.getOrError(generationSite.defaultAssignedCarId)
        : undefined;
      const generationSiteDefaultAssignedCarId =
        generationSiteDefaultAssignedCar &&
        carTypeMap.getOrError(generationSiteDefaultAssignedCar.carType.id).orderGroupId === formValues.orderGroupId
          ? generationSite.defaultAssignedCarId
          : undefined;
      const assignedCarId = formValues.assignedCarId === generationSiteDefaultAssignedCarId;

      // TODO: 排出場のデフォルト指定乗務員が複数選択できるようになったらこちらも反映させる
      const assignableDrivers = _.isEqual(
        formValues.assignableDrivers.map((assignableDriver) => assignableDriver.driverId),
        [generationSite.defaultAssignedDriverId]
      );

      // TODO: 排出場のデフォルト指定乗務員が複数選択できるようになったらこちらも反映させる。デフォルトは NotDistinguished。
      const driverAssignmentType = formValues.driverAssignmentType === DriverAssignmentType.NotDistinguished;

      const disposalSiteIds = _.isEqual(
        formValues.disposalSiteIds,
        arrayFromMaybe(generationSite.defaultAssignedDisposalSiteId)
      );
      const avoidHighways = formValues.avoidHighways === generationSite.defaultAvoidHighways;
      const routeCollectionAllowed = formValues.routeCollectionAllowed === generationSite.defaultRouteCollectionAllowed;

      const collectablePeriodTemplate =
        formValues.selectedCandidateDateCollectablePeriodItem.collectablePeriodTemplateId ===
        generationSite.defaultCollectablePeriodTemplateId;

      const isFixedArrivalTimeReportNeeded =
        formValues.isFixedArrivalTimeReportNeeded === generationSite.defaultIsFixedArrivalTimeReportNeeded;
      const marginTypeOfFixedArrivalTime =
        formValues.marginTypeOfFixedArrivalTime === generationSite.defaultMarginTypeOfFixedArrivalTime;
      const marginOfFixedArrivalTime =
        formValues.marginOfFixedArrivalTime === generationSite.defaultMarginOfFixedArrivalTime;

      return {
        assignableCarTypeIds,
        assignedCarId,
        driverAssignmentType,
        assignableDrivers,
        disposalSiteIds,
        avoidHighways,
        routeCollectionAllowed,
        collectablePeriodTemplate,
        isFixedArrivalTimeReportNeeded,
        marginTypeOfFixedArrivalTime,
        marginOfFixedArrivalTime,
      };
    },
    async onClickCheckOrderAcceptanceButton(): Promise<void> {
      if (this.viewModel === undefined) throw new Error(`Impossible!`);

      this.$rinGtm.push(RinEventNames.ACCEPTANCE_CHECK, {
        [AdditionalInfoKeys.RESERVATION_ID]: this.viewModel.reservation.persistentId,
      });

      await (this.$refs.orderAcceptanceCheck as any).checkOrderAcceptance(this.viewModel.getOrderAcceptanceCheckData());
    },
    onUpdateIsChecking(value: boolean): void {
      // TODO どこかに移植
      // チェック中にフォーカスが当たっていると不自然な動きになるので消す
      // 本当はフォーカスをもう少し構造的に制御できる仕組みが必要だが、ひとまず
      if (value && document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.reservationFormPanel.updateDisplayedWidth(value);
    },
    onValidationError(): void {
      this.$context.snackbar.error('入力に問題があります。確認してください。');
    },
    async searchOrders(): Promise<void> {
      if (!this.viewModel) throw new Error('Impossible!');

      const searchOrdersConditions: SearchOrdersConditions = {
        clientId: this.viewModel.clientId,
        generationSiteId: this.viewModel.generationSiteId,
      };

      this.$context.events.searchOrdersEvent.emit(searchOrdersConditions);
      await this.close();
    },
    onClickCancel(): void {
      this.isCancelConfirmDialogActive = true;
    },
    async onClickCancelConfirm(): Promise<void> {
      this.isCancelConfirmDialogActive = false;
      const viewModel = unwrap(this.viewModel);

      this.$rinGtm.push(RinEventNames.CANCEL_RESERVATION, {
        [AdditionalInfoKeys.ORDER_ID]: viewModel.reservation.persistentId,
        [AdditionalInfoKeys.REFERRER]: PageNames.RESERVATION_FORM,
      });

      await this.reservationApplicationService.cancelReservation(viewModel.reservation.id);
      await this.close(undefined, true);
    },
    onClickCancelAbort(): void {
      this.isCancelConfirmDialogActive = false;
    },
  },
});
