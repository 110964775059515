import { IOfficeSettingFormPanel, OfficeSettingFormPanel } from '~/framework/view-models/panels/officeSettingFormPanel';
import { ContainerTaskFormPanel, IContainerTaskFormPanel } from '~/framework/view-models/panels/containerTaskFromPanel';
import { CarFormPanel, ICarFormPanel } from '~/framework/view-models/panels/carFormPanel';
import { IPanelState, PanelState } from '~/framework/view-models/panels/panelState';
import { IOrderFormPanel, OrderFormPanel } from '~/framework/view-models/panels/orderFormPanel';
import {
  GenerationSiteFormPanel,
  IGenerationSiteFormPanel,
} from '~/framework/view-models/panels/generationSiteFormPanel';
import { IDriverFormPanel, DriverFormPanel } from '~/framework/view-models/panels/driverFormPanel';
import {
  IDriverAttendanceTemplateFormPanel,
  DriverAttendanceTemplateFormPanel,
} from '~/framework/view-models/panels/driverAttendanceTemplateFormPanel';
import { ClientFormPanel, IClientFormPanel } from '~/framework/view-models/panels/clientFormPanel';
import {
  ICollectablePeriodTemplateFormPanel,
  CollectablePeriodTemplateFormPanel,
} from '~/framework/view-models/panels/collectablePeriodTemplateFormPanel';
import { ContainerTypeFormPanel, IContainerTypeFormPanel } from '~/framework/view-models/panels/containerTypeFormPanel';
import { DisposalSiteFormPanel, IDisposalSiteFormPanel } from '~/framework/view-models/panels/disposalSiteFormPanel';
import { IPanel, IUpdateDisplayedWidthEvent } from '~/framework/view-models/panels/panel';
import { Pixel } from '~/framework/typeAliases';
import { ITypedEvent, TypedEvent } from '~/framework/events/typedEvent';
import { CarTypeFormPanel, ICarTypeFormPanel } from '~/framework/view-models/panels/carTypeFormPanel';
import {
  IPackingPlacementsFormPanel,
  PackingPlacementsFormPanel,
} from '~/framework/view-models/panels/packingPlacementsFormPanel';
import { IReservationFormPanel, ReservationFormPanel } from '~/framework/view-models/panels/reservationFormPanel';
import { IBaseSiteFormPanel, BaseSiteFormPanel } from '~/framework/view-models/panels/baseSiteFormPanel';
import { IWasteTypeFormPanel, WasteTypeFormPanel } from '~/framework/view-models/panels/wasteTypeFormPanel';
import { ITaskTypeFormPanel, TaskTypeFormPanel } from '~/framework/view-models/panels/taskTypeFromPanel';
import {
  IRoutingRegulationFormPanel,
  RoutingRegulationFormPanel,
} from '~/framework/view-models/panels/routingRegulationFormPanel';

export class PanelManager {
  private readonly panelState: IPanelState;
  private readonly panelWidthMap: Map<IPanel, Pixel>;
  displayedPanelWidth: Pixel;
  updateDisplayedPanelWidthEvent: ITypedEvent<Pixel>;

  get isPanelOpen(): boolean {
    return this.panelState.isPanelOpen;
  }

  readonly orderFormPanel: IOrderFormPanel;
  readonly generationSiteFormPanel: IGenerationSiteFormPanel;
  readonly driverFormPanel: IDriverFormPanel;
  readonly officeSettingFormPanel: IOfficeSettingFormPanel;
  readonly driverAttendanceTemplateFormPanel: IDriverAttendanceTemplateFormPanel;
  readonly containerTaskFormPanel: IContainerTaskFormPanel;
  readonly clientFormPanel: IClientFormPanel;
  readonly collectablePeriodTemplateFormPanel: ICollectablePeriodTemplateFormPanel;
  readonly containerTypeFormPanel: IContainerTypeFormPanel;
  readonly taskTypeFormPanel: ITaskTypeFormPanel;
  readonly disposalSiteFormPanel: IDisposalSiteFormPanel;
  readonly carTypeFormPanel: ICarTypeFormPanel;
  readonly carFormPanel: ICarFormPanel;
  readonly packingPlacementsFormPanel: IPackingPlacementsFormPanel;
  readonly reservationFormPanel: IReservationFormPanel;
  readonly baseSiteFormPanel: IBaseSiteFormPanel;
  readonly wasteTypeFormPanel: IWasteTypeFormPanel;
  readonly routingRegulationFormPanel: IRoutingRegulationFormPanel;

  constructor() {
    this.panelState = new PanelState();
    this.panelWidthMap = new Map<IPanel, Pixel>();
    this.displayedPanelWidth = 0;
    this.updateDisplayedPanelWidthEvent = new TypedEvent();

    this.orderFormPanel = new OrderFormPanel(this.panelState);
    this.orderFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.generationSiteFormPanel = new GenerationSiteFormPanel(this.panelState);
    this.generationSiteFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.driverFormPanel = new DriverFormPanel(this.panelState);
    this.driverFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.officeSettingFormPanel = new OfficeSettingFormPanel(this.panelState);
    this.officeSettingFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.driverAttendanceTemplateFormPanel = new DriverAttendanceTemplateFormPanel(this.panelState);
    this.driverAttendanceTemplateFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.containerTaskFormPanel = new ContainerTaskFormPanel(this.panelState);
    this.containerTaskFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.clientFormPanel = new ClientFormPanel(this.panelState);
    this.clientFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.collectablePeriodTemplateFormPanel = new CollectablePeriodTemplateFormPanel(this.panelState);
    this.collectablePeriodTemplateFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.containerTypeFormPanel = new ContainerTypeFormPanel(this.panelState);
    this.containerTypeFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.taskTypeFormPanel = new TaskTypeFormPanel(this.panelState);
    this.taskTypeFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.disposalSiteFormPanel = new DisposalSiteFormPanel(this.panelState);
    this.disposalSiteFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.carTypeFormPanel = new CarTypeFormPanel(this.panelState);
    this.carTypeFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.carFormPanel = new CarFormPanel(this.panelState);
    this.carFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.packingPlacementsFormPanel = new PackingPlacementsFormPanel(this.panelState);
    this.packingPlacementsFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.reservationFormPanel = new ReservationFormPanel(this.panelState);
    this.reservationFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.baseSiteFormPanel = new BaseSiteFormPanel(this.panelState);
    this.baseSiteFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.wasteTypeFormPanel = new WasteTypeFormPanel(this.panelState);
    this.wasteTypeFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));

    this.routingRegulationFormPanel = new RoutingRegulationFormPanel(this.panelState);
    this.routingRegulationFormPanel.updateDisplayedWidthEvent.on(this.onUpdatePanelWidth.bind(this));
  }

  /**
   * 各パネルの表示幅の最大のものを今の仮想的なパネルの最大幅として設定する。
   * 画面上はその幅よりも左側であれば表示できるという事になる。
   *
   * @param event
   * @private
   */
  private onUpdatePanelWidth(event: IUpdateDisplayedWidthEvent): void {
    this.panelWidthMap.set(event.panel, event.width);
    const maxWidth = Math.max(...this.panelWidthMap.values());
    this.displayedPanelWidth = maxWidth;
    this.updateDisplayedPanelWidthEvent.emit(maxWidth);
  }
}
