
import Vue, { PropType } from 'vue';
import { AggregatedOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { formatPeriod, secsToHhMm, dateToYyyymmddDay } from '~/framework/services/date-time/date-time';
import { Maybe } from '~/framework/typeAliases';

export default Vue.extend({
  name: 'RDialogOrder',
  props: {
    order: {
      type: Object as PropType<Maybe<AggregatedOrderEntity>>,
      required: true,
    },
  },
  computed: {
    getDialogOrderCollectablePeriodText(): string {
      let text = '';
      if (this.order === undefined) return text;
      if (this.order.collectablePeriodTemplateName === undefined) return '未選択';
      text += this.order.collectablePeriodTemplateName;
      // どちらもある場合はformatPeriod
      if (this.order.collectablePeriodStart && this.order.collectablePeriodEnd) {
        text += ` ${formatPeriod(this.order.collectablePeriodStart, this.order.collectablePeriodEnd)}`;
      }
      // startのみの場合(時間厳守)はstartのみ
      else if (this.order.collectablePeriodTemplateName === '時間厳守' && this.order.collectablePeriodStart) {
        text += ` ${secsToHhMm(this.order.collectablePeriodStart)}`;
      }
      return text;
    },
  },
  methods: {
    dateToYyyymmddDay,
  },
});
