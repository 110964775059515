import { MarginType, OrderDisposalSiteAssignmentType } from '@/graphql/graphQLServerApi';
import { ReservationEntityData, ReservationEntity } from '~/framework/domain/reservation/reservation/reservationEntity';
import {
  AggregatedClientEntity,
  AggregatedClientEntityData,
} from '~/framework/domain/masters/client/aggregatedClientEntity';
import { OrderGroupEntity, OrderGroupEntityData } from '~/framework/domain/masters/order-group/orderGroupEntity';
import {
  GenerationSiteEntity,
  GenerationSiteEntityData,
} from '~/framework/domain/masters/generation-site/generationSiteEntity';
import {
  AggregatedDisposalSiteEntity,
  AggregatedDisposalSiteEntityData,
} from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { DriverEntity, DriverEntityData } from '~/framework/domain/masters/driver/driverEntity';
import { CarTypeEntity, CarTypeEntityData } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity, AggregatedCarEntityData } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { DriverAssignmentType } from '~/framework/domain/typeAliases';
import { CandidateDate } from '~/framework/domain/reservation/reservation/candidateDate';
import {
  AggregatedReservationGenerationSiteTask,
  AggregatedReservationGenerationSiteTaskData,
} from '~/framework/domain/reservation/reservation/reservation-generation-site-task/aggregatedReservationGenerationSiteTask';
import { IOrderAssignableDriver } from '~/framework/server-api/schedule/order/driver/assignableDriver';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';

export interface AggregatedReservationEntityData extends ReservationEntityData {
  client: AggregatedClientEntityData;
  orderGroup: OrderGroupEntityData;
  generationSite: GenerationSiteEntityData;
  assignedDisposalSites: AggregatedDisposalSiteEntityData[];
  assignableDriverEntities: Maybe<DriverEntityData[]>;
  assignedCar: Maybe<AggregatedCarEntityData>;
  assignableCarTypes: CarTypeEntityData[];
  generationSiteTasks: AggregatedReservationGenerationSiteTaskData[];
}

export class AggregatedReservationEntity extends ReservationEntity {
  client: AggregatedClientEntity;
  orderGroup: OrderGroupEntity;
  generationSite: GenerationSiteEntity;
  assignedDisposalSites: AggregatedDisposalSiteEntity[];
  assignableDriverEntities: Maybe<DriverEntity[]>;
  assignableCarTypes: CarTypeEntity[];
  assignedCar: Maybe<AggregatedCarEntity>;
  generationSiteTasks: AggregatedReservationGenerationSiteTask[];

  constructor(
    id: PersistentId,
    candidateDates: CandidateDate[],
    note: Maybe<string>,
    sourceOrderNote: Maybe<string>,
    clientId: string,
    orderGroupId: string,
    generationSiteId: string,
    durationAtGenerationSite: number,
    routeCollectionAllowed: boolean,
    // TODO: 処分場の入退場時間の後続リリースで削除
    assignedDisposalSiteIds: string[],
    orderAssignedDisposalSites: IOrderAssignedDisposalSite[],
    disposalSiteAssignmentType: OrderDisposalSiteAssignmentType,
    driverAssignmentType: DriverAssignmentType,
    assignableDrivers: IOrderAssignableDriver[],
    assignedCarId: Maybe<string>,
    assignableCarTypeIds: string[],
    carNum: number,
    minAssignedDriverNum: number,
    maxAssignedDriverNum: number,
    avoidHighways: boolean,
    isFixedArrivalTimeReportNeeded: boolean,
    marginTypeOfFixedArrivalTime: MarginType,
    marginOfFixedArrivalTime: number,
    client: AggregatedClientEntity,
    orderGroup: OrderGroupEntity,
    generationSite: GenerationSiteEntity,
    assignedDisposalSites: AggregatedDisposalSiteEntity[],
    assignableDriverEntities: Maybe<DriverEntity[]>,
    assignableCarTypes: CarTypeEntity[],
    assignedCar: Maybe<AggregatedCarEntity>,
    generationSiteTasks: AggregatedReservationGenerationSiteTask[],
    isCancelled: boolean
  ) {
    super(
      id,
      candidateDates,
      note,
      sourceOrderNote,
      clientId,
      orderGroupId,
      generationSiteId,
      durationAtGenerationSite,
      routeCollectionAllowed,
      // TODO: 処分場の入退場時間の後続リリースで削除
      assignedDisposalSiteIds,
      orderAssignedDisposalSites,
      disposalSiteAssignmentType,
      driverAssignmentType,
      assignableDrivers,
      assignedCarId,
      assignableCarTypeIds,
      carNum,
      minAssignedDriverNum,
      maxAssignedDriverNum,
      avoidHighways,
      isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime,
      isCancelled
    );
    this.client = client;
    this.orderGroup = orderGroup;
    this.generationSite = generationSite;
    this.assignedDisposalSites = assignedDisposalSites;
    this.assignableDriverEntities = assignableDriverEntities;
    this.assignableCarTypes = assignableCarTypes;
    this.assignedCar = assignedCar;
    this.generationSiteTasks = generationSiteTasks;
  }
}
