import { TaskType } from '~/framework/domain/typeAliases';
import { GenerationSiteTaskEntity } from '~/framework/domain/schedule/order/generation-site-task/generationSiteTaskEntity';
import { Maybe } from '~/framework/typeAliases';
import {
  AggregatedWasteTypeEntity,
  AggregatedWasteTypeEntityData,
} from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';
import {
  ContainerTypeEntity,
  ContainerTypeEntityData,
} from '~/framework/domain/masters/container-type/containerTypeEntity';

export interface AggregatedGenerationSiteTaskEntityData {
  wasteType: Maybe<AggregatedWasteTypeEntityData>;
  containerType: Maybe<ContainerTypeEntityData>;
  id: string;
  orderId: string;
  taskType: TaskType;
  wasteTypeId: Maybe<string>;
  containerTypeId: Maybe<string>;
  containerNum: Maybe<number>;
}
/**
 * こいつは扱いが特殊で、Order の現場タスクを更新すると毎回新しい ID が振られる。
 * なので ID が同じでも集約の内容が更新される事はあり得ず、トラックする必要はない。
 */
export class AggregatedGenerationSiteTaskEntity extends GenerationSiteTaskEntity {
  wasteType: Maybe<AggregatedWasteTypeEntity>;
  containerType: Maybe<ContainerTypeEntity>;

  constructor(
    id: string,
    orderId: string,
    taskType: TaskType,
    wasteTypeId: Maybe<string>,
    containerTypeId: Maybe<string>,
    containerNum: Maybe<number>,
    wasteType: Maybe<AggregatedWasteTypeEntity>,
    containerType: Maybe<ContainerTypeEntity>
  ) {
    super(id, orderId, taskType, wasteTypeId, containerTypeId, containerNum);
    this.wasteType = wasteType;
    this.containerType = containerType;
  }
}
