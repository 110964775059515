import {
  DriverReasonEntity,
  IDriverReasonEntity,
  IInfeasibilityEntity,
  InfeasibilityEntity,
} from '~/framework/domain/schedule/schedule/pseudo-entities/infeasibilityEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { Maybe } from '~/framework/typeAliases';
import {
  EditAttendanceSolution,
  EditGenerationSiteSolution,
  EditOrderSolution,
  IDriverReason as IErrorDriverReason,
  IInfeasibility as IErrorInfeasibility,
  IInfeasibilitySolution,
  InfeasibilitySolutionTypes,
  IOrder,
  IPotentialModification,
  RinLinkSolution,
} from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import {
  InfeasibilityCauses,
  InfeasibilityDriverReasons,
  InfeasibilityReasons,
  ScheduleInfeasibilityTypes,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { Path } from '~/framework/constants';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { PostponeOrderStatus } from '~/framework/domain/schedule/order/aggregatedOrderEntity';

export interface IInfeasibility extends IErrorInfeasibility<IDriverReason>, IInfeasibilityEntity<IDriverReason> {
  // IErrorInfeasibility は r-schedule-errors 側の IInfeasibility
  /**
   * 最適化から返ってきた時の配列の中での順番
   */
  index: number;
  /**
   * クライアントサイドでひとまず解決したものとして扱うかどうか
   */
  deemedAsSolved: boolean;
}

export interface IDriverReason extends IErrorDriverReason, IDriverReasonEntity {
  // IErrorDriverReason は r-schedule-errors 側の IDriverReason
}
export class Infeasibility<Order extends IOrder> extends InfeasibilityEntity<DriverReason> implements IInfeasibility {
  index: number;
  deemedAsSolved: boolean;
  order: Order;
  assignedDriver: Maybe<DriverEntity>;
  assignedCar: Maybe<AggregatedCarEntity>;
  acceptableCarTypes: Maybe<CarTypeEntity[]>;
  acceptableCarTypeNames: Maybe<string[]>;
  possibleSolutions: IInfeasibilitySolution<any>[];
  hasConditions: boolean;
  assignableCarTypeNames: Maybe<string[]>;
  potentialModifications: Maybe<IPotentialModification[]>;
  // postponeOrdersを行ったかどうか
  postponeOrderStatus: PostponeOrderStatus;

  constructor(
    id: string,
    index: number,
    deemedAsSolved: boolean,
    orderId: PseudoId,
    cause: InfeasibilityCauses,
    reasons: Maybe<InfeasibilityReasons[]>,
    driverReasons: Maybe<DriverReason[]>,
    assignedDriverId: Maybe<PseudoId>,
    assignedCarId: Maybe<PseudoId>,
    acceptableCarTypeIds: Maybe<PseudoId[]>,
    order: Order,
    assignedDriver: Maybe<DriverEntity>,
    assignedCar: Maybe<AggregatedCarEntity>,
    acceptableCarTypes: Maybe<CarTypeEntity[]>,
    type: ScheduleInfeasibilityTypes,
    assignableCarTypeIds: Maybe<PseudoId[]>,
    releaseDriverAssignment: Maybe<boolean>,
    durationAtGenerationSite: Maybe<number>,
    durationAtDisposalSite: Maybe<number>,
    durationOfDriving: Maybe<number>,
    reducibleDurationByHighway: Maybe<number>,
    assignableCarTypes: Maybe<CarTypeEntity[]>,
    potentialModifications: Maybe<IPotentialModification[]>
  ) {
    super(
      id,
      orderId,
      cause,
      reasons,
      driverReasons,
      assignedDriverId,
      assignedCarId,
      acceptableCarTypeIds,
      type,
      assignableCarTypeIds,
      releaseDriverAssignment,
      durationAtGenerationSite,
      durationAtDisposalSite,
      durationOfDriving,
      reducibleDurationByHighway
    );
    this.index = index;
    this.deemedAsSolved = deemedAsSolved;
    this.order = order;
    this.assignedDriver = assignedDriver;
    this.assignedCar = assignedCar;
    this.acceptableCarTypes = acceptableCarTypes;
    this.acceptableCarTypeNames =
      acceptableCarTypes !== undefined && 0 < acceptableCarTypes.length
        ? acceptableCarTypes.map((carType) => carType.name)
        : undefined;
    this.possibleSolutions = [];
    this.hasConditions =
      this.assignedCarId !== undefined ||
      this.assignedDriverId !== undefined ||
      this.acceptableCarTypeIds !== undefined;
    this.assignableCarTypeNames =
      assignableCarTypes !== undefined && 0 < assignableCarTypes.length
        ? assignableCarTypes.map((carType) => carType.name)
        : undefined;
    this.potentialModifications = potentialModifications;
    this.postponeOrderStatus = PostponeOrderStatus.Default;

    this.setPossibleSolutions();
  }

  hasSolution(type: InfeasibilitySolutionTypes): boolean {
    return this.possibleSolutions.some((solution) => solution.type === type);
  }

  private setPossibleSolutions(): void {
    if (this.cause === InfeasibilityCauses.Order) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
    } else if (this.cause === InfeasibilityCauses.OrderOrMaster) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      if (this.reasons?.includes(InfeasibilityReasons.GenerationSiteRestPeriod)) {
        this.addSolution(new EditGenerationSiteSolution());
      } else {
        this.addSolution(new RinLinkSolution(Path.masters.index, `マスターを編集`));
      }
    } else if (this.cause === InfeasibilityCauses.AssignedDriverAttendance) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new EditAttendanceSolution());
    } else if (this.cause === InfeasibilityCauses.AssignedDriverCondition) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
    } else if (this.cause === InfeasibilityCauses.PrimaryCarAttendance) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new EditAttendanceSolution());
    } else if (this.cause === InfeasibilityCauses.PrimaryCarCondition) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new EditAttendanceSolution());
    } else if (this.cause === InfeasibilityCauses.Optimization) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
    } else if (this.cause === InfeasibilityCauses.DisposalSiteRestPeriod) {
      this.addSolution(new EditOrderSolution(this.orderId.value));
      this.addSolution(new RinLinkSolution(Path.masters['disposal-site'], `処分場を編集`));
    }
  }

  private addSolution(solution: IInfeasibilitySolution<any>): void {
    this.possibleSolutions.push(solution);
  }
}

export class DriverReason extends DriverReasonEntity implements IDriverReasonEntity, IDriverReason {
  driver: DriverEntity;

  constructor(driverId: PseudoId, reasons: InfeasibilityDriverReasons[], driver: DriverEntity) {
    super(driverId, reasons);
    this.driver = driver;
  }
}
