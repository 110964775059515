import { BooleanValue, IntegerValue, ContainerTypeTaskTypeStatusValue, TaskType } from '~/framework/domain/typeAliases';
import { IEntity, IPersistentEntity } from '~/framework/core/entity';

export interface ContainerTypeTaskTypeEntityData extends Omit<ContainerTypeTaskTypeEntity, 'persistentId'> {}

export class ContainerTypeTaskTypeEntity implements IEntity, IPersistentEntity {
  readonly persistentId: string;

  constructor(
    public readonly id: string,
    public containerTypeId: string,
    public taskType: TaskType,
    public duration: IntegerValue,
    public status: ContainerTypeTaskTypeStatusValue,
    public isProportionalToCount: BooleanValue // 個数が時間に影響するかどうか
  ) {
    this.persistentId = id;
  }
}
