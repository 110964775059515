
import Vue, { PropType } from 'vue';
import Sortable, { SortableEvent } from 'sortablejs';
import RDialog from '~/components/common/r-dialog/RDialog.vue';
import RSearchablePulldown from '~/components/common/r-searchable-pulldown/RSearchablePulldown.vue';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  sortableInstance: Sortable | undefined;
  selectedDisposalSiteIds: Array<string | undefined>;
  /**
   * `updateCount` is used to force update list render when list is updated on drag and drop.
   */
  updateCount: number;
  errorMessages: Array<string>;
  isInputInvalid: boolean;
  listenerDisposer: Maybe<() => void>;
};

enum EventTypes {
  Close = 'close',
  Update = 'update',
}

enum ErrorMessages {
  NoValueInput = '処分場を指定してください。',
  DuplicateValueInput = '同じ処分場を複数回指定することはできません。',
}

export default Vue.extend({
  name: 'RDisposalSiteSequenceDialog',
  components: {
    RDialog,
    RSearchablePulldown,
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<DisposalSiteEntity[]>,
      required: true,
    },
    preSelectedDisposalSiteIds: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data(): DataType {
    return {
      sortableInstance: undefined,
      selectedDisposalSiteIds: [undefined],
      updateCount: 0,
      errorMessages: [],
      isInputInvalid: true,
      listenerDisposer: undefined,
    };
  },
  watch: {
    isDialogActive(isDialogActiveValue: boolean) {
      if (isDialogActiveValue === true) {
        // Stop propagation of all keyboard key press events to parent components.
        this.createKeyboardEventListener();
      }

      if (isDialogActiveValue === true && 0 < this.preSelectedDisposalSiteIds.length) {
        this.selectedDisposalSiteIds = [...this.preSelectedDisposalSiteIds];
      }

      if (isDialogActiveValue === true && !this.sortableInstance) {
        // DOM element to sort doesn't exist at the instant when `isDialogActive`
        // is set to true since rendering of template is still not complete.
        // Hence instantiate `Sortable` at next tick when the template is ready
        // and DOM element to sort is defined.
        this.$nextTick(() => {
          this.createSortableInstance();
        });
      }

      // Dialogが閉じられるタイミングで不要になったインスタンスを破棄する
      // インスタンスが存在しない状態でdestorySortableInstanceをコールするとエラーが起きるのでインスタンスが存在するときのみコールするようにしている
      if (isDialogActiveValue === false && this.sortableInstance) {
        this.destroySortableInstance();
      }
    },
    selectedDisposalSiteIds: {
      handler() {
        // `onClickClose` and `onClickComplete` methods trigger this watch handler,
        // because of `selectedDisposalSiteIds = [undefined]`,
        // which shows validation error during dialog close animation.
        // Hence only validate when dialog is active.
        if (!this.isDialogActive) return;
        this.validateInput();
      },
      deep: true,
    },
  },
  methods: {
    validateInput() {
      this.errorMessages = [];

      // Check for duplicates without `undefined` values
      const definedSelectedDisposalSiteIds = this.selectedDisposalSiteIds.filter((id) => id !== undefined);
      const definedSelectedDisposalSiteIdsSet = new Set(definedSelectedDisposalSiteIds);
      const hasDuplicates = definedSelectedDisposalSiteIds.length !== definedSelectedDisposalSiteIdsSet.size;

      const areAllUndefined = this.selectedDisposalSiteIds.every((id) => id === undefined);

      if (areAllUndefined) this.errorMessages.push(ErrorMessages.NoValueInput);
      else if (hasDuplicates) this.errorMessages.push(ErrorMessages.DuplicateValueInput);

      this.isInputInvalid = hasDuplicates || areAllUndefined;
    },
    /**
     * Close dialog when `Esc` is pressed.
     * Stop propagation of all keyboard key press events to parents.
     */
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.onClickClose();
      }
      context.stop();
    },
    onClickClose() {
      this.disposeKeyboardEventListener();
      this.selectedDisposalSiteIds = [undefined];
      this.$emit(EventTypes.Close);
    },
    onClickComplete() {
      const definedSelectedDisposalSiteIds = this.selectedDisposalSiteIds.filter((id) => id !== undefined);
      this.$emit(EventTypes.Update, definedSelectedDisposalSiteIds);

      this.onClickClose();
    },
    onClickDelete(index: number) {
      this.selectedDisposalSiteIds.splice(index, 1);
    },
    onDragAndDrop(event: SortableEvent) {
      if (event.oldIndex === undefined || event.newIndex === undefined) {
        throw new Error("Can't find indexes of dragged item");
      }

      const itemToMove = this.selectedDisposalSiteIds[event.oldIndex];
      this.selectedDisposalSiteIds.splice(event.oldIndex, 1);
      this.selectedDisposalSiteIds.splice(event.newIndex, 0, itemToMove);

      this.updateCount++;
    },
    createSortableInstance() {
      const sortableEl = this.$refs.sortContainer as HTMLElement | undefined;
      if (!sortableEl) throw new Error("Can't create sortable instance since SortableEl is undefined");

      this.sortableInstance = new Sortable(sortableEl, {
        handle: '.r-disposal-site-sequence-dialog__input-item__sort-icon',
        onEnd: this.onDragAndDrop,
        // これをしないとドロップ後にドラッグ開始時のマウス位置にイベントが発生してしまう
        forceFallback: true,
      });
    },
    destroySortableInstance() {
      if (!this.sortableInstance) throw new Error("Can't destroy undefined SortableInstance");

      this.sortableInstance.destroy();
      this.sortableInstance = undefined;
    },
    addItemInList() {
      this.selectedDisposalSiteIds = [...this.selectedDisposalSiteIds, undefined];
    },
    createKeyboardEventListener() {
      const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
        this.onKeydown,
        KeyboardEventPriority.Dialog
      );
      this.listenerDisposer = () => {
        keyboardEventListenerDisposer.dispose();
      };
    },
    disposeKeyboardEventListener() {
      if (this.listenerDisposer !== undefined) this.listenerDisposer();
    },
  },
});
