import { OriginalCollectionEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalCollectionEntity';
import { CarUsage } from '~/pages/schedule/carUsage';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { AggregatedOrderEntity as IOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';

export class OriginalCollection extends OriginalCollectionEntity {
  order: IOrderEntity;
  orderCarAssignments: CarUsage[];

  constructor(
    id: string,
    index: number,
    orderId: PseudoId,
    generationSiteArrivalTime: number,
    generationSiteDepartureTime: number,
    hasRestBeforeGenerationSiteArrival: boolean,
    isFixedAssignment: boolean,
    order: IOrderEntity,
    orderCarAssignments: CarUsage[]
  ) {
    super(
      id,
      index,
      orderId,
      generationSiteArrivalTime,
      generationSiteDepartureTime,
      hasRestBeforeGenerationSiteArrival,
      isFixedAssignment
    );
    this.order = order;
    this.orderCarAssignments = orderCarAssignments;
  }
}
