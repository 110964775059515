import { StoredMapperBase } from '~/framework/core/mapper';
import {
  OfficeSettingEntityData,
  OfficeSettingEntity,
} from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { OfficeSettingStore } from '~/framework/domain/masters/office-setting/officeSettingStore';

export class OfficeSettingMapper extends StoredMapperBase<OfficeSettingEntityData, OfficeSettingEntity> {
  protected store: OfficeSettingStore;

  constructor(store: OfficeSettingStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: OfficeSettingEntityData): OfficeSettingEntity {
    return new OfficeSettingEntity(
      data.id,
      data.defaultDurationAtBaseSite,
      data.defaultDurationAtGenerationSite,
      data.defaultDurationAtDisposalSite,
      data.defaultRestPeriodStartOfGenerationSite,
      data.defaultRestPeriodEndOfGenerationSite,
      data.defaultRestPeriodStartOfDisposalSite,
      data.defaultRestPeriodEndOfDisposalSite,
      data.defaultBannedDriverIdsAtGenerationSite,
      data.defaultPreloadStatus,
      data.defaultRouteCollectionAllowed,
      data.defaultAvoidHighways,
      data.isPackingPlacementManagementEnabled,
      data.isFixedArrivalTimeReportEnabled,
      data.defaultIsFixedArrivalTimeReportNeeded,
      data.defaultMarginTypeOfFixedArrivalTime,
      data.defaultMarginOfFixedArrivalTime,
      data.isOnlineReservationEnabled,
      data.isPublishingStatusOfScheduleManagementEnabled,
      data.canPrintSchedules,
      data.canUseErp,
      data.useCsvImport,
      data.useCsvExport
    );
  }

  protected updateWithData(data: OfficeSettingEntityData, entity: OfficeSettingEntity): void {
    entity.defaultDurationAtBaseSite = data.defaultDurationAtBaseSite;
    entity.defaultDurationAtGenerationSite = data.defaultDurationAtGenerationSite;
    entity.defaultDurationAtDisposalSite = data.defaultDurationAtDisposalSite;
    entity.defaultRestPeriodStartOfGenerationSite = data.defaultRestPeriodStartOfGenerationSite;
    entity.defaultRestPeriodEndOfGenerationSite = data.defaultRestPeriodEndOfGenerationSite;
    entity.defaultRestPeriodStartOfDisposalSite = data.defaultRestPeriodStartOfDisposalSite;
    entity.defaultRestPeriodEndOfDisposalSite = data.defaultRestPeriodEndOfDisposalSite;
    entity.defaultBannedDriverIdsAtGenerationSite = data.defaultBannedDriverIdsAtGenerationSite;
    entity.defaultPreloadStatus = data.defaultPreloadStatus;
    entity.defaultRouteCollectionAllowed = data.defaultRouteCollectionAllowed;
    entity.defaultAvoidHighways = data.defaultAvoidHighways;
    entity.isPackingPlacementManagementEnabled = data.isPackingPlacementManagementEnabled;
    entity.isOnlineReservationEnabled = data.isOnlineReservationEnabled;
    entity.isPublishingStatusOfScheduleManagementEnabled = data.isPublishingStatusOfScheduleManagementEnabled;
    entity.canPrintSchedules = data.canPrintSchedules;
    entity.canUseErp = data.canUseErp;
    entity.useCsvImport = data.useCsvImport;
    entity.useCsvExport = data.useCsvExport;
  }
}
