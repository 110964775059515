import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import {
  DriverAttendanceEntity,
  DriverAttendanceEntityData,
} from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { AttendanceEntity, AttendanceEntityData } from '~/framework/domain/masters/attendance/attendanceEntity';
import {
  AggregatedDriverEntity,
  AggregatedDriverEntityData,
} from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedCarEntity, AggregatedCarEntityData } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { Maybe } from '~/framework/typeAliases';

export interface AggregatedDriverAttendanceEntityData extends DriverAttendanceEntityData {
  attendance: AttendanceEntityData;
  driver: AggregatedDriverEntityData;
  primaryCar: AggregatedCarEntityData;
}

export class AggregatedDriverAttendanceEntity extends DriverAttendanceEntity {
  attendance: AttendanceEntity;
  driver: AggregatedDriverEntity;
  primaryCar: AggregatedCarEntity;

  constructor(
    id: string,
    attendanceId: string,
    driverId: string,
    primaryCarId: string,
    forceRidePrimaryCar: boolean,
    templateName: string,
    regularWorkPeriodStart: number,
    regularWorkPeriodEnd: number,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    overtimeWorkType: OvertimeWorkType,
    overtimeWorkableDuration: number,
    attendance: AttendanceEntity,
    driver: AggregatedDriverEntity,
    primaryCar: AggregatedCarEntity
  ) {
    super(
      id,
      attendanceId,
      driverId,
      primaryCarId,
      forceRidePrimaryCar,
      templateName,
      regularWorkPeriodStart,
      regularWorkPeriodEnd,
      restPeriodStart,
      restPeriodEnd,
      overtimeWorkType,
      overtimeWorkableDuration
    );
    this.attendance = attendance;
    this.driver = driver;
    this.primaryCar = primaryCar;
  }
}
