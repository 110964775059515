import _ from 'lodash';
import { IRouteEntityData } from '~/framework/domain/schedule/schedule/pseudo-entities/routeEntity';
import { IOriginalCollectionEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalCollectionEntity';
import { IOriginalDisposalEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalDisposalEntity';
import { IInconsistentRouteInfoData } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfoData';
import { Maybe } from '~/framework/typeAliases';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';

/**
 * 編集前のルート情報を保持する
 * 同じ ID の別インスタンスを生成するのは気持ち悪いのだが、致し方なしと判断
 */
export interface IOriginalRouteEntity<
  Collection extends IOriginalCollectionEntity,
  Disposal extends IOriginalDisposalEntity,
  InconsistentRouteInfo extends IInconsistentRouteInfoData
> extends IRouteEntityData<Collection, Disposal, InconsistentRouteInfo> {
  /**
   * route にかかる時間
   */
  routeDuration: number;

  /**
   * 名前がどうなのかという感じもするが、排出場から排出場までの平均的な移動時間
   *
   * 全ての移動時間を足して割ったもの。回収が 1 つしかないルートには移動時間が存在しないので、
   * その場合には undefined になる。
   */
  averageGenerationSiteTravelTime: Maybe<number>;

  /**
   * 回収にどれだけの時間を使っているか
   * 最初の回収の arrival 〜 最後の回収の departure の幅
   */
  collectionDuration: number;
}

export class OriginalRouteEntity<
  Collection extends IOriginalCollectionEntity,
  Disposal extends IOriginalDisposalEntity,
  InconsistentRouteInfo extends IInconsistentRouteInfoData
> implements IOriginalRouteEntity<Collection, Disposal, InconsistentRouteInfo>
{
  baseSiteArrivalTime: Maybe<number>;
  baseSiteDepartureTime: Maybe<number>;
  baseSiteId: Maybe<PseudoId>;
  carId: Maybe<PseudoId>;
  carIndex: Maybe<number>;
  driverId: PseudoId;
  isDriver: Maybe<boolean>;
  isHelper: Maybe<boolean>;
  endTime: number;
  garageSiteArrivalId: Maybe<PseudoId>;
  garageSiteArrivalTime: Maybe<number>;
  garageSiteDepartureId: Maybe<PseudoId>;
  garageSiteDepartureTime: Maybe<number>;
  hasRestBeforeBaseSiteArrival: Maybe<boolean>;
  hasRestBeforeGarageSiteArrival: Maybe<boolean>;
  hasRestAfterGenerationSiteDeparture: Maybe<boolean>;
  hasRestAfterDisposalSiteDeparture: Maybe<boolean>;
  hasChangeCar: Maybe<boolean>;
  id: string;
  index: number;
  isFixedAssignment: boolean;
  isFixedSchedule: boolean;
  startTime: number;
  collections: Collection[];
  disposals: Disposal[];
  inconsistentLoadingRouteInfos: InconsistentRouteInfo[];
  inconsistentTimeRouteInfos: InconsistentRouteInfo[];

  get routeDuration(): number {
    return this.endTime - this.startTime;
  }

  get averageGenerationSiteTravelTime(): Maybe<number> {
    // 一つしかないものには移動時間は存在しない
    if (this.collections.length === 1) return undefined;

    let travelTime = 0;
    let lastGenerationSiteDepartureTime: Maybe<number>;
    for (const collection of this.collections) {
      if (lastGenerationSiteDepartureTime !== undefined) {
        travelTime += collection.generationSiteArrivalTime - lastGenerationSiteDepartureTime;
      }
      lastGenerationSiteDepartureTime = collection.generationSiteDepartureTime;
    }
    return travelTime / (this.collections.length - 1);
  }

  get collectionDuration(): number {
    const first = _.first(this.collections)!;
    const last = _.last(this.collections)!;
    return last.generationSiteDepartureTime - first.generationSiteArrivalTime;
  }

  constructor(
    id: string,
    index: number,
    carId: Maybe<PseudoId>,
    carIndex: Maybe<number>,
    driverId: PseudoId,
    isDriver: Maybe<boolean>,
    isHelper: Maybe<boolean>,
    startTime: number,
    endTime: number,
    garageSiteDepartureId: Maybe<PseudoId>,
    garageSiteDepartureTime: Maybe<number>,
    garageSiteArrivalId: Maybe<PseudoId>,
    garageSiteArrivalTime: Maybe<number>,
    hasRestBeforeGarageSiteArrival: Maybe<boolean>,
    hasChangeCar: Maybe<boolean>,
    baseSiteId: Maybe<PseudoId>,
    baseSiteArrivalTime: Maybe<number>,
    baseSiteDepartureTime: Maybe<number>,
    hasRestBeforeBaseSiteArrival: Maybe<boolean>,
    hasRestAfterGenerationSiteDeparture: Maybe<boolean>,
    hasRestAfterDisposalSiteDeparture: Maybe<boolean>,
    isFixedAssignment: boolean,
    isFixedSchedule: boolean,
    collections: Collection[],
    disposals: Disposal[],
    inconsistentLoadingRouteInfos: InconsistentRouteInfo[],
    inconsistentTimeRouteInfos: InconsistentRouteInfo[]
  ) {
    this.id = id;
    this.index = index;
    this.carId = carId;
    this.carIndex = carIndex;
    this.driverId = driverId;
    this.isDriver = isDriver;
    this.isHelper = isHelper;
    this.startTime = startTime;
    this.endTime = endTime;
    this.garageSiteDepartureId = garageSiteDepartureId;
    this.garageSiteDepartureTime = garageSiteDepartureTime;
    this.garageSiteArrivalId = garageSiteArrivalId;
    this.garageSiteArrivalTime = garageSiteArrivalTime;
    this.hasRestBeforeGarageSiteArrival = hasRestBeforeGarageSiteArrival;
    this.hasChangeCar = hasChangeCar;
    this.baseSiteId = baseSiteId;
    this.baseSiteArrivalTime = baseSiteArrivalTime;
    this.baseSiteDepartureTime = baseSiteDepartureTime;
    this.hasRestBeforeBaseSiteArrival = hasRestBeforeBaseSiteArrival;
    this.hasRestAfterGenerationSiteDeparture = hasRestAfterGenerationSiteDeparture;
    this.hasRestAfterDisposalSiteDeparture = hasRestAfterDisposalSiteDeparture;
    this.isFixedAssignment = isFixedAssignment;
    this.isFixedSchedule = isFixedSchedule;
    this.collections = collections;
    this.disposals = disposals;
    this.inconsistentLoadingRouteInfos = inconsistentLoadingRouteInfos;
    this.inconsistentTimeRouteInfos = inconsistentTimeRouteInfos;
  }
}
