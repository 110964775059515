import _ from 'lodash';
import { IBusinessDaysService } from '~/framework/services/business-days/businessDaysService';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { MarginType, OrderDefault, PreloadStatus } from '~/framework/domain/typeAliases';
import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';
import { IFormValues as IOrderFormValues } from '~/framework/view-models/panels/orderFormPanel';
import { IFormValues as IReservationFormValues } from '~/framework/view-models/panels/reservationFormPanel';
import DateCollectablePeriodTypes from '~/components/panels/schedule/r-order-form/dateCollectablePeriodTypes';
import { Maybe } from '~/framework/typeAliases';
import { DefaultCollectablePeriod } from '~/components/pages/masters/generation-site/r-generation-site/defaultCollectablePeriod';

export interface IOrderDefaultService {
  /**
   * Get default value for route collection allowed.
   */
  defaultRouteCollectionAllowed(): boolean;

  /**
   * Get default value for is fixed arrival time report needed.
   */
  defaultIsFixedArrivalTimeReportNeeded(): boolean;

  /**
   * Get default value for margin type of fixed arrival time.
   */
  defaultMarginTypeOfFixedArrivalTime(): MarginType;

  /**
   * Get default value for margin of fixed arrival time.
   */
  defaultMarginOfFixedArrivalTime(): number;

  /**
   * Get default collectable period template.
   * @param collectablePeriodTemplateEntities
   */
  defaultCollectablePeriodTemplate(
    collectablePeriodTemplateEntities: Array<CollectablePeriodTemplateEntity>
  ): CollectablePeriodTemplateEntity;

  /**
   * Get default preload status, and date collectable period items with updated unload date.
   * @param dateCollectablePeriodItems
   * @param businessDaysService
   */
  defaultPreloadStatusWithUpdatedUnloadDate(
    dateCollectablePeriodItems: Array<DateCollectablePeriodItem>,
    businessDaysService: IBusinessDaysService
  ): {
    defaultPreloadStatus: PreloadStatus;
    dateCollectablePeriodItemsWithUpdatedUnloadDate: Array<DateCollectablePeriodItem>;
  };

  /**
   * Update form values with defaults. Update is skipped for an argument that is undefined.
   * @param formValues
   * @param defaultRouteCollectionAllowed
   * @param defaultPreloadStatus
   * @param defaultIsFixedArrivalTimeReportNeeded
   * @param defaultMarginTypeOfFixedArrivalTime
   * @param defaultMarginOfFixedArrivalTime
   * @param datePeriodCollectableItemsWithUpdatedUnloadDate
   * @param defaultCollectablePeriodTemplate
   */
  updateFormValuesWithDefaults(
    formValues: IOrderFormValues,
    defaultRouteCollectionAllowed: Maybe<boolean>,
    defaultPreloadStatus: Maybe<PreloadStatus>,
    datePeriodCollectableItemsWithUpdatedUnloadDate: Maybe<Array<DateCollectablePeriodItem>>,
    defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>,
    defaultMarginTypeOfFixedArrivalTime: Maybe<MarginType>,
    defaultMarginOfFixedArrivalTime: Maybe<number>,
    defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>,
    defaultCollectablePeriodStart: Maybe<number>,
    defaultCollectablePeriodEnd: Maybe<number>
  ): void;

  /**
   * Update reservation form values with defaults. Update is skipped for an argument that is undefined.
   * @param formValues
   * @param defaultRouteCollectionAllowed
   * @param defaultIsFixedArrivalTimeReportNeeded
   * @param defaultMarginTypeOfFixedArrivalTime
   * @param defaultMarginOfFixedArrivalTime
   * @param defaultCollectablePeriodTemplate
   */
  updateReservationFormValuesWithDefaults(
    formValues: IReservationFormValues,
    defaultRouteCollectionAllowed: Maybe<boolean>,
    defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>,
    defaultMarginTypeOfFixedArrivalTime: Maybe<MarginType>,
    defaultMarginOfFixedArrivalTime: Maybe<number>
  ): void;
}

export class OrderDefaultService implements IOrderDefaultService {
  private readonly orderDefault: OrderDefault;

  constructor(orderDefault: OrderDefault) {
    this.orderDefault = orderDefault;
  }

  defaultRouteCollectionAllowed() {
    return this.orderDefault.defaultRouteCollectionAllowed;
  }

  defaultIsFixedArrivalTimeReportNeeded() {
    return this.orderDefault.defaultIsFixedArrivalTimeReportNeeded;
  }

  defaultMarginTypeOfFixedArrivalTime() {
    return this.orderDefault.defaultMarginTypeOfFixedArrivalTime;
  }

  defaultMarginOfFixedArrivalTime() {
    return this.orderDefault.defaultMarginOfFixedArrivalTime;
  }

  defaultPreloadStatusWithUpdatedUnloadDate(
    dateCollectablePeriodItems: Array<DateCollectablePeriodItem>,
    businessDaysService: IBusinessDaysService
  ) {
    const defaultPreloadStatus = this.orderDefault.defaultPreloadStatus;
    const dateCollectablePeriodItemsWithUpdatedUnloadDate = _.cloneDeep(dateCollectablePeriodItems);

    if (this.orderDefault.defaultPreloadStatus === PreloadStatus.Forced) {
      for (const dateCollectablePeriodItem of dateCollectablePeriodItemsWithUpdatedUnloadDate) {
        const nextBusinessDate = businessDaysService.getNextBusinessDate(dateCollectablePeriodItem.getDate());
        dateCollectablePeriodItem.unloadDate = nextBusinessDate;
      }
    } else {
      for (const dateCollectablePeriodItem of dateCollectablePeriodItemsWithUpdatedUnloadDate) {
        dateCollectablePeriodItem.unloadDate = undefined;
      }
    }

    return {
      defaultPreloadStatus,
      dateCollectablePeriodItemsWithUpdatedUnloadDate,
    };
  }

  defaultCollectablePeriodTemplate(collectablePeriodTemplateEntities: Array<CollectablePeriodTemplateEntity>) {
    const defaultCollectablePeriodTemplate = collectablePeriodTemplateEntities.find(
      (template) => template.id === this.orderDefault.defaultCollectablePeriodTemplateId
    );
    if (!defaultCollectablePeriodTemplate) throw new Error("Can't find defaultCollectablePeriodTemplate by id");
    return defaultCollectablePeriodTemplate;
  }

  updateFormValuesWithDefaults(
    formValues: IOrderFormValues,
    defaultRouteCollectionAllowed: Maybe<boolean>,
    defaultPreloadStatus: Maybe<PreloadStatus>,
    datePeriodCollectableItemsWithUpdatedUnloadDate: Maybe<Array<DateCollectablePeriodItem>>,
    defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>,
    defaultMarginTypeOfFixedArrivalTime: Maybe<MarginType>,
    defaultMarginOfFixedArrivalTime: Maybe<number>,
    defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>,
    defaultCollectablePeriodStart: Maybe<number>,
    defaultCollectablePeriodEnd: Maybe<number>
  ): void {
    if (defaultRouteCollectionAllowed !== undefined) {
      formValues.routeCollectionAllowed = defaultRouteCollectionAllowed;
    }

    if (defaultPreloadStatus !== undefined && datePeriodCollectableItemsWithUpdatedUnloadDate !== undefined) {
      formValues.preloadStatus = defaultPreloadStatus;
      formValues.dateCollectablePeriodItems = datePeriodCollectableItemsWithUpdatedUnloadDate;
    }

    if (defaultIsFixedArrivalTimeReportNeeded !== undefined) {
      formValues.isFixedArrivalTimeReportNeeded = defaultIsFixedArrivalTimeReportNeeded;
    }

    if (defaultMarginTypeOfFixedArrivalTime !== undefined) {
      formValues.marginTypeOfFixedArrivalTime = defaultMarginTypeOfFixedArrivalTime;
    }

    if (defaultMarginOfFixedArrivalTime !== undefined) {
      formValues.marginOfFixedArrivalTime = defaultMarginOfFixedArrivalTime;
    }

    const hasDefaultCollectablePeriod =
      defaultCollectablePeriodTemplate !== undefined ||
      defaultCollectablePeriodStart !== undefined ||
      defaultCollectablePeriodEnd !== undefined;

    // NOTE: 到着日時が詳細指定されている場合は上書きしない
    // NOTE: デフォルト到着時間が全て未設定の場合は formValue を更新しない
    if (formValues.dateCollectablePeriodType === DateCollectablePeriodTypes.Default && hasDefaultCollectablePeriod) {
      // NOTE: デフォルト到着時間テンプレートの template, start, end からフォームに反映すべき値を計算してくれるオブジェクト
      const defaultCollectablePeriod = new DefaultCollectablePeriod(
        defaultCollectablePeriodTemplate,
        defaultCollectablePeriodStart,
        defaultCollectablePeriodEnd
      );

      // 詳細指定なしの場合は DateCollectablePeriodItem が1件のみのはずなので、1件として上書きする
      formValues.dateCollectablePeriodItems = [
        new DateCollectablePeriodItem(
          formValues.dateCollectablePeriodItems[0].date,
          defaultCollectablePeriod.templateName,
          defaultCollectablePeriod.isCollectablePeriodDistinct() ? undefined : defaultCollectablePeriod.start,
          defaultCollectablePeriod.isCollectablePeriodDistinct() ? undefined : defaultCollectablePeriod.end,
          formValues.dateCollectablePeriodItems[0].unloadDate,
          formValues.dateCollectablePeriodItems[0].carNum,
          defaultCollectablePeriod.templateId,
          defaultCollectablePeriod.isCollectablePeriodDistinct() ? defaultCollectablePeriod.distinct : undefined
        ),
      ];
    }
  }

  updateReservationFormValuesWithDefaults(
    formValues: IReservationFormValues,
    defaultRouteCollectionAllowed: Maybe<boolean>,
    defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>,
    defaultMarginTypeOfFixedArrivalTime: Maybe<MarginType>,
    defaultMarginOfFixedArrivalTime: Maybe<number>
  ): void {
    if (defaultRouteCollectionAllowed !== undefined) {
      formValues.routeCollectionAllowed = defaultRouteCollectionAllowed;
    }

    if (defaultIsFixedArrivalTimeReportNeeded !== undefined) {
      formValues.isFixedArrivalTimeReportNeeded = defaultIsFixedArrivalTimeReportNeeded;
    }

    if (defaultMarginTypeOfFixedArrivalTime !== undefined) {
      formValues.marginTypeOfFixedArrivalTime = defaultMarginTypeOfFixedArrivalTime;
    }

    if (defaultMarginOfFixedArrivalTime !== undefined) {
      formValues.marginOfFixedArrivalTime = defaultMarginOfFixedArrivalTime;
    }
  }
}
