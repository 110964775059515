import { INamespace, NamespaceBase } from '~/framework/core/namespace';
import { IReactiveBEntity } from '~/framework/domain/schedule/b/bEntity';

export interface IBStore extends INamespace<IReactiveBEntity> {
  //
}

export class BStore extends NamespaceBase<IReactiveBEntity> implements IBStore {
  //
}
