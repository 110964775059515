import { DriverType } from '~/framework/domain/typeAliases';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { PersistentId } from '~/framework/typeAliases';

export interface IOrderAssignableDriver {
  driverId: string;
  driverType: DriverType;
}

/**
 * IOrderAssignableDriver[] から 特定の driverType を指定して driverId[] を取得する
 */
export function getAssignableDriverIdsByDriverType(
  driverType: DriverType,
  assignableDrivers: IOrderAssignableDriver[]
): string[] {
  return assignableDrivers
    .filter((assignableDriver) => assignableDriver.driverType === driverType)
    .map((assignableDriver) => assignableDriver.driverId);
}

/**
 * driverType を指定して driverId から IOrderAssignableDriver を生成する
 */
export function buildAssignableDriversById(driverType: DriverType, driverId: PersistentId): IOrderAssignableDriver {
  return {
    driverType,
    driverId,
  };
}

/**
 * driverType を指定して driverId[] から IOrderAssignableDriver[] を生成する
 */
export function buildAssignableDriversByIds(
  driverType: DriverType,
  driverIds: PersistentId[]
): IOrderAssignableDriver[] {
  return driverIds.map((driverId) => buildAssignableDriversById(driverType, driverId));
}

/**
 * 乗務員の人数と指定された乗務員の人数を指定して候補かどうか計算する
 * 乗務員の人数より多く指定されていると候補
 */
export function isDriversCandidate(assignedDriverNum: number, assignableDrivers: IOrderAssignableDriver[]) {
  return (
    assignableDrivers.filter((assignableDriver) => assignableDriver.driverType === DriverType.Driver).length >
    assignedDriverNum
  );
}

/**
 * 車台数と指定された運転者の人数から候補かどうか計算する
 * 車台数より多い場合は候補となる
 */
export function isOperatorsCandidate(carNum: number, assignableDrivers: IOrderAssignableDriver[]) {
  return (
    assignableDrivers.filter((assignableDriver) => assignableDriver.driverType === DriverType.Operator).length > carNum
  );
}

/**
 * 乗務員の人数と指定された乗務員の人数を指定して候補かどうか計算する
 * 乗務員の人数 - 車台数より補助員指定が多ければ候補となる
 */
export function isHelpersCandidate(
  carNum: number,
  assignedDriverNum: number,
  assignableDrivers: IOrderAssignableDriver[]
) {
  return (
    assignableDrivers.filter((assignableDriver) => assignableDriver.driverType === DriverType.Helper).length >
    assignedDriverNum - carNum
  );
}

/**
 * IOrderAssignableDriver[] から DriverType を指定してその entity を検索して取得する
 */
export function getDriverEntities(
  driverType: DriverType,
  assignableDrivers: IOrderAssignableDriver[],
  driverEntities: DriverEntity[]
): DriverEntity[] {
  const driverIds = assignableDrivers
    .filter((assignableDriver) => assignableDriver.driverType === driverType)
    .map((assignableDriver) => assignableDriver.driverId);
  return driverEntities.filter((driverEntity) => driverIds.includes(driverEntity.id));
}
