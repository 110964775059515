import { Maybe } from '~/framework/typeAliases';

export interface CollectablePeriodTemplateEntityData {
  id: string;
  name: string;
  collectablePeriodStart: Maybe<number>;
  collectablePeriodEnd: Maybe<number>;
  note: Maybe<string>;
  isDefault: boolean;
}

export class CollectablePeriodTemplateEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  collectablePeriodStart: Maybe<number>;
  collectablePeriodEnd: Maybe<number>;
  note: Maybe<string>;
  isDefault: boolean;

  constructor(
    id: string,
    name: string,
    collectablePeriodStart: Maybe<number>,
    collectablePeriodEnd: Maybe<number>,
    note: Maybe<string>,
    isDefault: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.collectablePeriodStart = collectablePeriodStart;
    this.collectablePeriodEnd = collectablePeriodEnd;
    this.note = note;
    this.isDefault = isDefault;
  }
}
