import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  collectablePeriodTemplate$getAllSymbol,
  collectablePeriodTemplate$updateSymbol,
  collectablePeriodTemplate$createSymbol,
} from '~/framework/server-api/masters/collectablePeriodTemplate';
import {
  CollectablePeriodTemplateEntity,
  CollectablePeriodTemplateEntityData,
} from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { CollectablePeriodTemplateMapper } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateMapper';

import { WithoutId } from '~/framework/typeAliases';

export const collectablePeriodTemplateSymbol = Symbol('collectablePeriodTemplate');

export class CollectablePeriodTemplateApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async getAll(): Promise<CollectablePeriodTemplateEntity[]> {
    const getAllApi = this.serverApis.get(collectablePeriodTemplate$getAllSymbol);
    const mapper: CollectablePeriodTemplateMapper = new CollectablePeriodTemplateMapper(
      this.store.masters.collectablePeriodTemplate
    );
    const result = await getAllApi.getAll();
    const entities = mapper.map(result);
    return entities;
  }

  async update(data: CollectablePeriodTemplateEntityData): Promise<CollectablePeriodTemplateEntity> {
    const updateApi = this.serverApis.get(collectablePeriodTemplate$updateSymbol);
    await updateApi.update([data]);
    // NOTE: updating entity with `isDefault: true` sets all other entity's `isDefault` to `false`.
    // Hence all entities must be fetched again.
    const results = await this.getAll();

    const mapper: CollectablePeriodTemplateMapper = new CollectablePeriodTemplateMapper(
      this.store.masters.collectablePeriodTemplate
    );
    const entities = mapper.map(results);
    return entities.find((entity) => entity.id === data.id)!;
  }

  async create(data: WithoutId<CollectablePeriodTemplateEntityData>): Promise<CollectablePeriodTemplateEntity> {
    const createApi = this.serverApis.get(collectablePeriodTemplate$createSymbol);
    const ids = await createApi.create([data]);
    // NOTE: creating entity with `isDefault: true` sets all other entity's `isDefault` to `false`.
    // Hence all entities must be fetched again.
    const results = await this.getAll();
    const mapper: CollectablePeriodTemplateMapper = new CollectablePeriodTemplateMapper(
      this.store.masters.collectablePeriodTemplate
    );
    const entities = mapper.map(results);
    return entities.find((entity) => entity.id === ids[0])!;
  }

  async getDefault(): Promise<CollectablePeriodTemplateEntity> {
    const allEntities = await this.getAll();
    const defaultEntity = allEntities.find((template) => template.isDefault === true);
    // One entity set as default will always exist as per specifications.
    if (!defaultEntity) throw new Error('Default `collectablePeriodTemplate` was not found!');
    return defaultEntity;
  }
}
