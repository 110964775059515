
import Vue, { PropType } from 'vue';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';

enum EventTypes {
  OpenEditDialog = 'open:edit-dialog',
}

export default Vue.extend({
  name: 'RDriverAssignmentMultipleView',
  props: {
    label: {
      type: String,
      required: true,
    },
    driverEntities: {
      type: Array as PropType<DriverEntity[]>,
      required: false,
      default: () => [],
    },
    isCandidate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      maxDisplayCount: 2,
    };
  },
  computed: {
    isOverflow(): boolean {
      return this.driverEntities.length > this.maxDisplayCount;
    },
    displayDrivers(): Array<DriverEntity> {
      if (this.isOverflow) return this.driverEntities.slice(0, this.maxDisplayCount);

      return this.driverEntities;
    },
  },
  methods: {
    openEditDialog() {
      this.$emit(EventTypes.OpenEditDialog);
    },
  },
});
