import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedPackingPlacementEntity,
  AggregatedPackingPlacementEntityData,
} from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementEntity';
import { AggregatedPackingPlacementStore } from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementStore';
import { ContainerTypeMapper } from '~/framework/domain/masters/container-type/containerTypeMapper';

export class AggregatedPackingPlacementMapper extends StoredMapperBase<
  AggregatedPackingPlacementEntityData,
  AggregatedPackingPlacementEntity
> {
  protected store: AggregatedPackingPlacementStore;
  private containerTypeMapper: ContainerTypeMapper;

  constructor(store: AggregatedPackingPlacementStore) {
    super();
    this.store = store;
    this.containerTypeMapper = new ContainerTypeMapper();
  }

  protected instantiateWithData(data: AggregatedPackingPlacementEntityData): AggregatedPackingPlacementEntity {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    return new AggregatedPackingPlacementEntity(
      data.id,
      data.containerTypeId,
      data.num,
      data.lastAllocatedAt,
      containerType
    );
  }

  protected updateWithData(data: AggregatedPackingPlacementEntityData, entity: AggregatedPackingPlacementEntity) {
    const containerType = this.containerTypeMapper.mapSingle(data.containerType);
    entity.containerTypeId = data.containerTypeId;
    entity.num = data.num;
    entity.lastAllocatedAt = data.lastAllocatedAt;
    entity.containerType = containerType;
  }
}
