import { IDriverData } from '~/framework/server-api/masters/driver';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { car$getAllSymbol } from '~/framework/server-api/masters/car';
import { carType$getAllSymbol } from '~/framework/server-api/masters/carType';
import { driverAttendanceTemplate$getAllSymbol } from '~/framework/server-api/masters/driverAttendanceTemplate';
import { AggregatedDriverMapper } from '~/framework/domain/masters/driver/aggregatedDriverMapper';
import { mapData } from '~/framework/core/mapper';
import { containerType$getAllSymbol } from '~/framework/server-api/masters/containerType';

export interface IDriverFactory {
  buildByData(data: IDriverData[]): Promise<AggregatedDriverEntity[]>;
}

export class DriverFactory implements IDriverFactory {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async buildByData(data: IDriverData[]): Promise<AggregatedDriverEntity[]> {
    const car$getAllApi = this.serverApis.get(car$getAllSymbol);
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const driverAttendanceTemplate$getAll = this.serverApis.get(driverAttendanceTemplate$getAllSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const driverMapper: AggregatedDriverMapper = new AggregatedDriverMapper(
      this.store.masters.aggregatedDriver,
      this.store.masters.aggregatedCar,
      this.store.masters.aggregatedCarType,
      this.store.masters.orderGroup,
      this.store.masters.aggregatedCarTypeContainerType,
      this.store.masters.aggregatedBaseSite,
      this.store.masters.driverAttendanceTemplate,
      this.store.masters.user
    );

    const [carData, carTypeData, driverAttendanceTemplateData, containerTypesData] = await Promise.all([
      car$getAllApi.getAll(),
      carType$getAllApi.getAll(),
      driverAttendanceTemplate$getAll.getAll(),
      containerType$getAllApi.getAll(),
    ]);

    const carTypeMap = mapData(
      carTypeData.map((carType) => {
        return {
          ...carType,
          orderGroupId: carType.orderGroup.id,
        };
      }),
      'id'
    );
    const containerTypeDataMap = mapData(containerTypesData, 'id');
    const carsEntityData = carData.map((car) => {
      const carTypeEntityData = {
        ...car.carType,
        orderGroupId: car.carType.orderGroup.id,
        loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      return { ...car, carType: carTypeEntityData };
    });
    const carMap = mapData(carsEntityData, 'id');

    const driverAttendanceTemplateMap = mapData(driverAttendanceTemplateData, 'id');

    const drivers = driverMapper.map(
      data.map((aData) => {
        const defaultPrimaryCar = aData.defaultPrimaryCarId?.map(carMap);
        const defaultAttendanceTemplate = aData.defaultAttendanceTemplateId?.map(driverAttendanceTemplateMap);
        const licensedCarTypes = aData.licensedCarTypeIds.map((id) => carTypeMap.getOrError(id));
        return {
          id: aData.id,
          name: aData.name,
          nameRuby: aData.nameRuby,
          employmentStatus: aData.employmentStatus,
          defaultPrimaryCarId: aData.defaultPrimaryCarId,
          licensedCarTypeIds: aData.licensedCarTypeIds,
          defaultAttendanceTemplateId: aData.defaultAttendanceTemplateId,
          status: aData.status,
          signInUrl: aData.signInUrl,
          defaultPrimaryCar,
          licensedCarTypes,
          defaultAttendanceTemplate,
        };
      })
    );

    return drivers;
  }
}
