import { DayOfWeek } from '~/framework/typeAliases';

export type HolidayRuleFields = Pick<
  HolidayRuleEntity,
  'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday' | 'nationalHoliday'
>;

export interface HolidayRuleEntityData {
  id: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  nationalHoliday: boolean;
}

export class HolidayRuleEntity implements HolidayRuleEntity {
  readonly id: string;
  readonly persistentId: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  nationalHoliday: boolean;

  constructor(
    id: string,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
    nationalHoliday: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.monday = monday;
    this.tuesday = tuesday;
    this.wednesday = wednesday;
    this.thursday = thursday;
    this.friday = friday;
    this.saturday = saturday;
    this.sunday = sunday;
    this.nationalHoliday = nationalHoliday;
  }

  isDayHoliday(day: DayOfWeek): boolean {
    if (day === 0) return this.sunday;
    if (day === 1) return this.monday;
    if (day === 2) return this.tuesday;
    if (day === 3) return this.wednesday;
    if (day === 4) return this.thursday;
    if (day === 5) return this.friday;
    if (day === 6) return this.saturday;
    throw new Error(`Impossible day: ${day}`);
  }

  // 本当は文字はリソースに切り出した方がいい
  getDayLabelBy(fieldName: keyof HolidayRuleEntity): string {
    if (fieldName === 'monday') return '月';
    if (fieldName === 'tuesday') return '火';
    if (fieldName === 'wednesday') return '水';
    if (fieldName === 'thursday') return '木';
    if (fieldName === 'friday') return '金';
    if (fieldName === 'saturday') return '土';
    if (fieldName === 'sunday') return '日';
    if (fieldName === 'nationalHoliday') return '祝日';
    throw new Error(`Impossible fieldName: ${fieldName}`);
  }
}
