import { Maybe } from '~/framework/typeAliases';
import { WasteCategory, WasteTypeStatus } from '~/framework/domain/typeAliases';
import { WasteTypeEntity, WasteTypeEntityData } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import {
  JwnetWasteMasterEntity,
  JwnetWasteMasterEntityData,
} from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';
import { UserEntity, UserEntityData } from '~/framework/domain/masters/user/userEntity';

export interface AggregatedWasteTypeEntityData extends WasteTypeEntityData {
  jwnetWasteMaster: Maybe<JwnetWasteMasterEntityData>;
  createdBy: UserEntityData;
  updatedBy: UserEntityData;
}

export class AggregatedWasteTypeEntity extends WasteTypeEntity {
  jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>;
  createdBy: UserEntity;
  updatedBy: UserEntity;

  constructor(
    id: string,
    category: WasteCategory,
    code: string,
    name: string,
    status: WasteTypeStatus,
    isProminent: boolean,
    createdAt: Date,
    updatedAt: Date,
    jwnetWasteMaster: Maybe<JwnetWasteMasterEntity>,
    createdBy: UserEntity,
    updatedBy: UserEntity
  ) {
    super(id, category, code, name, status, isProminent, createdAt, updatedAt);
    this.jwnetWasteMaster = jwnetWasteMaster;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }
}
