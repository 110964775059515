
import Vue, { PropType } from 'vue';
import RGenerationSiteOptions from '~/components/panels/schedule/r-order-form/RGenerationSiteOptions.vue';
import { EnteredGenerationSiteNameState, OrderForm } from '~/components/panels/schedule/r-order-form/ROrderForm';
import { AdditionalInfoKeys, PageNames, RinEventNames } from '~/framework/services/rin-events/rinEventParams';
import { ExtractVue, Maybe } from '~/framework/typeAliases';
import { OrderApplicationService } from '~/framework/application/schedule/order/orderApplicationService';
import { SearchOrdersConditions } from '~/pages/schedule/orders/searchOrders';
import { BusinessDaysService } from '~/framework/services/business-days/businessDaysService';
import RLazySearchablePulldown from '~/components/common/r-lazy-searchable-pulldown/RLazySearchablePulldown.vue';

export default Vue.extend({
  components: { RLazySearchablePulldown, RGenerationSiteOptions },
  props: {
    orderApplicationService: {
      type: Object as PropType<OrderApplicationService>,
      required: true,
    },
    businessDaysService: {
      type: Object as PropType<BusinessDaysService>,
      required: true,
    },
    viewModel: {
      type: Object as PropType<OrderForm>,
      required: true,
    },
  },
  data() {
    return {
      generationSiteId: '',
      clientId: '',
    };
  },
  computed: {
    EnteredGenerationSiteNameState() {
      return EnteredGenerationSiteNameState;
    },
  },
  methods: {
    onClickCreateGenerationSiteButton(): void {
      const searchInput: Maybe<string> = (
        this.$refs.searchablePulldownGenerationSite as ExtractVue<typeof RLazySearchablePulldown>
      ).searchInput;
      this.$emit('onClickCreateGenerationSite', searchInput);
    },

    searchOrders(): void {
      if (!this.viewModel) throw new Error('Impossible!');

      this.$rinGtm.push(RinEventNames.TRANSIT, {
        [AdditionalInfoKeys.FROM]: PageNames.ORDER_FORM,
        [AdditionalInfoKeys.TO]: PageNames.SEARCH_ORDERS,
      });

      const searchOrdersConditions: SearchOrdersConditions = {
        clientId: this.viewModel.clientId,
        generationSiteId: this.viewModel.generationSiteId,
      };

      this.$context.events.searchOrdersEvent.emit(searchOrdersConditions);
      this.$emit('close');
    },
    onClickCreateGenerationSitePopup(): void {
      this.$emit('onClickCreateGenerationSite');
    },

    /**
     * export enum EnteredGenerationSiteNameState {
     *   Exist,
     *   New,
     *   Searching,
     * }
     */
    getEnteredGenerationSiteNameState(): EnteredGenerationSiteNameState {
      const lazySearchablePullDown = this.$refs.searchablePulldownGenerationSite as ExtractVue<
        typeof RLazySearchablePulldown
      >;
      if (
        lazySearchablePullDown === undefined ||
        lazySearchablePullDown.isLoading ||
        lazySearchablePullDown.searchScheduledAt !== undefined
      )
        return EnteredGenerationSiteNameState.Searching;
      return this.viewModel?.generationSite?.name !== lazySearchablePullDown.searchInput
        ? EnteredGenerationSiteNameState.New
        : EnteredGenerationSiteNameState.Exist;
    },
  },
});
