import { INamespace, NamespaceBase } from '~/framework/core/namespace';
import { AttendanceEntity } from '~/framework/domain/masters/attendance/attendanceEntity';

export interface IAttendanceStore extends INamespace<AttendanceEntity> {
  //
}

export class AttendanceStore extends NamespaceBase<AttendanceEntity> implements IAttendanceStore {
  //
}
