import { StoredMapperBase } from '~/framework/core/mapper';
import {
  IScheduleEntity,
  IScheduleEntityData,
  ScheduleEntity,
} from '~/framework/domain/schedule/schedule/scheduleEntity';
import { ScheduleStore } from '~/framework/domain/schedule/schedule/scheduleStore';

export class ScheduleMapper extends StoredMapperBase<IScheduleEntityData, IScheduleEntity> {
  protected store: ScheduleStore;

  constructor(store: ScheduleStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: IScheduleEntityData): IScheduleEntity {
    return new ScheduleEntity(
      data.id,
      data.attendanceId,
      data.orderGroupId,
      data.status,
      data.creatingScheduleStartedAt,
      data.scheduleData,
      data.scheduleResponse,
      data.isPublished
    );
  }

  protected updateWithData(data: IScheduleEntityData, entity: IScheduleEntity): void {
    entity.attendanceId = data.attendanceId;
    entity.orderGroupId = data.orderGroupId;
    entity.status = data.status;
    entity.creatingScheduleStartedAt = data.creatingScheduleStartedAt;
    entity.scheduleData = data.scheduleData;
    entity.scheduleResponse = data.scheduleResponse;
    entity.isPublished = data.isPublished;
  }
}
