import { StoredMapperBase } from '~/framework/core/mapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import {
  AggregatedClientEntity,
  AggregatedClientEntityData,
} from '~/framework/domain/masters/client/aggregatedClientEntity';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';

export class AggregatedClientMapper extends StoredMapperBase<AggregatedClientEntityData, AggregatedClientEntity> {
  protected store: AggregatedClientStore;
  private userMapper: UserMapper;

  constructor(store: AggregatedClientStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: AggregatedClientEntityData): AggregatedClientEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    return new AggregatedClientEntity(
      data.id,
      data.code,
      data.name,
      data.nameRuby,
      data.note,
      data.createdAt,
      data.status,
      data.signInUrl,
      createdBy,
      data.updatedAt,
      updatedBy,
      data.reservationSiteEnabled,
      data.packingStyleReservationSettings
    );
  }

  protected updateWithData(data: AggregatedClientEntityData, entity: AggregatedClientEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    entity.code = data.code;
    entity.name = data.name;
    entity.nameRuby = data.nameRuby;
    entity.note = data.note;
    entity.createdAt = data.createdAt;
    entity.updatedAt = data.updatedAt;
    entity.status = data.status;
    entity.signInUrl = data.signInUrl;
    entity.createdBy = createdBy;
    entity.updatedBy = updatedBy;
    entity.reservationSiteEnabled = data.reservationSiteEnabled;
    entity.packingStyleReservationSettings = data.packingStyleReservationSettings;
  }
}
