
import Vue from 'vue';
import { FunctionLike, Maybe, ValidationRule } from '~/framework/typeAliases';
import { maxLength, required } from '~/framework/view-models/rules';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { yomiganize } from '~/framework/services/string/string';

import { getHoursAndMinutesOf, secsToMinutes, getSecondsFromMinutes } from '~/framework/services/date-time/date-time';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { hasRestPeriodOptions, IHasRestPeriodOption } from '~/framework/view-models/restPeriodOption';
import { IDisposalSiteFormPanelOption } from '~/framework/view-models/panels/disposalSiteFormPanel';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import {
  DisposalSiteApplicationService,
  disposalSiteSymbol,
} from '~/framework/application/masters/disposal-site/disposalSiteApplicationService';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { ClientForDisposalSite } from '~/framework/server-api/masters/disposalSite';
import { Client } from '~/components/common/r-lazy-searchable-pulldown/client';
import { ClientsByKeywordsCondition, ClientsByKeywordsOrder } from '~/graphql/graphQLServerApi';
import { ClientEntity } from '~/framework/domain/masters/client/clientEntity';
import { AcceptanceLimitDefault } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { officeSettingSymbol } from '~/framework/application/masters/office-setting/officeSettingApplicationService';
import { sanitizeNaturalNumber } from '~/framework/view-models/ruleLogics';

enum FormMode {
  Register,
  Edit,
}

type DataType = AsyncDataType & {
  /**
   * このパネルを表示したい時に true
   */
  isActive: boolean;
  isConfirmDialogActive: boolean;
  defaultAcceptanceLimitOptions: { text: string; value: number }[];
  acceptanceLimitDefault: number;
  masters: Maybe<Masters>;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  FormMode: typeof FormMode;
  disposalSiteApplicationService: DisposalSiteApplicationService;
  clientSearchCondition: ClientsByKeywordsCondition;
};

type AsyncDataType = {
  viewModel: DisposalSiteForm;
};
type InitialFormValues = {
  name: string;
  nameRuby: string;
  zipCode: Maybe<string>;
  address1: string;
  address2: string;
  address3: string;
  address4: Maybe<string>;
  latitude: Maybe<number>;
  longitude: Maybe<number>;
  disposablePeriodStart: Maybe<number>;
  disposablePeriodEnd: Maybe<number>;
  client: Maybe<ClientForDisposalSite>;
  note: Maybe<string>;
  durationAtEntrance: number;
  hasRestPeriod: boolean;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  hasAcceptanceLimit: boolean;
  acceptanceLimit: number;
  isAddressValidated: boolean;
  isAddressComplete: boolean;
  temporalAcceptanceInterval: number | undefined;
  temporalAcceptanceLimit: number | undefined;
};

class DisposalSiteForm {
  officeSetting: OfficeSettingEntity;

  title: string;
  formMode: FormMode;
  disposalSite: Maybe<AggregatedDisposalSiteEntity>;
  client: Maybe<ClientForDisposalSite>;
  createdDisposalSite: Maybe<AggregatedDisposalSiteEntity>;
  updatedDisposalSite: Maybe<AggregatedDisposalSiteEntity>;
  rules: { [key: string]: ValidationRule };
  name: string = '';
  nameRuby: string = '';
  zipCode: Maybe<string> = '';
  address1: string = '';
  address2: string = '';
  address3: string = '';
  address4: Maybe<string> = undefined;
  latitude: Maybe<number> = undefined;
  longitude: Maybe<number> = undefined;
  disposablePeriodStart: Maybe<number> = undefined;
  disposablePeriodEnd: Maybe<number> = undefined;
  note: Maybe<string> = undefined;
  durationAtEntranceHours: number = 0;
  durationAtEntranceMinutes: number = 0;
  hasRestPeriod: boolean;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  hasAcceptanceLimit: boolean;
  acceptanceLimit: number;
  isAddressComplete: boolean;
  hasRestPeriodOptions: IHasRestPeriodOption[];
  _isAddressValidated: boolean = false;
  isRegistering: boolean = false;
  _isFormValid: boolean = false;
  isNameRubyTooltipOpen: boolean = false;
  isRegisterButtonDisabled: boolean = true;
  isPastingAddress: boolean = false;
  minAcceptanceInterval: number = 1;
  maxAcceptanceInterval: number = 180;
  minAcceptanceLimit: number = 1;
  maxAcceptanceLimit: number = 100;
  temporalAcceptanceInterval: number | undefined;
  temporalAcceptanceLimit: number | undefined;

  clientLoader: Client;

  // オプション
  closeCallback: Maybe<FunctionLike<void>>;
  registerButtonLabel: Maybe<string>;

  private initialFormValues: InitialFormValues;

  get address() {
    const components = [];
    if (this.zipCode) components.push(this.zipCode);
    if (this.address1) components.push(this.address1);
    if (this.address2) components.push(this.address2);
    if (this.address3) components.push(this.address3);
    // address4 を入れるとそこまで premise で返ってきてしまうため、あえて入れない
    return components.join(' ');
  }

  get isAddressValidated() {
    return this._isAddressValidated;
  }

  set isAddressValidated(value: boolean) {
    this._isAddressValidated = value;
    this.updateRegisterButtonDisabled();
    if (this._isAddressValidated) {
      this.isAddressComplete = true;
    }
  }

  get isFormValid(): boolean {
    return this._isFormValid;
  }

  set isFormValid(value: boolean) {
    this._isFormValid = value;
    this.updateRegisterButtonDisabled();
  }

  get isDirty(): boolean {
    const isDirty =
      this.name !== this.initialFormValues.name ||
      this.nameRuby !== this.initialFormValues.nameRuby ||
      this.zipCode !== this.initialFormValues.zipCode ||
      this.address1 !== this.initialFormValues.address1 ||
      this.address2 !== this.initialFormValues.address2 ||
      this.address3 !== this.initialFormValues.address3 ||
      this.address4 !== this.initialFormValues.address4 ||
      this.latitude !== this.initialFormValues.latitude ||
      this.longitude !== this.initialFormValues.longitude ||
      this.disposablePeriodStart !== this.initialFormValues.disposablePeriodStart ||
      this.disposablePeriodEnd !== this.initialFormValues.disposablePeriodEnd ||
      this.client?.id !== this.initialFormValues.client?.id ||
      this.note !== this.initialFormValues.note ||
      this.durationAtEntrance !== this.initialFormValues.durationAtEntrance ||
      this.hasRestPeriod !== this.initialFormValues.hasRestPeriod ||
      this.restPeriodStart !== this.initialFormValues.restPeriodStart ||
      this.restPeriodEnd !== this.initialFormValues.restPeriodEnd ||
      this.acceptanceLimit !== this.initialFormValues.acceptanceLimit ||
      this.isAddressComplete !== this.initialFormValues.isAddressComplete ||
      this.temporalAcceptanceInterval !== this.initialFormValues.temporalAcceptanceInterval ||
      this.temporalAcceptanceLimit !== this.initialFormValues.temporalAcceptanceLimit;
    return isDirty;
  }

  get durationAtEntrance(): number {
    return this.durationAtEntranceHours * 60 * 60 + this.durationAtEntranceMinutes * 60;
  }

  constructor(
    disposalSite: Maybe<AggregatedDisposalSiteEntity>,
    officeSetting: OfficeSettingEntity,
    closeCallback: Maybe<FunctionLike<void>>,
    registerButtonLabel: Maybe<string>,
    clientLoader: Client
  ) {
    this.disposalSite = disposalSite;
    this.officeSetting = officeSetting;
    this.closeCallback = closeCallback;
    this.rules = { required };
    this.hasRestPeriodOptions = hasRestPeriodOptions;
    this.formMode = this.disposalSite === undefined ? FormMode.Register : FormMode.Edit;
    this.title = this.formMode === FormMode.Register ? '処分場の登録' : '処分場の編集';
    this.registerButtonLabel = registerButtonLabel ?? (this.formMode === FormMode.Register ? '登録' : '編集完了');
    this.initialFormValues = this.getInitialFormValues(disposalSite);
    this.name = this.initialFormValues.name;
    this.nameRuby = this.initialFormValues.nameRuby;
    this.client = this.initialFormValues.client;
    this.zipCode = this.initialFormValues.zipCode;
    this.address1 = this.initialFormValues.address1;
    this.address2 = this.initialFormValues.address2;
    this.address3 = this.initialFormValues.address3;
    this.address4 = this.initialFormValues.address4;
    this.latitude = this.initialFormValues.latitude;
    this.longitude = this.initialFormValues.longitude;
    this.disposablePeriodStart = this.initialFormValues.disposablePeriodStart;
    this.disposablePeriodEnd = this.initialFormValues.disposablePeriodEnd;
    this.note = this.initialFormValues.note;
    const [hours, minutes] = getHoursAndMinutesOf(this.initialFormValues.durationAtEntrance);
    this.durationAtEntranceHours = hours;
    this.durationAtEntranceMinutes = minutes;
    this.hasRestPeriod = this.initialFormValues.hasRestPeriod;
    this.hasAcceptanceLimit = this.initialFormValues.acceptanceLimit !== AcceptanceLimitDefault;
    this.acceptanceLimit = this.initialFormValues.acceptanceLimit;
    this.restPeriodStart = this.initialFormValues.restPeriodStart;
    this.restPeriodEnd = this.initialFormValues.restPeriodEnd;
    this.isAddressValidated = this.initialFormValues.isAddressValidated;
    this.isAddressComplete = this.initialFormValues.isAddressComplete;

    this.temporalAcceptanceInterval = this.initialFormValues.temporalAcceptanceInterval;
    this.temporalAcceptanceLimit = this.initialFormValues.temporalAcceptanceLimit;

    this.clientLoader = clientLoader;
  }

  onChangeNameRuby(): void {
    if (this.nameRuby) {
      const [replacedNameRuby, containedIllegalValue] = yomiganize(this.nameRuby);
      Vue.nextTick().then(() => {
        this.nameRuby = replacedNameRuby;
        if (containedIllegalValue) {
          this.isNameRubyTooltipOpen = true;
          setTimeout(() => {
            this.isNameRubyTooltipOpen = false;
          }, 2000);
        }
      });
    }
  }

  onChangedisposablePeriod(start: number, end: number): void {
    this.disposablePeriodStart = start;
    this.disposablePeriodEnd = end;
    this.updateRegisterButtonDisabled();
  }

  onChangeRestPeriod(start: number, end: number): void {
    this.restPeriodStart = start;
    this.restPeriodEnd = end;
    this.updateRegisterButtonDisabled();
  }

  onChangeToggleAcceptancePeriodTime(value: boolean) {
    if (value) {
      this.temporalAcceptanceInterval = 60;
      this.temporalAcceptanceLimit = 1;

      return;
    }
    this.temporalAcceptanceInterval = undefined;
    this.temporalAcceptanceLimit = undefined;
  }

  onChangeHasRestPeriod(): void {
    this.restPeriodStart = undefined;
    this.restPeriodEnd = undefined;
  }

  onChangeAcceptancInterval(value: number): void {
    if (value < this.minAcceptanceInterval) {
      this.temporalAcceptanceInterval = this.minAcceptanceInterval * 60;
    } else if (value > this.maxAcceptanceInterval) {
      this.temporalAcceptanceInterval = this.maxAcceptanceInterval * 60;
    } else {
      this.temporalAcceptanceInterval = value * 60;
    }
  }

  onChangeAcceptanceLimit(value: number): void {
    if (value < this.minAcceptanceLimit) {
      this.temporalAcceptanceLimit = this.minAcceptanceLimit;
    } else if (value > this.maxAcceptanceLimit) {
      this.temporalAcceptanceLimit = this.maxAcceptanceLimit;
    } else {
      this.temporalAcceptanceLimit = value;
    }
  }

  private updateRegisterButtonDisabled(): void {
    this.isRegisterButtonDisabled = !this.isAddressValidated || !this.isFormValid;
  }

  private getInitialFormValues(disposalSite: Maybe<AggregatedDisposalSiteEntity>) {
    return disposalSite === undefined
      ? this.getDefaultInitialFormValues()
      : this.getInitialFormValuesByDisposalSite(disposalSite);
  }

  private getDefaultInitialFormValues(): InitialFormValues {
    return {
      name: '',
      nameRuby: '',
      zipCode: '',
      client: this.officeSetting.canUseErp ? { id: '', name: '', nameRuby: '' } : undefined,
      address1: '',
      address2: '',
      address3: '',
      address4: undefined,
      latitude: undefined,
      longitude: undefined,
      disposablePeriodStart: undefined,
      disposablePeriodEnd: undefined,
      note: undefined,
      durationAtEntrance: this.officeSetting.defaultDurationAtDisposalSite,
      hasRestPeriod: this.officeSetting.hasDefaultRestPeriodOfDisposalSite,
      hasAcceptanceLimit: false,
      acceptanceLimit: AcceptanceLimitDefault,
      restPeriodStart: this.officeSetting.defaultRestPeriodStartOfDisposalSite,
      restPeriodEnd: this.officeSetting.defaultRestPeriodEndOfDisposalSite,
      isAddressValidated: false,
      isAddressComplete: true,
      temporalAcceptanceInterval: undefined,
      temporalAcceptanceLimit: undefined,
    };
  }

  private getInitialFormValuesByDisposalSite(disposalSite: AggregatedDisposalSiteEntity): InitialFormValues {
    return {
      name: disposalSite.name,
      nameRuby: disposalSite.nameRuby,
      zipCode: disposalSite.zipCode,
      address1: disposalSite.address1,
      address2: disposalSite.address2,
      address3: disposalSite.address3,
      address4: disposalSite.address4,
      latitude: disposalSite.latitude,
      longitude: disposalSite.longitude,
      client: disposalSite?.client,
      disposablePeriodStart: disposalSite.disposablePeriodStart,
      disposablePeriodEnd: disposalSite.disposablePeriodEnd,
      note: disposalSite.note,
      durationAtEntrance: disposalSite.durationAtEntrance,
      hasRestPeriod: disposalSite.hasRestPeriod,
      restPeriodStart: disposalSite.restPeriodStart,
      restPeriodEnd: disposalSite.restPeriodEnd,
      hasAcceptanceLimit: disposalSite.acceptanceLimit !== AcceptanceLimitDefault,
      acceptanceLimit: disposalSite.acceptanceLimit,
      isAddressValidated: true,
      isAddressComplete: disposalSite.isAddressComplete,
      temporalAcceptanceInterval: disposalSite.temporalAcceptanceInterval,
      temporalAcceptanceLimit: disposalSite.temporalAcceptanceLimit,
    };
  }
}

enum EventTypes {
  Change = 'change',
}

type Masters = {
  officeSetting: OfficeSettingEntity;
};

export default Vue.extend({
  name: 'RDisposalSiteForm',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType & AsyncDataType {
    const disposalSiteApplicationService = this.$context.applications.get(disposalSiteSymbol);

    return {
      isActive: false,
      isConfirmDialogActive: false,
      defaultAcceptanceLimitOptions: [...Array(50).keys()].map((n) => {
        const v = n + 1;
        return { text: v.toString(), value: v };
      }),
      acceptanceLimitDefault: AcceptanceLimitDefault,
      viewModel: {} as DisposalSiteForm,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      FormMode,
      masters: undefined,
      disposalSiteApplicationService,
      clientSearchCondition: {
        keywords: undefined,
        since: undefined,
        orderBy: ClientsByKeywordsOrder.CreatedAtDesc,
      },
    };
  },
  computed: {
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.DISPOSAL_SITE;
    },
    clientId(): Maybe<string> {
      return this.viewModel.client?.id;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.disposalSiteFormPanel.openFormEvent.on(this.onOpenForm);
    this.$context.panels.disposalSiteFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );

    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    maxLength,
    required,
    secsToMinutes,
    getSecondsFromMinutes,
    sanitizeNaturalNumber,
    onChangeClient(client: ClientEntity) {
      this.viewModel.client = client;
    },
    /**
     * @public
     */
    async open(
      disposalSite: Maybe<AggregatedDisposalSiteEntity>,
      option?: IDisposalSiteFormPanelOption
    ): Promise<void> {
      if (this.isActive) return;

      if (this.masters === undefined || option?.useCache !== true) {
        const officeSettingApplicationService = this.$context.applications.get(officeSettingSymbol);
        const officeSetting = await officeSettingApplicationService.get();
        this.masters = { officeSetting };
      }
      if (this.masters === undefined) {
        throw new Error('Could not find masters');
      }
      const clientLoader = new Client(this.$context, this.clientSearchCondition);
      this.viewModel = new DisposalSiteForm(
        disposalSite,
        this.masters.officeSetting,
        option?.closeCallback,
        option?.registerButtonLabel,
        clientLoader
      );
      await (this.$refs.RSideform as any as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @param preserveContext このパネルが開かれたコンテキストを尊重してコールバックを呼ぶべきかどうか
     */
    async close(preserveContext: boolean): Promise<void> {
      if (this.isActive === false) return;
      if (this.viewModel === undefined) throw new Error(`Impossible!`);
      const viewModel = this.viewModel;
      await (this.$refs.RSideform as any as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeFormArgs: ICloseEntityFormArgs<AggregatedDisposalSiteEntity> = {
        entity: viewModel.disposalSite,
        createdEntity: viewModel.createdDisposalSite,
        updatedEntity: viewModel.updatedDisposalSite,
        removedEntity: undefined,
      };
      this.$context.panels.disposalSiteFormPanel.closeFormEvent.emit(closeFormArgs);
      if (preserveContext && viewModel.closeCallback !== undefined) {
        if (viewModel.closeCallback instanceof Promise) {
          await viewModel.closeCallback;
        } else {
          viewModel.closeCallback();
        }
      }
    },
    async onOpenForm(
      args: IOpenEntityFormArgs<AggregatedDisposalSiteEntity, IDisposalSiteFormPanelOption>
    ): Promise<void> {
      await this.open(args.entity, args.option);
    },
    /**
     * @param forceClose 編集した状態であってもダイアログ表示せずに強制的に閉じる場合にtrue
     * @param preserveContext このパネルが開かれたコンテキストを尊重してコールバックを呼ぶべきかどうか
     */
    async onCloseForm(forceClose: boolean = false, preserveContext: boolean = false): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (forceClose === false && this.viewModel !== undefined && this.viewModel.isDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close(preserveContext);
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    async onRegisterButtonClicked(continueRegistration: boolean): Promise<void> {
      const viewModel = this.viewModel;
      viewModel.isRegistering = true;
      if (viewModel.formMode === FormMode.Register) {
        const entity = await this.disposalSiteApplicationService.create(
          {
            name: viewModel.name,
            nameRuby: viewModel.nameRuby,
            zipCode: viewModel.zipCode,
            address1: viewModel.address1,
            address2: viewModel.address2,
            address3: viewModel.address3,
            address4: viewModel.address4,
            latitude: viewModel.latitude!,
            longitude: viewModel.longitude!,
            durationAtEntrance: viewModel.durationAtEntrance,
            disposablePeriodStart: viewModel.disposablePeriodStart!,
            disposablePeriodEnd: viewModel.disposablePeriodEnd!,
            restPeriodStart: viewModel.restPeriodStart,
            restPeriodEnd: viewModel.restPeriodEnd,
            acceptanceLimit: viewModel.acceptanceLimit,
            note: viewModel.note,
            isAddressComplete: true,
            clientId: this.clientId,
            temporalAcceptanceInterval: viewModel.temporalAcceptanceInterval,
            temporalAcceptanceLimit: viewModel.temporalAcceptanceLimit,
          },
          { client: viewModel.client }
        );
        this.viewModel.createdDisposalSite = entity;
      } else if (viewModel.formMode === FormMode.Edit) {
        if (viewModel.disposalSite === undefined) throw new Error('Impossible!');
        const disposalSite = viewModel.disposalSite;
        const entity = await this.disposalSiteApplicationService.update(
          {
            id: disposalSite.persistentId,
            name: viewModel.name,
            nameRuby: viewModel.nameRuby,

            clientId: this.clientId,
            zipCode: viewModel.zipCode,
            address1: viewModel.address1,
            address2: viewModel.address2,
            address3: viewModel.address3,
            address4: viewModel.address4,
            latitude: viewModel.latitude!,
            longitude: viewModel.longitude!,
            durationAtEntrance: viewModel.durationAtEntrance,
            disposablePeriodStart: viewModel.disposablePeriodStart!,
            disposablePeriodEnd: viewModel.disposablePeriodEnd!,
            restPeriodStart: viewModel.restPeriodStart,
            restPeriodEnd: viewModel.restPeriodEnd,
            acceptanceLimit: viewModel.acceptanceLimit,
            note: viewModel.note,
            isAddressComplete: viewModel.isAddressComplete,
            temporalAcceptanceInterval: viewModel.temporalAcceptanceInterval,
            temporalAcceptanceLimit: viewModel.temporalAcceptanceLimit,
          },
          { client: viewModel.client }
        );
        this.viewModel.updatedDisposalSite = entity;
      }
      viewModel.isRegistering = false;

      this.$rinGtm.push(continueRegistration ? RinEventNames.COMPLETE_INPUT_CONTINUE : RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.DISPOSAL_SITE,
        [AdditionalInfoKeys.MODE]:
          viewModel.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });

      this.$context.snackbar.success('処分場の登録完了');
      await this.close(true);
      if (continueRegistration) {
        await this.$context.panels.disposalSiteFormPanel.open(undefined, { useCache: true });
      }
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(() => this.onCloseForm(false, true));
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.disposalSiteFormPanel.updateDisplayedWidth(value);
    },
  },
});
