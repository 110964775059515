
import Vue from 'vue';
import { TaskType } from '~/framework/domain/typeAliases';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { greaterThanOrEqualToZero, greaterThanZero, max, min, required } from '~/framework/view-models/rules';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { IllegalStateException } from '~/framework/core/exception';
import {
  ApiDataFactory,
  FormValues,
  FormValuesFactory,
  IFormValues,
} from '~/components/panels/masters/r-container-task-form/formValues';
import { CloseGenericObjectArgs, OpenGenericObjectArgs } from '~/framework/view-models/panels/genericObjectPanel';
import { ICloseData, IOpenData } from '~/framework/view-models/panels/containerTaskFromPanel';
import {
  containerTaskSymbol,
  ContainerTaskApplicationService,
} from '~/framework/application/masters/container-type/containerTaskApplicationService';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { PackingStyleTaskTypeDefaultEntity } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultEntity';
import RContainerRow from '~/components/panels/masters/r-container-task-form/RContainerRow.vue';
import RPackingStyleRow from '~/components/panels/masters/r-container-task-form/RPackingStyleRow.vue';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { containerTypeSymbol } from '~/framework/application/masters/container-type/containerTypeApplicationService';
import { packingStyleSymbol } from '~/framework/application/masters/packingStyleApplicationService';
import {
  PackingStyleTaskTypeDefaultApplicationService,
  packingStyleTaskTypeDefaultSymbol,
} from '~/framework/application/masters/packingStyleTaskTypeDefaultApplicationService';

type Masters = {
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  containerTypes: AggregatedContainerTypeEntity[];
  packingStyles: PackingStyleEntity[];
  packingStyleTaskTypeDefaults: PackingStyleTaskTypeDefaultEntity[];
};

type DataType = {
  containerTaskApplicationService: ContainerTaskApplicationService;
  packingStyleTaskTypeDefaultApplicationService: PackingStyleTaskTypeDefaultApplicationService;
  isActive: boolean;
  isCloseConfirmDialogActive: boolean;
  isRegisterConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  registrationConfirmDialogResolver: Maybe<(value: boolean) => void>;
  editingTaskType: Maybe<TaskType>;
  title: string;
  durationHeaderLabel: string;
  isFormValid: boolean;
  formValues: Maybe<IFormValues>;
  masters: Maybe<Masters>;
  rules: { [key: string]: ValidationRule };
  initialFormValues: Maybe<IFormValues>;
  isRegistering: boolean;
  taskTypeName: string;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RContainerTaskForm',
  components: {
    RPackingStyleRow,
    RContainerRow,
  },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    return {
      containerTaskApplicationService: this.$context.applications.get(containerTaskSymbol),
      packingStyleTaskTypeDefaultApplicationService: this.$context.applications.get(packingStyleTaskTypeDefaultSymbol),
      isActive: false,
      isCloseConfirmDialogActive: false,
      isRegisterConfirmDialogActive: false,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      registrationConfirmDialogResolver: undefined,
      editingTaskType: undefined,
      formValues: undefined as Maybe<IFormValues>,
      masters: undefined as Maybe<Masters>,
      initialFormValues: undefined,
      title: '',
      durationHeaderLabel: '',
      isFormValid: true,
      isRegistering: false,
      rules: { required, greaterThanZero, greaterThanOrEqualToZero },
      taskTypeName: '',
    };
  },
  computed: {
    hasContainers(): boolean {
      return this.formValues?.packingStyles.some((packingStyle) => 0 < packingStyle.containers.length) || false;
    },
    isRegisterButtonDisabled(): boolean {
      return !this.isFormValid || !this.hasContainers;
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.CONTAINER_TASK;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.containerTaskFormPanel.openEvent.on(this.onOpen);
    this.$context.panels.containerTaskFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    min,
    max,
    /**
     * @public
     */
    async open(taskType: TaskType): Promise<void> {
      if (this.isActive) return;
      await this.initializeForm(taskType);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @public
     */
    async close(isUpdated: boolean = false): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeArgs: CloseGenericObjectArgs<IOpenData, ICloseData> = {
        openData: {
          taskType: this.editingTaskType!,
        },
        closeData: {
          taskType: this.editingTaskType!,
          isUpdated,
        },
      };
      this.$context.panels.containerTaskFormPanel.closeEvent.emit(closeArgs);
    },
    async onOpen(args: OpenGenericObjectArgs<IOpenData>): Promise<void> {
      if (args.openData === undefined) throw new IllegalStateException(`openData is required`);
      await this.open(args.openData.taskType);
    },
    async onCloseForm(): Promise<boolean> {
      if (this.formValues === undefined) throw new IllegalStateException(`Impossible`);
      if (this.initialFormValues === undefined) throw new IllegalStateException(`Impossible`);
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.formValues.isEqualTo(this.initialFormValues) === false) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    onConfirmRegistrationClose(value: boolean): void {
      this.isRegisterConfirmDialogActive = false;
      if (this.registrationConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.registrationConfirmDialogResolver(value);
    },
    /**
     *
     * @param carType
     */
    async initializeForm(taskType: TaskType): Promise<void> {
      const containerTypeApplicationService = this.$context.applications.get(containerTypeSymbol);
      const packingStyleApplicationService = this.$context.applications.get(packingStyleSymbol);

      const [containerTypeTaskTypes, containerTypes, packingStyles, packingStyleTaskTypeDefaults] = await Promise.all([
        this.containerTaskApplicationService.getAll(),
        containerTypeApplicationService.getAll(),
        packingStyleApplicationService.getAll(),
        this.packingStyleTaskTypeDefaultApplicationService.getAll(),
      ]);
      this.masters = {
        containerTypeTaskTypes,
        containerTypes,
        packingStyles,
        packingStyleTaskTypeDefaults,
      };

      this.editingTaskType = taskType;
      this.initialFormValues = new FormValuesFactory(
        this.masters.containerTypes,
        this.masters.containerTypeTaskTypes,
        this.masters.packingStyles,
        this.masters.packingStyleTaskTypeDefaults
      ).build(taskType);
      this.formValues = FormValues.clone(this.initialFormValues);
      this.title = '各荷姿の作業時間の編集';
      this.durationHeaderLabel = '作業時間';
      this.taskTypeName = taskType.name;
    },
    async onRegisterButtonClicked(): Promise<void> {
      if (this.masters === undefined) throw new IllegalStateException(`Impossible!`);
      if (this.formValues === undefined) throw new IllegalStateException(`Impossible!`);
      this.isRegistering = true;
      const factory = new ApiDataFactory(
        this.masters.containerTypes,
        this.masters.containerTypeTaskTypes,
        this.masters.packingStyles,
        this.masters.packingStyleTaskTypeDefaults
      );
      const updateData = factory.buildUpdateData(this.formValues.packingStyles);
      const [packingStyleTaskTypeDefaultUpdateData, containerTypeTaskTypeUpdateData] = updateData;
      await Promise.all([
        this.packingStyleTaskTypeDefaultApplicationService.update(packingStyleTaskTypeDefaultUpdateData),
        this.containerTaskApplicationService.update(containerTypeTaskTypeUpdateData),
      ]);
      this.$context.snackbar.success('各荷姿の作業時間の編集完了');
      this.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CONTAINER_TASK,
        [AdditionalInfoKeys.MODE]: FormModeParams.EDIT,
      });

      await this.close(true);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.carTypeFormPanel.updateDisplayedWidth(value);
    },
  },
});
