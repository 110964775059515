import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';

export interface DriverAttendanceTemplateEntityData {
  id: string;
  name: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
  defaultForceRidePrimaryCar: boolean;
}

export class DriverAttendanceTemplateEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
  defaultForceRidePrimaryCar: boolean;

  constructor(
    id: string,
    name: string,
    regularWorkPeriodStart: number,
    regularWorkPeriodEnd: number,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    overtimeWorkType: OvertimeWorkType,
    overtimeWorkableDuration: number,
    defaultForceRidePrimaryCar: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.regularWorkPeriodStart = regularWorkPeriodStart;
    this.regularWorkPeriodEnd = regularWorkPeriodEnd;
    this.restPeriodStart = restPeriodStart;
    this.restPeriodEnd = restPeriodEnd;
    this.overtimeWorkType = overtimeWorkType;
    this.overtimeWorkableDuration = overtimeWorkableDuration;
    this.defaultForceRidePrimaryCar = defaultForceRidePrimaryCar;
  }
}
