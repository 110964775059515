import { WasteCategory, WasteTypeStatus } from '~/framework/domain/typeAliases';

export interface WasteTypeEntityData {
  id: string;
  category: WasteCategory;
  code: string;
  name: string;
  status: WasteTypeStatus;
  isProminent: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class WasteTypeEntity {
  readonly id: string;
  readonly persistentId: string;
  category: WasteCategory;
  code: string;
  name: string;
  status: WasteTypeStatus;
  isProminent: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: string,
    category: WasteCategory,
    code: string,
    name: string,
    status: WasteTypeStatus,
    isProminent: boolean,
    createdAt: Date,
    updatedAt: Date
  ) {
    this.id = id;
    this.persistentId = id;
    this.category = category;
    this.code = code;
    this.name = name;
    this.status = status;
    this.isProminent = isProminent;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
