import {
  AggregatedCarAttendanceEntity,
  AggregatedCarAttendanceEntityData,
} from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceEntity';
import { AggregatedCarAttendanceStore } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceStore';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { IAttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { StoredMapperBase } from '~/framework/core/mapper';

export class AggregatedCarAttendanceMapper extends StoredMapperBase<
  AggregatedCarAttendanceEntityData,
  AggregatedCarAttendanceEntity
> {
  protected store: AggregatedCarAttendanceStore;
  private attendanceMapper: AttendanceMapper;

  constructor(store: AggregatedCarAttendanceStore, attendanceStore: IAttendanceStore) {
    super();
    this.store = store;
    this.attendanceMapper = new AttendanceMapper(attendanceStore);
  }

  protected instantiateWithData(data: AggregatedCarAttendanceEntityData): AggregatedCarAttendanceEntity {
    const attendanceEntity = this.attendanceMapper.mapSingle(data.attendance);

    return new AggregatedCarAttendanceEntity(
      data.id,
      attendanceEntity,
      data.carId,
      data.carAttendanceType,
      data.periodStart,
      data.periodEnd,
      data.note
    );
  }

  protected updateWithData(data: AggregatedCarAttendanceEntityData, entity: AggregatedCarAttendanceEntity): void {
    entity.attendance = this.attendanceMapper.mapSingle(data.attendance);
    entity.carId = data.carId;
    entity.carAttendanceType = data.carAttendanceType;
    entity.periodStart = data.periodStart;
    entity.periodEnd = data.periodEnd;
    entity.note = data.note;
  }
}
