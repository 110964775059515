import { StoredMapperBase } from '~/framework/core/mapper';
import { HolidayRuleEntity, HolidayRuleEntityData } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { HolidayRuleStore } from '~/framework/domain/masters/holiday-rule/holidayRuleStore';

export class HolidayRuleMapper extends StoredMapperBase<HolidayRuleEntityData, HolidayRuleEntity> {
  protected store: HolidayRuleStore;

  constructor(store: HolidayRuleStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: HolidayRuleEntityData): HolidayRuleEntity {
    return new HolidayRuleEntity(
      data.id,
      data.monday,
      data.tuesday,
      data.wednesday,
      data.thursday,
      data.friday,
      data.saturday,
      data.sunday,
      data.nationalHoliday
    );
  }

  protected updateWithData(data: HolidayRuleEntityData, entity: HolidayRuleEntity): void {
    entity.monday = data.monday;
    entity.tuesday = data.tuesday;
    entity.wednesday = data.wednesday;
    entity.thursday = data.thursday;
    entity.friday = data.friday;
    entity.saturday = data.saturday;
    entity.sunday = data.sunday;
    entity.nationalHoliday = data.nationalHoliday;
  }
}
