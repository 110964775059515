import { Maybe, PersistentId } from '~/framework/typeAliases';
import { DriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/driverAttendanceEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { WasteTypeEntity } from '~/framework/domain/masters/waste-type/wasteTypeEntity';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { ContainerTypeEntity } from '~/framework/domain/masters/container-type/containerTypeEntity';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

import { holidayRuleSymbol } from '~/framework/application/masters/holiday-rule/holidayRuleApplicationService';
import { officeSettingSymbol } from '~/framework/application/masters/office-setting/officeSettingApplicationService';
import { clientSymbol } from '~/framework/application/masters/client/clientApplicationService';
import { orderGroupSymbol } from '~/framework/application/masters/order-group/orderGroupApplicationService';
import { disposalSiteSymbol } from '~/framework/application/masters/disposal-site/disposalSiteApplicationService';
import { wasteTypeSymbol } from '~/framework/application/masters/waste-type/wasteTypeApplicationService';
import { containerTypeSymbol } from '~/framework/application/masters/container-type/containerTypeApplicationService';
import { containerTaskSymbol } from '~/framework/application/masters/container-type/containerTaskApplicationService';
import { collectablePeriodTemplateSymbol } from '~/framework/application/masters/collectable-period-template/collectablePeriodTemplateApplicationService';
import { carTypeSymbol } from '~/framework/application/masters/car-type/carTypeApplicationService';
import { carSymbol } from '~/framework/application/masters/car/carApplicationService';
import { packingStyleSymbol } from '~/framework/application/masters/packingStyleApplicationService';
import { SystemContext } from '~/framework/systemContext';
import { driverSymbol } from '~/framework/application/masters/driver/driverApplicationService';
import { generationSiteSymbol } from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import { taskTypeSymbol } from '~/framework/application/masters/task-type/taskTypeApplicationService';
import { driverAttendanceSymbol } from '~/framework/application/masters/driver-attendance/driverAttendanceApplicationService';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';

export interface IFormData {
  cars: AggregatedCarEntity[];
  carTypes: AggregatedCarTypeEntity[];
  collectablePeriodTemplates: CollectablePeriodTemplateEntity[];
  wasteTypes: WasteTypeEntity[];
  orderGroups: OrderGroupEntity[];
  drivers: DriverEntity[];
  disposalSites: AggregatedDisposalSiteEntity[];
  client: Maybe<AggregatedClientEntity>;
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  assignableDriverAttendances: Maybe<DriverAttendanceEntity[]>;
  containerTypes: ContainerTypeEntity[];
  generationSite: Maybe<GenerationSiteEntity>;
  officeSetting: OfficeSettingEntity;
  packingStyles: PackingStyleEntity[];
  taskTypes: TaskTypeEntity[];
  holidayRule: HolidayRuleEntity;
}

export async function getOrderFormData(
  context: SystemContext,
  date: Maybe<Date>,
  clientId: Maybe<PersistentId>,
  generationSiteId: Maybe<PersistentId>,
  driverIds: PersistentId[]
): Promise<IFormData> {
  const officeSettingApplicationService = context.applications.get(officeSettingSymbol);
  const orderGroupApplicationService = context.applications.get(orderGroupSymbol);
  const wasteTypeApplicationService = context.applications.get(wasteTypeSymbol);
  const containerTypeApplicationService = context.applications.get(containerTypeSymbol);
  const containerTypeTaskTypeApplicationService = context.applications.get(containerTaskSymbol);
  const collectablePeriodTemplateApplicationService = context.applications.get(collectablePeriodTemplateSymbol);
  const carTypeApplicationService = context.applications.get(carTypeSymbol);
  const carApplicationService = context.applications.get(carSymbol);
  const holidayRuleApplicationService = context.applications.get(holidayRuleSymbol);
  const packingStyleApplicationService = context.applications.get(packingStyleSymbol);

  const clientApplicationService = context.applications.get(clientSymbol);
  const disposalSiteApplicationService = context.applications.get(disposalSiteSymbol);
  const driverApplicationService = context.applications.get(driverSymbol);
  const generationSiteApplicationService = context.applications.get(generationSiteSymbol);
  const driverAttendanceApplicationService = context.applications.get(driverAttendanceSymbol);
  const taskTypeApplicationService = context.applications.get(taskTypeSymbol);

  const [
    officeSetting,
    orderGroups,
    wasteTypes,
    containerTypes,
    containerTypeTaskTypes,
    collectablePeriodTemplates,
    carTypes,
    cars,
    holidayRule,
    packingStyles,
  ] = await Promise.all([
    officeSettingApplicationService.get(),
    orderGroupApplicationService.getAll(),
    wasteTypeApplicationService.getAll(),
    containerTypeApplicationService.getAll(),
    containerTypeTaskTypeApplicationService.getAll(),
    collectablePeriodTemplateApplicationService.getAll(),
    carTypeApplicationService.getAll(),
    carApplicationService.getAll(),
    holidayRuleApplicationService.get(),
    packingStyleApplicationService.getAll(),
  ]);

  const driverAttendanceJobs =
    date !== undefined && driverIds.length !== 0
      ? driverIds.map((driverId) => driverAttendanceApplicationService.getListOfDriver(driverId, date, date))
      : undefined;

  const [client, disposalSites, drivers, generationSite, assignableDriverAttendances, taskTypes] = await Promise.all([
    clientId ? clientApplicationService.getById(clientId) : Promise.resolve(undefined),
    disposalSiteApplicationService.getAll(),
    driverApplicationService.getAll(),
    generationSiteId ? generationSiteApplicationService.getById(generationSiteId) : Promise.resolve(undefined),
    driverAttendanceJobs ? Promise.all(driverAttendanceJobs) : Promise.resolve(undefined),
    taskTypeApplicationService.getAll(),
  ]);

  if (driverIds) {
    const allActiveDriverIdSet = new Set(drivers.map((driver) => driver.id));
    const deletedDriverids = driverIds.filter((driverId) => !allActiveDriverIdSet.has(driverId));
    if (deletedDriverids) {
      const deletedDrivers = await driverApplicationService.getByIds(deletedDriverids);
      drivers.concat(deletedDrivers);
    }
  }

  if (generationSite) {
    generationSite.displayName = `${generationSite.name} [${generationSite.client?.name}] `;
  }

  return {
    officeSetting,
    client,
    generationSite,
    orderGroups,
    disposalSites,
    wasteTypes,
    containerTypes,
    containerTypeTaskTypes,
    collectablePeriodTemplates,
    drivers,
    carTypes,
    cars,
    assignableDriverAttendances: assignableDriverAttendances?.flat(),
    holidayRule,
    packingStyles,
    taskTypes,
  };
}
