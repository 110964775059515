import { EmploymentStatus, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { DriverEntity, DriverEntityData } from '~/framework/domain/masters/driver/driverEntity';
import { Maybe } from '~/framework/typeAliases';
import { AggregatedCarEntity, AggregatedCarEntityData } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { CarTypeEntity, CarTypeEntityData } from '~/framework/domain/masters/car-type/carTypeEntity';
import {
  DriverAttendanceTemplateEntity,
  DriverAttendanceTemplateEntityData,
} from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';

export interface AggregatedDriverEntityData extends DriverEntityData {
  defaultPrimaryCar: Maybe<AggregatedCarEntityData>;
  licensedCarTypes: CarTypeEntityData[];
  defaultAttendanceTemplate: Maybe<DriverAttendanceTemplateEntityData>;
}

export class AggregatedDriverEntity extends DriverEntity {
  defaultPrimaryCar: Maybe<AggregatedCarEntity>;
  licensedCarTypes: CarTypeEntity[];
  defaultAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>;

  constructor(
    id: string,
    name: string,
    nameRuby: string,
    employmentStatus: EmploymentStatus,
    defaultPrimaryCarId: Maybe<string>,
    licensedCarTypeIds: string[],
    defaultAttendanceTemplateId: Maybe<string>,
    status: SoftDeleteStatus,
    signInUrl: string,
    defaultPrimaryCar: Maybe<AggregatedCarEntity>,
    licensedCarTypes: CarTypeEntity[],
    defaultAttendanceTemplate: Maybe<DriverAttendanceTemplateEntity>
  ) {
    super(
      id,
      name,
      nameRuby,
      employmentStatus,
      defaultPrimaryCarId,
      licensedCarTypeIds,
      defaultAttendanceTemplateId,
      status,
      signInUrl
    );
    this.defaultPrimaryCar = defaultPrimaryCar;
    this.licensedCarTypes = licensedCarTypes;
    this.defaultAttendanceTemplate = defaultAttendanceTemplate;
  }
}
