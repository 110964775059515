import {
  IOriginalDisposalEntity,
  OriginalDisposalEntity,
} from '~/framework/domain/schedule/schedule/pseudo-entities/originalDisposalEntity';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';

export interface IOriginalDisposal extends IOriginalDisposalEntity {
  disposalSite: DisposalSiteEntity;
}

export class OriginalDisposal extends OriginalDisposalEntity implements IOriginalDisposal {
  disposalSite: DisposalSiteEntity;

  constructor(
    id: string,
    index: number,
    disposalSiteId: PseudoId,
    disposalSiteArrivalTime: number,
    disposalSiteDepartureTime: number,
    hasRestBeforeDisposalSiteArrival: boolean,
    prioritizeAssignedDisposalSite: boolean,
    sequentialAssignedDisposalSite: boolean,
    disposalSite: DisposalSiteEntity
  ) {
    super(
      id,
      index,
      disposalSiteId,
      disposalSiteArrivalTime,
      disposalSiteDepartureTime,
      hasRestBeforeDisposalSiteArrival,
      prioritizeAssignedDisposalSite,
      sequentialAssignedDisposalSite
    );
    this.disposalSite = disposalSite;
  }
}
