import {
  ContainerTypeEntity,
  ContainerTypeEntityData,
} from '~/framework/domain/masters/container-type/containerTypeEntity';
import {
  PackingPlacementEntityData,
  PackingPlacementEntity,
} from '~/framework/domain/packing-placement/packing-placement/packingPlacementEntity';

export interface AggregatedPackingPlacementEntityData extends PackingPlacementEntityData {
  containerType: ContainerTypeEntityData;
}

export class AggregatedPackingPlacementEntity extends PackingPlacementEntity {
  containerType: ContainerTypeEntity;

  constructor(
    id: string,
    containerTypeId: string,
    num: number,
    lastAllocatedAt: Date,
    containerType: ContainerTypeEntity
  ) {
    super(id, containerTypeId, num, lastAllocatedAt);
    this.containerType = containerType;
  }
}
