import { BaseTaskTypeName, OrderKind, SiteType } from '~/framework/domain/typeAliases';
import { IEntity, IPersistentEntity } from '~/framework/core/entity';
import { ValueOf } from '~/types/type';

export const BaseTaskTypeDisplayName = {
  ReplaceAtGenerationSite: '交換',
  AllocateAtGenerationSite: '設置',
  FetchAtGenerationSite: '引上',
  LoadAtGenerationSite: '積込',
  DisposeAtDisposalSite: '処分',
  LoadAtBaseSite: '積荷',
  UnloadAtBaseSite: '積下',
} as const;

const SiteTypeDisplayName = {
  GenerationSite: '排出場',
  DisposalSite: '処分場',
  BaseSite: 'コンテナ拠点',
} as const;
export type SiteTypeDisplayNameValue = ValueOf<typeof SiteTypeDisplayName>;
export type TaskTypeDisplayNameValue = ValueOf<typeof BaseTaskTypeDisplayName>;

export type TaskTypeEntityData = Omit<TaskTypeEntity, 'persistentId'>;

export class TaskTypeEntity implements IEntity, IPersistentEntity {
  readonly id: string;
  readonly persistentId: string;
  readonly baseTaskType: BaseTaskTypeEntityData;
  constructor(id: string, public name: string, baseTaskType: BaseTaskTypeEntityData, public bannedDriverIds: string[]) {
    this.id = id;
    this.persistentId = id;
    this.baseTaskType = new BaseTaskTypeEntity(
      baseTaskType.id,
      baseTaskType.name,
      baseTaskType.orderKind,
      baseTaskType.siteType,
      baseTaskType.displayName
    );
    this.bannedDriverIds = bannedDriverIds;
  }
}

export type BaseTaskTypeEntityData = Omit<BaseTaskTypeEntity, 'persistentId'>;
export class BaseTaskTypeEntity implements IEntity, IPersistentEntity {
  readonly persistentId: string;
  readonly displayName: string | TaskTypeDisplayNameValue;
  readonly siteTypeDisplayName: SiteTypeDisplayNameValue;
  constructor(
    public readonly id: string,
    public readonly name: BaseTaskTypeName,
    public orderKind: OrderKind,
    public siteType: SiteType,
    displayName?: string
  ) {
    this.persistentId = id;
    this.displayName = displayName || BaseTaskTypeDisplayName[name];
    this.siteTypeDisplayName = SiteTypeDisplayName[siteType];
  }
}
