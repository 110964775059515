// TypeScript の asserts とは別物だが、よく使う様な制約系は何度も書くのが面倒なのでここにまとめておく

import { Maybe } from '~/framework/typeAliases';
import { IPersistentEntity } from '~/framework/core/entity';
import { Exception } from '~/framework/core/exception';
import { NuxtErrorStatusCodes } from '~/nuxt';

/**
 * 取得を試みたエンティティが見つからなかった時のエラー
 * 基本的にはあり得ないが、同時編集などでローカル側にエンティティがなかった時に起こり得る
 */
export class EntityNotFoundException extends Exception {
  readonly name = 'EntityNotFoundException';
  readonly statusCode = NuxtErrorStatusCodes.EntityNotFoundException;
}

/**
 * 取得を試みた id の分だけエンティティが取れている事を確認する
 *
 * @param givenIds
 * @param gotEntities
 */
export const assertNumberOfEntities = (
  givenIds: string[],
  gotEntities: IPersistentEntity[],
  className?: Maybe<string>
) => {
  if (className === undefined) className = 'unknown';
  if (givenIds.length !== gotEntities.length) {
    throw new EntityNotFoundException(
      `Expected ${givenIds.length} ${className} entities but got ${gotEntities.length}, ids: ${JSON.stringify(
        givenIds
      )}`
    );
  }
};
