import { WithoutId } from '~/framework/typeAliases';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';

export const driverAttendanceTemplate$createSymbol = Symbol('create');
export const driverAttendanceTemplate$getAllSymbol = Symbol('getAll');
export const driverAttendanceTemplate$getByIdsSymbol = Symbol('getByIds');
export const driverAttendanceTemplate$updateSymbol = Symbol('update');

export type DriverAttendanceTemplate = {
  [driverAttendanceTemplate$getAllSymbol]: IGetAllApi;
  [driverAttendanceTemplate$getByIdsSymbol]: IGetByIdsApi;
  [driverAttendanceTemplate$updateSymbol]: IUpdateApi;
  [driverAttendanceTemplate$createSymbol]: ICreateApi;
};

export interface ICreateApi {
  create(data: WithoutId<IDriverAttendanceTemplateData>[]): Promise<string[]>;
}

export interface IDriverAttendanceTemplateData {
  id: string;
  name: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
  defaultForceRidePrimaryCar: boolean;
}

export interface IGetAllApi {
  getAll(): Promise<IDriverAttendanceTemplateData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IDriverAttendanceTemplateData[]>;
}

export interface IUpdateApi {
  update(data: IDriverAttendanceTemplateData[]): Promise<string[]>;
}
