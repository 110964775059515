import { StoredMapperBase } from '~/framework/core/mapper';
import {
  PackingStyleTaskTypeDefaultEntityData,
  PackingStyleTaskTypeDefaultEntity,
} from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultEntity';
import { PackingStyleTaskTypeDefaultStore } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultStore';

export class PackingStyleTaskTypeDefaultMapper extends StoredMapperBase<
  PackingStyleTaskTypeDefaultEntityData,
  PackingStyleTaskTypeDefaultEntity
> {
  protected store: PackingStyleTaskTypeDefaultStore;

  constructor(store: PackingStyleTaskTypeDefaultStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: PackingStyleTaskTypeDefaultEntityData): PackingStyleTaskTypeDefaultEntity {
    return new PackingStyleTaskTypeDefaultEntity(
      data.id,
      data.packingStyleId,
      data.taskType,
      data.duration,
      data.status,
      data.isProportionalToCount
    );
  }

  protected updateWithData(
    data: PackingStyleTaskTypeDefaultEntityData,
    entity: PackingStyleTaskTypeDefaultEntity
  ): void {
    entity.duration = data.duration;
    entity.status = data.status;
    entity.isProportionalToCount = data.isProportionalToCount;
  }
}
