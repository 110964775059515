export interface AttendanceEntityData {
  id: string;
  date: Date;
}

export class AttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  date: Date;

  constructor(id: string, date: Date) {
    this.id = id;
    this.persistentId = id;
    this.date = date;
  }
}
