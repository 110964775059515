export interface IZipCodeAddress {
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
}

/**
 * 郵便番号と部分的な住所を組み立てて、表示用の住所全文に編集する
 *
 * `ReactiveProperty` を通して実際のデータに bind されることを想定し、function で定義している
 *
 * @param this 郵便番号と住所を持つ任意のデータ
 */
export function updateAddress<T extends IZipCodeAddress & { address: string }>(this: T): void {
  const components = [];
  if (this.zipCode !== undefined && this.zipCode !== '') {
    components.push(`〒${this.zipCode}`);
  }
  if (this.address1 !== '') components.push(this.address1);
  if (this.address2 !== '') components.push(this.address2);
  if (this.address3 !== '') components.push(this.address3);
  if (this.address4 !== undefined && this.address4 !== '') components.push(this.address4);
  this.address = components.join(' ');
}
