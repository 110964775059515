
import Vue, { PropType } from 'vue';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { OrderDisposalSitePriority } from '~/framework/domain/typeAliases';

type OrderAssignedDisposalSiteAndName = {
  name: string;
} & IOrderAssignedDisposalSite;

enum EventTypes {
  OpenEditDialog = 'open:edit-dialog',
}

export default Vue.extend({
  name: 'RDisposalSitePrioritySingleAssignment',
  props: {
    orderAssignedDisposalSites: {
      type: Array as PropType<Array<IOrderAssignedDisposalSite>>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<Array<DisposalSiteEntity>>,
      required: true,
    },
  },
  computed: {
    selectedDisposalSites(): OrderAssignedDisposalSiteAndName[] {
      return this.orderAssignedDisposalSites.map((orderAssignedDisposalSite) => {
        const disposalSiteEntity = this.disposalSiteEntities.find(
          (disposalSiteEntity) => disposalSiteEntity.id === orderAssignedDisposalSite.disposalSiteId
        );
        if (disposalSiteEntity === undefined) {
          throw new Error(`disposalSiteEntity is not found!: ${orderAssignedDisposalSite.disposalSiteId}`);
        }
        return {
          ...orderAssignedDisposalSite,
          // NOTE: 優先度が設定されていないデータは OrderDisposalSitePriority.High としておく。
          // Dialog を開いた際も同じ挙動をする
          priority: orderAssignedDisposalSite.priority ?? OrderDisposalSitePriority.High,
          name: disposalSiteEntity.name,
        };
      });
    },
    displayEntities(): Array<OrderAssignedDisposalSiteAndName> {
      return this.selectedDisposalSites.slice(0, 2);
    },
    highPriorityOrderAssignedDisposalSiteAndNames(): OrderAssignedDisposalSiteAndName[] {
      return this.selectedDisposalSites.filter(
        (orderAssignedDisposalSite) =>
          orderAssignedDisposalSite.priority === OrderDisposalSitePriority.High ||
          orderAssignedDisposalSite.priority === undefined
      );
    },
    lowPriorityOrderAssignedDisposalSiteAndNames(): OrderAssignedDisposalSiteAndName[] {
      return this.selectedDisposalSites.filter(
        (orderAssignedDisposalSite) => orderAssignedDisposalSite.priority === OrderDisposalSitePriority.Low
      );
    },
    highPriorityDisposalSiteNames(): string {
      const highPriorityDisposalSiteNames = this.highPriorityOrderAssignedDisposalSiteAndNames.map(
        (disposalSite) => disposalSite.name
      );

      return highPriorityDisposalSiteNames.join(', ');
    },
    lowPriorityDisposalSiteNames(): string {
      const lowPriorityDisposalSiteNames = this.lowPriorityOrderAssignedDisposalSiteAndNames.map(
        (disposalSite) => disposalSite.name
      );

      return lowPriorityDisposalSiteNames.join(', ');
    },
  },
  methods: {
    openEditDialog() {
      this.$emit(EventTypes.OpenEditDialog);
    },
  },
});
