import {
  CreateSchedulesMutation,
  CreateSchedulesMutationVariables,
  LatestSchedulesQuery,
  LatestSchedulesQueryVariables,
  RevertScheduleInput,
  RevertScheduleQuery,
  RevertScheduleQueryVariables,
  RevertScheduleResult,
  ScheduleByScheduleRequestInput,
  ScheduleByScheduleRequestQuery,
  ScheduleByScheduleRequestQueryVariables,
  ScheduleByScheduleRequestResult,
  SchedulesByIdsQuery,
  SchedulesByIdsQueryVariables,
  UpdatePublishingStatusOfScheduleMutation,
  UpdatePublishingStatusOfScheduleMutationVariables,
} from '@/graphql/graphQLServerApi';
import {
  LatestSchedules,
  SchedulesByIds,
  scheduleByScheduleRequest,
  revertSchedule,
} from '@/../graphql/server-api/queries/schedule.gql';
import { CreateSchedules, UpdatePublishingStatusOfSchedule } from '@/../graphql/server-api/mutations/schedule.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { IGetByIdsApi, schedule$getByIdsSymbol } from '~/framework/server-api/schedule/schedule/getByIds';
import { IScheduleData, ScheduleStatus } from '~/framework/server-api/schedule/schedule/schedule';
import { IGetLatestApi, schedule$getLatestSymbol } from '~/framework/server-api/schedule/schedule/getLatest';
import {
  ICreateApi,
  IScheduleCreateData,
  schedule$createSymbol,
} from '~/framework/server-api/schedule/schedule/create';
import {
  IUpdatePublishingStateApi,
  IUpdatePublishingStatusInputData,
  schedule$updatePublishingStateSymbol,
} from '~/framework/server-api/schedule/schedule/updatePublishingState';
import {
  IGetByScheduleRequestApi,
  schedule$getByScheduleRequestSymbol,
} from '~/framework/server-api/schedule/schedule/getByScheduleRequest';
import {
  IRevertScheduleApi,
  schedule$revertScheduleSymbol,
} from '~/framework/server-api/schedule/schedule/revertSchedule';

export class GetByIdsApi extends GraphqlApiBase implements IGetByIdsApi {
  [schedule$getByIdsSymbol] = undefined;
  async getByIds(ids: string[]): Promise<IScheduleData[]> {
    const result = await this.query<SchedulesByIdsQuery, SchedulesByIdsQueryVariables>({
      query: SchedulesByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.schedulesByIds, `SchedulesByIds`);
    return result.schedulesByIds;
  }
}

export class GetLatestApi extends GraphqlApiBase implements IGetLatestApi {
  [schedule$getLatestSymbol] = undefined;
  async getLatest(attendanceId: string, orderGroupId: string, statuses: ScheduleStatus[]): Promise<IScheduleData[]> {
    const result = await this.query<LatestSchedulesQuery, LatestSchedulesQueryVariables>({
      query: LatestSchedules,
      variables: { condition: { attendanceId, orderGroupId, statuses } },
    });
    return result.latestSchedules;
  }
}

export class CreateApi extends GraphqlApiBase implements ICreateApi {
  [schedule$createSymbol] = undefined;
  async create(data: IScheduleCreateData[]): Promise<string[]> {
    const result = await this.mutate<CreateSchedulesMutation, CreateSchedulesMutationVariables>({
      mutation: CreateSchedules,
      variables: { schedules: data },
    });
    this.validateArrayConsistency(data, result.createSchedules, `CreateSchedules`);
    return result.createSchedules;
  }
}

export class UpdatePublishingStateApi extends GraphqlApiBase implements IUpdatePublishingStateApi {
  [schedule$updatePublishingStateSymbol] = undefined;

  async updatePublishingStatusOfSchedule(publishingStatusInput: IUpdatePublishingStatusInputData): Promise<string> {
    const result = await this.mutate<
      UpdatePublishingStatusOfScheduleMutation,
      UpdatePublishingStatusOfScheduleMutationVariables
    >({
      mutation: UpdatePublishingStatusOfSchedule,
      variables: { publishingStatusInput },
    });
    return result.updatePublishingStatusOfSchedule;
  }
}

export class GetByScheduleRequestApi extends GraphqlApiBase implements IGetByScheduleRequestApi {
  // @ts-ignore
  [schedule$getByScheduleRequestSymbol]: void;
  async getByScheduleRequest(
    scheduleByScheduleRequestInput: ScheduleByScheduleRequestInput
  ): Promise<ScheduleByScheduleRequestResult> {
    const result = await this.query<ScheduleByScheduleRequestQuery, ScheduleByScheduleRequestQueryVariables>({
      query: scheduleByScheduleRequest,
      variables: { scheduleByScheduleRequestInput },
    });
    return result.scheduleByScheduleRequest;
  }
}

export class RevertScheduleApi extends GraphqlApiBase implements IRevertScheduleApi {
  // @ts-ignore
  [schedule$revertScheduleSymbol]: void;
  async revertSchedule(revertScheduleInput: RevertScheduleInput): Promise<RevertScheduleResult> {
    const result = await this.query<RevertScheduleQuery, RevertScheduleQueryVariables>({
      query: revertSchedule,
      variables: { revertScheduleInput },
    });
    return result.revertSchedule;
  }
}
