import {
  CollectablePeriodTemplateEntity,
  CollectablePeriodTemplateEntityData,
} from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { SoftDeleteStatus, PreloadStatus, MarginType } from '~/framework/domain/typeAliases';
import {
  GenerationSiteEntity,
  GenerationSiteEntityData,
} from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { DriverEntity, DriverEntityData } from '~/framework/domain/masters/driver/driverEntity';
import { Maybe } from '~/framework/typeAliases';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { CarTypeEntity, CarTypeEntityData } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity, AggregatedCarEntityData } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { UserEntity, UserEntityData } from '~/framework/domain/masters/user/userEntity';
import {
  AggregatedClientEntity,
  AggregatedClientEntityData,
} from '~/framework/domain/masters/client/aggregatedClientEntity';
import { UploadedFile } from '~/graphql/graphQLServerApi';
import { AggregatedDisposalSiteEntityData } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';

export interface AggregatedGenerationSiteEntityData extends GenerationSiteEntityData {
  client: AggregatedClientEntityData;
  bannedDrivers: DriverEntityData[];
  defaultAssignedDriver: Maybe<DriverEntityData>;
  defaultAssignedDisposalSite: Maybe<AggregatedDisposalSiteEntityData>;
  defaultAssignableCarTypes: CarTypeEntityData[];
  defaultAssignedCar: Maybe<AggregatedCarEntityData>;
  createdBy: UserEntityData;
  defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntityData>;
}

export class AggregatedGenerationSiteEntity extends GenerationSiteEntity {
  client: AggregatedClientEntity;
  bannedDrivers: DriverEntity[];
  defaultAssignedDriver: Maybe<DriverEntity>;
  defaultAssignedDisposalSite: Maybe<DisposalSiteEntity>;
  defaultAssignableCarTypes: CarTypeEntity[];
  defaultAssignedCar: Maybe<AggregatedCarEntity>;
  createdBy: UserEntity;
  defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>;

  constructor(
    id: string,
    clientId: string,
    code: Maybe<string>,
    name: string,
    nameRuby: string,
    zipCode: Maybe<string>,
    address1: string,
    address2: string,
    address3: string,
    address4: Maybe<string>,
    latitude: number,
    longitude: number,
    bannedDriverIds: string[],
    defaultAssignedDriverId: Maybe<string>,
    defaultAssignedDisposalSiteId: Maybe<string>,
    defaultAssignableCarTypeIds: string[],
    defaultAssignedCarId: Maybe<string>,
    defaultAvoidHighways: boolean,
    defaultDurationAtEntrance: number,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    note: Maybe<string>,
    attachments: UploadedFile[],
    isAddressComplete: boolean,
    createdAt: Date,
    status: SoftDeleteStatus,
    defaultCollectablePeriodTemplateId: Maybe<string>,
    defaultCollectablePeriodStart: Maybe<number>,
    defaultCollectablePeriodEnd: Maybe<number>,
    defaultRouteCollectionAllowed: Maybe<boolean>,
    defaultPreloadStatus: Maybe<PreloadStatus>,
    defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>,
    defaultMarginTypeOfFixedArrivalTime: MarginType,
    defaultMarginOfFixedArrivalTime: number,
    noteForOffice: Maybe<string>,
    client: AggregatedClientEntity,
    bannedDrivers: DriverEntity[],
    defaultAssignedDriver: Maybe<DriverEntity>,
    defaultAssignedDisposalSite: Maybe<DisposalSiteEntity>,
    defaultAssignableCarTypes: CarTypeEntity[],
    defaultAssignedCar: Maybe<AggregatedCarEntity>,
    createdBy: UserEntity,
    defaultCollectablePeriodTemplate: Maybe<CollectablePeriodTemplateEntity>
  ) {
    super(
      id,
      clientId,
      code,
      name,
      nameRuby,
      zipCode,
      address1,
      address2,
      address3,
      address4,
      latitude,
      longitude,
      bannedDriverIds,
      defaultAssignedDriverId,
      defaultAssignedDisposalSiteId,
      defaultAssignableCarTypeIds,
      defaultAssignedCarId,
      defaultAvoidHighways,
      defaultDurationAtEntrance,
      restPeriodStart,
      restPeriodEnd,
      note,
      attachments,
      isAddressComplete,
      createdAt,
      status,
      defaultCollectablePeriodTemplateId,
      defaultCollectablePeriodStart,
      defaultCollectablePeriodEnd,
      defaultRouteCollectionAllowed,
      defaultPreloadStatus,
      defaultIsFixedArrivalTimeReportNeeded,
      defaultMarginTypeOfFixedArrivalTime,
      defaultMarginOfFixedArrivalTime,
      noteForOffice
    );
    this.client = client;
    this.bannedDrivers = bannedDrivers;
    this.defaultAssignedDriver = defaultAssignedDriver;
    this.defaultAssignedDisposalSite = defaultAssignedDisposalSite;
    this.defaultAssignableCarTypes = defaultAssignableCarTypes;
    this.defaultAssignedCar = defaultAssignedCar;
    this.createdBy = createdBy;
    this.defaultCollectablePeriodTemplate = defaultCollectablePeriodTemplate;
  }
}
