import * as Search from './search';
import { Id, Maybe } from '~/framework/typeAliases';
import {
  ICondition,
  packingPlacement$getByKeywordsSymbol,
} from '~/framework/server-api/packing-placement/getByKeywords';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { IGenerationSiteFactory } from '~/framework/factories/masters/generationSiteFactory';
import { mapEntity } from '~/framework/core/mapper';
import { AggregatedPackingPlacementMapper } from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementMapper';
import { IUpdateData, packingPlacement$updateSymbol } from '~/framework/server-api/packing-placement/update';
import { AggregatedPackingPlacementEntity } from '~/framework/domain/packing-placement/packing-placement/aggregatedPackingPlacementEntity';

export const packingPlacementSymbol = Symbol('packingPlacement');

export class PackingPlacementApplicationService implements Search.ISearch {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly generationSiteFactory: IGenerationSiteFactory;

  constructor(store: Store, serverApis: ServerApiManager, generationSiteFactory: IGenerationSiteFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.generationSiteFactory = generationSiteFactory;
  }

  async search(first: number, after: Maybe<string>, condition: ICondition): Promise<Search.IList> {
    const packingPlacement$getByKeywordsApi = this.serverApis.get(packingPlacement$getByKeywordsSymbol);
    const packingPlacementMapper = new AggregatedPackingPlacementMapper(
      this.store.packingPlacement.aggregatedPackingPlacement
    );
    const result = await packingPlacement$getByKeywordsApi.getByKeywords(first, after, condition);

    // 現状、排出場以外である事は想定しない
    const generationSiteEdges = result.edges.filter(
      (edge) => edge.node.__typename === 'PackingPlacementsAtGenerationSite'
    );
    const generationSiteDatas = generationSiteEdges.map((edge) => edge.node.generationSite);
    const generationSites = await this.generationSiteFactory.buildByData(generationSiteDatas);
    const generationSiteMap = mapEntity(generationSites);

    const output: Search.IList = {
      pageInfo: result.pageInfo,
      totalCount: result.totalCount,
      items: generationSiteEdges.map((edge) => {
        return {
          __typename: 'PackingPlacementsAtGenerationSite',
          generationSite: generationSiteMap.getOrError(edge.node.generationSite.id),
          cursor: edge.cursor,
          packingPlacements: packingPlacementMapper.map(
            edge.node.packingPlacements.map((aData) => {
              return {
                containerTypeId: aData.containerType.id,
                ...aData,
              };
            })
          ),
          lastAllocatedAt: edge.node.lastAllocatedAt,
          managementStartedOn: edge.node.managementStartedOn,
        };
      }),
    };
    return output;
  }

  async update(generationSiteId: Id, packingPlacements: IUpdateData[]): Promise<AggregatedPackingPlacementEntity[]> {
    const packingPlacement$updateApi = this.serverApis.get(packingPlacement$updateSymbol);
    const packingPlacementMapper = new AggregatedPackingPlacementMapper(
      this.store.packingPlacement.aggregatedPackingPlacement
    );
    const result = await packingPlacement$updateApi.update(generationSiteId, packingPlacements);
    const entities = packingPlacementMapper.map(
      result.map((aData) => {
        return {
          containerTypeId: aData.containerType.id,
          ...aData,
        };
      })
    );
    return entities;
  }
}
