import { StoredMapperBase } from '~/framework/core/mapper';
import { OrderGroupEntityData, OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';

export class OrderGroupMapper extends StoredMapperBase<OrderGroupEntityData, OrderGroupEntity> {
  protected store: OrderGroupStore;

  constructor(store: OrderGroupStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: OrderGroupEntityData): OrderGroupEntity {
    return new OrderGroupEntity(data.id, data.name, data.colorName);
  }

  protected updateWithData(data: OrderGroupEntityData, entity: OrderGroupEntity): void {
    entity.name = data.name;
    entity.colorName = data.colorName;
  }
}
