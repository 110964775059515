import { Store } from '~/framework/domain/store';
import { ApplicationServiceType, ApplicationServiceTypes } from '~/framework/application/applicationServiceTypes';
import {
  CollectablePeriodTemplateApplicationService,
  collectablePeriodTemplateSymbol,
} from '~/framework/application/masters/collectable-period-template/collectablePeriodTemplateApplicationService';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Instantiator } from '~/framework/typeAliases';
import {
  WasteTypeApplicationService,
  wasteTypeSymbol,
} from '~/framework/application/masters/waste-type/wasteTypeApplicationService';
import {
  OrderGroupApplicationService,
  orderGroupSymbol,
} from '~/framework/application/masters/order-group/orderGroupApplicationService';
import {
  BaseSiteApplicationService,
  baseSiteSymbol,
} from '~/framework/application/masters/base-site/baseSiteApplicationService';
import {
  CarTypeApplicationService,
  carTypeSymbol,
} from '~/framework/application/masters/car-type/carTypeApplicationService';
import {
  DriverAttendanceTemplateApplicationService,
  driverAttendanceTemplateSymbol,
} from '~/framework/application/masters/driver-attendance-template/driverAttendanceTemplateApplicationService';
import { OrderApplicationService, orderSymbol } from '~/framework/application/schedule/order/orderApplicationService';
import { CarApplicationService, carSymbol } from '~/framework/application/masters/car/carApplicationService';
import {
  DriverApplicationService,
  driverSymbol,
} from '~/framework/application/masters/driver/driverApplicationService';
import {
  GenerationSiteApplicationService,
  generationSiteSymbol,
} from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import {
  AttendanceApplicationService,
  attendanceSymbol,
} from '~/framework/application/masters/attendance/attendanceApplicationService';
import {
  DisposalSiteApplicationService,
  disposalSiteSymbol,
} from '~/framework/application/masters/disposal-site/disposalSiteApplicationService';
import {
  ClientApplicationService,
  clientSymbol,
} from '~/framework/application/masters/client/clientApplicationService';
import {
  ContainerTypeApplicationService,
  containerTypeSymbol,
} from '~/framework/application/masters/container-type/containerTypeApplicationService';
import {
  DriverAttendanceApplicationService,
  driverAttendanceSymbol,
} from '~/framework/application/masters/driver-attendance/driverAttendanceApplicationService';
import {
  OfficeSettingApplicationService,
  officeSettingSymbol,
} from '~/framework/application/masters/office-setting/officeSettingApplicationService';
import {
  UserSettingApplicationService,
  userSettingSymbol,
} from '~/framework/application/userSettingApplicationService';
import {
  AuthenticationApplicationService,
  authenticationSymbol,
} from '~/framework/application/authentication/authenticationApplicationService';
import { VersionApplicationService, versionSymbol } from '~/framework/application/version/versionApplicationService';
import {
  ScheduleApplicationService,
  scheduleSymbol,
} from '~/framework/application/schedule/schedule/scheduleApplicationService';
import {
  OrderAcceptanceCheckApplicationService,
  orderAcceptanceCheckSymbol,
} from '~/framework/application/schedule/order/order-acceptance-check/orderAcceptanceCheckApplicationService';
import { OrderFactory } from '~/framework/factories/schedule/orderFactory';
import { ScheduleFactory } from '~/framework/factories/schedule/scheduleFactory';
import { DriverFactory } from '~/framework/factories/masters/driverFactory';
import { GenerationSiteFactory } from '~/framework/factories/masters/generationSiteFactory';
import {
  CarAttendanceApplicationService,
  carAttendanceSymbol,
} from '~/framework/application/masters/car-attendance/carAttendanceApplicationService';
import {
  ContainerTaskApplicationService,
  containerTaskSymbol,
} from '~/framework/application/masters/container-type/containerTaskApplicationService';
import {
  PackingStyleApplicationService,
  packingStyleSymbol,
} from '~/framework/application/masters/packingStyleApplicationService';
import {
  PackingStyleTaskTypeDefaultApplicationService,
  packingStyleTaskTypeDefaultSymbol,
} from '~/framework/application/masters/packingStyleTaskTypeDefaultApplicationService';
import {
  PackingPlacementApplicationService,
  packingPlacementSymbol,
} from '~/framework/application/packing-placement/packingPlacementApplicationService';
import { DriverAttendanceFactory } from '~/framework/factories/masters/driverAttendanceFactory';
import {
  ReservationApplicationService,
  reservationSymbol,
} from '~/framework/application/reservation/reservationApplicationService';
import { ReservationFactory } from '~/framework/factories/reservation/reservationFactory';
import {
  TaskTypeApplicationService,
  taskTypeSymbol,
} from '~/framework/application/masters/task-type/taskTypeApplicationService';
import {
  RoutingRegulationApplicationService,
  routingRegulationServiceSymbol,
} from '~/framework/application/masters/routing-regulation/routingRegulationApplicationService';
import {
  ErpOrderApplicationService,
  erpOrderApplicationServiceSymbol,
} from '~/framework/application/schedule/order/erpOrderApplicationService';
import { HolidayRuleApplicationService, holidayRuleSymbol } from './masters/holiday-rule/holidayRuleApplicationService';
import { AnnouncementApplicationService, announcementSymbol } from './announcementApplicationService';

export class ApplicationServiceManager {
  private readonly serverApis: ServerApiManager;
  private readonly store: Store;
  private readonly map: Map<symbol, Instantiator<unknown>>;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
    this.map = new Map<symbol, Instantiator<unknown>>();

    // DI コンテナを利用してもよいが、何をやっているのか分かりづらいコードになるよりは
    // 手動で分かりやすい方がよいと考え、この様な形にしている。
    // どうしても手間が気になる様であれば今後 DI コンテナを利用してもよい。
    this.add(collectablePeriodTemplateSymbol, () => new CollectablePeriodTemplateApplicationService(store, serverApis));
    this.add(wasteTypeSymbol, () => new WasteTypeApplicationService(store, serverApis));
    this.add(orderGroupSymbol, () => new OrderGroupApplicationService(store, serverApis));
    this.add(baseSiteSymbol, () => new BaseSiteApplicationService(store, serverApis));
    this.add(carTypeSymbol, () => new CarTypeApplicationService(store, serverApis));
    this.add(driverAttendanceTemplateSymbol, () => new DriverAttendanceTemplateApplicationService(store, serverApis));
    this.add(orderSymbol, () => {
      const orderFactory = new OrderFactory(store, serverApis);
      return new OrderApplicationService(store, serverApis, orderFactory);
    });
    this.add(carSymbol, () => {
      return new CarApplicationService(store, serverApis);
    });
    this.add(generationSiteSymbol, () => {
      const generationSiteFactory = new GenerationSiteFactory(store, serverApis);
      return new GenerationSiteApplicationService(store, serverApis, generationSiteFactory);
    });
    this.add(driverSymbol, () => {
      const driverFactory = new DriverFactory(store, serverApis);
      return new DriverApplicationService(store, serverApis, driverFactory);
    });
    this.add(driverAttendanceSymbol, () => {
      const driverAttendanceFactory = new DriverAttendanceFactory(store, serverApis);
      return new DriverAttendanceApplicationService(store, serverApis, driverAttendanceFactory);
    });
    this.add(attendanceSymbol, () => new AttendanceApplicationService(store, serverApis));
    this.add(disposalSiteSymbol, () => new DisposalSiteApplicationService(store, serverApis));
    this.add(clientSymbol, () => new ClientApplicationService(store, serverApis));
    this.add(containerTypeSymbol, () => new ContainerTypeApplicationService(store, serverApis));
    this.add(containerTaskSymbol, () => new ContainerTaskApplicationService(store, serverApis));
    this.add(officeSettingSymbol, () => new OfficeSettingApplicationService(store, serverApis));
    this.add(holidayRuleSymbol, () => new HolidayRuleApplicationService(store, serverApis));
    this.add(userSettingSymbol, () => new UserSettingApplicationService(store, serverApis));
    this.add(announcementSymbol, () => new AnnouncementApplicationService(serverApis));
    this.add(authenticationSymbol, () => new AuthenticationApplicationService(store, serverApis));
    this.add(versionSymbol, () => new VersionApplicationService(serverApis));
    this.add(scheduleSymbol, () => {
      const orderFactory = new OrderFactory(store, serverApis);
      const driverAttendanceFactory = new DriverAttendanceFactory(store, serverApis);
      const scheduleFactory = new ScheduleFactory(store, serverApis, orderFactory, driverAttendanceFactory);
      return new ScheduleApplicationService(store, serverApis, orderFactory, scheduleFactory);
    });
    this.add(orderAcceptanceCheckSymbol, () => {
      const orderFactory = new OrderFactory(store, serverApis);
      const driverAttendanceFactory = new DriverAttendanceFactory(store, serverApis);
      const scheduleFactory = new ScheduleFactory(store, serverApis, orderFactory, driverAttendanceFactory);
      return new OrderAcceptanceCheckApplicationService(store, serverApis, scheduleFactory);
    });
    this.add(carAttendanceSymbol, () => new CarAttendanceApplicationService(store, serverApis));
    this.add(packingStyleSymbol, () => new PackingStyleApplicationService(store, serverApis));
    this.add(
      packingStyleTaskTypeDefaultSymbol,
      () => new PackingStyleTaskTypeDefaultApplicationService(store, serverApis)
    );
    this.add(packingPlacementSymbol, () => {
      const generationSiteFactory = new GenerationSiteFactory(store, serverApis);
      return new PackingPlacementApplicationService(store, serverApis, generationSiteFactory);
    });
    this.add(reservationSymbol, () => {
      const reservationFactory = new ReservationFactory(store, serverApis);
      return new ReservationApplicationService(serverApis, reservationFactory);
    });
    this.add(taskTypeSymbol, () => {
      return new TaskTypeApplicationService(store, serverApis);
    });
    this.add(routingRegulationServiceSymbol, () => {
      return new RoutingRegulationApplicationService(serverApis);
    });
    this.add(erpOrderApplicationServiceSymbol, () => new ErpOrderApplicationService(serverApis));
  }

  get<Type extends keyof ApplicationServiceTypes>(type: Type): ApplicationServiceType<Type> {
    return (this.map.getOrError(type) as Instantiator<ApplicationServiceType<Type>>)();
  }

  add<Type extends keyof ApplicationServiceTypes>(
    type: Type,
    instantiator: Instantiator<ApplicationServiceType<Type>>
  ): void {
    this.map.set(type, instantiator);
  }
}
