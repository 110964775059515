import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';
import { ClientForDisposalSite } from '~/framework/server-api/masters/disposalSite';

export const AcceptanceLimitDefault = 9999;

export interface DisposalSiteEntityData {
  id: string;
  code: Maybe<string>;
  name: string;
  nameRuby: string;
  zipCode: Maybe<string>;
  address1: string;
  address2: string;
  address3: string;
  address4: Maybe<string>;
  latitude: number;
  longitude: number;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  durationAtEntrance: number;
  restPeriodStart: Maybe<number>;
  restPeriodEnd: Maybe<number>;
  acceptanceLimit: number;
  note: Maybe<string>;
  isAddressComplete: boolean;
  createdAt: Date;
  client: Maybe<ClientForDisposalSite>;
  temporalAcceptanceInterval: Maybe<number>;
  temporalAcceptanceLimit: Maybe<number>;
}

export class DisposalSiteEntity {
  readonly id: string;
  readonly persistentId: string;
  code: Maybe<string>;
  name: string;
  nameRuby: string;
  @ReactiveProperty(DisposalSiteEntity.prototype.updateAddress)
  zipCode: Maybe<string>;

  @ReactiveProperty(DisposalSiteEntity.prototype.updateAddress)
  address1: string;

  @ReactiveProperty(DisposalSiteEntity.prototype.updateAddress)
  address2: string;

  @ReactiveProperty(DisposalSiteEntity.prototype.updateAddress)
  address3: string;

  @ReactiveProperty(DisposalSiteEntity.prototype.updateAddress)
  address4: Maybe<string>;

  latitude: number;
  longitude: number;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  durationAtEntrance: number;
  @ReactiveProperty(DisposalSiteEntity.prototype.updateHasRestPeriod)
  restPeriodStart: Maybe<number>;

  @ReactiveProperty(DisposalSiteEntity.prototype.updateHasRestPeriod)
  restPeriodEnd: Maybe<number>;

  acceptanceLimit: number;

  note: Maybe<string>;
  isAddressComplete: boolean;
  createdAt: Date;
  client: Maybe<ClientForDisposalSite>;

  temporalAcceptanceInterval: number | undefined;
  temporalAcceptanceLimit: number | undefined;

  // reactive property
  hasRestPeriod: boolean = false;
  address: string = '';

  private updateHasRestPeriod(): void {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    this.hasRestPeriod = this.restPeriodStart !== undefined && this.restPeriodEnd !== undefined;
  }

  constructor(
    id: string,
    code: Maybe<string>,
    name: string,
    nameRuby: string,
    zipCode: Maybe<string>,
    address1: string,
    address2: string,
    address3: string,
    address4: Maybe<string>,
    latitude: number,
    longitude: number,
    disposablePeriodStart: number,
    disposablePeriodEnd: number,
    durationAtEntrance: number,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    acceptanceLimit: number,
    note: Maybe<string>,
    isAddressComplete: boolean,
    createdAt: Date,
    client: Maybe<ClientForDisposalSite>,
    temporalAcceptanceInterval: Maybe<number>,
    temporalAcceptanceLimit: Maybe<number>
  ) {
    this.id = id;
    this.persistentId = id;
    this.code = code;
    this.name = name;
    this.nameRuby = nameRuby;
    this.zipCode = zipCode;
    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.address4 = address4;
    this.latitude = latitude;
    this.longitude = longitude;
    this.disposablePeriodStart = disposablePeriodStart;
    this.disposablePeriodEnd = disposablePeriodEnd;
    this.durationAtEntrance = durationAtEntrance;
    this.restPeriodStart = restPeriodStart;
    this.restPeriodEnd = restPeriodEnd;
    this.acceptanceLimit = acceptanceLimit;
    this.note = note;
    this.isAddressComplete = isAddressComplete;
    this.createdAt = createdAt;
    this.client = client;
    this.temporalAcceptanceInterval = temporalAcceptanceInterval;
    this.temporalAcceptanceLimit = temporalAcceptanceLimit;
  }

  private updateAddress(): void {
    const components = [];
    if (this.zipCode !== undefined && this.zipCode !== '') {
      components.push(`〒${this.zipCode}`);
    }
    if (this.address1 !== '') components.push(this.address1);
    if (this.address2 !== '') components.push(this.address2);
    if (this.address3 !== '') components.push(this.address3);
    if (this.address4 !== undefined && this.address4 !== '') components.push(this.address4);
    this.address = components.join(' ');
  }
}
