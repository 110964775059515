import { CarTypeEntity, CarTypeEntityData } from '~/framework/domain/masters/car-type/carTypeEntity';
import { OrderGroupEntity, OrderGroupEntityData } from '~/framework/domain/masters/order-group/orderGroupEntity';
import {
  AggregatedCarTypeContainerTypeEntity,
  AggregatedCarTypeContainerTypeEntityData,
} from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeEntity';
import { Seconds } from '~/framework/typeAliases';

export interface AggregatedCarTypeEntityData extends CarTypeEntityData {
  orderGroup: OrderGroupEntityData;
  loadableContainerTypes: AggregatedCarTypeContainerTypeEntityData[];
}

export class AggregatedCarTypeEntity extends CarTypeEntity implements AggregatedCarTypeEntity {
  orderGroup: OrderGroupEntity;
  loadableContainerTypes: AggregatedCarTypeContainerTypeEntity[];

  constructor(
    id: string,
    name: string,
    drivingSpeedCoefficient: number,
    durationOfBoarding: Seconds,
    durationOfAlighting: Seconds,
    orderGroup: OrderGroupEntity,
    loadableContainerTypes: AggregatedCarTypeContainerTypeEntity[]
  ) {
    super(id, name, orderGroup.id, drivingSpeedCoefficient, durationOfBoarding, durationOfAlighting);
    this.orderGroup = orderGroup;
    this.loadableContainerTypes = loadableContainerTypes;
  }
}
