import { Maybe } from '~/framework/typeAliases';
import { RawScheduleJsonObject } from '~/framework/server-api/schedule/schedule/schedule';
import { RawRouteJsonObjectWithId } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { ScheduleResponseJsonObject } from '~/graphql/custom-scalars/scheduleResponseJsonObjectTypes';

export const schedule$createSymbol = Symbol('schedule$createSymbol');

export interface ICreateApi {
  [schedule$createSymbol]: void;
  create(data: IScheduleCreateData[]): Promise<string[]>;
}

export interface IScheduleCreateData {
  attendanceId: string;
  orderGroupId: string;
  scheduleData: Maybe<RawScheduleJsonObject<RawRouteJsonObjectWithId>>;
  scheduleResponse: Maybe<ScheduleResponseJsonObject>;
}
