/**
 * 車を使うという行為に対して割り当てるエンティティ
 * 拠点でのみ乗り換え（乗り降り）が行えるので、ルート単位になる
 * 一方で、受注は複数割り当てられる可能性があるのでここには含めない
 */
import { IDriverAssignment, DriverType } from '~/framework/domain/schedule/schedule/pseudo-entities/driverAssignment';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { createLogger } from '~/framework/logger';

export interface ICarUsageEntityData {
  /**
   * 車の ID
   */
  carId: PseudoId;

  /**
   * 車使用の ID
   */
  carIndex: number;
}

export interface ICarUsageEntity extends ICarUsageEntityData {
  /**
   * 運転する人の ID
   */
  driverId: PseudoId;

  /**
   * 補助する人（乗務員）の ID
   * 空である可能性はある
   */
  helperIds: PseudoId[];

  /**
   * 補助あり(同乗)の状態になっているかどうか
   */
  isSharedRide: boolean;
}

/**
 * メインのドライバーはとりあえず仮で先頭で与えられた乗務員という事にしている
 */
export class CarUsageEntity implements ICarUsageEntity {
  carId: PseudoId;
  carIndex: number;
  driverId: PseudoId;
  helperIds: PseudoId[];
  isSharedRide: boolean;

  constructor(carId: PseudoId, carIndex: number, driverAssignments: IDriverAssignment[]) {
    const logger = createLogger(`carUsageEntity`);
    if (driverAssignments.length === 0) {
      throw new Error(`Cannot build CarUsageEntity with 0 driver!`);
    }
    this.carId = carId;
    this.carIndex = carIndex;

    // NOTE: 乗務員全体の ids
    const driverIds = [...driverAssignments.map((driverAssignment) => driverAssignment.driverId)];

    const drivers: IDriverAssignment[] = driverAssignments.filter(
      (driverAssignment) => driverAssignment.driverType === DriverType.Driver
    );

    if (drivers.length > 1) {
      // NOTE: 配車表 json に運転者フィールドが存在するが、運転者が2人以上存在する場合
      logger.warn(
        `Number of drivers (${drivers.length}) can't be more than 1!, carId: ${carId}, carIndex: ${carIndex} driverAssignments: ${driverAssignments}`
      );
    }

    // NOTE: 配車表に is_driver / is_helper が存在すればそれを元に運転者を決定する
    if (drivers.length === 1) {
      this.driverId = drivers[0].driverId;
      this.helperIds = driverAssignments
        .filter((driverAssignment) => driverAssignment.driverType === DriverType.Helper)
        .map((driverAssignment) => driverAssignment.driverId);
    } else {
      // NOTE: 配車表 json に is_driver / is_helper フラグが追加される前の配車表フォーマットを考慮し、後方互換性を保つために FE で「運転者/補助員」決定するロジックを残している
      // 具体的には、同じ車に乗車予定の乗務員が複数いた場合、driverIds 配列の先頭 id の乗務員を運転者として残りを補助員としている
      this.driverId = driverIds.splice(0, 1)[0];
      this.helperIds = driverIds;
    }
    this.isSharedRide = 0 < this.helperIds.length;
  }
}
