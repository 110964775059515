import { Maybe } from '~/framework/typeAliases';
import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { PackingStyleEntity } from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { getOrInit } from '~/framework/core/map';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import {
  IPackingStyleFormValues,
  PackingStyleFormValues,
} from '~/components/panels/masters/r-car-type-form/packingStyleFormValues';
import {
  ILoadableContainerTypeFormValues,
  LoadableContainerTypeFormValuesFactory,
} from '~/components/panels/masters/r-car-type-form/loadableContainerTypeFormValues';
import { ICreateData, IUpdateData, ICarTypeContainerTypeCreateData } from '~/framework/server-api/masters/carType';

import { IllegalStateException } from '~/framework/core/exception';

export interface IFormValues {
  carTypeId: Maybe<string>;
  orderGroupId: Maybe<string>;
  name: string;
  drivingSpeedCoefficient: number;
  durationOfBoarding: number;
  durationOfAlighting: number;
  packingStyles: IPackingStyleFormValues[];
  isEqualTo(another: IFormValues): boolean;
  hasInvalidLoadableContainerTypes(): boolean;
}

export class FormValues implements IFormValues {
  carTypeId: Maybe<string>;
  orderGroupId: Maybe<string>;
  name: string;
  drivingSpeedCoefficient: number;
  durationOfBoarding: number;
  durationOfAlighting: number;
  packingStyles: IPackingStyleFormValues[];

  constructor(
    carTypeId: Maybe<string>,
    orderGroupId: Maybe<string>,
    name: string,
    drivingSpeedCoefficient: number,
    durationOfBoarding: number,
    durationOfAlighting: number,
    packingStyles: IPackingStyleFormValues[]
  ) {
    this.carTypeId = carTypeId;
    this.orderGroupId = orderGroupId;
    this.name = name;
    this.drivingSpeedCoefficient = drivingSpeedCoefficient;
    this.durationOfBoarding = durationOfBoarding;
    this.durationOfAlighting = durationOfAlighting;
    this.packingStyles = packingStyles;
  }

  static clone(original: IFormValues): IFormValues {
    const packingStyles = original.packingStyles.map((packingStyle) => PackingStyleFormValues.clone(packingStyle));
    return new FormValues(
      original.carTypeId,
      original.orderGroupId,
      original.name,
      original.drivingSpeedCoefficient,
      original.durationOfBoarding,
      original.durationOfAlighting,
      packingStyles
    );
  }

  isEqualTo(another: IFormValues): boolean {
    // 後でデバッグしやすい様にやや冗長なやり方をしている
    const equalityMap: Map<keyof IFormValues, boolean> = new Map<keyof IFormValues, boolean>();
    const packingStyles =
      this.packingStyles.length === another.packingStyles.length &&
      this.packingStyles.every((item, index) => item.isEqualTo(another.packingStyles[index]));
    equalityMap.set('orderGroupId', this.orderGroupId === another.orderGroupId);
    equalityMap.set('name', this.name === another.name);
    equalityMap.set('drivingSpeedCoefficient', this.drivingSpeedCoefficient === another.drivingSpeedCoefficient);
    equalityMap.set('durationOfBoarding', this.durationOfBoarding === another.durationOfBoarding);
    equalityMap.set('durationOfAlighting', this.durationOfAlighting === another.durationOfAlighting);
    equalityMap.set('packingStyles', packingStyles);
    return Array.from(equalityMap.values()).every((equality) => equality === true);
  }

  hasInvalidLoadableContainerTypes(): boolean {
    return this.packingStyles.some((packingStyle) =>
      packingStyle.loadableContainerTypes.some((loadableContainerType) => !loadableContainerType.isValid())
    );
  }
}

export class FormValuesFactory {
  private readonly packingStyleEntities: PackingStyleEntity[];
  private readonly containerTypeEntities: AggregatedContainerTypeEntity[];
  private readonly orderGroups: OrderGroupEntity[];

  constructor(
    orderGroups: OrderGroupEntity[],
    packingStyleEntities: PackingStyleEntity[],
    containerTypeEntities: AggregatedContainerTypeEntity[]
  ) {
    this.orderGroups = orderGroups;
    this.packingStyleEntities = [...packingStyleEntities].sort((a, b) => a.code.localeCompare(b.code));
    this.containerTypeEntities = containerTypeEntities;
  }

  build(carType: Maybe<AggregatedCarTypeEntity>): IFormValues {
    // loadableContainerType からは packingStyle の ID が引けないのでマップを用意しておく
    const containerTypePackingStyleMap: Map<string, string> = new Map();
    for (const containerTypeEntity of this.containerTypeEntities) {
      containerTypePackingStyleMap.set(containerTypeEntity.id, containerTypeEntity.packingStyle.id);
    }

    const packingStyleContainersMap: Map<string, AggregatedContainerTypeEntity[]> = new Map();
    for (const containerTypeEntity of this.containerTypeEntities) {
      const containers = getOrInit(packingStyleContainersMap, containerTypeEntity.packingStyle.id, []);
      containers.push(containerTypeEntity);
    }

    const packingStyleLoadableContainersMap: Map<string, ILoadableContainerTypeFormValues[]> = new Map();
    for (const loadableContainerTypeEntity of carType?.loadableContainerTypes ?? []) {
      const loadableContainerType =
        LoadableContainerTypeFormValuesFactory.buildByLoadableContainerType(loadableContainerTypeEntity);
      const packingStyleId = containerTypePackingStyleMap.getOrError(loadableContainerTypeEntity.containerTypeId);
      const loadableContainerTypes = getOrInit(packingStyleLoadableContainersMap, packingStyleId, []);
      loadableContainerTypes.push(loadableContainerType);
    }

    const packingStyles: IPackingStyleFormValues[] = [];
    for (const packingStyleEntity of this.packingStyleEntities) {
      packingStyles.push(
        new PackingStyleFormValues(
          packingStyleEntity.id,
          packingStyleEntity.name,
          packingStyleContainersMap.get(packingStyleEntity.id) ?? [],
          packingStyleLoadableContainersMap.get(packingStyleEntity.id) ?? []
        )
      );
    }

    if (carType === undefined) {
      const orderGroupId = this.orderGroups.length === 1 ? this.orderGroups[0].id : undefined;
      return new FormValues(undefined, orderGroupId, '', 100, 5, 5, packingStyles);
    } else {
      // JavaScript の内部数値表現は 64bit の浮動小数点数で、
      // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Number
      // drivingSpeedCoefficient は API 上は係数の float（1.x など、GraphQL は JSON なので文字列表現）になる。
      // 1.1 など、小数点以下を2進数表現にした場合に無限小数となってしまうものは不必要に仮数部が長くなり、10進数に
      // 変換した場合に小数点以下に意図しなかった小数が追加される事になってしまう。そこで floor しておき、表面上
      // そういった数はなかった事にしておく（精度が要求される値ではないので実質これでも問題はないはず）。
      return new FormValues(
        carType.id,
        carType.orderGroupId,
        carType.name,
        Math.floor(carType.drivingSpeedCoefficient * 100),
        carType.durationOfBoarding / 60,
        carType.durationOfAlighting / 60,
        packingStyles
      );
    }
  }
}

export class ApiDataFactory {
  buildCreateData(formValues: IFormValues): ICreateData {
    return {
      name: formValues.name,
      orderGroupId: formValues.orderGroupId!,
      drivingSpeedCoefficient: formValues.drivingSpeedCoefficient / 100,
      durationOfBoarding: formValues.durationOfBoarding * 60,
      durationOfAlighting: formValues.durationOfAlighting * 60,
      loadableContainerTypes: this.buildLoadableContainerTypeData(formValues),
    };
  }

  buildUpdateData(formValues: IFormValues): IUpdateData {
    if (formValues.carTypeId === undefined) {
      throw new IllegalStateException(`Can't build update data.`);
    }
    return {
      id: formValues.carTypeId,
      name: formValues.name,
      orderGroupId: formValues.orderGroupId!,
      drivingSpeedCoefficient: formValues.drivingSpeedCoefficient / 100,
      durationOfBoarding: formValues.durationOfBoarding * 60,
      durationOfAlighting: formValues.durationOfAlighting * 60,
      loadableContainerTypes: this.buildLoadableContainerTypeData(formValues),
    };
  }

  private buildLoadableContainerTypeData(formValues: IFormValues): ICarTypeContainerTypeCreateData[] {
    const data: ICarTypeContainerTypeCreateData[] = [];
    for (const packingStyle of formValues.packingStyles) {
      for (const loadableContainerType of packingStyle.loadableContainerTypes) {
        if (loadableContainerType.isValid() === false) continue;
        data.push({
          containerTypeId: loadableContainerType.containerTypeId!,
          emptyCount: loadableContainerType.emptyCount || 0,
          emptyCountOccupation: (loadableContainerType.emptyCountOccupation || 0) / 100,
          lowerTierFullCount: loadableContainerType.lowerTierFullCount || 0,
          upperTierFullCount: loadableContainerType.upperTierFullCount || 0,
          fullCountOccupation: (loadableContainerType.fullCountOccupation || 0) / 100,
          isUpperTierAvailable: loadableContainerType.isUpperTierAvailable,
        });
      }
    }
    return data;
  }
}
