import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedCarEntity, AggregatedCarEntityData } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { AggregatedCarTypeMapper } from '~/framework/domain/masters/car-type/aggregatedCarTypeMapper';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import {
  AggregatedBaseSiteMapper,
  IAggregatedBaseSiteMapper,
} from '~/framework/domain/masters/base-site/aggregatedBaseSiteMapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';

export class AggregatedCarMapper extends StoredMapperBase<AggregatedCarEntityData, AggregatedCarEntity> {
  protected store: AggregatedCarStore;
  private carTypeMapper: AggregatedCarTypeMapper;
  private baseSiteMapper: IAggregatedBaseSiteMapper;

  constructor(
    store: AggregatedCarStore,
    carTypeStore: AggregatedCarTypeStore,
    orderGroupStore: OrderGroupStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore,
    baseSiteStore: AggregatedBaseSiteStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.carTypeMapper = new AggregatedCarTypeMapper(carTypeStore, orderGroupStore, carTypeContainerTypeStore);
    this.baseSiteMapper = new AggregatedBaseSiteMapper(baseSiteStore, userStore);
  }

  protected instantiateWithData(data: AggregatedCarEntityData): AggregatedCarEntity {
    const carTypeEntity = this.carTypeMapper.mapSingle(data.carType);
    const baseSiteEntity = this.baseSiteMapper.mapSingle(data.baseSite);

    return new AggregatedCarEntity(data.id, data.name, carTypeEntity, baseSiteEntity, data.enabledOn);
  }

  protected updateWithData(data: AggregatedCarEntityData, entity: AggregatedCarEntity): void {
    const carTypeEntity = this.carTypeMapper.mapSingle(data.carType);
    const baseSiteEntity = this.baseSiteMapper.mapSingle(data.baseSite);

    entity.name = data.name;
    entity.enabledOn = data.enabledOn;
    entity.carType = carTypeEntity;
    entity.baseSite = baseSiteEntity;
  }
}
