import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';

/**
 * Warning の種類
 */
export enum InconsistentReasonType {
  /**
   * ルートを固定している
   */
  IsFixedSchedule,
  /**
   * ルート回収でルートグループを設定している
   */
  RoutingGroup,
}

/**
 * 最適化エンジンに指定された、固定が原因で配車不可能になりうるドライバーとルートのindexの情報
 */
export interface IInconsistentRouteInfoData {
  /**
   * ドライバーの順番（ドライバーで一意）
   */
  index: number;
  /**
   * ドライバーの ID
   */
  driverId: PseudoId;
  /**
   * Warning の種類、現状は is_fixed_schedule か routing_group のみ
   */
  type: InconsistentReasonType;
}
