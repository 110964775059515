import {
  ContainerTypeEntity,
  ContainerTypeEntityData,
} from '~/framework/domain/masters/container-type/containerTypeEntity';
import { CarTypeEntity, CarTypeEntityData } from '~/framework/domain/masters/car-type/carTypeEntity';
import { Maybe } from '~/framework/typeAliases';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import {
  PackingStyleEntity,
  PackingStyleEntityData,
} from '~/framework/domain/masters/packing-style/packingStyleEntity';

export interface AggregatedContainerTypeEntityData extends ContainerTypeEntityData {
  transformingCarType: Maybe<CarTypeEntityData>;
  packingStyle: PackingStyleEntityData;
}

export class AggregatedContainerTypeEntity extends ContainerTypeEntity {
  transformingCarType: Maybe<CarTypeEntity>;
  packingStyle: PackingStyleEntity;

  constructor(
    id: string,
    name: string,
    unitName: string,
    transformingCarTypeId: Maybe<string>,
    status: SoftDeleteStatus,
    reservationSiteEnabled: boolean,
    transformingCarType: Maybe<CarTypeEntity>,
    packingStyle: PackingStyleEntity
  ) {
    super(id, name, packingStyle.id, unitName, transformingCarTypeId, status, reservationSiteEnabled);
    this.transformingCarType = transformingCarType;
    this.packingStyle = packingStyle;
  }
}
