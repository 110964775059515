
import Vue, { PropType } from 'vue';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { DriverType, DriverAssignmentType } from '~/framework/domain/typeAliases';
import {
  IOrderAssignableDriver,
  isDriversCandidate,
  isOperatorsCandidate,
  isHelpersCandidate,
  getDriverEntities,
  getAssignableDriverIdsByDriverType,
  buildAssignableDriversById,
  buildAssignableDriversByIds,
} from '~/framework/server-api/schedule/order/driver/assignableDriver';
import { ItemType } from '~/components/panels/schedule/r-order-form/rOrderFormSelectItem';
import { VuetifyColors } from '~/framework/constants';
import ROrderFormSelect from '~/components/panels/schedule/r-order-form/ROrderFormSelect.vue';
import RDriverAssignmentMultipleDialog from '~/components/panels/schedule/r-order-form/RDriverAssignmentMultipleDialog.vue';
import RDriverAssignmentMultipleView from '~/components/panels/schedule/r-order-form/RDriverAssignmentMultipleView.vue';
import { IGenerationSiteTaskItem } from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import { required, arrayLengthLessThanOrEqualToNum } from '~/framework/view-models/rules';
import { Id, Maybe } from '~/framework/typeAliases';
import { RinEventNames } from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  selectionItems: ItemType[];
  isDriverAssignmentMultipleDialogActive: boolean;
};

enum EventTypes {
  ChangeAssignableDrivers = 'change-assignable-drivers',
  ChangeHelperEnabled = 'change-helper-enabled',
  ChangeCandidate = 'change-candidate',
  SetAllFieldWorkersToHelper = 'set-all-field-workers-to-helper',
}

export default Vue.extend({
  name: 'RDriverAssignment',
  components: {
    ROrderFormSelect,
    RDriverAssignmentMultipleView,
    RDriverAssignmentMultipleDialog,
  },
  props: {
    isCandidate: {
      type: Boolean,
      required: true,
    },
    driverNum: {
      type: Number,
      required: true,
    },
    carNum: {
      type: Number,
      required: true,
    },
    numItems: {
      type: Array as PropType<Array<number>>,
      required: true,
    },
    driverAssignmentType: {
      type: String as PropType<DriverAssignmentType>,
      required: true,
    },
    assignableDrivers: {
      type: Array as PropType<IOrderAssignableDriver[]>,
      required: false,
      default: () => [],
    },
    driverItems: {
      type: Array as PropType<Array<DriverEntity>>,
      required: false,
      default: () => [],
    },
    generationSiteBannedDriverIds: {
      type: Array as PropType<Array<Id>>,
      required: false,
      default: () => [],
    },
    taskItems: {
      type: Array as PropType<Array<IGenerationSiteTaskItem>>,
      required: false,
      default: () => [],
    },
    driverNumDisabled: {
      type: Boolean,
      required: true,
    },
    driverAssignmentDisabled: {
      type: Boolean,
      required: true,
    },
    errorMessages: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    maxErrorCount: {
      type: Number,
      required: true,
    },
  },
  data(): DataType {
    return {
      selectionItems: [
        {
          title: '候補を複数登録',
          value: 'enableMultipleAssignmentDialog',
          icon: 'ffi-drivers',
          class: 'item--primary',
          color: VuetifyColors.Primary,
        },
        {
          title: '詳細設定をリセット',
          value: 'resetMultipleAssignment',
          icon: 'mdi-close',
          class: 'item--error',
          color: VuetifyColors.Error,
        },
      ],
      isDriverAssignmentMultipleDialogActive: false,
    };
  },
  computed: {
    isHelperEnabled(): boolean {
      return this.driverAssignmentType === DriverAssignmentType.Distinguished;
    },
    displaySelectionItems(): ItemType[] {
      if (this.isCandidate) {
        return this.selectionItems;
      } else {
        return this.selectionItems.filter((item) => item.value !== 'resetMultipleAssignment');
      }
    },
    // NOTE: 補助員の最大人数
    maxHelperNum(): number {
      return this.driverNum - this.carNum;
    },
    selectedDriverIds(): string[] {
      return getAssignableDriverIdsByDriverType(DriverType.Driver, this.assignableDrivers);
    },
    selectedDriverEntities(): DriverEntity[] {
      return getDriverEntities(DriverType.Driver, this.assignableDrivers, this.driverItems);
    },
    selectedOperatorIds(): string[] {
      return getAssignableDriverIdsByDriverType(DriverType.Operator, this.assignableDrivers);
    },
    selectedOperatorEntities(): DriverEntity[] {
      return getDriverEntities(DriverType.Operator, this.assignableDrivers, this.driverItems);
    },
    selectedHelperIds(): string[] {
      return getAssignableDriverIdsByDriverType(DriverType.Helper, this.assignableDrivers);
    },
    selectedHelperEntities(): DriverEntity[] {
      return getDriverEntities(DriverType.Helper, this.assignableDrivers, this.driverItems);
    },
    selectableOperatorItems(): DriverEntity[] {
      return this.driverItems.filter((driverItem) => !this.selectedHelperIds.includes(driverItem.id));
    },
    selectableHelperItems(): DriverEntity[] {
      return this.driverItems.filter((driverItem) => !this.selectedOperatorIds.includes(driverItem.id));
    },
  },

  watch: {
    taskItems: {
      handler() {
        // 作業に更新があれば作業担当不可乗務員が変更されている可能性があるため、validationをトリガーする
        this.$emit(EventTypes.ChangeAssignableDrivers, this.driverNum, [...this.assignableDrivers]);
      },
      deep: true,
    },
  },

  methods: {
    isDriversCandidate,
    isOperatorsCandidate,
    isHelpersCandidate,
    getDriverEntities,
    buildAssignableDriversById,
    buildAssignableDriversByIds,
    required,
    arrayLengthLessThanOrEqualToNum,
    onChangeDriverNum(value: number): void {
      this.$emit(EventTypes.ChangeAssignableDrivers, value, this.assignableDrivers);
      // NOTE: 補助指定がオンなら driverNum === carNum になると補助員は存在しないはずなので、補助指定をオフにする
      if (value === this.carNum && this.isHelperEnabled) {
        this.$emit(EventTypes.ChangeHelperEnabled, false);
        this.$emit(EventTypes.ChangeAssignableDrivers, value, []);
      }
    },
    onChangeHelperEnabled(value: boolean): void {
      this.$emit(EventTypes.ChangeHelperEnabled, value);
      this.$emit(EventTypes.ChangeAssignableDrivers, this.driverNum, []);
    },
    onClickSetAllFieldWorkersToHelper(): void {
      this.$rinGtm.push(RinEventNames.SET_ALL_FIELD_WORKERS_TO_HELPER);
      this.$emit(EventTypes.SetAllFieldWorkersToHelper);
    },
    onChangeAssignableDriver(driverType: string, values: string[]): void {
      const operators = this.buildAssignableDriversByIds(DriverType.Operator, this.selectedOperatorIds);
      const helpers = this.buildAssignableDriversByIds(DriverType.Helper, this.selectedHelperIds);
      const assignableDrivers: IOrderAssignableDriver[] = [];
      switch (driverType) {
        case 'Driver': {
          if (values.length === 0) {
            break;
          }
          assignableDrivers.push(...values.map((value) => buildAssignableDriversById(DriverType.Driver, value)));
          break;
        }
        case 'Operator': {
          // NOTE: 運転者が0人 or クリアされた場合、設定済みの補助員の値だけ入れて更新する
          // クリアされた場合は value: [undefined] となる
          if (values.length === 0 || values[0] === undefined) {
            assignableDrivers.push(...helpers);
            break;
          }
          assignableDrivers.push(...values.map((value) => buildAssignableDriversById(DriverType.Operator, value)));
          assignableDrivers.push(...helpers);
          break;
        }
        case 'Helper': {
          // NOTE: 補助員が0人の場合、設定済みの運転者の値だけ入れて更新する
          if (values.length === 0) {
            assignableDrivers.push(...operators);
            break;
          }
          assignableDrivers.push(...values.map((value) => buildAssignableDriversById(DriverType.Helper, value)));
          assignableDrivers.push(...operators);
          break;
        }
        default:
          throw new Error(`selected driver type is unexpected. driverType: ${driverType}, driverIds: ${values}`);
      }
      this.$emit(EventTypes.ChangeAssignableDrivers, this.driverNum, assignableDrivers);
    },

    onChangeDriverAssignmentDetails(item: string): void {
      // NOTE: プルダウンで押したメニューが r-v-input の #append slot に表示されるようになっているが、
      // 候補指定 (isCandidate: true) の状態になっていなくてもリセットするまで表示される。これは処分場の実装は同じ
      switch (item) {
        case 'enableMultipleAssignmentDialog':
          this.toggleDriverAssignmentMultipleEditDialog(true);
          break;
        case 'resetMultipleAssignment':
          this.$emit(EventTypes.ChangeCandidate, false);
          this.$emit(EventTypes.ChangeAssignableDrivers, this.driverNum, []);
          this.$emit(EventTypes.ChangeHelperEnabled, false);
          break;
        default:
          throw new Error(`Unsupported action: ${item}`);
      }
    },

    toggleDriverAssignmentMultipleEditDialog(value: boolean) {
      this.isDriverAssignmentMultipleDialogActive = value;
    },

    onUpdateDriverAssignmentMultiple(value: {
      driverNum: number;
      isHelperEnabled: boolean;
      assignableDrivers: IOrderAssignableDriver[];
    }) {
      this.$emit(EventTypes.ChangeHelperEnabled, value.isHelperEnabled);
      this.$emit(EventTypes.ChangeAssignableDrivers, value.driverNum, value.assignableDrivers);
      this.$emit(EventTypes.ChangeCandidate, true);
      this.toggleDriverAssignmentMultipleEditDialog(false);
    },
    notIncludesGenerationSiteBannedDrivers(values: Maybe<Id | Id[]>) {
      return (
        !values ||
        ![values].flat().some((id) => this.generationSiteBannedDriverIds.includes(id)) ||
        '排出場の担当不可乗務員が指定されています'
      );
    },
    notIncludesTaskBannedDrivers(valueOrValues: Maybe<Id | Id[]>) {
      if (!valueOrValues) {
        return true;
      }
      const values = [valueOrValues].flat();
      const found = this.taskItems.find((item) =>
        (item.taskType?.bannedDriverIds || []).some((id) => values.includes(id))
      );
      return found ? `${found.taskType?.name}の担当不可乗務員が指定されています` : true;
    },
  },
});
