import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { UserEntity, UserEntityData } from '~/framework/domain/masters/user/userEntity';
import { Maybe } from '~/framework/typeAliases';
import { ClientEntity, ClientEntityData } from '~/framework/domain/masters/client/clientEntity';
import { IPackingStyleReservationSetting } from '~/framework/server-api/masters/client';

export interface AggregatedClientEntityData extends ClientEntityData {
  createdBy: UserEntityData;
  updatedBy: UserEntityData;
}

export class AggregatedClientEntity extends ClientEntity {
  createdBy: UserEntity;
  updatedBy: UserEntity;

  constructor(
    id: string,
    code: Maybe<string>,
    name: string,
    nameRuby: string,
    note: Maybe<string>,
    createdAt: Date,
    status: SoftDeleteStatus,
    signInUrl: Maybe<string>,
    createdBy: UserEntity,
    updatedAt: Date,
    updatedBy: UserEntity,
    reservationSiteEnabled: Maybe<boolean>,
    packingStyleReservationSettings: IPackingStyleReservationSetting[]
  ) {
    super(
      id,
      code,
      name,
      nameRuby,
      note,
      createdAt,
      status,
      signInUrl,
      updatedAt,
      reservationSiteEnabled,
      packingStyleReservationSettings
    );
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
  }
}
