import { DriverType } from '~/framework/domain/schedule/schedule/pseudo-entities/driverAssignment';
import { CarUsageEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/carUsageEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { mapEntity } from '~/framework/core/mapper';
import { PersistentId } from '~/framework/typeAliases';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';

export class CarUsage extends CarUsageEntity {
  /**
   * 車
   */
  car: AggregatedCarEntity;
  /**
   * 車種
   */
  carType: CarTypeEntity;
  /**
   * 運転する人
   */
  driver: DriverEntity;
  /**
   * ただ同乗していく人 (補助員)
   */
  helpers: DriverEntity[];

  constructor(
    carId: PseudoId,
    carIndex: number,
    driverAssignments: {
      driverType: DriverType;
      driverId: PseudoId;
    }[],
    car: AggregatedCarEntity,
    carType: CarTypeEntity,
    drivers: DriverEntity[]
  ) {
    super(carId, carIndex, driverAssignments);
    this.car = car;
    this.carType = carType;
    const driverMap = mapEntity(drivers);
    this.driver = driverMap.getOrError(this.driverId.value);
    this.helpers = this.helperIds.map((helperId) => driverMap.getOrError(helperId.value));
  }

  /**
   * 運転者なのかどうかを取得する
   *
   * @param driver
   */
  isDriver(id: PersistentId): boolean {
    return this.driverId.value === id;
  }

  /**
   * 補助員なのかどうかを取得する
   *
   * @param driver
   */
  isHelper(id: PersistentId): boolean {
    return this.helperIds.some((helperId) => helperId.value === id);
  }
}
