import { StoredMapperBase } from '~/framework/core/mapper';
import {
  JwnetWasteMasterEntityData,
  JwnetWasteMasterEntity,
} from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterEntity';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';

export class JwnetWasteMasterMapper
  extends StoredMapperBase<JwnetWasteMasterEntityData, JwnetWasteMasterEntity>
  implements JwnetWasteMasterMapper
{
  protected store: JwnetWasteMasterStore;

  constructor(store: JwnetWasteMasterStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: JwnetWasteMasterEntityData): JwnetWasteMasterEntity {
    return new JwnetWasteMasterEntity(
      data.id,
      data.firstCategoryName,
      data.secondCategoryName,
      data.thirdCategoryName,
      data.code,
      data.isUsable
    );
  }

  protected updateWithData(data: JwnetWasteMasterEntityData, entity: JwnetWasteMasterEntity) {
    entity.firstCategoryName = data.firstCategoryName;
    entity.secondCategoryName = data.secondCategoryName;
    entity.thirdCategoryName = data.thirdCategoryName;
    entity.code = data.code;
    entity.isUsable = data.isUsable;
  }
}
