import _ from 'lodash';
import { defaultRouteSettings, IRouteEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/routeEntity';
import { IOriginalRouteEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalRouteEntity';

import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { integerize } from '~/framework/domain/schedule/schedule/pseudo-entities/integerization';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { ICollectionEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/collectionEntity';
import { OriginalCollection } from '~/pages/schedule/originalCollection';

import { IDisposalEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/disposalEntity';
import { IInconsistentRouteInfo as IBaseInconsistentRouteInfo } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfo';
import {
  IDriverReasonEntity,
  IInfeasibilityEntity,
} from '~/framework/domain/schedule/schedule/pseudo-entities/infeasibilityEntity';
import { IInconsistencyEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistencyEntity';
import { IAbstractFactory } from '~/framework/domain/schedule/schedule/pseudo-entities/abstractFactory';
import { IllegalArgumentException, IllegalStateException } from '~/framework/core/exception';
import { RouteComparison } from '~/framework/domain/schedule/schedule/pseudo-entities/routeComparison';
import { IScheduleDiff, ScheduleDiffBuilder } from '~/framework/domain/schedule/schedule/pseudo-entities/scheduleDiff';
import { AffectedRange, IAffectedRange } from '~/framework/domain/schedule/schedule/pseudo-entities/affectedRange';
import { AggregatedOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { Collection as ScheduleCollection } from '~/pages/schedule/collection';
import { EntityIdGenerateFunctor } from '~/framework/systemContext';
import { IdGenerator } from '~/framework/core/id';

/**
 * こいつは UoW の管理下になく異質な存在なのだが、集約しているエンティティと言えばそうなのかもしれないのでここに置く
 * 直接的にデータの更新には関わらず、ここから IScheduleJsonObject をシリアライズする
 */
export interface IScheduleDataEntity<
  Route extends IRouteEntity<Original, Collection, Disposal, InconsistentRouteInfo, Route>,
  Collection extends ICollectionEntity<any, Collection>,
  Disposal extends IDisposalEntity<any, Disposal>,
  InconsistentRouteInfo extends IBaseInconsistentRouteInfo<InconsistentRouteInfo>,
  Original extends IOriginalRouteEntity<any, any, any>,
  Inconsistency extends IInconsistencyEntity,
  Infeasibility extends IInfeasibilityEntity<IDriverReasonEntity>
> {
  /**
   * 識別子
   * ビューの更新にも利用される
   */
  id: string;

  /**
   * 編集後のルート
   */
  routes: Route[];

  /**
   * 編集前のルート
   */
  originalRoutes: Original[];

  /**
   * 固定のエラー
   */
  inconsistencies: Maybe<Inconsistency[]>;

  /**
   * マスター等のエラー
   */
  infeasibilities: Maybe<Infeasibility[]>;

  // 配車表に組み込まれた受注のId一覧
  reassignedStep3InfeasibilityOrderIds: string[];

  /**
   * ルートを取得する
   *
   * @param driverId
   * @param routeIndex
   */
  getRoute(driverId: string, routeIndex: number): Route;

  /**
   * ルートを取得する
   *
   * @param driverId
   */
  getRoutes(driverId: string): Route[];

  /**
   * 乗務員内のルートの順番を入れ替える
   *
   * @param oldIndex routes に対して一意、0-origin の index である事に注意
   * @param newIndex routes に対して一意、0-origin の index である事に注意
   */
  swapRoute(oldIndex: number, newIndex: number): void;

  /**
   * 乗務員の特定のルート内の収集の順番を入れ替える
   *
   * @param driverId
   * @param routeIndex
   * @param oldIndex collections に対して一意、0-origin の index である事に注意
   * @param newIndex collections に対して一意、0-origin の index である事に注意
   */
  swapCollection(driverId: string, routeIndex: number, oldIndex: number, newIndex: number): void;

  /**
   * 指定した場所に回収を移動できるかどうか
   *
   * @param fromDriverId 移動元の乗務員ID
   * @param fromRouteIndex 移動元のルートINDEX
   * @param fromCollectionIndex 移動元の回収INDEX
   * @param toDriverId 移動先の乗務員ID
   * @param toRouteIndex 移動先のルートINDEX
   * @param toCollectionIndex 移動先の回収INDEX
   * @param intoPresentRoute ルートの中に移動するかどうか
   * @param infeasibilityOrder
   *
   * @returns 検証結果 (セットの中身が空であれば検証成功を意味する)
   */
  canReassignCollection(
    fromDriverId: Maybe<string>,
    fromRouteIndex: Maybe<number>,
    fromCollectionIndex: Maybe<number>,
    toDriverId: PersistentId,
    toRouteIndex: number,
    toCollectionIndex: number,
    intoPresentRoute: boolean,
    infeasibilityOrder: Maybe<AggregatedOrderEntity>
  ): Set<ReassignCollectionErrorReason>;

  /**
   * 誰かの回収を別の誰かに割り当てる
   * 同じ人の別ルートになる事も許容される
   */
  reassignCollection(
    fromDriverId: Maybe<PersistentId>,
    fromRouteIndex: Maybe<number>,
    fromCollectionIndex: Maybe<number>,
    toDriverId: PersistentId,
    toRouteIndex: number,
    toCollectionIndex: number,
    intoPresentRoute: boolean,
    infeasibilityOrder: Maybe<AggregatedOrderEntity>,
    scrollToRoute: Maybe<Function>
  ): void;

  /**
   * ある乗務員を与えてその乗務員に関する変更、あるいはその乗務員の影響に関係する全乗務員の ID を返す
   * この乗務員の ID を元に revertChangesOf でオリジナルに戻す事ができる
   * @param driverId
   */
  getAffectedRangeFrom(driverId: string): IAffectedRange;

  /**
   * 特定乗務員の特定ルートの特定収集の開始時間の編集を取り消す
   *
   * @param driverId
   * @param routeIndex
   */
  revertStartTime(driverId: string, routeIndex: number): void;

  /**
   * 特定乗務員の特定ルートの特定収集の終了時間の編集を取り消す
   *
   * @param driverId
   * @param routeIndex
   */
  revertEndTime(driverId: string, routeIndex: number): void;

  /**
   * ルートの開始時刻をいじる
   * もしその前にルートがあれば、その終了時刻も一緒にいじられる
   *
   * @param driverId
   * @param routeIndex
   */
  setStartTimeOfRoute(driverId: string, routeIndex: number, startTime: number): void;

  /**
   * ルートの終了時刻をいじる
   * もしその後にルートがあれば、その開始時刻も一緒にいじられる
   *
   * @param driverId
   * @param routeIndex
   * @param endTime
   */
  setEndTimeOfRoute(driverId: string, routeIndex: number, endTime: number): void;

  /**
   * ルート全体をロック（もしくは解除）する
   *
   * @param driverId
   * @param index
   * @param value
   */
  setIsFixedAssignmentOfRoute(driverId: string, index: number, value: boolean): void;

  /**
   * 回収の順番を固定する
   *
   * @param driverId
   * @param routeIndex
   * @param collectionIndex
   * @param value
   */
  setIsFixedAssignmentOfCollection(driverId: string, routeIndex: number, collectionIndex: number, value: boolean): void;

  /**
   * 特定ドライバーのルートを全て unfix する
   *
   * @param driverId
   */
  unfixAllAssignmentsOf(driverId: string): void;

  /**
   * 保持しているオリジナルの状態からの差分の情報を取得する
   */
  getDiffFromOriginalRoutes(): IScheduleDiff;

  /**
   * 乗務員が担当する全てのルートの状態IDを更新する
   *
   * @param driverId 乗務員ID
   */
  updateAllRouteStateIdByDriverId(driverId: string): void;

  /**
   * ルートの状態IDを更新する
   *
   * @param driverId 乗務員ID
   * @param routeIndex ルートINDEX
   */
  updateRouteStateId(driverId: string, routeIndex: number): void;
}

/**
 * View 層のモデルでも対応可能な様にジェネリクスにしておく。
 *
 * R = IRouteEntity
 * O = IOriginalRouteEntity
 */
export class ScheduleDataEntity<
  Route extends IRouteEntity<Original, Collection, Disposal, InconsistentRouteInfo, Route>,
  Collection extends ICollectionEntity<any, Collection>,
  Disposal extends IDisposalEntity<any, Disposal>,
  InconsistentRouteInfo extends IBaseInconsistentRouteInfo<InconsistentRouteInfo>,
  Original extends IOriginalRouteEntity<any, any, any>,
  Inconsistency extends IInconsistencyEntity,
  Infeasibility extends IInfeasibilityEntity<IDriverReasonEntity>
> implements
    IScheduleDataEntity<Route, Collection, Disposal, InconsistentRouteInfo, Original, Inconsistency, Infeasibility>
{
  private readonly factory: IAbstractFactory<Route, Collection>;
  private readonly idGenerator: EntityIdGenerateFunctor = IdGenerator.generateNewId;

  id: string;
  routes: Route[];
  originalRoutes: Original[];
  inconsistencies: Maybe<Inconsistency[]>;
  infeasibilities: Maybe<Infeasibility[]>;
  reassignedStep3InfeasibilityOrderIds: string[] = [];

  constructor(
    id: string,
    factory: IAbstractFactory<Route, Collection>,
    routes: Route[],
    originalRoutes: Original[],
    inconsistencies: Maybe<Inconsistency[]>,
    infeasibilities: Maybe<Infeasibility[]>
  ) {
    this.id = id;
    this.factory = factory;
    this.routes = routes;
    this.originalRoutes = originalRoutes;
    // V1ロジックをそのまま併用するために、空配列の場合はundefinedにする
    this.inconsistencies = inconsistencies && inconsistencies.length > 0 ? inconsistencies : undefined;
    this.infeasibilities = infeasibilities;
    this.resetRouteProperties();
    this.updateRouteProperties();
    this.updateRouteLink();
    this.updateCollectionStartAndEndTime();
    this.updateIsTimetableUnstable();
    this.updateHasChangeCar();
  }

  // region public

  /**
   * 保持しているオリジナルの状態からの差分の情報を取得する
   */
  getDiffFromOriginalRoutes(): IScheduleDiff {
    const builder = new ScheduleDiffBuilder();
    return builder.buildDiffFrom(this.originalRoutes, this.routes);
  }

  getRoute(driverId: string, routeIndex: number): Route {
    const route = _.first(
      this.routes.filter((route) => route.driverId.value === driverId && route.index === routeIndex)
    );
    if (!route) {
      throw new Error(`Route was not found! driverId: ${driverId}, routeIndex: ${routeIndex}`);
    }
    return route;
  }

  getRoutes(driverId: string): Route[] {
    return this.routes.filter((entity) => entity.driverId.value === driverId);
  }

  getAffectedRangeFrom(driverId: string): IAffectedRange {
    const driverIdSet: Set<string> = new Set<string>();
    const originalCollectionAssignmentMap: Map<string, string> = new Map(); // [key: collection-id]: driver-id
    const presentCollectionAssignmentMap: Map<string, string> = new Map(); // [key: collection-id]: driver-id
    for (const route of this.originalRoutes) {
      for (const collection of route.collections) {
        originalCollectionAssignmentMap.set(collection.id, route.driverId.value);
      }
    }
    for (const route of this.routes) {
      for (const collection of route.collections) {
        presentCollectionAssignmentMap.set(collection.id, route.driverId.value);
      }
    }
    this.addAffectedRangeFrom(driverId, driverIdSet, originalCollectionAssignmentMap, presentCollectionAssignmentMap);
    return new AffectedRange(driverIdSet.toArray());
  }

  revertStartTime(driverId: string, routeIndex: number): void {
    const route = this.getRoute(driverId, routeIndex);
    route.fixedStartTime = undefined;
    if (route.previousRoute !== undefined && route.previousRoute.driverId.equals(route.driverId)) {
      route.previousRoute.fixedEndTime = undefined;
    }
    this.updateTimetableAndProperties(driverId);
  }

  revertEndTime(driverId: string, routeIndex: number): void {
    const route = this.getRoute(driverId, routeIndex);
    route.fixedEndTime = undefined;
    if (route.nextRoute !== undefined && route.nextRoute.driverId.equals(route.driverId)) {
      route.nextRoute.fixedStartTime = undefined;
    }
    this.updateTimetableAndProperties(driverId);
  }

  swapCollection(driverId: string, routeIndex: number, oldIndex: number, newIndex: number): void {
    const route = this.getRoute(driverId, routeIndex);
    if (oldIndex < 0 || route.collections.length <= oldIndex) {
      throw new Error(`oldIndex should be 0 <= oldIndex < ${route.collections.length}, but ${oldIndex}`);
    }
    if (newIndex < 0 || route.collections.length <= newIndex) {
      throw new Error(`newIndex should be 0 <= newIndex < ${route.collections.length}, but ${newIndex}`);
    }

    const oldCollection = route.collections[oldIndex];
    route.collections.splice(oldIndex, 1);
    route.collections.splice(newIndex, 0, oldCollection);

    route.updateCollectionIndexes();

    // 収集順を入れ替えたので時間の再計算が必要
    this.updateTimetableAndProperties(driverId);
  }

  swapRoute(oldIndex: number, newIndex: number): void {
    if (oldIndex < 0 || this.routes.length <= oldIndex) {
      throw new Error(`oldIndex should be 0 <= oldIndex < ${this.routes.length}, but ${oldIndex}`);
    }
    if (newIndex < 0 || this.routes.length <= newIndex) {
      throw new Error(`newIndex should be 0 <= newIndex < ${this.routes.length}, but ${newIndex}`);
    }

    // route の index は乗務員内で一意のため、その中での位置調整を行う必要がある
    // 乗務員を飛び越えたルートの移動は view 層で禁止するため、ここでは最終チェックのみ行う
    const newRoute = this.routes[newIndex];
    const oldRoute = this.routes[oldIndex];
    const driverId = oldRoute.driverId;
    if (oldRoute.driverId.equals(newRoute.driverId) === false) {
      throw new Error(`Cannot swap route of driver ${oldRoute.driverId.toString()} to ${newRoute.driverId.toString()}`);
    }

    this.routes.splice(oldIndex, 1);
    this.routes.splice(newIndex, 0, oldRoute);

    this.updateRouteIndexes();

    // 入れ替えた上で乗務員を飛び越えた入れ替えを行っていないかチェックする
    this.validateDriverOrder();

    // ルート順を入れ替えたのでリンクを再構築する
    this.updateRouteLink();

    // ルートを入れ替えたので時間の再計算が必要
    this.updateTimetableAndProperties(driverId.value);

    this.updateHasChangeCar();
  }

  canReassignCollection(
    fromDriverId: Maybe<string>,
    fromRouteIndex: Maybe<number>,
    fromCollectionIndex: Maybe<number>,
    toDriverId: string,
    toRouteIndex: number,
    toCollectionIndex: number,
    intoPresentRoute: boolean
  ): Set<ReassignCollectionErrorReason> {
    const errors = new Set<ReassignCollectionErrorReason>();
    const routes = this.getRoutes(toDriverId);

    // fromDriverId,fromRouteIndex,fromCollectionIndexが指定されていない場合は考慮しない
    if (fromDriverId !== undefined && fromRouteIndex !== undefined && fromCollectionIndex !== undefined) {
      const fromRoute = this.getRoute(fromDriverId, fromRouteIndex);
      const fromCollection = fromRoute.getCollection(fromCollectionIndex);

      if (fromCollection.isFixedAssignment) {
        errors.add(ReassignCollectionErrorReason.OriginalCollectionFixed);
      }
    }

    const toRoute = routes.find((route) => route.index === toRouteIndex);
    const toCollection = toRoute?.collections.find((collection) => collection.index === toCollectionIndex);

    if (!intoPresentRoute && toRoute?.isFixedAssignmentInAnyOfCollections) {
      errors.add(ReassignCollectionErrorReason.ReassigningBeforeFixedRoute);
    }

    if (intoPresentRoute && toCollection?.isFixedAssignment) {
      errors.add(ReassignCollectionErrorReason.ReassigningBeforeFixedCollection);
    }

    return errors;
  }

  reassignCollection(
    fromDriverId: Maybe<PersistentId>,
    fromRouteIndex: Maybe<number>,
    fromCollectionIndex: Maybe<number>,
    toDriverId: PersistentId,
    toRouteIndex: number,
    toCollectionIndex: number,
    intoPresentRoute: boolean,
    infeasibilityOrder: Maybe<AggregatedOrderEntity>,
    scrollToRoute: Maybe<Function>
  ) {
    const errors = this.canReassignCollection(
      fromDriverId,
      fromRouteIndex,
      fromCollectionIndex,
      toDriverId,
      toRouteIndex,
      toCollectionIndex,
      intoPresentRoute
    );
    if (errors.size !== 0) {
      throw new Error(
        'Invalid reassignment. ' +
          `fromDriverId: ${fromDriverId} ` +
          `fromRouteIndex: ${fromRouteIndex} ` +
          `fromCollectionIndex: ${fromCollectionIndex} ` +
          `toDriverId: ${toDriverId} ` +
          `toRouteIndex: ${toRouteIndex} ` +
          `toCollectionIndex: ${toCollectionIndex} ` +
          `intoPresentRoute: ${intoPresentRoute} ` +
          `infeasibilityOrder: ${infeasibilityOrder} `
      );
    }

    let assignmentCollection: Maybe<Collection>;
    // infeasibilityOrderがある場合はここでInfeasibilityOrderをベースにCollectionを作成する
    if (infeasibilityOrder !== undefined) {
      assignmentCollection = this.createCollectionFromOrderEntity(infeasibilityOrder);
    }

    // 作成不可受注でない場合はhasCollectionがtrueになっている前提の処理順
    if (
      infeasibilityOrder === undefined &&
      fromCollectionIndex !== undefined &&
      fromRouteIndex !== undefined &&
      fromDriverId !== undefined
    ) {
      // 同じ乗務員の同じ便内で移動すると index がズレる
      if (fromDriverId === toDriverId && fromRouteIndex === toRouteIndex && fromCollectionIndex < toCollectionIndex) {
        toCollectionIndex--;
      }
      if (
        fromDriverId === toDriverId &&
        fromRouteIndex === toRouteIndex &&
        fromCollectionIndex === toCollectionIndex &&
        intoPresentRoute
      ) {
        return;
      }
      const fromDriverRoutes = this.getRoutes(fromDriverId);
      // ここで移動元のcollectionを消して、そのcollectionを保持している
      assignmentCollection = this.removeCollection(fromDriverId, fromRouteIndex, fromCollectionIndex);
      const toDriverRoutes = this.getRoutes(toDriverId);

      // 同じ乗務員で移動を行うと元のルートが消える可能性がある
      if (
        fromDriverId === toDriverId &&
        fromDriverRoutes.length !== toDriverRoutes.length &&
        fromRouteIndex < toRouteIndex
      ) {
        toRouteIndex = Math.max(0, toRouteIndex - 1);
      }
      if (intoPresentRoute === false) {
        if (toDriverRoutes.length < toRouteIndex) {
          throw new IllegalArgumentException(
            `Cannot assign to ${toRouteIndex}, current route length is: ${toDriverRoutes.length}`
          );
        }
      }
    }

    if (assignmentCollection === undefined) {
      throw new Error('assignmentCollection is undefined');
    }

    // アサイン先のルートが既にあるならそれを利用するし、ないなら作成する
    if (intoPresentRoute) {
      const toRoute = this.getRoute(toDriverId, toRouteIndex);
      // ここで消したcollectionを追加している
      toRoute.addCollection(toCollectionIndex, assignmentCollection);
      toRoute.updateCollectionIndexes();
    } else {
      const toRoute = this.factory.createRoute(toDriverId, [assignmentCollection]);
      toRoute.updateCollectionIndexes();
      // ここで消したcollectionを追加している
      this.addRoute(toDriverId, toRouteIndex, toRoute);
    }

    this.updateRouteIndexes();
    this.updateRouteLink();

    // 移動先のルートではそのルートに至るまで全てを固定する必要がある。理由は、
    // 1. もともと最適ではない事をやろうとしているので固定しないと元に戻ってしまうから
    // 2. 今は上から順にしか固定できないという仕様になっているから
    for (let routeIndex = 0; routeIndex <= toRouteIndex; routeIndex++) {
      const route = this.getRoute(toDriverId, routeIndex);
      Array.from(route.collections.keys()).forEach((collectionIndex) => {
        const isDestinationRoute = routeIndex === toRouteIndex;
        const isOnOrBeforeDestinationCollection = collectionIndex <= toCollectionIndex;
        if (!isDestinationRoute || (isDestinationRoute && isOnOrBeforeDestinationCollection)) {
          this.setIsFixedAssignmentOfCollection(toDriverId, routeIndex, collectionIndex, true);
        }
      });
    }

    if (fromDriverId !== undefined) {
      this.updateTimetableAndProperties(fromDriverId);
    }

    this.updateTimetableAndProperties(toDriverId);

    this.updateHasChangeCar();

    // 移動先のルートにスクロールする
    if (scrollToRoute !== undefined && toDriverId !== undefined && toRouteIndex !== undefined) {
      const route = this.getRoute(toDriverId, toRouteIndex);
      scrollToRoute(route.id);
    }
  }

  // IAggregatedOrderEntityからCollectionを作成する
  createCollectionFromOrderEntity(order: AggregatedOrderEntity): Collection {
    const pseudoId = new PseudoId('order', order.id);
    // OriginalCollectionを作成する
    const originalCollection: OriginalCollection = new OriginalCollection(
      this.idGenerator(),
      0,
      pseudoId,
      // 巡りにめぐってこれが作成不可自由な移動での新規ルート作成時の時間計算の起点となっている
      36000,
      39600,
      false,
      false,
      order,
      []
    );

    // Collectionを作成する
    // extendsされているCollectionと生成に用いるCollectionの命名が重複している為、Collection→ScheduleCollectionと改名している
    // 既存のassignmentCollection側まで修正を入れたくないので、渋々こうしている
    // 配車表リファクタで消えるはず
    const collection: any = new ScheduleCollection(
      originalCollection,
      originalCollection.id,
      originalCollection.index,
      originalCollection.orderId,
      originalCollection.generationSiteArrivalTime,
      originalCollection.generationSiteDepartureTime,
      originalCollection.hasRestBeforeGenerationSiteArrival,
      originalCollection.isFixedAssignment,
      order,
      []
    );

    return collection;
  }

  setStartTimeOfRoute(driverId: string, routeIndex: number, startTime: number) {
    const route = this.getRoute(driverId, routeIndex);
    if (
      route.previousRoute !== undefined &&
      route.previousRoute.driverId.equals(route.driverId) &&
      startTime <= route.previousRoute.startTime
    ) {
      throw new Error(
        `startTime should not be equal or less than the start time of previous route! startTime: ${startTime}, previous route startTime: ${route.previousRoute.startTime}`
      );
    }

    route.fixScheduleByStartTime(startTime);
    if (route.previousRoute !== undefined && route.previousRoute.driverId.equals(route.driverId)) {
      // 前にルートがあった場合はそいつが入らなくなるからそれもいじらざるを得ない
      // また、そいつが固定されていなかったら強制的に固定するしかない
      route.previousRoute.fix();
      route.previousRoute.fixScheduleByEndTime(startTime);
    }
    this.updateTimetableAndProperties(driverId);
  }

  setEndTimeOfRoute(driverId: string, routeIndex: number, endTime: number) {
    const route = this.getRoute(driverId, routeIndex);

    // このルート以降の終了時間が固定されている場合、
    // このルートの終了時間が以降の終了時間を超えることはできないため、fixScheduleByEndTime せずに終了。
    // ※endTime は UI から与えられる可能性があり、そのまま return すると UI 上の値が元に戻らなくなってしまうため、
    //   stateId を更新するために updateTimetableAndProperties を呼んでいる。
    const nextRoutes = this.getRoutes(driverId).filter((route) => route.index > routeIndex);
    for (const nextRoute of nextRoutes) {
      if (nextRoute.fixedEndTime !== undefined && nextRoute.fixedEndTime < endTime) {
        this.updateTimetableAndProperties(driverId);
        return;
      }
    }

    route.fixScheduleByEndTime(endTime);

    if (route.nextRoute !== undefined && route.nextRoute.driverId.equals(route.driverId)) {
      if (route.fixedEndTime === undefined) {
        // もし endTime を調整した事で fixedEndTime が存在しない状態になったのであれば、
        // 次のルートの開始時間も fixedStartTime がない状態にしないとおかしい
        // ただしこれはルート同士が繋がっているという前提
        route.nextRoute.fixedStartTime = undefined;
      } else if (route.nextRoute.isFixedSchedule && route.nextRoute.fixedStartTime !== undefined) {
        // もし次のルートが存在していて時間固定されていたら、その開始時間も一緒にいじらないとおかしくなる。
        // 厳密に言うとフロントエンドでいじった時は次のルートが存在していて isFixedSchedule だったとしても
        // endTime しか固定していない可能性はあるのだが、一回最適化して返ってくると isFixedSchedule から
        // _fixedStartTime と _fixedEndTime を設定せざるを得ない（どちらだったのかは判別が付かない）ので
        // この処理を入れる事は妥当と判断した。
        route.nextRoute.fixScheduleByStartTime(endTime);
      }
    }

    this.updateTimetableAndProperties(driverId);
  }

  setIsFixedAssignmentOfRoute(driverId: string, index: number, value: boolean): void {
    // このメソッドはインターフェース上ロックにも対応しているし解除にも対応している
    // - ロックする場合は、そのルートに至るまでのルートを全てロックする
    // - ロック解除する場合は、そのルート以降の全てのルートをロック解除する
    const route = this.getRoute(driverId, index);
    if (value) {
      if (0 < index) this.setIsFixedAssignmentOfRoute(driverId, index - 1, value);
      route.fix();
    } else {
      const routes = this.getRoutes(driverId);
      if (index + 1 < routes.length) this.setIsFixedAssignmentOfRoute(driverId, index + 1, value);
      route.unfix();
    }
  }

  setIsFixedAssignmentOfCollection(
    driverId: string,
    routeIndex: number,
    collectionIndex: number,
    value: boolean
  ): void {
    // ここに至るまでのルートも全てロック、もしくはロック解除をする
    if (value && 0 < routeIndex) {
      this.setIsFixedAssignmentOfRoute(driverId, routeIndex - 1, true);
    } else if (!value && routeIndex + 1 < this.getRoutes(driverId).length) {
      this.setIsFixedAssignmentOfRoute(driverId, routeIndex + 1, false);
    }

    const route = this.getRoute(driverId, routeIndex);

    // 順固定の更新をする前に、時間固定があったかどうか
    const isFixedScheduleBeforeUpdateFixedAssignment = route.isFixedSchedule;

    // 指定された回収の順番固定有無をセットする
    route.setIsFixedAssignmentOfCollection(collectionIndex, value);

    // ルートの順固定解除前に、時間固定があった場合は時間を再計算する必要がある
    if (route.isFixedAssignment === false && isFixedScheduleBeforeUpdateFixedAssignment) {
      route.resetFixedStartAndEndTime();
      this.updateTimetableAndProperties(driverId);
    }
  }

  unfixAllAssignmentsOf(driverId: string): void {
    const routes = this.getRoutes(driverId);

    // 順固定の更新をする前に、いずれかのルートに時間固定があったかどうか
    const isFixedScheduleBeforeUpdateFixedAssignment = routes.some((route) => route.isFixedSchedule);

    // ルートの順固定の解除
    for (const route of routes) {
      route.unfix();
    }

    // ルートの順固定解除前に、いずれかのルートに時間固定があった場合は時間を再計算する必要がある
    if (isFixedScheduleBeforeUpdateFixedAssignment) {
      this.updateTimetableAndProperties(driverId);
    }
  }

  updateAllRouteStateIdByDriverId(driverId: string): void {
    this.getRoutes(driverId).forEach((route) => route.updateStateId());
  }

  updateRouteStateId(driverId: string, routeIndex: number): void {
    this.getRoute(driverId, routeIndex).updateStateId();
  }

  // endregion

  // region private

  /**
   * 対象の乗務員を与えて、その乗務員が現状保持しているルートとオリジナルのルートが保持していた回収が
   * 現状割り当てられている乗務員とさらに最初に割り当てられていた乗務員を影響範囲として再帰的にリストアップする。
   * @param driverId
   * @param driverIdSet
   * @param originalCollectionAssignmentMap
   * @param presentCollectionAssignmentMap
   * @private
   */
  private addAffectedRangeFrom(
    driverId: string,
    driverIdSet: Set<string>,
    originalCollectionAssignmentMap: Map<string, string>,
    presentCollectionAssignmentMap: Map<string, string>
  ): void {
    driverIdSet.add(driverId);
    const routes = [...this.getRoutes(driverId), ...this.getOriginalRoutes(driverId)];
    for (const route of routes) {
      for (const collection of route.collections) {
        const originalAssignment = originalCollectionAssignmentMap.get(collection.id);
        if (originalAssignment && driverIdSet.has(originalAssignment) === false) {
          this.addAffectedRangeFrom(
            originalAssignment,
            driverIdSet,
            originalCollectionAssignmentMap,
            presentCollectionAssignmentMap
          );
        }
        const presentAssignment = presentCollectionAssignmentMap.get(collection.id);
        if (presentAssignment && driverIdSet.has(presentAssignment) === false) {
          this.addAffectedRangeFrom(
            presentAssignment,
            driverIdSet,
            originalCollectionAssignmentMap,
            presentCollectionAssignmentMap
          );
        }
      }
    }
  }

  /**
   * 乗務員のルートを指定されたものに置き換える
   * @param replaceRoutesMap
   * @private
   */
  private replaceDriverRoutes(replaceRoutesMap: Map<string, Route[]>): void {
    // なんとなく破壊的に扱うのが気持ち悪いのでコピーしている
    replaceRoutesMap = new Map(replaceRoutesMap);

    // 現状のルートを走査してまだ候補がなければ現状のものをそのまま入れる
    for (const route of this.routes) {
      const driverId = route.driverId.value;
      if (replaceRoutesMap.has(driverId)) continue;
      const driverRoutes = this.getRoutes(driverId);
      replaceRoutesMap.set(driverId, driverRoutes);
    }

    // 乗務員の ID を順番を可能な限り維持したまま取得する
    // replaceRoutesMap には新規の乗務員も入ってくる可能性があるが、これはどこに入れるのか
    // 不定なのでひとまず元あったルートの後ろに追加している
    const driverIdSet: Map<string, [driverId: string, index: number]> = new Map();
    const originalDriverIds = this.originalRoutes.map((route) => route.driverId.value);
    const replaceMapDriverIds = Array.from(replaceRoutesMap.keys()).sort(
      (a, b) => Number.parseInt(a) - Number.parseInt(b)
    );
    let index = 0;
    for (const driverId of originalDriverIds) {
      if (driverIdSet.has(driverId) === false) driverIdSet.set(driverId, [driverId, index++]);
    }
    for (const driverId of replaceMapDriverIds) {
      if (driverIdSet.has(driverId) === false) driverIdSet.set(driverId, [driverId, index++]);
    }
    const driverIds = Array.from(driverIdSet.values())
      .sort((a, b) => a[1] - b[1])
      .map((value) => value[0]);

    // driverIds にはリストアップされているが replaceRoutesMap には含まれていないルートも存在する
    // （回収がなくなってルート自体が消えた場合）ので、その場合はスキップして何も追加しない
    const replaceRoutes = [];
    for (const driverId of driverIds) {
      const routes = replaceRoutesMap.get(driverId);
      if (routes !== undefined && 0 < routes.length) replaceRoutes.push(...routes);
    }
    this.routes.splice(0, this.routes.length, ...replaceRoutes);

    this.updateRouteLink();
    for (const driverId of driverIds) {
      this.updateTimetableAndProperties(driverId);
    }
  }

  private removeCollection(driverId: PersistentId, routeIndex: number, collectionIndex: number): Collection {
    const route = this.getRoute(driverId, routeIndex);
    if (route.isFixedAssignment) {
      throw new IllegalArgumentException(`driver: ${driverId}, route: ${routeIndex} has already been fixed!`);
    }
    const collection: Collection = route.removeCollection(collectionIndex);

    if (route.collections.length === 0) {
      // 回収を削除した事によってルート内の回収が全くなくなってしまった場合はルート自体を不要とみなして削除する
      this.removeRoute(driverId, routeIndex);
      this.updateRouteIndexes();
    }
    return collection;
  }

  /**
   * @param driverId
   * @param routeIndex ドライバー内での index、routes 全体から見た index ではない
   * @private
   */
  private removeRoute(driverId: PersistentId, routeIndex: number): Route {
    let globalIndex: Maybe<number>;
    for (const [index, route] of this.routes.entries()) {
      if (route.driverId.value === driverId && route.index === routeIndex) globalIndex = index;
    }
    if (globalIndex === undefined) {
      throw new IllegalStateException(`Could not find route of driver: ${driverId}, index: ${routeIndex}`);
    }
    const [route] = this.routes.splice(globalIndex, 1);
    return route;
  }

  private addRoute(driverId: PersistentId, routeIndex: number, route: Route): void {
    const routes = this.getRoutes(driverId);
    routes.splice(routeIndex, 0, route);
    const replaceRouteMap: Map<string, Route[]> = new Map();
    replaceRouteMap.set(driverId, routes);
    this.replaceDriverRoutes(replaceRouteMap);
  }

  /**
   * ルートの index を現状に合わせて振り替える
   * @private
   */
  private updateRouteIndexes(): void {
    // 小細工が面倒なので乗務員内でガッと置き換え
    let lastDriverId: Maybe<PseudoId>;
    let index = 0;
    for (const route of this.routes) {
      if (lastDriverId && lastDriverId.equals(route.driverId)) {
        route.index = ++index;
      } else {
        index = 0;
        route.index = index;
      }
      lastDriverId = route.driverId;
    }
  }

  private updateTimetableAndProperties(driverId: string): void {
    // 時間枠全体を調整する
    this.updateTimetable(driverId);

    // 前回の車庫到着時間を再設定する必要がある
    this.resetRouteProperties();
    this.updateRouteProperties();

    // 収集の開始・終了時間は前回の車庫到着時間・処分場出発時間が分からないと
    // 更新できないのでここで更新しておく
    this.updateCollectionStartAndEndTime();

    // 時間が不定になっている可能性があるので更新しておく
    this.updateIsTimetableUnstableOfDriver(driverId);
  }

  /**
   * 編集状況に応じて時間を更新する
   * - 最初のルートが固定されていなければ元の配車表の開始時間を起点とする
   *
   * @param driverId 更新したいドライバーの ID
   */
  private updateTimetable(driverId: string): void {
    const routes = this.getRoutes(driverId);
    if (routes.length === 0) return;
    let time = this.getOriginalStartOfTheDay(driverId);
    for (const route of routes) {
      if (route.isFixedSchedule) {
        let startTime: Maybe<number>;
        if (route.fixedEndTime !== undefined) {
          const previousRoute = route.previousRoute;
          if (route.fixedEndTime < time) {
            // 「固定された終了時間よりも、想定される開始時間のほうが遅くなるとき」のパターン
            // time には、最初のルートの場合、getOriginalStartOfTheDay の返り値が、
            // 最初のルート以外の場合、前のルートの終了時間が設定されている。

            if (route.index === 0) {
              // 終了時間が時間固定されているときに、開始時間の時間固定に対して「戻す」を実施したとき、
              // 本来の開始時間(time) に戻れないことが発生するため、 route.fixedEndTime < time を比較し、
              // 元に戻れない場合、現在の startTime が設定されるようにしている。
              // collectionDuration から、開始時間を調整して前に伸ばすことは可能だが複雑になるので、いったんは元の開始時間を設定している。
              startTime = route.startTime;
            } else {
              // updateTimetable は最初のルートから順番に終了時間を決定し、その終了時間を元に次の開始時間を決めている。
              // 終了時間が時間固定されているときに、そのルートより前の終了時間が固定されたとき、
              // 上記の仕様により、前のルートの終了時間が、後ろの終了時間を超えることが発生する。
              // そのため、以下の処理では後ろのルートの終了時間を超えた場合、前のルートを遡り、終了時間を超えないように調整している。
              const previousRoutes = this.getRoutes(driverId).filter(
                (previousRoute) => previousRoute.index < route.index && route.endTime < previousRoute.endTime
              );
              // それぞれのルートの時間の長さから、各ルートの割合を算出するため、totalRouteDuration を求めている。
              let totalRouteDuration = route.routeDuration;
              for (const previousRoute of previousRoutes) {
                totalRouteDuration += previousRoute.routeDuration;
              }
              let previousRouteStartTime: Maybe<number>;
              for (const previousRoute of previousRoutes) {
                previousRouteStartTime = startTime === undefined ? previousRoute.startTime : startTime;
                startTime = integerize(
                  previousRouteStartTime +
                    (route.endTime - previousRouteStartTime) * (previousRoute.routeDuration / totalRouteDuration)
                );
                previousRoute.updateDurations(previousRouteStartTime, startTime);
                totalRouteDuration -= previousRoute.routeDuration;
              }
            }
          } else if (previousRoute !== undefined && previousRoute.driverId.equals(route.driverId)) {
            if (previousRoute.fixedEndTime !== undefined) {
              // 「終了時間が固定されている時に、前のルートの終了時間を時間固定するとき」のパターン
              // このルートの開始時間は前のルートの固定時間と同じになるため、前のルートの終了時間(time)を設定する。
              startTime = time;
            } else if (route.startTime < previousRoute.endTime) {
              // 「前のルートの終了時間が次のルートの開始時間を超えてしまったとき」のパターン
              // updateDurations の計算により、前のルートの終了時間が次のルートの開始時間を超えてしまう場合がある。
              // この場合、次のルートの開始時間は前のルートの終了時間になるため、前のルートの終了時間(time)を設定する。
              startTime = time;
            }
          } else if (route.index === 0) {
            // 「固定された終了時間よりも、想定される開始時間のほうが遅くなるとき」のパターンで開始時間が設定されていた場合、
            // 1番目のルートの時の開始時間がずれていることがあるので、元の開始時間(getOriginalStartOfTheDayの返り値)を設定できる場合は設定しておく。
            startTime = time;
          }
        }
        route.updateDurations(startTime, undefined);
        time = route.endTime;
      } else {
        // 終了時間含めて中身を調整する
        route.updateDurations(time, undefined);
        time += route.routeDuration;
      }
    }
  }

  /**
   * 全部の乗務員の unstable を更新する
   * @private
   */
  private updateIsTimetableUnstable(): void {
    const driverIdSet: Set<PersistentId> = new Set<PersistentId>();
    for (const route of this.routes) {
      driverIdSet.add(route.driverId.value);
    }
    for (const driverId of driverIdSet.toArray()) {
      this.updateIsTimetableUnstableOfDriver(driverId);
    }
  }

  private updateIsTimetableUnstableOfDriver(driverId: string): void {
    const originalRoutes = this.getOriginalRoutes(driverId);
    const routes = this.getRoutes(driverId);
    const routeComparison = new RouteComparison(originalRoutes, routes);

    let isTimetableUnstable = false;
    for (const route of routes) {
      // もし該当ルートが入れ替えられていたら問答無用でそこから不定
      const diff = routeComparison.diffs.get(route.id);
      if (diff && diff.hasIndexDiff) {
        isTimetableUnstable = true;
      } else if (route.isFixedSchedule) {
        // 基本的にはスケジュールを固定していたら不定なのだが、
        // その後の時間の不定化が免除される条件に当てはまれば不定にはしない
        isTimetableUnstable = !routeComparison.getIsMatchExemptionCondition(route.id);
      }
      route.updateIsTimetableUnstable(isTimetableUnstable);

      // ルート内で順序の変更を行っていてもそれ以降は時間が不定になる
      if (route.isCollectionEdited) {
        isTimetableUnstable = true;
      }
    }
  }

  /**
   * もともと設定されていたその乗務員の最初のルートの開始時間
   * 見つけられなかった場合には新規に作成したという事なので、
   * その乗務員に指定されている回収の元々の開始時間 - デフォルトで設定されている diff
   * という事にする
   */
  private getOriginalStartOfTheDay(driverId: string): number {
    const originalDriverRoute = _.first(this.getOriginalRoutes(driverId))!;
    if (originalDriverRoute) return originalDriverRoute.startTime;
    const [route] = this.getRoutes(driverId);
    if (route) {
      const generationSiteArrivalTime = route.collections[0].original.generationSiteArrivalTime;
      const generationSiteArrivalTimeDiff = defaultRouteSettings.generationSiteArrivalTimeDiff;
      return generationSiteArrivalTime - generationSiteArrivalTimeDiff;
    }
    throw new IllegalStateException(`no route candidate found, impossible`);
  }

  private resetRouteProperties(): void {
    for (const route of this.routes) {
      route.resetProperties();
    }
  }

  /**
   * 最後の車庫到着時間、最後の処分場出発時間を更新する
   */
  private updateRouteProperties(): void {
    let lastRoute: Maybe<Route>;
    for (const route of this.routes) {
      if (lastRoute !== undefined && lastRoute.driverId.equals(route.driverId)) {
        if (lastRoute.garageSiteArrivalTime === undefined && lastRoute.disposalSiteDepartureTime === undefined) {
          // 設置のタスクのみの場合などは処分場に寄らず車庫にも到着していない可能性があり、
          // この場合は最後の排出場の出発時間を設定しておく
          const lastCollectionOfLastRoute = _.last(lastRoute.collections)!;
          route.lastGenerationSiteDepartureTime = lastCollectionOfLastRoute.generationSiteDepartureTime;
        }
        route.lastGarageSiteArrivalTime = lastRoute.garageSiteArrivalTime;
        route.lastDisposalSiteDepartureTime = lastRoute.disposalSiteDepartureTime;
        if (lastRoute.hasRestAfterGenerationSiteDeparture === true) {
          route.lastRestAfterGenerationSiteDepartureEndTime = lastRoute.endTime;
        }
        if (lastRoute.hasRestAfterDisposalSiteDeparture === true) {
          route.lastRestAfterDisposalSiteDepartureEndTime = lastRoute.endTime;
        }
      }
      if (lastRoute === undefined || !lastRoute.driverId.equals(route.driverId)) {
        route.isFirstOfDriver = true;
        _.first(route.collections)!.isFirstOfDriver = true;
        if (lastRoute !== undefined) {
          _.last(lastRoute.collections)!.isLastOfDriver = true;
          lastRoute.isLastOfDriver = true;
        }
      }
      lastRoute = route;
    }
    if (lastRoute !== undefined) {
      lastRoute.isLastOfDriver = true;
      _.last(lastRoute.collections)!.isLastOfDriver = true;
    }
  }

  private updateHasChangeCar() {
    for (const [index, route] of this.routes.entries()) {
      if (index === 0) continue;
      const previousRoute = this.routes[index - 1];
      if (!previousRoute) continue;
      const isDriverSame = route.driverId.equals(previousRoute.driverId);
      // 共に車未定か車番が一致している
      const isCarIdSame =
        (route.carId === undefined && previousRoute.carId === undefined) ||
        (route.carId !== undefined && previousRoute.carId !== undefined && route.carId.equals(previousRoute.carId));
      // ドライバーが同じで車番が違う場合は乗り換え
      route.hasChangeCar = isDriverSame && !isCarIdSame;
    }
  }

  private updateCollectionStartAndEndTime(): void {
    for (const route of this.routes) {
      route.updateCollectionStartAndEndTime();
    }
  }

  /**
   * ルートの前後をつなげる
   */
  private updateRouteLink(): void {
    let lastRoute: Maybe<Route>;
    for (const route of this.routes) {
      route.previousRoute = lastRoute;
      route.nextRoute = undefined;
      if (lastRoute !== undefined) lastRoute.nextRoute = route;
      lastRoute = route;
    }
  }

  private getOriginalRoutes(driverId: string): Original[] {
    return this.originalRoutes.filter((entity) => entity.driverId.value === driverId);
  }

  /**
   * 乗務員ごとにルートがまとまっている事を担保する
   * 無駄なので運用が始まった段階では省いてもいいかもしれない
   */
  private validateDriverOrder(): void {
    const driverIdSet = new Set<string>();
    let lastDriverId: Maybe<PseudoId>;
    const routeIndexSet = new Set<number>();
    const driverRoutes: Route[] = [];
    for (const route of this.routes) {
      if (lastDriverId && !lastDriverId.equals(route.driverId)) {
        // 乗務員がまとまっている事の確認
        if (driverIdSet.has(route.driverId.toString())) {
          throw new Error('The driver structure is broken! Routes should be aligned by driver.');
        }

        // index が連番である事の確認
        const indices = driverRoutes.map((route) => route.index).sort();
        for (let index = 0; index < indices.length; index++) {
          if (indices[index] !== index) {
            throw new Error(`Route index should be serial!`);
          }
        }

        routeIndexSet.clear();
        driverRoutes.splice(0, driverRoutes.length);
      }

      // route 内で index が一意になっている事の確認
      if (routeIndexSet.has(route.index)) {
        throw new Error(`Route index: ${route.index} is already used for driver: ${route.driverId.toString()}!`);
      }

      driverRoutes.push(route);
      driverIdSet.add(route.driverId.toString());
      lastDriverId = route.driverId;
    }
  }

  // endregion
}

/**
 * 人またぎの回収移動のエラー理由
 */
export enum ReassignCollectionErrorReason {
  OriginalCollectionFixed, // 移動元の回収が固定されている
  ReassigningBeforeFixedRoute, // 既に順固定されているルートより前に移動しようとしている
  ReassigningBeforeFixedCollection, // 既に順固定されている回収より前に移動しようとしている
}
