import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';
import { IPackingStyleReservationSetting } from '~/framework/server-api/masters/client';

export interface ClientEntityData {
  id: string;
  code: Maybe<string>;
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  createdAt: Date;
  updatedAt: Date;
  status: SoftDeleteStatus;
  signInUrl: Maybe<string>;
  reservationSiteEnabled: Maybe<boolean>;
  packingStyleReservationSettings: IPackingStyleReservationSetting[];
}

export class ClientEntity {
  readonly id: string;
  readonly persistentId: string;
  code: Maybe<string>;
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  createdAt: Date;
  @ReactiveProperty(ClientEntity.prototype.updateStatus)
  status: SoftDeleteStatus;

  signInUrl: Maybe<string>;
  updatedAt: Date;
  reservationSiteEnabled: Maybe<boolean>;
  packingStyleReservationSettings: IPackingStyleReservationSetting[];
  // computed property
  isDeleted: boolean = false;

  constructor(
    id: string,
    code: Maybe<string>,
    name: string,
    nameRuby: string,
    note: Maybe<string>,
    createdAt: Date,
    status: SoftDeleteStatus,
    signInUrl: Maybe<string>,
    updatedAt: Date,
    reservationSiteEnabled: Maybe<boolean>,
    packingStyleReservationSettings: IPackingStyleReservationSetting[]
  ) {
    this.id = id;
    this.persistentId = id;
    this.code = code;
    this.name = name;
    this.nameRuby = nameRuby;
    this.note = note;
    this.createdAt = createdAt;
    this.status = status;
    this.signInUrl = signInUrl;
    this.updatedAt = updatedAt;
    this.reservationSiteEnabled = reservationSiteEnabled;
    this.packingStyleReservationSettings = packingStyleReservationSettings;
    this.updateStatus();
  }

  private updateStatus(): void {
    this.isDeleted = this.status === SoftDeleteStatus.Deleted;
  }
}
