import { StoredMapperBase } from '~/framework/core/mapper';
import {
  PackingStyleEntity,
  PackingStyleEntityData,
} from '~/framework/domain/masters/packing-style/packingStyleEntity';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';

export class PackingStyleMapper extends StoredMapperBase<PackingStyleEntityData, PackingStyleEntity> {
  protected store: PackingStyleStore;

  constructor(store: PackingStyleStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: PackingStyleEntityData): PackingStyleEntity {
    return new PackingStyleEntity(data.id, data.code, data.name, data.defaultUnitName);
  }

  protected updateWithData(data: PackingStyleEntityData, entity: PackingStyleEntity): void {
    entity.code = data.code;
    entity.name = data.name;
    entity.defaultUnitName = data.defaultUnitName;
  }
}
