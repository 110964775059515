import { IUserData } from '~/framework/server-api/masters/user';
export const disposalSite$createSymbol = Symbol('disposalSite$create');
export const disposalSite$getAllSymbol = Symbol('disposalSite$getAll');
export const disposalSite$updateSymbol = Symbol('disposalSite$update');

// FIXME: 変更するかも
export type ClientForDisposalSite = {
  id: string;
  name: string;
  nameRuby: string;
};

export type DisposalSite = {
  [disposalSite$getAllSymbol]: IGetAllApi;
  [disposalSite$createSymbol]: ICreateApi;
  [disposalSite$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: IDisposalSiteCreateData[]): Promise<IDisposalSiteCreateResultData[]>;
}

export interface IDisposalSiteCreateData {
  name: string;
  clientId: string | undefined;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  durationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  acceptanceLimit: number;
  note: string | undefined;
  isAddressComplete: boolean;
  temporalAcceptanceInterval: number | undefined;
  temporalAcceptanceLimit: number | undefined;
}

export interface IDisposalSiteCreateResultData {
  id: string;
  createdAt: Date;
  createdBy: IUserData;
}

export interface IDisposalSiteData {
  id: string;
  code: string | undefined;
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  durationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  acceptanceLimit: number;
  note: string | undefined;
  isAddressComplete: boolean;

  temporalAcceptanceInterval: number | undefined;
  temporalAcceptanceLimit: number | undefined;

  createdAt: Date;
  createdBy: IUserData;
  client: ClientForDisposalSite | undefined;
}

export interface IGetAllApi {
  getAll(): Promise<IDisposalSiteData[]>;
}

export interface IUpdateApi {
  update(data: IDisposalSiteUpdateData[]): Promise<string[]>;
}

export interface IDisposalSiteUpdateData {
  id: string;
  clientId: string | undefined;
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  disposablePeriodStart: number;
  disposablePeriodEnd: number;
  durationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  acceptanceLimit: number;
  note: string | undefined;
  isAddressComplete: boolean;
  temporalAcceptanceInterval: number | undefined;
  temporalAcceptanceLimit: number | undefined;
}
