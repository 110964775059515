import { PersistentId } from '~/framework/typeAliases';
import { UserSettingEntity } from '~/framework/domain/user-setting/userSettingEntity';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { AggregatedCarAttendanceEntity } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceEntity';
import {
  carAttendance$getByDateRangeSymbol,
  carAttendance$updateSymbol,
  IUpdateData,
} from '~/framework/server-api/masters/carAttendance';

import { AggregatedCarAttendanceMapper } from '~/framework/domain/masters/car-attendance/aggregatedCarAttendanceMapper';

export const carAttendanceSymbol = Symbol('carAttendance');

export interface IPageData {
  userSetting: UserSettingEntity;
  holidayRule: HolidayRuleEntity;
}

export class CarAttendanceApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async getCarsAttendanceByDateRange(start: Date, end: Date, carIds?: Array<PersistentId>) {
    const getByDateRangeApi = this.serverApis.get(carAttendance$getByDateRangeSymbol);
    const aggregatedCarAttendanceMapper: AggregatedCarAttendanceMapper = new AggregatedCarAttendanceMapper(
      this.store.masters.aggregatedCarAttendance,
      this.store.masters.attendance
    );

    const carAttendanceData = await getByDateRangeApi.getByDateRange(start, end, carIds);
    return aggregatedCarAttendanceMapper.map(carAttendanceData);
  }

  async updateCarAttendance(updateData: IUpdateData): Promise<AggregatedCarAttendanceEntity> {
    const updateCarAttendanceApi = this.serverApis.get(carAttendance$updateSymbol);
    const aggregatedCarAttendanceMapper: AggregatedCarAttendanceMapper = new AggregatedCarAttendanceMapper(
      this.store.masters.aggregatedCarAttendance,
      this.store.masters.attendance
    );

    const carAttendanceData = await updateCarAttendanceApi.update(updateData);
    return aggregatedCarAttendanceMapper.mapSingle(carAttendanceData);
  }
}
