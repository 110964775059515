import { IEntity, IPersistentEntity } from '~/framework/core/entity';
import { INamespace } from '~/framework/core/namespace';
import { IllegalStateException } from '~/framework/core/exception';

// エンティティからマップするためのキーを取り出すためのもの
type IdMapper<E> = (entity: E) => string;

// デフォルトのマッパーは persistentId を利用する
const defaultMapper: IdMapper<IPersistentEntity | IEntity> = (entity) => {
  if ('persistentId' in entity) return entity.persistentId;
  else if ('id' in entity) return entity.id;
  else throw new IllegalStateException(`Could not find both persistentId and id, impossible.`);
};

/**
 * エンティティの配列から persistentId -> entity の map にする
 *
 * @param entities
 * @param mapper 指定しなければ persistentId になる
 */
export const mapEntity = <E extends IPersistentEntity | IEntity>(
  entities: E[],
  mapper: IdMapper<E> = defaultMapper
): Map<string, E> => {
  const map: Map<string, E> = new Map<string, E>();
  for (const entity of entities) {
    map.set(mapper(entity), entity);
  }
  return map;
};

export const mapData = <Data, Key extends keyof Data>(data: Data[], key: Key): Map<string, Data> => {
  const map: Map<string, Data> = new Map<string, Data>();
  for (const aData of data) {
    map.set(aData[key] as unknown as string, aData);
  }
  return map;
};

export interface IEntityData {
  id: string;
}

/**
 * エンティティ作成のためのデータを与え、それを実際にエンティティもしくは何らかの ViewModel に変換するためのもの
 * ただプロパティを書き換えたいだけなら直接書き換えればよい
 */
export interface IMapper<Data, Entity> {
  /**
   * data は単数でも複数でもよい。
   * Dataが単数ならEntityも単数を返し、配列なら配列を返す
   * @param data
   */
  map(arg: Data[]): Entity[];
  mapSingle(arg: Data): Entity;
}

/**
 * store に格納しつつマップするためのベース
 * 一意性を担保したいものに利用する
 */
export abstract class StoredMapperBase<Data extends IEntityData, Entity extends IEntity> {
  protected abstract store: INamespace<Entity>;
  map(arg: Data[]): Entity[] {
    return this.mapData(arg);
  }

  mapSingle(arg: Data): Entity {
    return this.mapData([arg])[0];
  }

  private mapData(data: Data[]): Entity[] {
    const mappedData = mapData(data, 'id');
    const [cachedIds, nonCachedIds] = this.store.splitIds(Array.from(mappedData.keys()));
    const cachedEntities: Entity[] = [];
    const nonCachedEntities: Entity[] = [];
    for (const id of cachedIds) {
      const aData = mappedData.getOrError(id);
      const entity = this.store.getById(aData.id);
      if (!entity)
        throw new IllegalStateException(`the id has been determined as cached but it actually doesn't exist`);
      this.updateWithData(aData, entity);
      cachedEntities.push(entity);
    }
    for (const id of nonCachedIds) {
      const aData = mappedData.getOrError(id);
      const entity = this.instantiateWithData(aData);
      nonCachedEntities.push(entity);
    }
    this.store.add(nonCachedEntities);
    // data の id 順に Entity を並べ替える
    const entityMap: Map<string, Entity> = new Map<string, Entity>();
    for (const entity of [...cachedEntities, ...nonCachedEntities]) entityMap.set(entity.id, entity);
    return data.map((aData) => aData.id).mapValues(entityMap);
  }

  protected abstract updateWithData(data: Data, entity: Entity): void;
  protected abstract instantiateWithData(data: Data): Entity;
}

/**
 * 一意性を担保せずただエンティティを返す時に利用する
 */
export abstract class MapperBase<Data, Entity> {
  map(arg: Data[]): Entity[] {
    return this.mapData(arg);
  }

  mapSingle(arg: Data): Entity {
    return this.mapData([arg])[0];
  }

  private mapData(data: Data[]): Entity[] {
    const entities: Entity[] = [];
    for (const aData of data) {
      const entity = this.instantiateWithData(aData);
      entities.push(entity);
    }
    return entities;
  }

  protected abstract instantiateWithData(data: Data): Entity;
}
