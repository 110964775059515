import { StoredMapperBase } from '~/framework/core/mapper';
import {
  DriverAttendanceTemplateEntity,
  DriverAttendanceTemplateEntityData,
} from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';

export class DriverAttendanceTemplateMapper extends StoredMapperBase<
  DriverAttendanceTemplateEntityData,
  DriverAttendanceTemplateEntity
> {
  protected store: DriverAttendanceTemplateStore;

  constructor(store: DriverAttendanceTemplateStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: DriverAttendanceTemplateEntityData): DriverAttendanceTemplateEntity {
    return new DriverAttendanceTemplateEntity(
      data.id,
      data.name,
      data.regularWorkPeriodStart,
      data.regularWorkPeriodEnd,
      data.restPeriodStart,
      data.restPeriodEnd,
      data.overtimeWorkType,
      data.overtimeWorkableDuration,
      data.defaultForceRidePrimaryCar
    );
  }

  protected updateWithData(data: DriverAttendanceTemplateEntityData, entity: DriverAttendanceTemplateEntity): void {
    entity.name = data.name;
    entity.regularWorkPeriodStart = data.regularWorkPeriodStart;
    entity.regularWorkPeriodEnd = data.regularWorkPeriodEnd;
    entity.restPeriodStart = data.restPeriodStart;
    entity.restPeriodEnd = data.restPeriodEnd;
    entity.overtimeWorkType = data.overtimeWorkType;
    entity.overtimeWorkableDuration = data.overtimeWorkableDuration;
    entity.defaultForceRidePrimaryCar = data.defaultForceRidePrimaryCar;
  }
}
