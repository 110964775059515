import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';
import { updateAddress } from '~/framework/domain/common/address/zipCodeAddress';

export interface BaseSiteEntityData {
  id: string;
  name: string;
  nameRuby: string;
  zipCode: Maybe<string>;
  address1: string;
  address2: string;
  address3: string;
  address4: Maybe<string>;
  latitude: number;
  longitude: number;
  hasCars: boolean;
  hasContainers: boolean;
  createdAt: Date;
}

export class BaseSiteEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;

  nameRuby: string;
  @ReactiveProperty(updateAddress)
  zipCode: Maybe<string>;

  @ReactiveProperty(updateAddress)
  address1: string;

  @ReactiveProperty(updateAddress)
  address2: string;

  @ReactiveProperty(updateAddress)
  address3: string;

  @ReactiveProperty(updateAddress)
  address4: Maybe<string>;

  latitude: number;
  longitude: number;
  hasCars: boolean;
  hasContainers: boolean;
  createdAt: Date;

  // reactive property
  address: string = '';

  constructor(
    id: string,
    name: string,
    nameRuby: string,
    zipCode: Maybe<string>,
    address1: string,
    address2: string,
    address3: string,
    address4: Maybe<string>,
    latitude: number,
    longitude: number,
    hasCars: boolean,
    hasContainers: boolean,
    createdAt: Date
  ) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.nameRuby = nameRuby;
    this.zipCode = zipCode;
    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.address4 = address4;
    this.latitude = latitude;
    this.longitude = longitude;
    this.hasCars = hasCars;
    this.hasContainers = hasContainers;
    this.createdAt = createdAt;
  }
}
