import { ICreateData as ICreateOrderData } from '~/framework/server-api/schedule/order/createOrders';

export const reservation$acceptReservationSymbol = Symbol('reservation$acceptReservationSymbol');

export interface IAcceptReservationApi {
  [reservation$acceptReservationSymbol]: void;
  acceptReservation(data: IAcceptReservationData): Promise<IAcceptReservationMutationResultData>;
}

export interface IAcceptReservationData {
  id: string;
  /**
   * 予約を確定する処理に受注登録が含まれるため、受注登録と同等な情報を送る
   */
  order: ICreateOrderData;
}

export interface IAcceptReservationMutationResultData {
  id: string;
  orderId: string;
}
