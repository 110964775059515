import { AggregatedGenerationSiteEntity } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteEntity';
import { IGenerationSiteData } from '~/framework/server-api/masters/generationSite';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { client$getByIdsSymbol } from '~/framework/server-api/masters/client';
import { driver$getByIdsSymbol } from '~/framework/server-api/masters/driver';
import { disposalSite$getAllSymbol } from '~/framework/server-api/masters/disposalSite';
import { carType$getAllSymbol } from '~/framework/server-api/masters/carType';
import { car$getByIdsSymbol } from '~/framework/server-api/masters/car';
import { AggregatedGenerationSiteMapper } from '~/framework/domain/masters/generation-site/aggregatedGenerationSiteMapper';
import { mapData } from '~/framework/core/mapper';
import { collectablePeriodTemplate$getAllSymbol } from '~/framework/server-api/masters/collectablePeriodTemplate';
import { containerType$getAllSymbol } from '~/framework/server-api/masters/containerType';

export interface IGenerationSiteFactory {
  buildByData(data: IGenerationSiteData[]): Promise<AggregatedGenerationSiteEntity[]>;
}

export class GenerationSiteFactory implements IGenerationSiteFactory {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async buildByData(data: IGenerationSiteData[]): Promise<AggregatedGenerationSiteEntity[]> {
    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const car$getByIdsApi = this.serverApis.get(car$getByIdsSymbol);
    const collectablePeriodTemplate$getAll = this.serverApis.get(collectablePeriodTemplate$getAllSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const generationSiteMapper: AggregatedGenerationSiteMapper = new AggregatedGenerationSiteMapper(
      this.store.masters.aggregatedGenerationSite,
      this.store.masters.aggregatedClient,
      this.store.masters.user,
      this.store.masters.aggregatedDisposalSite,
      this.store.masters.collectablePeriodTemplate,
      this.store.masters.aggregatedCar,
      this.store.masters.aggregatedCarType,
      this.store.masters.orderGroup,
      this.store.masters.aggregatedCarTypeContainerType,
      this.store.masters.aggregatedBaseSite
    );

    const clientIdSet: Set<string> = new Set();
    const driverIdSet: Set<string> = new Set();
    const disposalSiteIdSet: Set<string> = new Set();
    const carTypeIdSet: Set<string> = new Set();
    const carIdSet: Set<string> = new Set();

    for (const aData of data) {
      clientIdSet.add(aData.clientId);
      driverIdSet.addValues(...aData.bannedDriverIds);
      carTypeIdSet.addValues(...aData.defaultAssignableCarTypeIds);
      if (aData.defaultAssignedDriverId) driverIdSet.add(aData.defaultAssignedDriverId);
      if (aData.defaultAssignedDisposalSiteId) disposalSiteIdSet.add(aData.defaultAssignedDisposalSiteId);
      if (aData.defaultAssignedCarId) carIdSet.add(aData.defaultAssignedCarId);
    }

    const [
      clientData,
      driverData,
      disposalSiteData,
      carTypeData,
      carData,
      collectablePeriodTemplateData,
      containerTypesData,
    ] = await Promise.all([
      client$getByIdsApi.getByIds(clientIdSet.toArray()),
      driver$getByIdsApi.getByIds(driverIdSet.toArray()),
      disposalSite$getAllApi.getAll(),
      carType$getAllApi.getAll(),
      car$getByIdsApi.getByIds(carIdSet.toArray()),
      collectablePeriodTemplate$getAll.getAll(),
      containerType$getAllApi.getAll(),
    ]);

    const clientDataMap = mapData(clientData, 'id');
    const driverDataMap = mapData(driverData, 'id');
    const disposalSiteDataMap = mapData(disposalSiteData, 'id');
    const carTypeDataMap = mapData(
      carTypeData.map((carType) => {
        return {
          ...carType,
          orderGroupId: carType.orderGroup.id,
        };
      }),
      'id'
    );
    const containerTypeDataMap = mapData(containerTypesData, 'id');
    const carsEntityData = carData.map((car) => {
      const carTypeEntityData = {
        ...car.carType,
        orderGroupId: car.carType.orderGroup.id,
        loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };

      return { ...car, carType: carTypeEntityData };
    });
    const carDataMap = mapData(carsEntityData, 'id');

    const collectablePeriodTemplateMap = mapData(collectablePeriodTemplateData, 'id');

    const entities = generationSiteMapper.map(
      data.map((aData) => {
        return {
          ...aData,
          client: clientDataMap.getOrError(aData.clientId),
          bannedDrivers: aData.bannedDriverIds.mapValues(driverDataMap),
          defaultAssignedDriver: aData.defaultAssignedDriverId?.map(driverDataMap),
          defaultAssignedDisposalSite: aData.defaultAssignedDisposalSiteId?.map(disposalSiteDataMap),
          defaultAssignableCarTypes: aData.defaultAssignableCarTypeIds.mapValues(carTypeDataMap),
          defaultAssignedCar: aData.defaultAssignedCarId?.map(carDataMap),
          defaultCollectablePeriodTemplate: aData.defaultCollectablePeriodTemplateId?.map(collectablePeriodTemplateMap),
        };
      })
    );

    return entities;
  }
}
