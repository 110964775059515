import { MarginType, PreloadStatus, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { WithoutId, Maybe } from '~/framework/typeAliases';

import { IUserData } from '~/framework/server-api/masters/user';

import { GenerationSitesByKeywordsOrder } from '~/framework/server-api/typeAliases';

import { IConnection } from '~/framework/server-api/connection';
import { UploadedFile } from '~/graphql/graphQLServerApi';

export const generationSite$createSymbol = Symbol('generationSite$createApi');
export const generationSite$getAllSymbol = Symbol('generationSite$getAllSymbol');
export const generationSite$getByIdsSymbol = Symbol('generationSite$getByIdsSymbol');
export const generationSite$getByKeywordsSymbol = Symbol('generationSite$getByKeywordsSymbol');
export const generationSite$updateSymbol = Symbol('generationSite$updateApi');

export type GenerationSite = {
  [generationSite$getAllSymbol]: IGetAllApi;
  [generationSite$getByIdsSymbol]: IGetByIdsApi;
  [generationSite$getByKeywordsSymbol]: IGetByKeywordsApi;
  [generationSite$createSymbol]: ICreateApi;
  [generationSite$updateSymbol]: IUpdateApi;
};

export interface ICreateApi {
  create(data: WithoutId<ICreateData>[]): Promise<ICreateResultData[]>;
}

export interface ICreateResultData {
  id: string;
  createdAt: Date;
  // 今のところ createdBy はサポートされていないが、本当はここで返した方がよい
  // createdBy: IUserData;
}

export interface ICreateData {
  clientId: string;
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  bannedDriverIds: string[];
  defaultAssignedDriverId: string | undefined;
  defaultAssignedDisposalSiteId: string | undefined;
  defaultAssignableCarTypeIds: string[];
  defaultAssignedCarId: string | undefined;
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  note: string | undefined;
  attachmentsToAdd: File[];
  isAddressComplete: boolean;
  status: SoftDeleteStatus;
  defaultCollectablePeriodTemplateId: string | undefined;
  defaultCollectablePeriodStart: number | undefined;
  defaultCollectablePeriodEnd: number | undefined;
  defaultRouteCollectionAllowed: boolean | undefined;
  defaultPreloadStatus: PreloadStatus | undefined;
  defaultIsFixedArrivalTimeReportNeeded: boolean | undefined;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: string | undefined;
}

export interface IGenerationSiteData {
  id: string;
  code: string | undefined;
  clientId: string;
  client?: {
    id: string;
    code: string | undefined;
    name: string;
  };
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  bannedDriverIds: string[];
  defaultAssignedDriverId: string | undefined;
  defaultAssignedDisposalSiteId: string | undefined;
  defaultAssignableCarTypeIds: string[];
  defaultAssignedCarId: string | undefined;
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  note: string | undefined;
  attachments: UploadedFile[] | undefined;
  isAddressComplete: boolean;
  status: SoftDeleteStatus;
  createdAt: Date;
  createdBy: IUserData;
  defaultCollectablePeriodTemplateId: string | undefined;
  defaultCollectablePeriodStart: number | undefined;
  defaultCollectablePeriodEnd: number | undefined;
  defaultRouteCollectionAllowed: boolean | undefined;
  defaultPreloadStatus: PreloadStatus | undefined;
  defaultIsFixedArrivalTimeReportNeeded: boolean | undefined;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: string | undefined;
}

export interface IGetAllApi {
  getAll(): Promise<IGenerationSiteData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IGenerationSiteData[]>;
}

export { GenerationSitesByKeywordsOrder } from '~/framework/server-api/typeAliases';

export interface IGetByKeywordsApi {
  /**
   * キーワードで排出場を検索する
   * @param first
   * @param after
   * @param condition
   */
  getByKeywords(first: number, after: Maybe<string>, condition: ICondition): Promise<IConnection<IGenerationSiteData>>;
}

export interface ICondition {
  clientId: Maybe<string>;
  keywords: Maybe<string[]>;
  since: Maybe<Date>;
  orderBy: GenerationSitesByKeywordsOrder;
}

export interface IUpdateApi {
  update(data: IUpdateData[]): Promise<string[]>;
}

export interface IUpdateData {
  id: string;
  clientId: string;
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  bannedDriverIds: string[];
  defaultAssignedDriverId: string | undefined;
  defaultAssignedDisposalSiteId: string | undefined;
  defaultAssignableCarTypeIds: string[];
  defaultAssignedCarId: string | undefined;
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  note: string | undefined;
  attachmentsToAdd: File[];
  attachmentsToRemove: string[];
  isAddressComplete: boolean;
  status: SoftDeleteStatus;
  defaultCollectablePeriodTemplateId: string | undefined;
  defaultCollectablePeriodStart: number | undefined;
  defaultCollectablePeriodEnd: number | undefined;
  defaultRouteCollectionAllowed: boolean | undefined;
  defaultPreloadStatus: PreloadStatus | undefined;
  defaultIsFixedArrivalTimeReportNeeded: boolean | undefined;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: string | undefined;
}
