import { StoredMapperBase } from '~/framework/core/mapper';
import { AggregatedReservationStore } from '~/framework/domain/reservation/reservation/aggregatedReservationStore';
import {
  AggregatedReservationEntity,
  AggregatedReservationEntityData,
} from '~/framework/domain/reservation/reservation/aggregatedReservationEntity';
import { AggregatedClientStore } from '~/framework/domain/masters/client/aggregatedClientStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';
import { OrderGroupMapper } from '~/framework/domain/masters/order-group/orderGroupMapper';
import { GenerationSiteMapper } from '~/framework/domain/masters/generation-site/generationSiteMapper';
import { AggregatedDisposalSiteMapper } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteMapper';
import { DriverMapper } from '~/framework/domain/masters/driver/driverMapper';
import { CarTypeMapper } from '~/framework/domain/masters/car-type/carTypeMapper';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { ContainerTypeMapper } from '~/framework/domain/masters/container-type/containerTypeMapper';
import { AggregatedReservationGenerationSiteTask } from '~/framework/domain/reservation/reservation/reservation-generation-site-task/aggregatedReservationGenerationSiteTask';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { AggregatedWasteTypeMapper } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeMapper';

export class AggregatedReservationMapper extends StoredMapperBase<
  AggregatedReservationEntityData,
  AggregatedReservationEntity
> {
  protected store: AggregatedReservationStore;
  private clientMapper: AggregatedClientMapper;
  private orderGroupMapper: OrderGroupMapper;
  private generationSiteMapper: GenerationSiteMapper;
  private disposalSiteMapper: AggregatedDisposalSiteMapper;
  private wasteTypeMapper: AggregatedWasteTypeMapper;
  private containerTypeMapper: ContainerTypeMapper;
  private driverMapper: DriverMapper;
  private carTypeMapper: CarTypeMapper;
  private carMapper: AggregatedCarMapper;

  constructor(
    store: AggregatedReservationStore,
    clientStore: AggregatedClientStore,
    userStore: UserStore,
    orderGroupStore: OrderGroupStore,
    disposalSiteStore: AggregatedDisposalSiteStore,
    wasteTypeStore: AggregatedWasteTypeStore,
    carStore: AggregatedCarStore,
    carTypeStore: AggregatedCarTypeStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore,
    baseSiteStore: AggregatedBaseSiteStore,
    jwnetWasteMaster: JwnetWasteMasterStore
  ) {
    super();
    this.store = store;
    this.clientMapper = new AggregatedClientMapper(clientStore, userStore);
    this.orderGroupMapper = new OrderGroupMapper(orderGroupStore);
    this.generationSiteMapper = new GenerationSiteMapper();
    this.disposalSiteMapper = new AggregatedDisposalSiteMapper(disposalSiteStore, userStore);
    this.wasteTypeMapper = new AggregatedWasteTypeMapper(wasteTypeStore, jwnetWasteMaster, userStore);
    this.containerTypeMapper = new ContainerTypeMapper();
    this.driverMapper = new DriverMapper();
    this.carTypeMapper = new CarTypeMapper();
    this.carMapper = new AggregatedCarMapper(
      carStore,
      carTypeStore,
      orderGroupStore,
      carTypeContainerTypeStore,
      baseSiteStore,
      userStore
    );
  }

  protected instantiateWithData(data: AggregatedReservationEntityData): AggregatedReservationEntity {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSite = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = this.disposalSiteMapper.map(data.assignedDisposalSites);
    const assignedDriverEntities = data.assignableDriverEntities
      ? this.driverMapper.map(data.assignableDriverEntities)
      : undefined;
    const assignableCarTypes = this.carTypeMapper.map(data.assignableCarTypes);
    const assignedCar = data.assignedCar ? this.carMapper.mapSingle(data.assignedCar) : undefined;
    const generationSiteTasks = data.generationSiteTasks.map((generationSiteTask) => {
      const wasteType = generationSiteTask.wasteType
        ? this.wasteTypeMapper.mapSingle(generationSiteTask.wasteType)
        : undefined;
      const containerType = this.containerTypeMapper.mapSingle(generationSiteTask.containerType);
      return new AggregatedReservationGenerationSiteTask(
        generationSiteTask.taskType,
        generationSiteTask.wasteTypeId,
        generationSiteTask.containerTypeId,
        generationSiteTask.containerNum,
        wasteType,
        containerType
      );
    });

    return new AggregatedReservationEntity(
      data.id,
      data.candidateDates,
      data.note,
      data.sourceOrderNote,
      data.clientId,
      data.orderGroupId,
      data.generationSiteId,
      data.durationAtGenerationSite,
      data.routeCollectionAllowed,
      // TODO: 処分場の入退場時間の後続リリースで削除
      data.assignedDisposalSiteIds,
      data.orderAssignedDisposalSites,
      data.disposalSiteAssignmentType,
      data.driverAssignmentType,
      data.assignableDrivers,
      data.assignedCarId,
      data.assignableCarTypeIds,
      data.carNum,
      data.minAssignedDriverNum,
      data.maxAssignedDriverNum,
      data.avoidHighways,
      data.isFixedArrivalTimeReportNeeded,
      data.marginTypeOfFixedArrivalTime,
      data.marginOfFixedArrivalTime,
      client,
      orderGroup,
      generationSite,
      assignedDisposalSites,
      assignedDriverEntities,
      assignableCarTypes,
      assignedCar,
      generationSiteTasks,
      data.isCancelled
    );
  }

  protected updateWithData(data: AggregatedReservationEntityData, entity: AggregatedReservationEntity): void {
    const client = this.clientMapper.mapSingle(data.client);
    const orderGroup = this.orderGroupMapper.mapSingle(data.orderGroup);
    const generationSite = this.generationSiteMapper.mapSingle(data.generationSite);
    const assignedDisposalSites = this.disposalSiteMapper.map(data.assignedDisposalSites);
    const assignedDriverEntities = data.assignableDriverEntities
      ? this.driverMapper.map(data.assignableDriverEntities)
      : undefined;
    const assignedCar = data.assignedCar ? this.carMapper.mapSingle(data.assignedCar) : undefined;
    const assignableCarTypes = this.carTypeMapper.map(data.assignableCarTypes);
    const generationSiteTasks = data.generationSiteTasks.map((generationSiteTask) => {
      const wasteType = generationSiteTask.wasteType
        ? this.wasteTypeMapper.mapSingle(generationSiteTask.wasteType)
        : undefined;
      const containerType = this.containerTypeMapper.mapSingle(generationSiteTask.containerType);
      return new AggregatedReservationGenerationSiteTask(
        generationSiteTask.taskType,
        generationSiteTask.wasteTypeId,
        generationSiteTask.containerTypeId,
        generationSiteTask.containerNum,
        wasteType,
        containerType
      );
    });

    entity.candidateDates = data.candidateDates;
    entity.note = data.note;
    entity.sourceOrderNote = data.sourceOrderNote;
    entity.clientId = data.clientId;
    entity.orderGroupId = data.orderGroupId;
    entity.generationSiteId = data.generationSiteId;
    entity.durationAtGenerationSite = data.durationAtGenerationSite;
    entity.routeCollectionAllowed = data.routeCollectionAllowed;
    // TODO: 処分場の入退場時間の後続リリースで削除
    entity.assignedDisposalSiteIds = data.assignedDisposalSiteIds;
    entity.orderAssignedDisposalSites = data.orderAssignedDisposalSites;
    entity.disposalSiteAssignmentType = data.disposalSiteAssignmentType;
    entity.driverAssignmentType = data.driverAssignmentType;
    entity.assignableDrivers = data.assignableDrivers;
    entity.assignedCarId = data.assignedCarId;
    entity.assignableCarTypeIds = data.assignableCarTypeIds;
    entity.carNum = data.carNum;
    entity.minAssignedDriverNum = data.minAssignedDriverNum;
    entity.maxAssignedDriverNum = data.maxAssignedDriverNum;
    entity.avoidHighways = data.avoidHighways;
    entity.isFixedArrivalTimeReportNeeded = data.isFixedArrivalTimeReportNeeded;
    entity.marginTypeOfFixedArrivalTime = data.marginTypeOfFixedArrivalTime;
    entity.marginOfFixedArrivalTime = data.marginOfFixedArrivalTime;
    entity.isCancelled = data.isCancelled;

    entity.client = client;
    entity.orderGroup = orderGroup;
    entity.generationSite = generationSite;
    entity.assignedDisposalSites = assignedDisposalSites;
    entity.assignableDriverEntities = assignedDriverEntities;
    entity.assignedCar = assignedCar;
    entity.assignableCarTypes = assignableCarTypes;
    entity.generationSiteTasks = generationSiteTasks;
  }
}
