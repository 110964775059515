import { InconsistentRouteInfo as BaseInconsistentRouteInfo } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfo';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { InconsistentReasonType } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfoData';

export class InconsistentRouteInfo extends BaseInconsistentRouteInfo {
  driver: DriverEntity;

  constructor(index: number, driverId: PseudoId, driver: DriverEntity, type: InconsistentReasonType) {
    super(index, driverId, type);
    this.driver = driver;
  }

  clone(): InconsistentRouteInfo {
    return new InconsistentRouteInfo(this.index, this.driverId, this.driver, this.type);
  }
}
