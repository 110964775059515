import { IMapper, StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedBaseSiteEntity,
  AggregatedBaseSiteEntityData,
} from '~/framework/domain/masters/base-site/aggregatedBaseSiteEntity';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';

export interface IAggregatedBaseSiteMapper extends IMapper<AggregatedBaseSiteEntityData, AggregatedBaseSiteEntity> {
  //
}

export class AggregatedBaseSiteMapper
  extends StoredMapperBase<AggregatedBaseSiteEntityData, AggregatedBaseSiteEntity>
  implements IAggregatedBaseSiteMapper
{
  protected store: AggregatedBaseSiteStore;
  private userMapper: UserMapper;

  constructor(store: AggregatedBaseSiteStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: AggregatedBaseSiteEntityData): AggregatedBaseSiteEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    return new AggregatedBaseSiteEntity(
      data.id,
      data.name,
      data.nameRuby,
      data.zipCode,
      data.address1,
      data.address2,
      data.address3,
      data.address4,
      data.latitude,
      data.longitude,
      data.hasCars,
      data.hasContainers,
      data.createdAt,
      createdBy
    );
  }

  protected updateWithData(data: AggregatedBaseSiteEntityData, entity: AggregatedBaseSiteEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    entity.name = data.name;
    entity.nameRuby = data.nameRuby;
    entity.zipCode = data.zipCode;
    entity.address1 = data.address1;
    entity.address2 = data.address2;
    entity.address3 = data.address3;
    entity.address4 = data.address4;
    entity.latitude = data.latitude;
    entity.longitude = data.longitude;
    entity.hasCars = data.hasCars;
    entity.hasContainers = data.hasContainers;
    entity.createdAt = data.createdAt;
    entity.createdBy = createdBy;
  }
}
