import {
  AllDriversWithOptionalsQuery,
  AllDriversWithOptionalsQueryVariables,
  CreateDriversMutation,
  CreateDriversMutationVariables,
  DriversByIdsQuery,
  DriversByIdsQueryVariables,
  UpdateDriverSequenceMutation,
  UpdateDriverSequenceMutationVariables,
  UpdateDriversMutation,
  UpdateDriversMutationVariables,
} from '@/graphql/graphQLServerApi';
import { AllDriversWithOptionals, DriversByIds } from '@/../graphql/server-api/queries/driver.gql';
import { CreateDrivers, UpdateDrivers, UpdateDriverSequence } from '@/../graphql/server-api/mutations/driver.gql';
import { GraphqlApiBase } from '~/framework/port.adapter/server-api/graphqlApiBase';
import {
  IDriverData,
  IDriverDataForUpdate,
  IDriverDataForCreate,
  IUpdateDriverSequenceData,
} from '~/framework/server-api/masters/driver';

export class GetAllApi extends GraphqlApiBase {
  async getAll(ids?: string[]) {
    const result = await this.query<AllDriversWithOptionalsQuery, AllDriversWithOptionalsQueryVariables>({
      query: AllDriversWithOptionals,
      variables: { ids: ids ?? [] },
    });
    const map: Map<string, IDriverData> = new Map();
    for (const aData of result.allDrivers) {
      map.set(aData.id, aData);
    }
    for (const aData of result.driversByIds) {
      map.set(aData.id, aData);
    }
    return Array.from(map.values());
  }
}

export class GetByIdsApi extends GraphqlApiBase {
  async getByIds(ids: string[]) {
    const result = await this.query<DriversByIdsQuery, DriversByIdsQueryVariables>({
      query: DriversByIds,
      variables: { ids },
    });
    this.validateArrayConsistency(ids, result.driversByIds, `DriversByIds`);
    return result.driversByIds;
  }
}

export class CreateApi extends GraphqlApiBase {
  async create(data: IDriverDataForCreate[]) {
    const result = await this.mutate<CreateDriversMutation, CreateDriversMutationVariables>({
      mutation: CreateDrivers,
      variables: { drivers: data },
    });
    this.validateArrayConsistency(data, result.createDrivers, `CreateDrivers`);
    return result.createDrivers;
  }
}

export class UpdateApi extends GraphqlApiBase {
  async update(data: IDriverDataForUpdate[]) {
    const result = await this.mutate<UpdateDriversMutation, UpdateDriversMutationVariables>({
      mutation: UpdateDrivers,
      variables: { drivers: data },
    });
    this.validateArrayConsistency(data, result.updateDrivers, `UpdateDrivers`);
    return result.updateDrivers;
  }

  async updateDriverSequence(data: IUpdateDriverSequenceData) {
    const result = await this.mutate<UpdateDriverSequenceMutation, UpdateDriverSequenceMutationVariables>({
      mutation: UpdateDriverSequence,
      variables: { driverSequence: data },
    });
    return result.updateDriverSequence;
  }
}
