import { IResultData } from '~/framework/server-api/schedule/order/order';
import {
  CreateGenerationSiteTaskInput,
  CreateIrregularTaskInput,
  CreateOrderAssignableDriversAndNumInput,
  CreateOrderDisposalSitesAndTypeInput,
  OrderRoutingGroupInput,
  OrderPlanInput,
} from '~/framework/server-api/typeAliases';
import {
  OrderStatus,
  PreloadStatus,
  OrderDisposalSiteAssignmentType,
  OrderSchedulingPriority,
  MarginType,
} from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';

export const order$updateOrdersSymbol = Symbol('order$updateOrdersSymbol');

export interface IUpdateOrdersApi {
  [order$updateOrdersSymbol]: void;
  updateOrders(data: IUpdateData[]): Promise<IResultData[]>;
}

export interface IUpdateData {
  id: string;
  date: Maybe<Date>;
  plan: OrderPlanInput;
  orderGroupId: string;
  generationSiteId: string;
  collectablePeriodTemplateName: string | undefined;
  collectablePeriodStart: number | undefined;
  collectablePeriodEnd: number | undefined;
  generationSiteTasks: CreateGenerationSiteTaskInput[] | undefined;
  irregularTasks: CreateIrregularTaskInput[] | undefined;
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  unloadDate: Date | undefined;
  assignedBaseSiteId: string | undefined;
  assignedDisposalSiteIds: string[];
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  assignedDisposalSitesAndType: CreateOrderDisposalSitesAndTypeInput;
  assignableDriversAndNum: CreateOrderAssignableDriversAndNumInput;
  assignedCarId: string | undefined;
  assignableCarTypeIds: string[];
  // TODO: carNum に置き換わるので削除する
  minAssignedCarNum: number;
  // TODO: carNum に置き換わるので削除する
  maxAssignedCarNum: number;
  carNum: number;
  note: string | undefined;
  noteForAssignedDriver: string | undefined;
  attachmentsToAdd: File[];
  attachmentsToRemove: string[];
  avoidHighways: boolean;
  includeFollowingRecurringOrders: boolean | undefined;
  fixedArrivalTime: number | undefined;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  routingGroup: OrderRoutingGroupInput | undefined;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: string | undefined;
  schedulingPriority: OrderSchedulingPriority;
  status: OrderStatus;
}
