import { GraphQLScalarType, Kind, print } from 'graphql';
import { JsonObject, Maybe, PersistentId } from '~/framework/typeAliases';
import {
  RawInconsistencyJsonObject,
  RawInfeasibilityJsonObject,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { convertNullToUndefined } from '~/framework/property';

export interface IRawOrderAcceptanceJsonObject {
  infeasibilities?: Maybe<RawInfeasibilityJsonObject[]>;
  inconsistencies?: Maybe<RawInconsistencyJsonObject[]>;
  error?: Maybe<IRawErrorJsonObject>;
}

export interface IRawErrorJsonObject {
  type: ErrorTypes;

  // type が violates_multiple_check_condition の時のみ
  checking?: {
    id: PersistentId;
  };
}

export enum ErrorTypes {
  /**
   * 勤怠が全く登録されていない時
   */
  NoAttendances = 'no_attendances',
  /**
   * 同時チェックの制約にひっかかった場合
   */
  ViolatesMultipleCheckCondition = 'violates_multiple_check_condition',
}

export class OrderAcceptanceJsonObject implements IRawOrderAcceptanceJsonObject {
  // NOTE フィールドとして定義されていないと Apollo から書き出されない
  // getter, setter では動かないので注意
  // serialize の方に押し込むのも手かもしれない
  infeasibilities?: Maybe<RawInfeasibilityJsonObject[]>;
  inconsistencies?: Maybe<RawInconsistencyJsonObject[]>;
  error?: Maybe<IRawErrorJsonObject>;

  constructor(data: JsonObject) {
    // NOTE 型としては Maybe としているものの、実際の JSON では null が入ってくる事があるため、
    // 実際に使う場合には convertNullToUndefined などを行って null を undefined に変換しておく
    // 必要がある事に注意する必要がある。
    const converted = convertNullToUndefined(data) as IRawOrderAcceptanceJsonObject;
    this.infeasibilities = converted.infeasibilities;
    this.inconsistencies = converted.inconsistencies;
    this.error = converted.error;
  }
}

/**
 * 受注情報検証結果のデータフォーマット
 */
export const OrderAcceptanceJsonObjectScalarType = new GraphQLScalarType({
  name: 'OrderAcceptanceJsonObject',
  /**
   * 内部値（フロントエンド側）を出力用（サーバー側）の値に変換する
   * @param value
   */
  serialize: (value: IRawOrderAcceptanceJsonObject): any => {
    return value;
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param value
   */
  parseValue: (value: JsonObject): IRawOrderAcceptanceJsonObject => {
    return new OrderAcceptanceJsonObject(value);
  },
  /**
   * 外部（サーバー側）から与えられた値を内部値（フロントエンド側）に変換する
   * @param valueAST
   */
  parseLiteral: (valueAST): OrderAcceptanceJsonObject => {
    // NOTE いつどう呼ばれるのかいまいち分からずテストができていない
    if (valueAST.kind === Kind.OBJECT) {
      const str = print(valueAST);
      const obj = JSON.parse(str);
      return new OrderAcceptanceJsonObject(obj);
    }
    throw new Error(`OrderAcceptanceJsonObject should be Object type!`);
  },
});
