import { BStore, IBStore } from '~/framework/domain/schedule/b/bStore';
import { AggregatedBStore, IAggregatedBStore } from '~/framework/domain/schedule/b/aggregatedBStore';
import { AggregatedOrderStore } from '~/framework/domain/schedule/order/aggregatedOrderStore';
import { OrderAcceptanceCheckStore } from '~/framework/domain/schedule/order/order-acceptance-check/orderAcceptanceCheckStore';
import { ScheduleStore } from '~/framework/domain/schedule/schedule/scheduleStore';

export class ScheduleNamespace {
  readonly aggregatedB: IAggregatedBStore;
  readonly b: IBStore;
  readonly schedule: ScheduleStore;
  readonly orderAcceptanceCheck: OrderAcceptanceCheckStore;
  readonly aggregatedOrder: AggregatedOrderStore;

  constructor() {
    this.aggregatedB = new AggregatedBStore();
    this.b = new BStore();
    this.schedule = new ScheduleStore();
    this.orderAcceptanceCheck = new OrderAcceptanceCheckStore();
    this.aggregatedOrder = new AggregatedOrderStore();
  }
}
