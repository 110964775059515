import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { attendance$getByDateRangeSymbol } from '~/framework/server-api/masters/attendance';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { AttendanceEntity } from '~/framework/domain/masters/attendance/attendanceEntity';

export const attendanceSymbol = Symbol('attendance');

export class AttendanceApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async getByDateRange(start: Date, end: Date): Promise<AttendanceEntity[]> {
    const attendance$getByDateRangeApi = this.serverApis.get(attendance$getByDateRangeSymbol);
    const attendanceMapper: AttendanceMapper = new AttendanceMapper(this.store.masters.attendance);
    const result = await attendance$getByDateRangeApi.getByDateRange(start, end);
    const entities = attendanceMapper.map(result);
    return entities;
  }
}
