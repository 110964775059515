import { INamespace, NamespaceBase } from '~/framework/core/namespace';
import { IReactiveAEntity } from '~/framework/domain/masters/a/aEntity';

export interface IAStore extends INamespace<IReactiveAEntity> {
  //
}

export class AStore extends NamespaceBase<IReactiveAEntity> implements IAStore {
  //
}
