import { PackingStyleCode } from '~/framework/domain/typeAliases';
export interface PackingStyleEntityData extends Omit<PackingStyleEntity, 'isCountedByInteger'> {}

export class PackingStyleEntity {
  constructor(
    public readonly id: string,
    public code: PackingStyleCode,
    public name: string,
    public defaultUnitName: string
  ) {}

  /**
   * 整数として数えられるかどうか。
   * バラの場合は整数として数えられないために小数点が含まれた数値になる場合がある。
   * @returns 整数として数えられる場合はtrue
   */
  public isCountedByInteger(): boolean {
    // PackingStyleCodeのCode01はバラに固定されている
    return this.code !== PackingStyleCode.Code01;
  }
}
