import { MapperBase } from '~/framework/core/mapper';
import { TaskTypeEntity, BaseTaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { IBaseTaskTypeData, ITaskTypeData } from '~/framework/server-api/masters/taskType';

export class TaskTypeMapper extends MapperBase<ITaskTypeData, TaskTypeEntity> {
  protected instantiateWithData(data: ITaskTypeData): TaskTypeEntity {
    const { id, name, orderKind, siteType } = data.baseTaskType;
    return new TaskTypeEntity(
      data.id,
      data.name,
      new BaseTaskTypeEntity(id, name, orderKind, siteType),
      data.bannedDriverIds ?? []
    );
  }

  protected updateWithData(data: ITaskTypeData, entity: TaskTypeEntity): void {
    entity.name = data.name;
    entity.bannedDriverIds = data.bannedDriverIds ?? [];
  }
}

export class BaseTaskTypeMapper extends MapperBase<IBaseTaskTypeData, BaseTaskTypeEntity> {
  protected instantiateWithData(data: IBaseTaskTypeData): BaseTaskTypeEntity {
    return new BaseTaskTypeEntity(data.id, data.name, data.orderKind, data.siteType);
  }

  updateWithData() {
    throw new Error('Logic Error, BaseTaskTypeは変更される仕様ではありません');
  }
}
