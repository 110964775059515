import {
  IOriginalRouteEntity,
  OriginalRouteEntity,
} from '~/framework/domain/schedule/schedule/pseudo-entities/originalRouteEntity';
import { Maybe } from '~/framework/typeAliases';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { OriginalCollection } from '~/pages/schedule/originalCollection';
import { CarUsage } from '~/pages/schedule/carUsage';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { BaseSiteEntity } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { IOriginalDisposal } from '~/pages/schedule/originalDisposal';
import { InconsistentRouteInfo } from '~/pages/schedule/inconsistentRouteInfo';

export interface IOriginalRoute
  extends IOriginalRouteEntity<OriginalCollection, IOriginalDisposal, InconsistentRouteInfo> {
  // original から復元する必要のあるものを入れている
  routeCarAssignment: Maybe<CarUsage>;
  driver: DriverEntity;
  car: Maybe<AggregatedCarEntity>;
  carType: Maybe<CarTypeEntity>;
  baseSite: Maybe<BaseSiteEntity>;
}

export class OriginalRoute
  extends OriginalRouteEntity<OriginalCollection, IOriginalDisposal, InconsistentRouteInfo>
  implements IOriginalRoute
{
  routeCarAssignment: Maybe<CarUsage>;
  driver: DriverEntity;
  car: Maybe<AggregatedCarEntity>;
  carType: Maybe<CarTypeEntity>;
  baseSite: Maybe<BaseSiteEntity>;

  constructor(
    id: string,
    index: number,
    carId: Maybe<PseudoId>,
    carIndex: Maybe<number>,
    driverId: PseudoId,
    isDriver: Maybe<boolean>,
    isHelper: Maybe<boolean>,
    startTime: number,
    endTime: number,
    garageSiteDepartureId: Maybe<PseudoId>,
    garageSiteDepartureTime: Maybe<number>,
    garageSiteArrivalId: Maybe<PseudoId>,
    garageSiteArrivalTime: Maybe<number>,
    hasRestBeforeGarageSiteArrival: Maybe<boolean>,
    hasChangeCar: Maybe<boolean>,
    baseSiteId: Maybe<PseudoId>,
    baseSiteArrivalTime: Maybe<number>,
    baseSiteDepartureTime: Maybe<number>,
    hasRestBeforeBaseSiteArrival: Maybe<boolean>,
    hasRestAfterGenerationSiteDeparture: Maybe<boolean>,
    hasRestAfterDisposalSiteDeparture: Maybe<boolean>,
    isFixedAssignment: boolean,
    isFixedSchedule: boolean,
    collections: OriginalCollection[],
    disposals: IOriginalDisposal[],
    inconsistentLoadingRouteInfos: InconsistentRouteInfo[],
    inconsistentTimeRouteInfos: InconsistentRouteInfo[],
    routeCarAssignment: Maybe<CarUsage>,
    driver: DriverEntity,
    car: Maybe<AggregatedCarEntity>,
    carType: Maybe<CarTypeEntity>,
    baseSite: Maybe<BaseSiteEntity>
  ) {
    super(
      id,
      index,
      carId,
      carIndex,
      driverId,
      isDriver,
      isHelper,
      startTime,
      endTime,
      garageSiteDepartureId,
      garageSiteDepartureTime,
      garageSiteArrivalId,
      garageSiteArrivalTime,
      hasRestBeforeGarageSiteArrival,
      hasChangeCar,
      baseSiteId,
      baseSiteArrivalTime,
      baseSiteDepartureTime,
      hasRestBeforeBaseSiteArrival,
      hasRestAfterGenerationSiteDeparture,
      hasRestAfterDisposalSiteDeparture,
      isFixedAssignment,
      isFixedSchedule,
      collections,
      disposals,
      inconsistentLoadingRouteInfos,
      inconsistentTimeRouteInfos
    );
    this.routeCarAssignment = routeCarAssignment;
    this.driver = driver;
    this.car = car;
    this.carType = carType;
    this.baseSite = baseSite;
  }
}
