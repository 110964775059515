import { StoredMapperBase } from '~/framework/core/mapper';
import {
  CollectablePeriodTemplateEntity,
  CollectablePeriodTemplateEntityData,
} from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';
import { CollectablePeriodTemplateStore } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateStore';

export class CollectablePeriodTemplateMapper extends StoredMapperBase<
  CollectablePeriodTemplateEntityData,
  CollectablePeriodTemplateEntity
> {
  protected store: CollectablePeriodTemplateStore;

  constructor(store: CollectablePeriodTemplateStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: CollectablePeriodTemplateEntityData): CollectablePeriodTemplateEntity {
    return new CollectablePeriodTemplateEntity(
      data.id,
      data.name,
      data.collectablePeriodStart,
      data.collectablePeriodEnd,
      data.note,
      data.isDefault
    );
  }

  protected updateWithData(data: CollectablePeriodTemplateEntityData, entity: CollectablePeriodTemplateEntity): void {
    entity.name = data.name;
    entity.collectablePeriodStart = data.collectablePeriodStart;
    entity.collectablePeriodEnd = data.collectablePeriodEnd;
    entity.note = data.note;
    entity.isDefault = data.isDefault;
  }
}
