import {
  CarTypeContainerTypeEntity,
  CarTypeContainerTypeEntityData,
} from '~/framework/domain/masters/car-type/car-type-container-type/carTypeContainerTypeEntity';

export interface AggregatedCarTypeContainerTypeEntityData extends CarTypeContainerTypeEntityData {
  containerName: string;
  containerUnitName: string;
}

export class AggregatedCarTypeContainerTypeEntity extends CarTypeContainerTypeEntity {
  /**
   * ひとまずこの情報だけ参照できればよい。
   * ここでコンテナのエンティティを参照しないという事はコンテナの名前を書き換えた時に
   * 車種画面で参照しているコンテナの名前が書き換わらないという事が起きるが、これは
   * 致し方ないという事にする。仮にそれを回避しようとすると、ここでコンテナに関する情報
   * を全て取得しなければいけないという事になってしまい、通信がえらい事になるため。
   */
  /**
   * コンテナ名
   */
  containerName: string;
  /**
   * 単位名
   */
  containerUnitName: string;

  constructor(
    id: string,
    containerTypeId: string,
    emptyCount: number,
    emptyCountOccupation: number,
    lowerTierFullCount: number,
    upperTierFullCount: number,
    fullCountOccupation: number,
    isUpperTierAvailable: boolean,

    containerName: string,
    containerUnitName: string
  ) {
    super(
      id,
      containerTypeId,
      emptyCount,
      emptyCountOccupation,
      lowerTierFullCount,
      upperTierFullCount,
      fullCountOccupation,
      isUpperTierAvailable
    );
    this.containerName = containerName;
    this.containerUnitName = containerUnitName;
  }
}
