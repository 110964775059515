import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { holidayRule$getSymbol, holidayRule$updateSymbol } from '~/framework/server-api/masters/holidayRule';
import { HolidayRuleMapper } from '~/framework/domain/masters/holiday-rule/holidayRuleMapper';
import { HolidayRuleEntity, HolidayRuleFields } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { IHolidayRuleData } from '~/framework/server-api/masters/holidayRule';

export const holidayRuleSymbol = Symbol('holidayRuleSymbol');

export class HolidayRuleApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async get(): Promise<HolidayRuleEntity> {
    const holidayRule$getApi = this.serverApis.get(holidayRule$getSymbol);
    const holidayRuleMapper: HolidayRuleMapper = new HolidayRuleMapper(this.store.masters.holidayRule);
    const holidayRuleData = await holidayRule$getApi.get();
    const holidayRule = holidayRuleMapper.mapSingle(holidayRuleData);
    return holidayRule;
  }

  async update(day: keyof HolidayRuleFields, value: boolean): Promise<void> {
    const holidayRule$getApi = this.serverApis.get(holidayRule$getSymbol);
    const holidayRule$updateApi = this.serverApis.get(holidayRule$updateSymbol);
    const holidayRuleMapper: HolidayRuleMapper = new HolidayRuleMapper(this.store.masters.holidayRule);
    const data = await holidayRule$getApi.get();
    const updateData: IHolidayRuleData = {
      id: data.id,
      monday: data.monday,
      tuesday: data.tuesday,
      wednesday: data.wednesday,
      thursday: data.thursday,
      friday: data.friday,
      saturday: data.saturday,
      sunday: data.sunday,
      nationalHoliday: data.nationalHoliday,
    };
    updateData[day] = value;
    await holidayRule$updateApi.update(updateData);
    holidayRuleMapper.mapSingle(updateData);
  }
}
