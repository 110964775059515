
import Vue, { PropType } from 'vue';
import { IDriverReason, IInfeasibility } from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import RInfeasibility from '~/components/common/r-infeasibility/RInfeasibilityForAcceptanceCheckDialog.vue';
import { Maybe } from '~/framework/typeAliases';

enum EventTypes {
  ClickClose = 'click:close',
  ClickRegisterOrder = 'click:register-order',
  ClickEditAttendance = 'click:edit-attendance',
  ClickRinLink = 'click:rin-link',
}

export default Vue.extend({
  name: 'RAcceptanceCheckResultAttendance',
  components: {
    RInfeasibility,
  },
  props: {
    infeasibilities: {
      type: Array as PropType<Maybe<IInfeasibility<IDriverReason>[]>>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    onClickCloseButton(): void {
      this.$emit(EventTypes.ClickClose);
    },
    onClickRegisterOrder(): void {
      this.$emit(EventTypes.ClickRegisterOrder);
    },
    onClickEditAttendance(): void {
      this.$emit(EventTypes.ClickEditAttendance);
    },
    onClickRinLink(infeasibility: IInfeasibility<IDriverReason>, path: string): void {
      this.$emit(EventTypes.ClickRinLink, infeasibility, path);
    },
  },
});
