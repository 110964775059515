import { MapperBase } from '~/framework/core/mapper';
import {
  AggregatedGenerationSiteTaskEntity,
  AggregatedGenerationSiteTaskEntityData,
} from '~/framework/domain/schedule/order/generation-site-task/aggregatedGenerationSiteTaskEntity';
import { AggregatedWasteTypeMapper } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeMapper';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { ContainerTypeMapper } from '~/framework/domain/masters/container-type/containerTypeMapper';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';

/**
 * トラックする必要はないので Mapper は厳密には必要ないが、同じ作法にしておいた方が
 * 分かりやすそうなので用意している
 */

export class AggregatedGenerationSiteTaskMapper extends MapperBase<
  AggregatedGenerationSiteTaskEntityData,
  AggregatedGenerationSiteTaskEntity
> {
  private readonly wasteTypeMapper: AggregatedWasteTypeMapper;
  private readonly containerTypeMapper: ContainerTypeMapper;

  constructor(
    aggregatedWasteTypeStore: AggregatedWasteTypeStore,
    jwnetWasteMasterStore: JwnetWasteMasterStore,
    userStore: UserStore
  ) {
    super();
    this.wasteTypeMapper = new AggregatedWasteTypeMapper(aggregatedWasteTypeStore, jwnetWasteMasterStore, userStore);
    this.containerTypeMapper = new ContainerTypeMapper();
  }

  protected instantiateWithData(data: AggregatedGenerationSiteTaskEntityData): AggregatedGenerationSiteTaskEntity {
    const wasteType = data.wasteType ? this.wasteTypeMapper.mapSingle(data.wasteType) : undefined;
    const containerType = data.containerType ? this.containerTypeMapper.mapSingle(data.containerType) : undefined;
    return new AggregatedGenerationSiteTaskEntity(
      data.id,
      data.orderId,
      data.taskType,
      data.wasteTypeId,
      data.containerTypeId,
      data.containerNum,
      wasteType,
      containerType
    );
  }
}
