import { OrderRecurringSettings, OrderPlan } from '~/framework/server-api/typeAliases';
import {
  PreloadStatus,
  OrderStatus,
  OrderDisposalSiteAssignmentType,
  MarginType,
  OrderCreatedVia,
  DriverAssignmentType,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { IOrderAssignableDriver } from '~/framework/server-api/schedule/order/driver/assignableDriver';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { IUserData } from '~/framework/server-api/masters/user';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { UploadedFile } from '~/graphql/graphQLServerApi';
import { IOrderRoutingGroup } from '~/framework/server-api/schedule/order/orderRoutingGroup';

export interface OrderEntityData {
  id: string;
  code: Maybe<string>;
  date: Maybe<Date>;
  plan: Maybe<OrderPlan>;
  serialNumber: number;
  clientId: string;
  createdVia: OrderCreatedVia;
  orderGroupId: string;
  generationSiteId: string;
  collectablePeriodTemplateName: string | undefined;
  collectablePeriodStart: number | undefined;
  collectablePeriodEnd: number | undefined;
  generationSiteTaskIds: string[];
  irregularTaskIds: string[];
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  unloadDate: Date | undefined;
  assignedBaseSiteId: string | undefined;
  // TODO: 処分場の入退場時間の後続リリースで削除
  assignedDisposalSiteIds: string[];
  orderAssignedDisposalSites: IOrderAssignedDisposalSite[];
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: IOrderAssignableDriver[];
  assignedCarId: string | undefined;
  assignableCarTypeIds: string[];
  carNum: number;
  minAssignedDriverNum: number;
  maxAssignedDriverNum: number;
  note: string | undefined;
  noteForAssignedDriver: string | undefined;
  noteFromReservation: Maybe<string>;
  attachments: UploadedFile[];
  avoidHighways: boolean;
  fixedArrivalTime: number | undefined;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  routingGroup: Maybe<IOrderRoutingGroup>;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: string | undefined;
  schedulingPriority: OrderSchedulingPriority;
  recurringSettings: OrderRecurringSettings | undefined;
  status: OrderStatus;
  createdBy: IUserData;
  createdAt: Date;
  updatedBy: IUserData;
  updatedAt: Date;
}

export class OrderEntity {
  readonly id: string;
  readonly persistentId: string;
  code: Maybe<string>;
  date: Maybe<Date>;
  plan: Maybe<OrderPlan>;
  serialNumber: number;
  clientId: PersistentId;
  orderGroupId: PersistentId;
  generationSiteId: PersistentId;
  collectablePeriodTemplateName: Maybe<string>;
  collectablePeriodStart: Maybe<number>;
  collectablePeriodEnd: Maybe<number>;
  generationSiteTaskIds: PersistentId[];
  irregularTaskIds: PersistentId[];
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  preloadStatus: PreloadStatus;
  unloadDate: Maybe<Date>;
  // TODO: 処分場の入退場時間の後続リリースで削除
  assignedDisposalSiteIds: PersistentId[];
  orderAssignedDisposalSites: IOrderAssignedDisposalSite[];
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: IOrderAssignableDriver[];
  assignedCarId: Maybe<PersistentId>;
  assignedBaseSiteId: Maybe<PersistentId>;
  assignableCarTypeIds: PersistentId[];
  carNum: number;
  minAssignedDriverNum: number;
  maxAssignedDriverNum: number;
  note: Maybe<string>;
  noteForAssignedDriver: Maybe<string>;
  noteFromReservation: Maybe<string>;
  attachments: UploadedFile[];
  avoidHighways: boolean;
  fixedArrivalTime: Maybe<number>;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  routingGroup: Maybe<IOrderRoutingGroup>;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: Maybe<string>;
  schedulingPriority: OrderSchedulingPriority;
  recurringSettings: Maybe<OrderRecurringSettings>;
  status: OrderStatus;
  createdVia: OrderCreatedVia;
  createdBy: UserEntity;
  createdAt: Date;
  updatedBy: UserEntity;
  updatedAt: Date;

  constructor(
    id: string,
    code: Maybe<string>,
    date: Maybe<Date>,
    plan: Maybe<OrderPlan>,
    serialNumber: number,
    clientId: PersistentId,
    orderGroupId: PersistentId,
    generationSiteId: PersistentId,
    collectablePeriodTemplateName: Maybe<string>,
    collectablePeriodStart: Maybe<number>,
    collectablePeriodEnd: Maybe<number>,
    generationSiteTaskIds: PersistentId[],
    irregularTaskIds: PersistentId[],
    durationAtGenerationSite: number,
    routeCollectionAllowed: boolean,
    preloadStatus: PreloadStatus,
    unloadDate: Maybe<Date>,
    // TODO: 処分場の入退場時間の後続リリースで削除
    assignedDisposalSiteIds: PersistentId[],
    orderAssignedDisposalSites: IOrderAssignedDisposalSite[],
    disposalSiteAssignmentType: OrderDisposalSiteAssignmentType,
    driverAssignmentType: DriverAssignmentType,
    assignableDrivers: IOrderAssignableDriver[],
    assignedCarId: Maybe<PersistentId>,
    assignedBaseSiteId: Maybe<PersistentId>,
    assignableCarTypeIds: PersistentId[],
    carNum: number,
    minAssignedDriverNum: number,
    maxAssignedDriverNum: number,
    note: Maybe<string>,
    noteForAssignedDriver: Maybe<string>,
    noteFromReservation: Maybe<string>,
    attachments: UploadedFile[],
    avoidHighways: boolean,
    fixedArrivalTime: Maybe<number>,
    isFixedArrivalTimeReportNeeded: boolean,
    marginTypeOfFixedArrivalTime: MarginType,
    marginOfFixedArrivalTime: number,
    routingGroup: Maybe<IOrderRoutingGroup>,
    fixedDisplayOnReservation: boolean,
    fixedDisplayOnReservationName: Maybe<string>,
    schedulingPriority: OrderSchedulingPriority,
    recurringSettings: Maybe<OrderRecurringSettings>,
    status: OrderStatus,
    createdVia: OrderCreatedVia,
    createdBy: UserEntity,
    createdAt: Date,
    updatedBy: UserEntity,
    updatedAt: Date
  ) {
    this.id = id;
    this.persistentId = id;
    this.code = code;
    this.date = date;
    this.plan = plan;
    this.serialNumber = serialNumber;
    this.clientId = clientId;
    this.orderGroupId = orderGroupId;
    this.generationSiteId = generationSiteId;
    this.collectablePeriodTemplateName = collectablePeriodTemplateName;
    this.collectablePeriodStart = collectablePeriodStart;
    this.collectablePeriodEnd = collectablePeriodEnd;
    this.generationSiteTaskIds = generationSiteTaskIds;
    this.irregularTaskIds = irregularTaskIds;
    this.durationAtGenerationSite = durationAtGenerationSite;
    this.routeCollectionAllowed = routeCollectionAllowed;
    this.preloadStatus = preloadStatus;
    this.unloadDate = unloadDate;
    // TODO: 処分場の入退場時間の後続リリースで削除
    this.assignedDisposalSiteIds = assignedDisposalSiteIds;
    this.orderAssignedDisposalSites = orderAssignedDisposalSites;
    this.disposalSiteAssignmentType = disposalSiteAssignmentType;
    this.driverAssignmentType = driverAssignmentType;
    this.assignableDrivers = assignableDrivers;
    this.assignedCarId = assignedCarId;
    this.assignedBaseSiteId = assignedBaseSiteId;
    this.assignableCarTypeIds = assignableCarTypeIds;
    this.carNum = carNum;
    this.minAssignedDriverNum = minAssignedDriverNum;
    this.maxAssignedDriverNum = maxAssignedDriverNum;
    this.note = note;
    this.noteForAssignedDriver = noteForAssignedDriver;
    this.noteFromReservation = noteFromReservation;
    this.attachments = attachments;
    this.avoidHighways = avoidHighways;
    this.fixedArrivalTime = fixedArrivalTime;
    this.isFixedArrivalTimeReportNeeded = isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = marginTypeOfFixedArrivalTime;
    this.marginOfFixedArrivalTime = marginOfFixedArrivalTime;
    this.routingGroup = routingGroup;
    this.fixedDisplayOnReservation = fixedDisplayOnReservation;
    this.fixedDisplayOnReservationName = fixedDisplayOnReservationName;
    this.schedulingPriority = schedulingPriority;
    this.recurringSettings = recurringSettings;
    this.status = status;
    this.createdVia = createdVia;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = updatedAt;
  }
}
