import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { InconsistencyData } from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistencyData';
import { InconsistencyReasons } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';

export interface IInconsistencyEntity extends InconsistencyData {
  // 必要であれば追記
}

export class InconsistencyEntity implements IInconsistencyEntity {
  driverId: PseudoId;
  id: string;
  indexes: number[];
  reason: InconsistencyReasons;

  constructor(id: string, driverId: PseudoId, indexes: number[], reason: InconsistencyReasons) {
    this.id = id;
    this.driverId = driverId;
    this.indexes = indexes;
    this.reason = reason;
  }
}
