export interface CarTypeContainerTypeEntityData {
  id: string;
  containerTypeId: string;
  emptyCount: number;
  emptyCountOccupation: number;
  lowerTierFullCount: number;
  upperTierFullCount: number;
  fullCountOccupation: number;
  isUpperTierAvailable: boolean;
}

export class CarTypeContainerTypeEntity {
  readonly id: string;
  containerTypeId: string;
  emptyCount: number;
  emptyCountOccupation: number;
  lowerTierFullCount: number;
  upperTierFullCount: number;
  fullCountOccupation: number;
  isUpperTierAvailable: boolean;

  constructor(
    id: string,
    containerTypeId: string,
    emptyCount: number,
    emptyCountOccupation: number,
    lowerTierFullCount: number,
    upperTierFullCount: number,
    fullCountOccupation: number,
    isUpperTierAvailable: boolean
  ) {
    this.id = id;
    this.containerTypeId = containerTypeId;
    this.emptyCount = emptyCount;
    this.emptyCountOccupation = emptyCountOccupation;
    this.lowerTierFullCount = lowerTierFullCount;
    this.upperTierFullCount = upperTierFullCount;
    this.fullCountOccupation = fullCountOccupation;
    this.isUpperTierAvailable = isUpperTierAvailable;
  }
}
