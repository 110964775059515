
import Vue, { PropType } from 'vue';
import { Ripple } from 'vuetify/lib';
import { InputMessage } from 'vuetify';
import _ from 'lodash';
import Sortable, { SortableEvent } from 'sortablejs';
import RVAutocomplete from './RVAutoComplete.vue';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import {
  IRSearchablePulldownEntity,
  IRSearchablePulldownItem,
} from '~/components/common/r-searchable-pulldown/rSearchablePulldownTypes';
import { CssClasses, ValidationRule } from '~/framework/typeAliases';
import { UndefinedPersistentId } from '~/framework/constants';

type DataType = {
  viewModel: RSearchablePulldown;
};

enum EventTypes {
  RegisterButtonClicked = 'click:register-button',
  Input = 'input',
  Change = 'change',
  Mount = 'mount',
  Unmount = 'unmount',
}

class RSearchablePulldownItem implements IRSearchablePulldownItem {
  private readonly entity: IRSearchablePulldownEntity;
  private _initialRuby?: string;
  private _isFirstAtRuby: boolean = false;
  private _isLastAtRuby: boolean = false;
  private _isSelected: boolean = false;

  get id(): string {
    return this.entity.id;
  }

  get persistentId(): string | undefined {
    return this.entity.persistentId;
  }

  get initialRuby(): string | undefined {
    if (this.nameRuby) {
      return this.katakanize(this.nameRuby[0]);
    } else {
      return undefined;
    }
  }

  get hasRuby(): boolean {
    return this.nameRuby !== undefined && this.nameRuby !== '';
  }

  set initialRuby(value: string | undefined) {
    this._initialRuby = value;
  }

  get isFirstAtRuby(): boolean {
    return this._isFirstAtRuby;
  }

  set isFirstAtRuby(value: boolean) {
    this._isFirstAtRuby = value;
  }

  get isLastAtRuby(): boolean {
    return this._isLastAtRuby;
  }

  set isLastAtRuby(value: boolean) {
    this._isLastAtRuby = value;
  }

  get name(): string {
    return this.entity.name;
  }

  get nameRuby(): string | undefined {
    return this.entity.nameRuby;
  }

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }

  get isDeleted(): boolean {
    // status があり Deleted なものは選択できない状態にする
    return this.entity.status === SoftDeleteStatus.Deleted;
  }

  get description(): string | undefined {
    return this.entity.description;
  }

  set description(value: string | undefined) {
    this.entity.description = value;
  }

  constructor(entity: IRSearchablePulldownEntity) {
    this.entity = entity;
  }

  private katakanize(hiragana: string): string {
    return hiragana.replace(/[ぁ-ん]/g, (s) => String.fromCharCode(s.charCodeAt(0) + 0x60));
  }
}

/**
 * 個別のアイテムでは知り得ない情報について担保奴
 * 例えばルビの先頭にあるアイテムなのかどうか等
 */
class RSearchablePulldown {
  private readonly entities: IRSearchablePulldownEntity[];
  private _items: IRSearchablePulldownItem[];
  private shownItems: IRSearchablePulldownItem[];

  get items() {
    return this._items;
  }

  set items(value: IRSearchablePulldownItem[]) {
    this._items = value;
  }

  constructor(entities: IRSearchablePulldownEntity[]) {
    this.entities = entities;
    this._items = [];
    for (const entity of this.entities) {
      this._items.push(new RSearchablePulldownItem(entity));
    }
    this._items = this._items.sort(this.compare);
    this.shownItems = this._items;
  }

  onFilteredItemsChanged(items: IRSearchablePulldownItem[]): void {
    this.shownItems = items;
    this.updateInitialRuby();
  }

  filterDeletedItems(value: any, itemValue: keyof IRSearchablePulldownItem): void {
    // 論理削除されているものはまた選択する意味はないので isDeleted でかつ選択状態にないアイテムは除外する
    // ここで items を filter するともう復活する事はできないのだが、削除されたものは復活できなくてよいので大丈夫
    if (Array.isArray(value)) {
      this.items = this.items.filter((item) => {
        return !(item.isDeleted && value.every((valueItem) => valueItem !== item[itemValue]));
      });
    } else {
      this.items = this.items.filter((item) => !(item.isDeleted && value !== item[itemValue]));
    }
  }

  private compare(a: IRSearchablePulldownItem, b: IRSearchablePulldownItem): number {
    const isNameRubyAEmpty = a.nameRuby === undefined || a.nameRuby === '';
    const isNameRubyBEmpty = b.nameRuby === undefined || b.nameRuby === '';
    if (isNameRubyAEmpty && isNameRubyBEmpty) return 0;
    if (isNameRubyAEmpty) return 1;
    if (isNameRubyBEmpty) return -1;
    const nameRuby = a.nameRuby!.localeCompare(b.nameRuby!);
    if (nameRuby !== 0) return nameRuby;
    const isIdAEmpty = a.persistentId === undefined || a.persistentId === UndefinedPersistentId;
    const isIdBEmpty = a.persistentId === undefined || b.persistentId === UndefinedPersistentId;
    if (isIdAEmpty && isIdBEmpty) return 0;
    if (isIdAEmpty) return 1;
    if (isIdBEmpty) return -1;
    return Number.parseInt(a.persistentId!) - Number.parseInt(b.persistentId!);
  }

  private updateInitialRuby(): void {
    for (const item of this.items) {
      item.isFirstAtRuby = false;
      item.isLastAtRuby = false;
    }
    let lastInitial: string | undefined;
    let lastItem: IRSearchablePulldownItem | undefined;
    for (const item of this.shownItems) {
      if (item.initialRuby && item.initialRuby !== lastInitial) {
        item.isFirstAtRuby = true;
        lastInitial = item.initialRuby;
        if (lastItem) lastItem.isLastAtRuby = true;
      }
      lastItem = item;
    }
    if (lastItem) lastItem.isLastAtRuby = true;
  }
}

/**
 * 内部的に別オブジェクトに詰め替えているので return-object は実質的に動作しないためサポート外とした
 */
export default Vue.extend({
  name: 'RSearchablePulldown',
  components: {
    RVAutocomplete,
  },
  directives: { Ripple },
  model: {
    event: EventTypes.Change,
    prop: 'value',
  },
  props: {
    entityName: {
      type: String as PropType<string>,
      required: true,
    },
    entities: {
      type: Array as PropType<IRSearchablePulldownEntity[]>,
      required: true,
      default: () => [],
    },
    enableRegisterButton: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    hideLabel: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    persistentPlaceholder: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    value: {
      type: undefined as any as PropType<any>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    rules: {
      type: Array as PropType<Array<ValidationRule>>,
      required: false,
      default() {
        return [];
      },
    },
    itemText: {
      type: String as PropType<string>,
      required: false,
      default: 'name',
    },
    itemValue: {
      type: String as PropType<string>,
      required: false,
      default: 'id',
    },
    hideDetails: {
      type: undefined as any as PropType<String | boolean>,
      required: false,
      default: false,
    },
    allowSort: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    hint: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    persistentHint: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    noDataText: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    autofocus: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: undefined,
    },
    messages: {
      type: [String, Array] as PropType<InputMessage>,
      default: () => [],
    },
    errorMessages: {
      type: [String, Array] as PropType<InputMessage>,
      default: () => [],
    },
    errorCount: {
      type: [Number, String],
      default: 1,
    },
  },
  data(): DataType {
    const viewModel = new RSearchablePulldown(this.entities);
    viewModel.filterDeletedItems(this.value, this.itemValue as any);
    return {
      viewModel,
    };
  },
  computed: {
    hideNoData() {
      return this.noDataText === undefined;
    },
    classes(): CssClasses {
      return {
        'r-v-autocomplete': true,
        'r-v-autocomplete--hide-label': this.hideLabel,
        'r-v-autocomplete--single': !this.multiple,
      };
    },
  },
  watch: {
    entities(value: IRSearchablePulldownEntity[]) {
      const viewModel = new RSearchablePulldown(value);
      viewModel.filterDeletedItems(this.value, this.itemValue as any);
      this.viewModel = viewModel;
    },
  },
  mounted(): void {
    if (this.multiple && this.allowSort) {
      const list = this.$refs.RVAutoComplete as Vue;
      const selectionElement = _.first(list.$el.getElementsByClassName('v-select__selections'));
      if (selectionElement) {
        Sortable.create(selectionElement as HTMLElement, {
          onEnd: this.onManuallySorted,
          draggable: '.v-chip',
          // これをしないとドロップ後にドラッグ開始時のマウス位置にイベントが発生してしまう
          forceFallback: true,
          direction: 'horizontal',
        });
      }
    }
    this.$emit(EventTypes.Mount, this);
  },
  beforeDestroy() {
    this.$emit(EventTypes.Unmount, this);
  },
  methods: {
    onFilteredItemsChanged(value: IRSearchablePulldownItem[]): void {
      this.viewModel.onFilteredItemsChanged(value);
    },
    onRegisterButtonClicked(): void {
      this.$emit(EventTypes.RegisterButtonClicked);
    },
    selectItem(item: IRSearchablePulldownItem): void {
      // TODO これを any にしない方法
      (this.$refs.RVAutoComplete as any).selectItem(item);
    },
    selectAllItems(): void {
      this.viewModel.items.forEach((item) => {
        if (!(this.$refs.RVAutoComplete as any).hasItem(item)) {
          this.selectItem(item);
        }
      });
    },
    unselectAllItems(): void {
      this.viewModel.items.forEach((item) => {
        if ((this.$refs.RVAutoComplete as any).hasItem(item)) {
          this.selectItem(item);
        }
      });
    },
    hasItem(item: IRSearchablePulldownItem): boolean {
      // TODO これを any にしない方法
      return (this.$refs.RVAutoComplete as any).hasItem(item);
    },
    onInput(inputArgs: any): void {
      this.viewModel.filterDeletedItems(inputArgs, this.itemValue as any);
      this.$emit(EventTypes.Input, inputArgs);
    },
    onChange(changeArgs: any): void {
      // NOTE: clear されると null が入ってくるため undefined に変換する
      const sanitizedArgs = changeArgs === null ? undefined : changeArgs;
      this.viewModel.filterDeletedItems(sanitizedArgs, this.itemValue as any);
      this.$emit(EventTypes.Change, sanitizedArgs);
    },
    onManuallySorted(event: SortableEvent): void {
      if (event.oldDraggableIndex !== undefined && event.newDraggableIndex !== undefined) {
        const values = Array.from(this.value);
        const oldIndex = Math.min(Math.max(0, event.oldDraggableIndex), values.length - 1);
        const newIndex = Math.min(Math.max(0, event.newDraggableIndex), values.length - 1);
        const oldItem = values[oldIndex];
        values.splice(oldIndex, 1);
        values.splice(newIndex, 0, oldItem);

        this.$emit(EventTypes.Change, values);
      }
    },
    /**
     * required な rule を指定した状態で value を undefined などにするとバリデーションエラーになる
     * 一方で何かの条件が変化した事で指定されていたものをリセットしたい事がある
     * この様な場合に reset を呼ぶと値がクリアされた状態でバリデーションエラーも出さない様にできる
     */
    reset(): void {
      (this.$refs.RVAutoComplete as any).reset();
    },
  },
});
