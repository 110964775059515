
import Vue, { PropType } from 'vue';
import { format, getDay } from 'date-fns';
import RAcceptanceCheckResultAttendance from './result-details/RAcceptanceCheckResultAttendance.vue';
import RAcceptanceCheckResultOtherOrder from './result-details/RAcceptanceCheckResultOtherOrder.vue';
import RAcceptanceCheckResultInconsistent from './result-details/RAcceptanceCheckResultInconsistent.vue';
import RAcceptanceCheckResultUnstable from './result-details/RAcceptanceCheckResultUnstable.vue';
import { secsToHhMm } from '~/framework/services/date-time/date-time';
import daysOfWeekMap from '~/assets/settings/daysOfWeek.json';
import {
  OrderAcceptanceCheckApplicationService,
  IOrderAcceptanceCheckData,
  orderAcceptanceCheckSymbol,
} from '~/framework/application/schedule/order/order-acceptance-check/orderAcceptanceCheckApplicationService';
import { Maybe, Seconds } from '~/framework/typeAliases';
import { IOrderAcceptanceCheckResult } from '~/framework/application/schedule/order/order-acceptance-check/orderAcceptanceResult';
import {
  AbortedError,
  CancelledByUserError,
  CancelledError,
} from '~/framework/application/schedule/order/order-acceptance-check/errors';
import {
  IInconsistencyError,
  IInfeasibilityOfAttendanceError,
  IInfeasibilityOfOptimizationError,
  IInfeasibilityOfPrecheckError,
  INoAttendancesError,
  IViolatesMultipleCheckConditionError,
} from '~/framework/application/schedule/order/order-acceptance-check/resultErrors';
import { IInconsistency } from '~/pages/schedule/inconsistency';
import { IDriverReason, IInfeasibility } from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import { RinEventNames, AdditionalInfoKeys } from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  progress: number;
  startAt: Maybe<Date>;
  durationToFinish: Maybe<number>;
  status: IStatus;
  errorText: Maybe<string>;
  errorType: 'attendance' | 'otherOrder' | 'inconsistent' | 'unstableResult' | undefined;
  infeasibilities: Maybe<IInfeasibility<IDriverReason>[]>;
  inconsistencies: Maybe<IInconsistency[]>;
  abortText: Maybe<string>;
  orderAppceptanceCheckResult: Maybe<IOrderAcceptanceCheckResult>;
  showDetail: boolean;
  orderAcceptanceCheckApplicationService: OrderAcceptanceCheckApplicationService;
};

interface IStatus {
  isChecking: boolean;
  estimatedFinishAt: Maybe<Date>;
  ordersNum: Maybe<number>;
}

enum EventTypes {
  Complete = 'complete',
  Register = 'register',
  Abort = 'abort',
  Close = 'close',
  CheckSchedule = 'check-schedule',
  CreateSchedule = 'create-schedule',
  EditAttendance = 'edit-attendance',
  RinLink = 'rin-link',
  InputPrecheckInfeasibilities = 'input:precheck-infeasibilities',
}

export default Vue.extend({
  name: 'ROrderAcceptanceCheckItem',
  components: {
    RAcceptanceCheckResultAttendance,
    RAcceptanceCheckResultOtherOrder,
    RAcceptanceCheckResultInconsistent,
    RAcceptanceCheckResultUnstable,
  },
  props: {
    orderAcceptanceCheckData: {
      type: Object as PropType<IOrderAcceptanceCheckData>,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: true,
    },
    detail: {
      type: Boolean,
      required: false,
      default: false,
    },
    // NOTE: 受注登録ボタンを表示するか
    registerButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data(): DataType {
    const orderAcceptanceCheckApplicationService = this.$context.applications.get(orderAcceptanceCheckSymbol);
    return {
      progress: 0,
      startAt: undefined,
      durationToFinish: undefined,
      status: {
        isChecking: false,
        estimatedFinishAt: undefined,
        ordersNum: undefined,
      },
      errorText: undefined,
      errorType: undefined,
      infeasibilities: undefined,
      inconsistencies: undefined,
      abortText: undefined,
      orderAppceptanceCheckResult: undefined,
      showDetail: false,
      orderAcceptanceCheckApplicationService,
    };
  },
  computed: {
    date(): Date {
      if (this.orderAcceptanceCheckData.date === undefined)
        throw new Error(`orderAcceptanceCheckData.date is undefined: ${this.orderAcceptanceCheckData}`);

      return this.orderAcceptanceCheckData.date;
    },
    dateText(): string {
      return format(this.date, 'yyyy/MM/dd') + ' ' + `(${daysOfWeekMap[getDay(this.date)]})`;
    },
    timeText(): string {
      if (this.orderAcceptanceCheckData.collectablePeriodStart === undefined) return '';
      const start = secsToHhMm(this.orderAcceptanceCheckData.collectablePeriodStart);
      const end =
        this.orderAcceptanceCheckData.collectablePeriodEnd !== undefined
          ? secsToHhMm(this.orderAcceptanceCheckData.collectablePeriodEnd)
          : '';

      // NOTE: IOrderAcceptanceCheckData は templateId を持たないので仕方なく名前で比較している
      if (this.orderAcceptanceCheckData.collectablePeriodTemplateName === '時間厳守') {
        return start;
      }

      return `${start}-${end}`;
    },
    isAborted(): boolean {
      return this.abortText !== undefined;
    },
    isAcceptable(): boolean {
      if (this.orderAppceptanceCheckResult === undefined) return false;
      return (
        this.orderAppceptanceCheckResult.isAcceptable.object && this.orderAppceptanceCheckResult.isAcceptable.others
      );
    },
  },
  watch: {
    'status.estimatedFinishAt'(value: Maybe<Date>, oldValue: Maybe<Date>): void {
      if (oldValue === undefined && value !== undefined) {
        // NOTE: estimatedFinishAt が初期値から変わった場合は startAt を設定する
        this.startAt = new Date();
      } else if (oldValue !== undefined && value === undefined) {
        // NOTE: estimatedFinishAt が初期値に戻った場合は startAt をクリアする
        this.startAt = undefined;
      } else if (value !== undefined && oldValue !== undefined && value.getTime() !== oldValue.getTime()) {
        // NOTE: estimatedFinishAt が変更されたら startAt も更新する
        this.startAt = new Date();
      }
      this.update();
    },
    isActive(value: boolean) {
      if (value) {
        this.checkOrderAcceptance();
      }
    },
  },
  async mounted() {
    this.orderAcceptanceCheckApplicationService.addPresenter({
      updateStatus: (isChecking: boolean, estimatedFinishAt: Maybe<Date>, ordersNum: Maybe<number>) => {
        this.status.isChecking = isChecking;
        this.status.estimatedFinishAt = estimatedFinishAt;
        this.status.ordersNum = ordersNum;
      },
    });
    if (this.isActive) {
      await this.checkOrderAcceptance();
    }
  },
  beforeDestroy() {
    if (this.status.isChecking) this.orderAcceptanceCheckApplicationService.cancel();
  },
  methods: {
    async checkOrderAcceptance(): Promise<void> {
      try {
        const orderAppceptanceCheckResult = await this.orderAcceptanceCheckApplicationService.checkOrderAcceptance(
          this.orderAcceptanceCheckData
        );

        if (orderAppceptanceCheckResult.error !== undefined) {
          await orderAppceptanceCheckResult.error.accept(this);
        } else {
          this.$emit(EventTypes.Complete, true);
        }

        await this.$nextTick();
        this.orderAppceptanceCheckResult = orderAppceptanceCheckResult;
      } catch (e) {
        if (e instanceof CancelledByUserError) {
          this.abortText = 'キャンセルしました。';

          return;
        }
        if (e instanceof CancelledError) {
          this.abortText = 'エラーにより瞬間チェックが行えませんでした。';

          return;
        }
        if (e instanceof AbortedError) {
          this.abortText = '想定よりも時間がかかっているため、キャンセルされました。';

          return;
        }

        this.abortText = '想定外のエラーが発生しました。';
        this.$emit(EventTypes.Abort);
      }
    },
    secsToDuration(value: Seconds): string {
      const minutes = Math.floor(value / 60);
      const seconds = Math.floor(value % 60);
      if (minutes === 0) return `${seconds}秒`;
      else return `${minutes}分${seconds}秒`;
    },
    update(): void {
      if (this.status.estimatedFinishAt !== undefined && this.startAt !== undefined) {
        // 開始時から終了までどれくらい時間がかかるか（の予測、msec）
        const durationToFinish = this.status.estimatedFinishAt.getTime() - this.startAt.getTime();
        // 現在時刻からあとどれくらい時間がかかるか（の予測、msec）
        const remainingDuration = Math.max(this.status.estimatedFinishAt.getTime() - new Date().getTime(), 0);
        this.durationToFinish = remainingDuration / 1000;
        this.progress = 100 - (remainingDuration / durationToFinish) * 100;
        // あと0秒になった時に progress が 100 だと indeterminate の表示が微妙なので 0 にしておく
        if (this.durationToFinish !== undefined && this.durationToFinish === 0) this.progress = 0;
      }
    },
    onClickShowDetailButton(): void {
      this.showDetail = !this.showDetail;

      if (this.showDetail) {
        this.$rinGtm.push(RinEventNames.OPEN_ACCEPTANCE_CHECK_RESULT, {
          [AdditionalInfoKeys.ORDER_ID]: this.orderAcceptanceCheckData.id,
        });
      }
    },
    onClickRegister(): void {
      this.$emit(EventTypes.Register);
    },
    onClickClose(): void {
      this.$emit(EventTypes.Close);
    },
    onCheckSchedule(): void {
      this.$emit(EventTypes.CheckSchedule);
    },
    onClickRinLink(path: string): void {
      this.$emit(EventTypes.RinLink, path);
    },
    onClickEditAttendance(): void {
      this.$emit(EventTypes.EditAttendance);
    },
    onCreateSchedule(): void {
      this.$emit(EventTypes.CreateSchedule);
    },
    // NOTE: process... は IErrorProcessor の実装
    // IOrderAcceptanceCheckResult.error.accept() から実行される
    processNoAttendanceError(_error: INoAttendancesError): Promise<void> {
      this.errorText = this.detail
        ? '勤怠が登録されていないため、瞬間チェックできませんでした。該当の日の勤怠を登録して下さい。'
        : '勤怠が登録されていないため、瞬間チェックできませんでした。';
      this.$emit(EventTypes.Complete, false);

      // NOTE: method は async ではないが、 INoAttendancesError.accept が async なので resolve する必要がある
      return Promise.resolve();
    },
    async processViolatesMultipleCheckConditionError(error: IViolatesMultipleCheckConditionError): Promise<void> {
      // NOTE: もし既にチェック中の ID が分かっている場合はその情報を取る
      let secsToFinish;
      if (error.checking !== undefined) {
        const checkingEntity = await this.orderAcceptanceCheckApplicationService.getById(error.checking);
        secsToFinish =
          checkingEntity.estimatedFinishAt === undefined
            ? undefined
            : Math.max((checkingEntity.estimatedFinishAt.getTime() - new Date().getTime()) / 1000, 0);
      }
      const message =
        secsToFinish !== undefined && secsToFinish !== 0
          ? `瞬間チェックを既に実行中のため、実行できませんでした。${this.secsToDuration(
              secsToFinish
            )}ほど待ってもう一度実行して下さい。`
          : `瞬間チェックを既に実行中のため、実行できませんでした。しばらく待ってもう一度実行して下さい。`;

      this.errorText = this.detail ? message : '瞬間チェックを既に実行中のため、実行できませんでした。';
      this.$emit(EventTypes.Abort);
    },
    processInfeasibilityOfPrecheckError(error: IInfeasibilityOfPrecheckError): Promise<void> {
      if (error.hasError.object) {
        this.errorText = this.detail
          ? '受注の設定に問題があり、瞬間チェックができません。受注の問題を解決してください。'
          : '受注の設定に問題があり、瞬間チェックができません。';
        if (this.detail) {
          // このコンポーネントでは表示できないので、上位に任せる
          this.$emit(EventTypes.InputPrecheckInfeasibilities, error.infeasibilities.object);
        }
        this.$emit(EventTypes.Abort);
      } else {
        this.errorText = this.detail
          ? '他の受注に問題があり、この受注の瞬間チェックができません。先にその受注の問題を解決してください。'
          : '他の受注に問題があり、この受注の瞬間チェックができません。';
        this.errorType = 'otherOrder';
        this.$emit(EventTypes.Complete, false);
      }
      // NOTE: method は async ではないが、 IInfeasibilityOfPrecheckError.accept が async なので resolve する必要がある
      return Promise.resolve();
    },
    async processInconsistencyError(error: IInconsistencyError): Promise<void> {
      this.$emit(EventTypes.Complete, false);
      this.inconsistencies = await this.orderAcceptanceCheckApplicationService.getOrderAcceptanceCheckInconsistencies(
        error.inconsistencies
      );
      this.errorText = '配車表の固定に問題があり、瞬間チェックができません。';
      this.errorType = 'inconsistent';
    },
    async processInfeasibilityOfAttendanceError(error: IInfeasibilityOfAttendanceError): Promise<void> {
      if (error.hasError.object) {
        this.infeasibilities = await this.orderAcceptanceCheckApplicationService.getOrderAcceptanceCheckInfeasibilities(
          error.infeasibilities.object,
          this.orderAcceptanceCheckData
        );
        this.errorText = this.detail
          ? 'この受注に対応できる乗務員がいません。乗務時間を変更することで受注できる可能性があります。'
          : 'この受注に対応できる乗務員がいません。';
        this.errorType = 'attendance';
      } else {
        this.errorText = this.detail
          ? '他の受注に問題があり、この受注の瞬間チェックができません。先にその受注の問題を解決してください。'
          : '他の受注に問題があり、この受注の瞬間チェックができません。';
        this.errorType = 'otherOrder';
      }
      this.$emit(EventTypes.Complete, false);
    },
    processInfeasibilityOfOptimizationError(error: IInfeasibilityOfOptimizationError): Promise<void> {
      if (error.hasError.object === false && error.hasError.others) {
        this.errorText = '現在は受注可能ですが、他の受注が影響し受注できなくなる可能性があります。';
        this.errorType = 'unstableResult';
      } else {
        this.errorText = '受注できません。';
      }

      this.$emit(EventTypes.Complete, false);
      // NOTE: method は async ではないが、 InfeasibilityOfOptimizationError.accept が async なので resolve する必要がある
      return Promise.resolve();
    },
  },
});
