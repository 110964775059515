
import Vue, { PropType } from 'vue';
import RDisposalSitesDurationTimeEditor from './RDisposalSitesDurationTimeEditor.vue';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { OrderDisposalSiteAssignmentType, OrderDisposalSitePriority } from '~/framework/domain/typeAliases';
import { PersistentId, ValidationRule } from '~/framework/typeAliases';
import RVInput from '~/components/common/r-v-input/RVInput.vue';
import RDisposalSiteAssignmentTypeInput from '~/components/panels/schedule/r-order-form/RDisposalSiteAssignmentTypeInput.vue';
import RDisposalSiteSequentialMultipleAssignment from '~/components/panels/schedule/r-order-form/RDisposalSiteSequentialMultipleAssignment.vue';
import RDisposalSitePrioritySingleAssignment from '~/components/panels/schedule/r-order-form/RDisposalSitePrioritySingleAssignment.vue';
import RDisposalSiteSequenceDialog from '~/components/panels/schedule/r-order-form/RDisposalSiteSequenceDialog.vue';
import RDisposalSitePriorityDialog from '~/components/panels/schedule/r-order-form/RDisposalSitePriorityDialog.vue';
import DisabledReason from '~/components/panels/schedule/r-order-form/disabledReasonEnum';
import { RVInputInstance } from '~/components/panels/schedule/r-order-form/rDisposalSites';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';

enum EventTypes {
  ChangeAssignmentType = 'change-disposal-site-assignment-type',
  ChangeDisposalSiteIds = 'change-disposal-site-ids',
  UpdateSelectedDisposalSite = 'update-selected-disposal-site',
  UpdateOrderAssignedDisposalSites = 'update-order-assigned-disposal-sites',
  EditDisposalSite = 'edit-disposal-site',
  Mounted = 'mounted',
  BeforeDestroy = 'beforeDestroy',
}

export default Vue.extend({
  name: 'RDisposalSites',
  components: {
    RVInput,
    RDisposalSiteAssignmentTypeInput,
    RDisposalSiteSequentialMultipleAssignment,
    RDisposalSitePrioritySingleAssignment,
    RDisposalSitePriorityDialog,
    RDisposalSiteSequenceDialog,
    RDisposalSitesDurationTimeEditor,
  },
  model: {
    prop: 'selectedDisposalSiteIds',
    event: EventTypes.ChangeDisposalSiteIds,
  },
  props: {
    disposalSiteAssignmentType: {
      type: String as PropType<OrderDisposalSiteAssignmentType>,
      required: true,
    },
    disabledReason: {
      type: Set as PropType<Set<DisabledReason>>,
      required: true,
    },
    selectedDisposalSiteIds: {
      type: Array as PropType<Array<PersistentId>>,
      required: true,
    },
    selectedDisposalSiteEntities: {
      type: Array as PropType<Array<DisposalSiteEntity>>,
      required: true,
    },
    disposalSiteEntities: {
      type: Array as PropType<Array<DisposalSiteEntity>>,
      required: true,
    },
    isIrregularTaskSelected: {
      type: Boolean,
      required: true,
    },
    orderAssignedDisposalSites: {
      type: Array as PropType<Array<IOrderAssignedDisposalSite>>,
      required: true,
    },
    totalDurationOfTasks: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationRules: {
      type: Array as PropType<Array<ValidationRule>>,
      required: false,
      default: () => {
        return [];
      },
    },
    errorMessages: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => {
        return [];
      },
    },
    maxErrorCount: {
      type: Number,
      required: true,
    },
  },
  data(): {
    OrderDisposalSiteAssignmentType: typeof OrderDisposalSiteAssignmentType;
    DisabledReason: typeof DisabledReason;
    isSequentialMultipleDialogActive: boolean;
    isPrioritySingleDialogActive: boolean;
  } {
    return {
      OrderDisposalSiteAssignmentType,
      DisabledReason,
      isSequentialMultipleDialogActive: false,
      isPrioritySingleDialogActive: false,
    };
  },
  mounted() {
    this.$emit(EventTypes.Mounted, this);
  },
  beforeDestroy() {
    this.$emit(EventTypes.BeforeDestroy);
  },
  methods: {
    onChangeSelectedDisposalSiteIds(value: Array<PersistentId>) {
      this.$emit(EventTypes.ChangeDisposalSiteIds, value);
      this.$emit(EventTypes.UpdateSelectedDisposalSite);
    },
    onChangeAssignmentType(value: OrderDisposalSiteAssignmentType) {
      this.$emit(EventTypes.ChangeAssignmentType, value);

      switch (value) {
        case OrderDisposalSiteAssignmentType.SequentialMultiple:
          this.toggleSequentialMultipleEditDialog(true);
          break;

        case OrderDisposalSiteAssignmentType.PrioritySingle:
          this.togglePrioritySingleEditDialog(true);
          break;
      }
    },
    onClickEditDisposalSite(value: DisposalSiteEntity) {
      this.$emit(EventTypes.EditDisposalSite, value);
    },
    onChangeSelectedDisposalSitesWithPriority(
      highPriorityDisposalSiteIds: string[],
      lowPriorityDisposalSiteIds: string[]
    ): void {
      const selectedDisposalSiteIds = [...highPriorityDisposalSiteIds, ...lowPriorityDisposalSiteIds];
      const newOrderAssignedDisposalSites = selectedDisposalSiteIds.map((id) => {
        // NOTE: すでに this.orderAssignedDisposalSites にある場合は既存のデータから priority だけ上書きする
        const orderAssignedDisposalSite = this.orderAssignedDisposalSites.find(
          (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId === id
        );

        if (orderAssignedDisposalSite !== undefined) {
          return {
            ...orderAssignedDisposalSite,
            priority: highPriorityDisposalSiteIds.includes(id)
              ? OrderDisposalSitePriority.High
              : OrderDisposalSitePriority.Low,
          };
        }

        // NOTE: ない場合は disposalSiteEntity からデータを作る
        const disposalSiteEntity = this.disposalSiteEntities.find((entity) => entity.id === id);

        if (disposalSiteEntity === undefined)
          throw new Error(`disposalSiteEntity was not found. disposalSiteId: ${id}`);

        return {
          disposalSiteId: id,
          defaultDurationAtEntrance: disposalSiteEntity.durationAtEntrance,
          durationAtEntrance: disposalSiteEntity.durationAtEntrance,
          priority: highPriorityDisposalSiteIds.includes(id)
            ? OrderDisposalSitePriority.High
            : OrderDisposalSitePriority.Low,
        };
      });
      this.$emit(EventTypes.ChangeDisposalSiteIds, selectedDisposalSiteIds);
      this.$emit(EventTypes.UpdateOrderAssignedDisposalSites, newOrderAssignedDisposalSites);

      this.$nextTick();
      this.$emit(EventTypes.UpdateSelectedDisposalSite);
    },
    onDisposalSiteDurationTimesChange(value: IOrderAssignedDisposalSite[]) {
      this.$emit(EventTypes.UpdateOrderAssignedDisposalSites, value);
    },
    toggleSequentialMultipleEditDialog(value: boolean) {
      this.isSequentialMultipleDialogActive = value;
    },
    togglePrioritySingleEditDialog(value: boolean) {
      this.isPrioritySingleDialogActive = value;
    },
    resetInput() {
      (this.$refs.input as RVInputInstance).reset();
    },
  },
});
