
import Vue from 'vue';
import { Maybe } from '~/framework/typeAliases';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { scheduleSymbol } from '~/framework/application/schedule/schedule/scheduleApplicationService';
import { SnackbarButton } from '~/components/common/r-snackbar/snackbarButton';
import { Path } from '~/framework/constants';
import { formatDateToString } from '~/framework/services/date/date';
import { CreateScheduleEvent } from '~/framework/events/eventManager';
import { getRouteLocationForSearchOrdersPage, SearchOrdersConditions } from '~/pages/schedule/orders/searchOrders';

// components
import RDisposalSiteForm from '~/components/panels/masters/r-disposal-site-form/RDisposalSiteForm.vue';
import RGenerationSiteForm from '~/components/panels/masters/r-generation-site-form/RGenerationSiteForm.vue';
import ROrderForm from '~/components/panels/schedule/r-order-form/ROrderForm.vue';
import RDriverMonthlyAttendancesDialog from '~/components/common/r-driver-monthly-attendances-dialog/RDriverMonthlyAttendancesDialog.vue';
import RDriverAttendanceTemplateForm from '~/components/panels/masters/r-driver-attendance-template-form/RDriverAttendanceTemplateForm.vue';
import RDriverForm from '~/components/panels/masters/r-driver-form/RDriverForm.vue';
import ROfficeSettingForm from '~/components/panels/masters/r-office-setting-form/ROfficeSettingForm.vue';
import RContainerTaskForm from '~/components/panels/masters/r-container-task-form/RContainerTaskForm.vue';
import RClientForm from '~/components/panels/masters/r-client-form/RClientForm.vue';
import RRoutingRegulationForm from '~/components/panels/masters/r-routing-regulation-form/RRoutingRegulationForm.vue';
import RCollectablePeriodTemplateForm from '~/components/panels/masters/r-collectable-period-template-form/RCollectablePeriodTemplateForm.vue';
import RContainerTypeForm from '~/components/panels/masters/r-container-type-form/RContainerTypeForm.vue';
import RTaskTypeForm from '~/components/panels/masters/r-task-type-form/RTaskTypeForm.vue';
import RCarTypeForm from '~/components/panels/masters/r-car-type-form/RCarTypeForm.vue';
import RCarForm from '~/components/panels/masters/r-car-form/RCarForm.vue';
import RPackingPlacementsForm from '~/components/panels/packing-placement/r-packing-placements-form/RPackingPlacementsForm.vue';
import RReservationForm from '~/components/panels/reservation/r-reservation-from/RReservationForm.vue';
import RBaseSiteForm from '~/components/panels/masters/r-base-site-form/RBaseSiteForm.vue';
import RWasteTypeForm from '~/components/panels/masters/r-waste-type-form/RWasteTypeForm.vue';
import RAnnouncements from '~/components/common/r-announcement/RAnnouncements.vue';
import {
  AdditionalInfoKeys,
  FormTargetParams,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';

type DataType = {
  registerOrderPanelOpened: boolean;
  transactionCount: number;
  listenerDisposer: Maybe<() => void>;
  IsReloadNeeded: boolean;
  isNavbarExpanded: boolean;
  navTimerId: number;
};

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    RDisposalSiteForm,
    RGenerationSiteForm,
    ROrderForm,
    RDriverMonthlyAttendancesDialog,
    RDriverAttendanceTemplateForm,
    RDriverForm,
    ROfficeSettingForm,
    RContainerTaskForm,
    RClientForm,
    RRoutingRegulationForm,
    RCollectablePeriodTemplateForm,
    RContainerTypeForm,
    RTaskTypeForm,
    RCarTypeForm,
    RCarForm,
    RPackingPlacementsForm,
    RReservationForm,
    RBaseSiteForm,
    RWasteTypeForm,
    RAnnouncements,
  },
  middleware: ['compatibility', 'login', 'notification'],
  data(): DataType {
    return {
      registerOrderPanelOpened: false,
      transactionCount: 0,
      listenerDisposer: undefined,
      IsReloadNeeded: false,
      isNavbarExpanded: false,
      navTimerId: 0,
    };
  },
  computed: {
    isLoading(): boolean {
      return 1 <= this.transactionCount;
    },
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  mounted() {
    const loadStartEventListenerDisposer = this.$context.events.loadStartEvent.on(this.onLoadStart);
    const loadFinishEventListenerDisposer = this.$context.events.loadFinishEvent.on(this.onLoadFinish);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.DefaultLayout
    );
    const createScheduleEventDisposer = this.$context.events.createScheduleEvent.on(this.onCreateSchedule);
    const searchOrdersEventDisposer = this.$context.events.searchOrdersEvent.on(this.openSearchOrdersPage);

    this.listenerDisposer = () => {
      loadStartEventListenerDisposer.dispose();
      loadFinishEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
      createScheduleEventDisposer.dispose();
      searchOrdersEventDisposer.dispose();
    };
  },
  methods: {
    hoverNav() {
      this.navTimerId = window.setTimeout(() => {
        this.isNavbarExpanded = true;
      }, 800);
    },
    outNav() {
      this.isNavbarExpanded = false;
      window.clearTimeout(this.navTimerId);
    },
    async onClickRegisterOrder(): Promise<void> {
      this.$rinGtm.push(RinEventNames.OPEN_FORM, { [AdditionalInfoKeys.TARGET]: FormTargetParams.ORDER });
      await this.$context.panels.orderFormPanel.open(undefined);
    },
    async onClickRegisterGenerationSite(): Promise<void> {
      await this.$context.panels.generationSiteFormPanel.open(undefined);
    },
    onLoadStart(): void {
      this.transactionCount++;
    },
    onLoadFinish(): void {
      this.transactionCount--;
    },
    async onCreateSchedule(event: CreateScheduleEvent): Promise<void> {
      const scheduleApplicationService = this.$context.applications.get(scheduleSymbol);
      const schedule = await scheduleApplicationService.createScheduleOfDate(event.date, event.orderGroupId);
      if (schedule === undefined) {
        const buttons = [
          new SnackbarButton('勤怠登録', {
            path: Path.masters.driver,
            hash: 'driver-attendances',
            query: { date: formatDateToString(event.date) },
          }),
        ];
        this.$context.snackbar.error('乗務員の勤怠が登録されておらず、\n配車表を作成できません。', { buttons });
      }
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (e.isCodeWithoutModifiers(KeyboardEventCode.KeyC)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.C);
        Vue.nextTick(this.onClickRegisterOrder);
        context.stop();
      } else if (e.isCodeWithoutModifiers(KeyboardEventCode.KeyG)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.G);
        Vue.nextTick(this.onClickRegisterGenerationSite);
        context.stop();
      } else if (e.isCodeWithoutModifiers(KeyboardEventCode.KeyM)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.M);
        Vue.nextTick(() => this.$router.push(Path.masters.index));
        context.stop();
      } else if (e.isCodeWithoutModifiers(KeyboardEventCode.KeyS)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.S);
        Vue.nextTick(() => this.$router.push(Path.schedule.index));
        context.stop();
      } else if (e.isCodeWithoutModifiers(KeyboardEventCode.KeyA)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.A);
        Vue.nextTick(() => this.$router.push({ path: Path.masters.driver, hash: 'driver-attendances' }));
        context.stop();
      }
    },
    openSearchOrdersPage(conditions: Maybe<SearchOrdersConditions>): void {
      const location = getRouteLocationForSearchOrdersPage(conditions);
      this.$router.push(location);
    },
  },
});
