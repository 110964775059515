import { MapperBase } from '~/framework/core/mapper';
import { CarTypeEntity, CarTypeEntityData } from '~/framework/domain/masters/car-type/carTypeEntity';

export class CarTypeMapper extends MapperBase<CarTypeEntityData, CarTypeEntity> {
  protected instantiateWithData(data: CarTypeEntityData): CarTypeEntity {
    return new CarTypeEntity(
      data.id,
      data.name,
      data.orderGroupId,
      data.drivingSpeedCoefficient,
      data.durationOfBoarding,
      data.durationOfAlighting
    );
  }

  protected updateWithData(data: CarTypeEntityData, entity: CarTypeEntity): void {
    entity.name = data.name;
    entity.orderGroupId = data.orderGroupId;
    entity.drivingSpeedCoefficient = data.drivingSpeedCoefficient;
    entity.durationOfBoarding = data.durationOfBoarding;
    entity.durationOfAlighting = data.durationOfAlighting;
  }
}
