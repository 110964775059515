import type { ApolloClientMethods } from 'vue-apollo/types/vue-apollo';
import type { NuxtAxiosInstance } from '@nuxtjs/axios';
import {
  LocalCurrentSessionApi,
  LocalSignInApi,
  LocalSignOutApi,
} from '../tests/framework/port.adapter/server-api/authentication';
import { reservation$cancelledReservationsByDateSymbol } from './framework/server-api/reservation/cancelledReservationsByDate';
import { reservation$pushBackReservationFromCancelledToWaitingReviewSymbol } from './framework/server-api/reservation/pushBackReservationFromCancelledToWaitingReview';
import { reservation$cancelReservationSymbol } from './framework/server-api/reservation/cancelReservation';
import { schedule$updatePublishingStateSymbol } from './framework/server-api/schedule/schedule/updatePublishingState';
import { order$routableOrders } from './framework/server-api/schedule/order/routableOrders';
import { order$latestOrders } from '~/framework/server-api/schedule/order/latestOrders';
import {
  car$createSymbol,
  car$updateSymbol,
  car$getAllSymbol,
  car$getByIdsSymbol,
} from '~/framework/server-api/masters/car';

import {
  GetByDateRangeApi as CarAttendance$GetByDateRangeApi,
  UpdateApi as CarAttendance$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/carAttendance';
import { orderDefault$orderDefaultByGenerationSiteId } from '~/framework/server-api/schedule/order/order-default/orderDefaultByGenerationSiteId';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { authentication$currentSessionSymbol } from '~/framework/server-api/authentication/session';
import {
  CurrentSessionApi as Session$CurrentSessionApi,
  SignInApi as Authentication$SignInApi,
  SignOutApi as Authentication$SignOutApi,
} from '~/framework/port.adapter/server-api/authentication';
import { GetVersionApi as Version$GetVersionApi } from '~/framework/port.adapter/server-api/version';
import { userSetting$getSymbol } from '~/framework/server-api/user-setting/get';
import {
  GetApi as UserSetting$GetApi,
  UpdateApi as UserSetting$UpdateApi,
} from '~/framework/port.adapter/server-api/userSetting';
import { userSetting$updateSymbol } from '~/framework/server-api/user-setting/update';
import {
  collectablePeriodTemplate$getAllSymbol as collectablePeriodTemplate$getAllApiType,
  collectablePeriodTemplate$updateSymbol as collectablePeriodTemplate$updateApiType,
  collectablePeriodTemplate$createSymbol as collectablePeriodTemplate$createApiType,
} from '~/framework/server-api/masters/collectablePeriodTemplate';
import {
  CreateApi as CollectablePeriodTemplate$CreateApi,
  GetAllApi as CollectablePeriodTemplate$GetAllApi,
  UpdateApi as CollectablePeriodTemplate$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/collectablePeriodTemplate';

import {
  wasteType$getAllSymbol,
  wasteType$getAllDisplayedSymbol,
  wasteType$getByKeywordsSymbol,
  wasteType$getAvailableCodeSymbol,
  wasteType$createSymbol,
  wasteType$updateSymbol,
} from '~/framework/server-api/masters/wasteType';

import {
  GetAllApi as WasteType$GetAllApi,
  GetAllDisplayedApi as WasteType$GetAllDisplayedApi,
  GetByKeywordsApi as WasteType$GetByKeywordsApi,
  GetAvailableCodeApi as WasteType$GetAvailableCodeApi,
  CreateApi as WasteType$CreateApi,
  UpdateApi as WasteType$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/wasteType';
import { orderGroup$getAllSymbol as orderGroup$getAllType } from '~/framework/server-api/masters/orderGroup';
import { GetAllApi as OrderGroup$GetAllApi } from '~/framework/port.adapter/server-api/masters/orderGroup';
import {
  carType$getAllSymbol as carType$getAllType,
  carType$getByIdsSymbol,
  carType$createSymbol,
  carType$updateSymbol,
} from '~/framework/server-api/masters/carType';
import {
  GetAllApi as CarType$GetAllApi,
  GetByIdsApi as CarType$GetByIdsApi,
  CreateApi as CarType$CreateApi,
  UpdateApi as CarType$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/carType';

import {
  driverAttendance$getByDateRangeSymbol,
  driverAttendance$getByDateRangeOfAllDriversSymbol,
  driverAttendance$createSymbol,
  driverAttendance$updateSymbol,
  driverAttendance$deleteSymbol,
  driverAttendance$createDefaultSymbol,
} from '~/framework/server-api/masters/driverAttendance';
import {
  CreateApi as DriverAttendance$CreateApi,
  CreateDefault as DriverAttendance$CreateDefaultApi,
  DeleteApi as DriverAttendance$DeleteApi,
  GetByDateRangeApi as DriverAttendance$GetByDateRangeApi,
  GetByDateRangeOfAllDriversApi as DriverAttendance$GetByDateRangeOfAllDriversApi,
  UpdateApi as DriverAttendance$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/driverAttendance';

import {
  driverAttendanceTemplate$getAllSymbol,
  driverAttendanceTemplate$getByIdsSymbol,
  driverAttendanceTemplate$createSymbol,
  driverAttendanceTemplate$updateSymbol,
} from '~/framework/server-api/masters/driverAttendanceTemplate';
import {
  CreateApi as DriverAttendanceTemplate$CreateApi,
  GetAllApi as DriverAttendanceTemplate$GetAllApi,
  GetByIdsApi as DriverAttendanceTemplate$GetByIdsApi,
  UpdateApi as DriverAttendanceTemplate$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/driverAttendanceTemplate';

import {
  GetAllApi as Car$GetAllApi,
  GetByIdsApi as Car$GetByIdsApi,
  CreateApi as Car$CreateApi,
  UpdateApi as Car$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/car';

import {
  driver$getAllSymbol,
  driver$getByIdsSymbol,
  driver$createSymbol,
  driver$updateSymbol,
} from '~/framework/server-api/masters/driver';
import {
  CreateApi as Driver$CreateApi,
  GetAllApi as Driver$GetAllApi,
  GetByIdsApi as Driver$GetByIdsApi,
  UpdateApi as Driver$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/driver';

import {
  attendance$getByIdsSymbol,
  attendance$getByDateRangeSymbol,
  attendance$createSymbol,
  attendance$getByDateSymbol,
} from '~/framework/server-api/masters/attendance';
import {
  CreateApi as Attendance$CreateApi,
  GetByDateApi,
  GetByDateRangeApi as Attendance$GetByDateRangeApi,
  GetByIdsApi as Attendance$GetByIdsApi,
} from '~/framework/port.adapter/server-api/masters/attendance';

import {
  baseSite$getAllSymbol,
  baseSite$createSymbol,
  baseSite$updateSymbol,
} from '~/framework/server-api/masters/baseSite';

import {
  GetAllApi as BaseSite$GetAllApi,
  CreateApi as BaseSite$CreateApi,
  UpdateApi as BaseSite$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/baseSite';
import {
  disposalSite$getAllSymbol,
  disposalSite$createSymbol,
  disposalSite$updateSymbol,
} from '~/framework/server-api/masters/disposalSite';
import {
  CreateApi as DisposalSite$CreateApi,
  GetAllApi as DisposalSite$GetAllApi,
  UpdateApi as DisposalSite$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/disposalSite';

import {
  client$getAllSymbol,
  client$getByIdsSymbol,
  client$getByKeywordsSymbol,
  client$createSymbol,
  client$updateSymbol,
  client$getWithGenerationSiteNumByIdsSymbol,
} from '~/framework/server-api/masters/client';
import {
  CreateApi as Client$CreateApi,
  GetAllApi as Client$GetAllApi,
  GetByIdsApi as Client$GetByIdsApi,
  GetByKeywordsApi as Client$GetByKeywordsApi,
  GetWithGenerationSiteNumByIdsApi as Client$GetWithGenerationSiteNumByIdsApi,
  UpdateApi as Client$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/client';

import {
  containerType$getAllSymbol,
  containerType$getByIdsSymbol,
  containerType$createSymbol,
  containerType$updateSymbol,
} from '~/framework/server-api/masters/containerType';
import {
  containerTypeTaskType$getAllSymbol,
  containerTypeTaskType$updateSymbol,
} from '~/framework/server-api/masters/containerTypeTaskType';
import {
  CreateApi as ContainerType$CreateApi,
  GetAllApi as ContainerType$GetAllApi,
  GetByIdsApi as ContainerType$GetByIdsApi,
  UpdateApi as ContainerType$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/containerType';

import {
  GetAll as ContainerTypeTaskType$GetAll,
  Update as ContainerTypeTaskType$Update,
} from '~/framework/port.adapter/server-api/masters/containerTypeTaskType';
import { GetAllApi as PackingStyle$GetAll } from '~/framework/port.adapter/server-api/masters/packingStyle';
import {
  GetAllApi as PackingStyleTaskTypeDefault$GetAll,
  UpdateApi as PackingStyleTaskTypeDefault$Update,
} from '~/framework/port.adapter/server-api/masters/packingStyleTaskTypeDefault';

import { officeSetting$getSymbol, officeSetting$updateSymbol } from '~/framework/server-api/masters/officeSetting';
import {
  GetApi as OfficeSetting$GetApi,
  UpdateApi as OfficeSetting$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/officeSetting';

import {
  generationSite$getAllSymbol,
  generationSite$getByIdsSymbol,
  generationSite$getByKeywordsSymbol,
  generationSite$createSymbol,
  generationSite$updateSymbol,
} from '~/framework/server-api/masters/generationSite';
import {
  CreateApi as GenerationSite$CreateApi,
  GetAllApi as GenerationSite$GetAllApi,
  GetByIdsApi as GenerationSite$GetByIdsApi,
  GetByKeywordsApi as GenerationSite$GetByKeywords,
  UpdateApi as GenerationSite$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/generationSite';

import { order$ordersByDateSymbol } from '~/framework/server-api/schedule/order/ordersByDate';
import {
  ActivateOrderApi as Order$ActivateOrderApi,
  CancelOrderApi as Order$CancelOrderApi,
  CreateOrdersApi as Order$CreateOrdersApi,
  GenerationSiteTasksByIdsApi as Order$GenerationSiteTasksByIdsApi,
  IrregularTaskByIdsApi as Order$IrregularTaskByIdsApi,
  OrdersByDateApi as Order$OrdersByDateApi,
  OrdersByIdsApi as Order$OrdersByIdsApi,
  UpdateOrdersApi as Order$UpdateOrdersApi,
  ValidateOrdersApi as Order$ValidateOrdersApi,
  OrderDefaultByGenerationSiteIdApi as Order$OrderDefaultByGenerationSiteIdApi,
  LatestOrdersApi as Order$LatestOrdersApi,
  RoutableOrdersApi as Order$RoutableOrdersApi,
  PostponeOrdersApi as Order$PostponeOrdersApi,
} from '~/framework/port.adapter/server-api/schedule/order';
import { order$ordersByIdsSymbol } from '~/framework/server-api/schedule/order/ordersByIds';
import { order$createOrdersSymbol } from '~/framework/server-api/schedule/order/createOrders';
import { order$updateOrdersSymbol } from '~/framework/server-api/schedule/order/updateOrders';
import { order$cancelOrderSymbol } from '~/framework/server-api/schedule/order/cancelOrder';
import { order$activateOrderSymbol } from '~/framework/server-api/schedule/order/activateOrder';
import { order$postponeOrdersSymbol } from '~/framework/server-api/schedule/order/postponeOrders';
import { generationSiteTask$generationSiteTasksByIds } from '~/framework/server-api/schedule/order/generation-site-task/generationSiteTasksByIds';
import { irregularTask$irregularTasksByIdsSymbol } from '~/framework/server-api/schedule/order/irregular-task/irregularTasksByIds';
import { order$validateOrdersSymbol } from '~/framework/server-api/schedule/order/validateOrders';
import { orderAcceptanceCheck$createSymbol } from '~/framework/server-api/schedule/order/order-acceptance-check/create';
import {
  CreateApi as OrderAcceptanceCheck$CreateApi,
  GetByIdsApi as OrderAcceptanceCheck$GetByIdsApi,
} from '~/framework/port.adapter/server-api/schedule/orderAcceptanceCheck';
import { orderAcceptanceCheck$getByIdsSymbol } from '~/framework/server-api/schedule/order/order-acceptance-check/getByIds';
import { schedule$getLatestSymbol } from '~/framework/server-api/schedule/schedule/getLatest';
import {
  CreateApi as Schedule$CreateApi,
  GetByIdsApi as Schedule$GetByIdsApi,
  GetLatestApi as Schedule$GetLatestApi,
  UpdatePublishingStateApi as Schedule$UpdatePublishingStateApi,
  GetByScheduleRequestApi as Schedule$GetByScheduleRequestApi,
  RevertScheduleApi as Schedule$RevertScheduleApi,
} from '~/framework/port.adapter/server-api/schedule/schedule';
import { schedule$getByIdsSymbol } from '~/framework/server-api/schedule/schedule/getByIds';
import { schedule$createSymbol } from '~/framework/server-api/schedule/schedule/create';
import { schedule$getByScheduleRequestSymbol } from '~/framework/server-api/schedule/schedule/getByScheduleRequest';
import { schedule$revertScheduleSymbol } from '~/framework/server-api/schedule/schedule/revertSchedule';
import { holidayRule$getSymbol, holidayRule$updateSymbol } from '~/framework/server-api/masters/holidayRule';
import {
  GetApi as HolidayRule$GetApi,
  UpdateApi as HolidayRule$UpdateApi,
} from '~/framework/port.adapter/server-api/masters/holidayRule';

import { authentication$signInSymbol } from '~/framework/server-api/authentication/signIn';
import { authentication$signOutSymbol } from '~/framework/server-api/authentication/signOut';
import { version$getVersionSymbol } from '~/framework/server-api/version/getVersion';

import {
  carAttendance$getByDateRangeSymbol,
  carAttendance$updateSymbol,
} from '~/framework/server-api/masters/carAttendance';

import { packingStyle$getAllSymbol } from '~/framework/server-api/masters/packingStyle';
import {
  packingStyleTaskTypeDefault$getAllSymbol,
  packingStyleTaskTypeDefault$updateSymbol,
} from '~/framework/server-api/masters/packingStyleTaskTypeDefault';

import { packingPlacement$getByKeywordsSymbol } from '~/framework/server-api/packing-placement/getByKeywords';
import {
  GetByKeywordsApi as PackingPlacement$GetByKeywordsApi,
  UpdateApi as PackingPlacement$UpdateApi,
} from '~/framework/port.adapter/server-api/packing-placement/packingPlacement';
import { packingPlacement$updateSymbol } from '~/framework/server-api/packing-placement/update';
import { reservation$reservationsByDateSymbol } from '~/framework/server-api/reservation/reservationsByDate';
import { reservation$acceptReservationSymbol } from '~/framework/server-api/reservation/acceptReservation';
import { reservation$waitingReviewReservationDatesByDateRangeSymbol } from '~/framework/server-api/reservation/waitingReviewReservationByDateRange';
import {
  AcceptReservationApi,
  CancelledReservationsByDateApi,
  CancelReservationApi,
  ReservationsByDateApi,
  PushBackReservationFromCancelledToWaitingReviewApi,
  WaitingReviewReservationDatesByDateRangeApi,
  ReservationDeadlineByDateApi,
  UpdateReservationDeadlineApi,
} from '~/framework/port.adapter/server-api/reservation/reservation';
import { jwnetWasteMaster$getAllSymbol } from '~/framework/server-api/masters/jwnetWasteMaster';
import { GetAllApi as JwnetWasteMaster$GetAllApi } from '~/framework/port.adapter/server-api/masters/jwnetWasteMaster';
import {
  baseTaskType$getAllSymbol,
  taskType$getAllSymbol,
  taskType$updateSymbol,
  taskType$createSymbol,
} from '~/framework/server-api/masters/taskType';
import {
  routingRegulation$getByIdsSymbol,
  routingRegulation$getAllSymbol,
  routingRegulation$createSymbol,
  routingRegulation$updateSymbol,
  routingRegulation$deleteSymbol,
} from '~/framework/server-api/masters/routingRegulation';
import { RoutingRegulationApi } from '~/framework/port.adapter/server-api/masters/routingRegulation';

import { TaskTypeApi } from '~/framework/port.adapter/server-api/masters/taskType';
import { BaseTaskTypeApi } from '~/framework/port.adapter/server-api/masters/baseTaskType';
import { AllTransportationClientsApi } from '~/framework/port.adapter/server-api/masters/erpClient';
import { erpClient$getAllTransportationClientsSymbol } from '~/framework/server-api/masters/erpClient';
import { erpOrder$createErpOrderSymbol } from '~/framework/server-api/schedule/order/createErpOrder';
import { erpOrder$erpOrderByOrderIdSymbol } from '~/framework/server-api/schedule/order/erpOrderByOrder';
import { erpOrder$deleteErpOrderByOrderIdSymbol } from '~/framework/server-api/schedule/order/deleteErpOrderByOrderId';
import {
  CreateErpOrderApi,
  GetErpOrderByOrderIdApi,
  UpdateErpOrderApi,
  DeleteErpOrderByOrderIdApi,
} from '~/framework/port.adapter/server-api/schedule/erpOrder';
import { erpOrder$updateErpOrderSymbol } from '~/framework/server-api/schedule/order/updateErpOrder';
import { reservation$reservationDeadlineByDateSymbol } from '~/framework/server-api/reservation/reservationDeadlineByDate';
import { reservation$updateReservationDeadlineSymbol } from '~/framework/server-api/reservation/updateReservationDeadline';
import { announcement$unreadAnnouncementsSymbol } from '~/framework/server-api/announcement/unreadAnnouncements';
import { UnreadAnnouncementsApi } from '~/framework/port.adapter/server-api/announcement';

export const injectProductionDependencies = (
  apis: ServerApiManager,
  apollo: ApolloClientMethods,
  axios: NuxtAxiosInstance
) => {
  // region GraphQL
  // authentication
  apis.add(authentication$currentSessionSymbol, () => new Session$CurrentSessionApi(apollo));
  // userSetting
  apis.add(userSetting$getSymbol, () => new UserSetting$GetApi(apollo));
  apis.add(userSetting$updateSymbol, () => new UserSetting$UpdateApi(apollo));
  // announcement
  apis.add(announcement$unreadAnnouncementsSymbol, () => new UnreadAnnouncementsApi(apollo));
  // collectablePeriodTemplate
  apis.add(collectablePeriodTemplate$getAllApiType, () => new CollectablePeriodTemplate$GetAllApi(apollo));
  apis.add(collectablePeriodTemplate$updateApiType, () => new CollectablePeriodTemplate$UpdateApi(apollo));
  apis.add(collectablePeriodTemplate$createApiType, () => new CollectablePeriodTemplate$CreateApi(apollo));
  // wasteType
  apis.add(wasteType$getAllSymbol, () => new WasteType$GetAllApi(apollo));
  apis.add(wasteType$getAllDisplayedSymbol, () => new WasteType$GetAllDisplayedApi(apollo));
  apis.add(wasteType$getByKeywordsSymbol, () => new WasteType$GetByKeywordsApi(apollo));
  apis.add(wasteType$getAvailableCodeSymbol, () => new WasteType$GetAvailableCodeApi(apollo));
  apis.add(wasteType$createSymbol, () => new WasteType$CreateApi(apollo));
  apis.add(wasteType$updateSymbol, () => new WasteType$UpdateApi(apollo));
  // orderGroup
  apis.add(orderGroup$getAllType, () => new OrderGroup$GetAllApi(apollo));
  // carType
  apis.add(carType$getAllType, () => new CarType$GetAllApi(apollo));
  apis.add(carType$getByIdsSymbol, () => new CarType$GetByIdsApi(apollo));
  apis.add(carType$createSymbol, () => new CarType$CreateApi(apollo));
  apis.add(carType$updateSymbol, () => new CarType$UpdateApi(apollo));
  // driverAttendance
  apis.add(driverAttendance$getByDateRangeSymbol, () => new DriverAttendance$GetByDateRangeApi(apollo));
  apis.add(
    driverAttendance$getByDateRangeOfAllDriversSymbol,
    () => new DriverAttendance$GetByDateRangeOfAllDriversApi(apollo)
  );
  apis.add(driverAttendance$createSymbol, () => new DriverAttendance$CreateApi(apollo));
  apis.add(driverAttendance$updateSymbol, () => new DriverAttendance$UpdateApi(apollo));
  apis.add(driverAttendance$deleteSymbol, () => new DriverAttendance$DeleteApi(apollo));
  apis.add(driverAttendance$createDefaultSymbol, () => new DriverAttendance$CreateDefaultApi(apollo));
  // driverAttendanceTemplate
  apis.add(driverAttendanceTemplate$getAllSymbol, () => new DriverAttendanceTemplate$GetAllApi(apollo));
  apis.add(driverAttendanceTemplate$getByIdsSymbol, () => new DriverAttendanceTemplate$GetByIdsApi(apollo));
  apis.add(driverAttendanceTemplate$createSymbol, () => new DriverAttendanceTemplate$CreateApi(apollo));
  apis.add(driverAttendanceTemplate$updateSymbol, () => new DriverAttendanceTemplate$UpdateApi(apollo));
  // car
  apis.add(car$getAllSymbol, () => new Car$GetAllApi(apollo));
  apis.add(car$getByIdsSymbol, () => new Car$GetByIdsApi(apollo));
  apis.add(car$createSymbol, () => new Car$CreateApi(apollo));
  apis.add(car$updateSymbol, () => new Car$UpdateApi(apollo));
  // driver
  apis.add(driver$getAllSymbol, () => new Driver$GetAllApi(apollo));
  apis.add(driver$getByIdsSymbol, () => new Driver$GetByIdsApi(apollo));
  apis.add(driver$createSymbol, () => new Driver$CreateApi(apollo));
  apis.add(driver$updateSymbol, () => new Driver$UpdateApi(apollo));
  // attendance
  apis.add(attendance$getByIdsSymbol, () => new Attendance$GetByIdsApi(apollo));
  apis.add(attendance$getByDateRangeSymbol, () => new Attendance$GetByDateRangeApi(apollo));
  apis.add(attendance$createSymbol, () => new Attendance$CreateApi(apollo));
  apis.add(attendance$getByDateSymbol, () => new GetByDateApi(apollo));
  // baseSite
  apis.add(baseSite$getAllSymbol, () => new BaseSite$GetAllApi(apollo));
  apis.add(baseSite$createSymbol, () => new BaseSite$CreateApi(apollo));
  apis.add(baseSite$updateSymbol, () => new BaseSite$UpdateApi(apollo));
  // disposalSite
  apis.add(disposalSite$getAllSymbol, () => new DisposalSite$GetAllApi(apollo));
  apis.add(disposalSite$createSymbol, () => new DisposalSite$CreateApi(apollo));
  apis.add(disposalSite$updateSymbol, () => new DisposalSite$UpdateApi(apollo));
  // client
  apis.add(client$getAllSymbol, () => new Client$GetAllApi(apollo));
  apis.add(client$getByIdsSymbol, () => new Client$GetByIdsApi(apollo));
  apis.add(client$getByKeywordsSymbol, () => new Client$GetByKeywordsApi(apollo));
  apis.add(client$createSymbol, () => new Client$CreateApi(apollo));
  apis.add(client$updateSymbol, () => new Client$UpdateApi(apollo));
  apis.add(client$getWithGenerationSiteNumByIdsSymbol, () => new Client$GetWithGenerationSiteNumByIdsApi(apollo));
  // containerType
  apis.add(containerType$getAllSymbol, () => new ContainerType$GetAllApi(apollo));
  apis.add(containerType$getByIdsSymbol, () => new ContainerType$GetByIdsApi(apollo));
  apis.add(containerType$createSymbol, () => new ContainerType$CreateApi(apollo));
  apis.add(containerType$updateSymbol, () => new ContainerType$UpdateApi(apollo));
  // containerTypeTaskType
  apis.add(containerTypeTaskType$getAllSymbol, () => new ContainerTypeTaskType$GetAll(apollo));
  apis.add(containerTypeTaskType$updateSymbol, () => new ContainerTypeTaskType$Update(apollo));
  // packingStyle
  apis.add(packingStyle$getAllSymbol, () => new PackingStyle$GetAll(apollo));
  apis.add(packingStyleTaskTypeDefault$getAllSymbol, () => new PackingStyleTaskTypeDefault$GetAll(apollo));
  apis.add(packingStyleTaskTypeDefault$updateSymbol, () => new PackingStyleTaskTypeDefault$Update(apollo));
  // officeSetting
  apis.add(officeSetting$getSymbol, () => new OfficeSetting$GetApi(apollo));
  apis.add(officeSetting$updateSymbol, () => new OfficeSetting$UpdateApi(apollo));
  // generationSite
  apis.add(generationSite$getAllSymbol, () => new GenerationSite$GetAllApi(apollo));
  apis.add(generationSite$getByIdsSymbol, () => new GenerationSite$GetByIdsApi(apollo));
  apis.add(generationSite$getByKeywordsSymbol, () => new GenerationSite$GetByKeywords(apollo));
  apis.add(generationSite$createSymbol, () => new GenerationSite$CreateApi(apollo));
  apis.add(generationSite$updateSymbol, () => new GenerationSite$UpdateApi(apollo));
  // order
  apis.add(order$ordersByDateSymbol, () => new Order$OrdersByDateApi(apollo));
  apis.add(order$ordersByIdsSymbol, () => new Order$OrdersByIdsApi(apollo));
  apis.add(order$createOrdersSymbol, () => new Order$CreateOrdersApi(apollo));
  apis.add(order$updateOrdersSymbol, () => new Order$UpdateOrdersApi(apollo));
  apis.add(order$cancelOrderSymbol, () => new Order$CancelOrderApi(apollo));
  apis.add(order$activateOrderSymbol, () => new Order$ActivateOrderApi(apollo));
  apis.add(order$postponeOrdersSymbol, () => new Order$PostponeOrdersApi(apollo));
  apis.add(generationSiteTask$generationSiteTasksByIds, () => new Order$GenerationSiteTasksByIdsApi(apollo));
  apis.add(irregularTask$irregularTasksByIdsSymbol, () => new Order$IrregularTaskByIdsApi(apollo));
  apis.add(order$validateOrdersSymbol, () => new Order$ValidateOrdersApi(apollo));
  apis.add(order$latestOrders, () => new Order$LatestOrdersApi(apollo));
  apis.add(order$routableOrders, () => new Order$RoutableOrdersApi(apollo));
  // orderAcceptanceCheck
  apis.add(orderAcceptanceCheck$createSymbol, () => new OrderAcceptanceCheck$CreateApi(apollo));
  apis.add(orderAcceptanceCheck$getByIdsSymbol, () => new OrderAcceptanceCheck$GetByIdsApi(apollo));
  // schedule
  apis.add(schedule$getLatestSymbol, () => new Schedule$GetLatestApi(apollo));
  apis.add(schedule$getByIdsSymbol, () => new Schedule$GetByIdsApi(apollo));
  apis.add(schedule$createSymbol, () => new Schedule$CreateApi(apollo));
  apis.add(schedule$updatePublishingStateSymbol, () => new Schedule$UpdatePublishingStateApi(apollo));
  apis.add(schedule$getByScheduleRequestSymbol, () => new Schedule$GetByScheduleRequestApi(apollo));
  apis.add(schedule$revertScheduleSymbol, () => new Schedule$RevertScheduleApi(apollo));
  // holidayRule
  apis.add(holidayRule$getSymbol, () => new HolidayRule$GetApi(apollo));
  apis.add(holidayRule$updateSymbol, () => new HolidayRule$UpdateApi(apollo));
  // orderDefault
  apis.add(orderDefault$orderDefaultByGenerationSiteId, () => new Order$OrderDefaultByGenerationSiteIdApi(apollo));
  // carAttendance
  apis.add(carAttendance$getByDateRangeSymbol, () => new CarAttendance$GetByDateRangeApi(apollo));
  apis.add(carAttendance$updateSymbol, () => new CarAttendance$UpdateApi(apollo));
  // packingPlacement
  apis.add(packingPlacement$getByKeywordsSymbol, () => new PackingPlacement$GetByKeywordsApi(apollo));
  apis.add(packingPlacement$updateSymbol, () => new PackingPlacement$UpdateApi(apollo));
  // reservation
  apis.add(reservation$reservationsByDateSymbol, () => new ReservationsByDateApi(apollo));
  apis.add(reservation$cancelledReservationsByDateSymbol, () => new CancelledReservationsByDateApi(apollo));
  apis.add(reservation$acceptReservationSymbol, () => new AcceptReservationApi(apollo));
  apis.add(reservation$cancelReservationSymbol, () => new CancelReservationApi(apollo));
  apis.add(
    reservation$pushBackReservationFromCancelledToWaitingReviewSymbol,
    () => new PushBackReservationFromCancelledToWaitingReviewApi(apollo)
  );
  apis.add(
    reservation$waitingReviewReservationDatesByDateRangeSymbol,
    () => new WaitingReviewReservationDatesByDateRangeApi(apollo)
  );
  apis.add(reservation$reservationDeadlineByDateSymbol, () => new ReservationDeadlineByDateApi(apollo));
  apis.add(reservation$updateReservationDeadlineSymbol, () => new UpdateReservationDeadlineApi(apollo));
  // taskType
  apis.add(taskType$getAllSymbol, () => new TaskTypeApi(apollo));
  apis.add(taskType$createSymbol, () => new TaskTypeApi(apollo));
  apis.add(taskType$updateSymbol, () => new TaskTypeApi(apollo));
  apis.add(baseTaskType$getAllSymbol, () => new BaseTaskTypeApi(apollo));

  // routingRegulation
  apis.add(routingRegulation$getByIdsSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$getAllSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$createSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$updateSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$deleteSymbol, () => new RoutingRegulationApi(apollo));

  // jwnetWasteMaster
  apis.add(jwnetWasteMaster$getAllSymbol, () => new JwnetWasteMaster$GetAllApi(apollo));
  // endregion

  // erp
  apis.add(erpClient$getAllTransportationClientsSymbol, () => new AllTransportationClientsApi(apollo));
  apis.add(erpOrder$createErpOrderSymbol, () => new CreateErpOrderApi(apollo));
  apis.add(erpOrder$updateErpOrderSymbol, () => new UpdateErpOrderApi(apollo));
  apis.add(erpOrder$erpOrderByOrderIdSymbol, () => new GetErpOrderByOrderIdApi(apollo));
  apis.add(erpOrder$deleteErpOrderByOrderIdSymbol, () => new DeleteErpOrderByOrderIdApi(apollo));

  // region axios
  apis.add(authentication$signInSymbol, () => new Authentication$SignInApi(axios));
  apis.add(authentication$signOutSymbol, () => new Authentication$SignOutApi(axios));
  // version axios
  apis.add(version$getVersionSymbol, () => new Version$GetVersionApi(axios));

  // endregion
};

export const injectDevelopmentDependencies = (
  apis: ServerApiManager,
  apollo: ApolloClientMethods,
  axios: NuxtAxiosInstance,
  isLocalMio: boolean
) => {
  // region GraphQL
  // userSetting
  apis.add(userSetting$getSymbol, () => new UserSetting$GetApi(apollo));
  apis.add(userSetting$updateSymbol, () => new UserSetting$UpdateApi(apollo));
  // announcement
  apis.add(announcement$unreadAnnouncementsSymbol, () => new UnreadAnnouncementsApi(apollo));
  // collectablePeriodTemplate
  apis.add(collectablePeriodTemplate$getAllApiType, () => new CollectablePeriodTemplate$GetAllApi(apollo));
  apis.add(collectablePeriodTemplate$updateApiType, () => new CollectablePeriodTemplate$UpdateApi(apollo));
  apis.add(collectablePeriodTemplate$createApiType, () => new CollectablePeriodTemplate$CreateApi(apollo));
  // wasteType
  apis.add(wasteType$getAllSymbol, () => new WasteType$GetAllApi(apollo));
  apis.add(wasteType$getAllDisplayedSymbol, () => new WasteType$GetAllDisplayedApi(apollo));
  apis.add(wasteType$getByKeywordsSymbol, () => new WasteType$GetByKeywordsApi(apollo));
  apis.add(wasteType$getAvailableCodeSymbol, () => new WasteType$GetAvailableCodeApi(apollo));
  apis.add(wasteType$createSymbol, () => new WasteType$CreateApi(apollo));
  apis.add(wasteType$updateSymbol, () => new WasteType$UpdateApi(apollo));
  // orderGroup
  apis.add(orderGroup$getAllType, () => new OrderGroup$GetAllApi(apollo));
  // carType
  apis.add(carType$getAllType, () => new CarType$GetAllApi(apollo));
  apis.add(carType$getByIdsSymbol, () => new CarType$GetByIdsApi(apollo));
  apis.add(carType$createSymbol, () => new CarType$CreateApi(apollo));
  apis.add(carType$updateSymbol, () => new CarType$UpdateApi(apollo));
  // driverAttendance
  apis.add(driverAttendance$getByDateRangeSymbol, () => new DriverAttendance$GetByDateRangeApi(apollo));
  apis.add(
    driverAttendance$getByDateRangeOfAllDriversSymbol,
    () => new DriverAttendance$GetByDateRangeOfAllDriversApi(apollo)
  );
  apis.add(driverAttendance$createSymbol, () => new DriverAttendance$CreateApi(apollo));
  apis.add(driverAttendance$updateSymbol, () => new DriverAttendance$UpdateApi(apollo));
  apis.add(driverAttendance$deleteSymbol, () => new DriverAttendance$DeleteApi(apollo));
  apis.add(driverAttendance$createDefaultSymbol, () => new DriverAttendance$CreateDefaultApi(apollo));
  // driverAttendanceTemplate
  apis.add(driverAttendanceTemplate$getAllSymbol, () => new DriverAttendanceTemplate$GetAllApi(apollo));
  apis.add(driverAttendanceTemplate$getByIdsSymbol, () => new DriverAttendanceTemplate$GetByIdsApi(apollo));
  apis.add(driverAttendanceTemplate$createSymbol, () => new DriverAttendanceTemplate$CreateApi(apollo));
  apis.add(driverAttendanceTemplate$updateSymbol, () => new DriverAttendanceTemplate$UpdateApi(apollo));
  // car
  apis.add(car$getAllSymbol, () => new Car$GetAllApi(apollo));
  apis.add(car$getByIdsSymbol, () => new Car$GetByIdsApi(apollo));
  apis.add(car$createSymbol, () => new Car$CreateApi(apollo));
  apis.add(car$updateSymbol, () => new Car$UpdateApi(apollo));
  // driver
  apis.add(driver$getAllSymbol, () => new Driver$GetAllApi(apollo));
  apis.add(driver$getByIdsSymbol, () => new Driver$GetByIdsApi(apollo));
  apis.add(driver$createSymbol, () => new Driver$CreateApi(apollo));
  apis.add(driver$updateSymbol, () => new Driver$UpdateApi(apollo));
  // attendance
  apis.add(attendance$getByIdsSymbol, () => new Attendance$GetByIdsApi(apollo));
  apis.add(attendance$getByDateRangeSymbol, () => new Attendance$GetByDateRangeApi(apollo));
  apis.add(attendance$createSymbol, () => new Attendance$CreateApi(apollo));
  apis.add(attendance$getByDateSymbol, () => new GetByDateApi(apollo));
  // baseSite
  apis.add(baseSite$getAllSymbol, () => new BaseSite$GetAllApi(apollo));
  apis.add(baseSite$createSymbol, () => new BaseSite$CreateApi(apollo));
  apis.add(baseSite$updateSymbol, () => new BaseSite$UpdateApi(apollo));
  // disposalSite
  apis.add(disposalSite$getAllSymbol, () => new DisposalSite$GetAllApi(apollo));
  apis.add(disposalSite$createSymbol, () => new DisposalSite$CreateApi(apollo));
  apis.add(disposalSite$updateSymbol, () => new DisposalSite$UpdateApi(apollo));
  // client
  apis.add(client$getAllSymbol, () => new Client$GetAllApi(apollo));
  apis.add(client$getByIdsSymbol, () => new Client$GetByIdsApi(apollo));
  apis.add(client$getByKeywordsSymbol, () => new Client$GetByKeywordsApi(apollo));
  apis.add(client$createSymbol, () => new Client$CreateApi(apollo));
  apis.add(client$updateSymbol, () => new Client$UpdateApi(apollo));
  apis.add(client$getWithGenerationSiteNumByIdsSymbol, () => new Client$GetWithGenerationSiteNumByIdsApi(apollo));
  // containerType
  apis.add(containerType$getAllSymbol, () => new ContainerType$GetAllApi(apollo));
  apis.add(containerType$getByIdsSymbol, () => new ContainerType$GetByIdsApi(apollo));
  apis.add(containerType$createSymbol, () => new ContainerType$CreateApi(apollo));
  apis.add(containerType$updateSymbol, () => new ContainerType$UpdateApi(apollo));
  // containerTypeTaskType
  apis.add(containerTypeTaskType$getAllSymbol, () => new ContainerTypeTaskType$GetAll(apollo));
  apis.add(containerTypeTaskType$updateSymbol, () => new ContainerTypeTaskType$Update(apollo));
  // packingStyle
  apis.add(packingStyle$getAllSymbol, () => new PackingStyle$GetAll(apollo));
  apis.add(packingStyleTaskTypeDefault$getAllSymbol, () => new PackingStyleTaskTypeDefault$GetAll(apollo));
  apis.add(packingStyleTaskTypeDefault$updateSymbol, () => new PackingStyleTaskTypeDefault$Update(apollo));
  // officeSetting
  apis.add(officeSetting$getSymbol, () => new OfficeSetting$GetApi(apollo));
  apis.add(officeSetting$updateSymbol, () => new OfficeSetting$UpdateApi(apollo));
  // generationSite
  apis.add(generationSite$getAllSymbol, () => new GenerationSite$GetAllApi(apollo));
  apis.add(generationSite$getByIdsSymbol, () => new GenerationSite$GetByIdsApi(apollo));
  apis.add(generationSite$getByKeywordsSymbol, () => new GenerationSite$GetByKeywords(apollo));
  apis.add(generationSite$createSymbol, () => new GenerationSite$CreateApi(apollo));
  apis.add(generationSite$updateSymbol, () => new GenerationSite$UpdateApi(apollo));
  // order
  apis.add(order$ordersByDateSymbol, () => new Order$OrdersByDateApi(apollo));
  apis.add(order$ordersByIdsSymbol, () => new Order$OrdersByIdsApi(apollo));
  apis.add(order$createOrdersSymbol, () => new Order$CreateOrdersApi(apollo));
  apis.add(order$updateOrdersSymbol, () => new Order$UpdateOrdersApi(apollo));
  apis.add(order$cancelOrderSymbol, () => new Order$CancelOrderApi(apollo));
  apis.add(order$activateOrderSymbol, () => new Order$ActivateOrderApi(apollo));
  apis.add(generationSiteTask$generationSiteTasksByIds, () => new Order$GenerationSiteTasksByIdsApi(apollo));
  apis.add(irregularTask$irregularTasksByIdsSymbol, () => new Order$IrregularTaskByIdsApi(apollo));
  apis.add(order$validateOrdersSymbol, () => new Order$ValidateOrdersApi(apollo));
  apis.add(order$latestOrders, () => new Order$LatestOrdersApi(apollo));
  apis.add(order$routableOrders, () => new Order$RoutableOrdersApi(apollo));
  apis.add(order$postponeOrdersSymbol, () => new Order$PostponeOrdersApi(apollo));
  // orderAcceptanceCheck
  apis.add(orderAcceptanceCheck$createSymbol, () => new OrderAcceptanceCheck$CreateApi(apollo));
  apis.add(orderAcceptanceCheck$getByIdsSymbol, () => new OrderAcceptanceCheck$GetByIdsApi(apollo));
  // schedule
  apis.add(schedule$getLatestSymbol, () => new Schedule$GetLatestApi(apollo));
  apis.add(schedule$getByIdsSymbol, () => new Schedule$GetByIdsApi(apollo));
  apis.add(schedule$createSymbol, () => new Schedule$CreateApi(apollo));
  apis.add(schedule$updatePublishingStateSymbol, () => new Schedule$UpdatePublishingStateApi(apollo));
  apis.add(schedule$getByScheduleRequestSymbol, () => new Schedule$GetByScheduleRequestApi(apollo));
  apis.add(schedule$revertScheduleSymbol, () => new Schedule$RevertScheduleApi(apollo));
  // holidayRule
  apis.add(holidayRule$getSymbol, () => new HolidayRule$GetApi(apollo));
  apis.add(holidayRule$updateSymbol, () => new HolidayRule$UpdateApi(apollo));
  // orderDefault
  apis.add(orderDefault$orderDefaultByGenerationSiteId, () => new Order$OrderDefaultByGenerationSiteIdApi(apollo));
  // carAttendance
  apis.add(carAttendance$getByDateRangeSymbol, () => new CarAttendance$GetByDateRangeApi(apollo));
  apis.add(carAttendance$updateSymbol, () => new CarAttendance$UpdateApi(apollo));
  // packingPlacement
  apis.add(packingPlacement$getByKeywordsSymbol, () => new PackingPlacement$GetByKeywordsApi(apollo));
  apis.add(packingPlacement$updateSymbol, () => new PackingPlacement$UpdateApi(apollo));
  // reservation
  apis.add(reservation$reservationsByDateSymbol, () => new ReservationsByDateApi(apollo));
  apis.add(reservation$acceptReservationSymbol, () => new AcceptReservationApi(apollo));
  apis.add(reservation$cancelledReservationsByDateSymbol, () => new CancelledReservationsByDateApi(apollo));
  apis.add(reservation$cancelReservationSymbol, () => new CancelReservationApi(apollo));
  apis.add(
    reservation$pushBackReservationFromCancelledToWaitingReviewSymbol,
    () => new PushBackReservationFromCancelledToWaitingReviewApi(apollo)
  );
  apis.add(
    reservation$waitingReviewReservationDatesByDateRangeSymbol,
    () => new WaitingReviewReservationDatesByDateRangeApi(apollo)
  );
  apis.add(reservation$reservationDeadlineByDateSymbol, () => new ReservationDeadlineByDateApi(apollo));
  apis.add(reservation$updateReservationDeadlineSymbol, () => new UpdateReservationDeadlineApi(apollo));
  // jwnetWasteMaster
  apis.add(jwnetWasteMaster$getAllSymbol, () => new JwnetWasteMaster$GetAllApi(apollo));

  // taskType
  apis.add(taskType$getAllSymbol, () => new TaskTypeApi(apollo));
  apis.add(taskType$updateSymbol, () => new TaskTypeApi(apollo));
  apis.add(taskType$createSymbol, () => new TaskTypeApi(apollo));
  apis.add(baseTaskType$getAllSymbol, () => new BaseTaskTypeApi(apollo));

  // routingRegulation
  apis.add(routingRegulation$getByIdsSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$getAllSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$createSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$updateSymbol, () => new RoutingRegulationApi(apollo));
  apis.add(routingRegulation$deleteSymbol, () => new RoutingRegulationApi(apollo));

  // erp
  apis.add(erpClient$getAllTransportationClientsSymbol, () => new AllTransportationClientsApi(apollo));
  apis.add(erpOrder$createErpOrderSymbol, () => new CreateErpOrderApi(apollo));
  apis.add(erpOrder$updateErpOrderSymbol, () => new UpdateErpOrderApi(apollo));
  apis.add(erpOrder$erpOrderByOrderIdSymbol, () => new GetErpOrderByOrderIdApi(apollo));
  apis.add(erpOrder$deleteErpOrderByOrderIdSymbol, () => new DeleteErpOrderByOrderIdApi(apollo));

  // endregion
  // region authentication
  if (isLocalMio) {
    apis.add(authentication$currentSessionSymbol, () => new Session$CurrentSessionApi(apollo));
    apis.add(authentication$signInSymbol, () => new Authentication$SignInApi(axios));
    apis.add(authentication$signOutSymbol, () => new Authentication$SignOutApi(axios));
  } else {
    apis.add(authentication$currentSessionSymbol, () => new LocalCurrentSessionApi());
    apis.add(authentication$signInSymbol, () => new LocalSignInApi());
    apis.add(authentication$signOutSymbol, () => new LocalSignOutApi());
  }
  // endregion
  // version api
  apis.add(version$getVersionSymbol, () => new Version$GetVersionApi(axios));
  // endregion
};
