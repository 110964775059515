import { StoredMapperBase } from '~/framework/core/mapper';
import {
  ContainerTypeTaskTypeEntity,
  ContainerTypeTaskTypeEntityData,
} from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { ContainerTypeTaskTypeStore } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeStore';

export class ContainerTypeTaskTypeMapper
  extends StoredMapperBase<ContainerTypeTaskTypeEntityData, ContainerTypeTaskTypeEntity>
  implements ContainerTypeTaskTypeMapper
{
  protected store: ContainerTypeTaskTypeStore;

  constructor(store: ContainerTypeTaskTypeStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: ContainerTypeTaskTypeEntityData): ContainerTypeTaskTypeEntity {
    return new ContainerTypeTaskTypeEntity(
      data.id,
      data.containerTypeId,
      data.taskType,
      data.duration,
      data.status,
      data.isProportionalToCount
    );
  }

  protected updateWithData(data: ContainerTypeTaskTypeEntityData, entity: ContainerTypeTaskTypeEntity): void {
    entity.duration = data.duration;
    entity.status = data.status;
    entity.taskType = data.taskType;
    entity.isProportionalToCount = data.isProportionalToCount;
  }
}
