import { TaskType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';

/**
 * 変更をトラックする必要がなく、エンティティにする必要もなさそうなのでひとまずそうしていない
 * マッパーも作らなかったのはマッパーが ID をベースにした仕組みなため
 */

export interface ReservationGenerationSiteTaskData {
  taskType: TaskType;
  wasteTypeId: Maybe<string>;
  containerTypeId: string;
  containerNum: number;
}

export class ReservationGenerationSiteTask {
  @ReactiveProperty(ReservationGenerationSiteTask.prototype.updateTaskTypeName)
  taskType: TaskType;

  wasteTypeId: Maybe<string>;
  containerTypeId: string;
  containerNum: number;

  taskTypeName!: string;

  constructor(taskType: TaskType, wasteTypeId: Maybe<string>, containerTypeId: string, containerNum: number) {
    this.taskType = taskType;
    this.wasteTypeId = wasteTypeId;
    this.containerTypeId = containerTypeId;
    this.containerNum = containerNum;
    this.updateTaskTypeName();
  }

  private updateTaskTypeName(): void {
    this.taskTypeName = this.taskType.name;
  }
}
