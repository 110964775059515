
import Vue from 'vue';
import { ru } from 'date-fns/locale';
import { SiteType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { maxLength, required } from '~/framework/view-models/rules';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { FormErrors } from '~/components/panels/masters/r-car-type-form/errors';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { BaseTaskTypeEntity, TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';
import { ICreateData, ITaskTypeMutationError, IUpdateData } from '~/framework/server-api/masters/taskType';
import { taskTypeSymbol } from '~/framework/application/masters/task-type/taskTypeApplicationService';
import { IllegalStateException } from '~/framework/core/exception';
import { TaskTypeFormValues } from '~/components/panels/masters/r-task-type-form/TaskTypeFormValues';
import RFieldTooltip from '~/components/common/r-field-tooltip/RFieldTooltip.vue';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { driverSymbol } from '~/framework/application/masters/driver/driverApplicationService';

enum EventTypes {
  Change = 'change',
}
enum FormMode {
  Register,
  Edit,
}

export default Vue.extend({
  name: 'RTaskTypeForm',
  components: { RFieldTooltip },
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data() {
    const taskTypeApplicationService = this.$context.applications.get(taskTypeSymbol);
    return {
      taskTypeApplicationService,
      isActive: false,
      isCloseConfirmDialogActive: false,
      isRegisterConfirmDialogActive: false,
      listenerDisposer: undefined as Maybe<Function>,
      closeConfirmDialogResolver: undefined as Maybe<Function>,
      registrationConfirmDialogResolver: undefined as Maybe<Function>,
      baseTaskTypeMaster: [] as BaseTaskTypeEntity[],
      driverMaster: [] as DriverEntity[],
      formMode: FormMode.Register as FormMode,
      formValues: {} as TaskTypeFormValues,
      initialFormValues: {} as TaskTypeFormValues,
      isFormValid: true,
      editingTaskType: undefined as Maybe<TaskTypeEntity>,
      createdTaskType: undefined as Maybe<TaskTypeEntity>,
      updatedTaskType: undefined as Maybe<TaskTypeEntity>,
      isRegistering: false,
      rules: { required, maxLength },
      isRegisterButtonDisabled: false,
      formErrors: new FormErrors(),
    };
  },
  computed: {
    ru() {
      return ru;
    },
    isCreate(): boolean {
      return FormMode.Register === this.formMode;
    },
    title(): string {
      return FormMode.Register === this.formMode ? '作業の登録' : '作業の編集';
    },
    baseTaskTypeMasterForSelect(): Array<Omit<BaseTaskTypeEntity, 'name' | 'id'> & { name: string; id: string }> {
      return this.baseTaskTypeMaster
        .map((b) => {
          return {
            ...b,
            name: b.displayName,
          };
        })
        .filter((b) => {
          return b.siteType === SiteType.GenerationSite;
        });
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.TASK_TYPE;
    },
  },
  watch: {
    isFormValid(value: boolean): void {
      this.isRegisterButtonDisabled = !value;
    },
  },
  async mounted() {
    const openEventListenerDisposer = this.$context.panels.taskTypeFormPanel.openFormEvent.on(this.onOpen);
    const driverApplicationService = this.$context.applications.get(driverSymbol);
    const [baseTaskTypes, drivers] = await Promise.all([
      this.taskTypeApplicationService.getBaseAll(),
      driverApplicationService.getAll(),
    ]);
    this.baseTaskTypeMaster = baseTaskTypes;
    this.driverMaster = drivers;
    this.$context.panels.taskTypeFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    async open(taskType: Maybe<TaskTypeEntity> = undefined): Promise<void> {
      if (this.isActive) return;

      await this.initializeForm(taskType);

      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);

      const closeArgs: ICloseEntityFormArgs<TaskTypeEntity> = {
        entity: this.editingTaskType,
        createdEntity: this.createdTaskType,
        updatedEntity: this.updatedTaskType,
        removedEntity: undefined,
      };

      this.$context.panels.taskTypeFormPanel.closeFormEvent.emit(closeArgs);
    },
    async onOpen(args: IOpenEntityFormArgs<TaskTypeEntity>): Promise<void> {
      await this.open(args.entity);
    },
    async onCloseForm(): Promise<boolean> {
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.formValues.isEqualTo(this.initialFormValues) === false) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }

      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    onConfirmRegistrationClose(value: boolean): void {
      this.isRegisterConfirmDialogActive = false;
      if (this.registrationConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.registrationConfirmDialogResolver(value);
    },
    initializeForm(taskType: Maybe<TaskTypeEntity>): void {
      this.formMode = taskType ? FormMode.Edit : FormMode.Register;
      this.formValues = new TaskTypeFormValues(
        taskType?.id || undefined,
        taskType?.name || '',
        taskType?.baseTaskType.id,
        taskType?.bannedDriverIds || []
      );
      this.initialFormValues = new TaskTypeFormValues(
        taskType?.id || undefined,
        taskType?.name || '',
        taskType?.baseTaskType.id,
        taskType?.bannedDriverIds || []
      );
    },
    onRegisterButtonClicked(): void {
      if (this.formValues.baseTaskTypeId === undefined) throw new IllegalStateException('baseTaskTypeId not set');

      this.isRegistering = true;
      this.formErrors.resetAllErrors();
      if (this.isCreate) {
        const createData: ICreateData = {
          name: this.formValues.taskTypeName,
          baseTaskTypeId: this.formValues.baseTaskTypeId,
          bannedDriverIds: this.formValues.bannedDriverIds,
        };
        this.taskTypeApplicationService
          .create(createData, this.baseTaskTypeMaster)
          .then((result) => {
            if (!result) throw new Error('something happen');
            this.onCreate(result);
          })
          .catch(this.handleFormError);
      } else {
        if (this.formValues.id === undefined) throw new IllegalStateException('target formValues.id undefined');
        const updateData: IUpdateData = {
          id: this.formValues.id,
          name: this.formValues.taskTypeName,
          bannedDriverIds: this.formValues.bannedDriverIds,
        };

        this.taskTypeApplicationService
          .update(updateData, this.baseTaskTypeMaster)
          .then((result) => {
            if (!result) throw new Error('something happen');
            this.onUpdate(result);
          })
          .catch(this.handleFormError);
      }
      this.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.TASK_TYPE,
        [AdditionalInfoKeys.MODE]: this.isCreate ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });
    },
    onCreate(entity: TaskTypeEntity): void {
      this.createdTaskType = entity;
      this.$context.snackbar.success('作業の登録完了');
      this.$nextTick(this.close);
    },
    onUpdate(entity: TaskTypeEntity): void {
      this.updatedTaskType = entity;

      this.$context.snackbar.success('作業の編集完了');
      this.$nextTick(this.close);
    },
    handleFormError(error: ITaskTypeMutationError | IllegalStateException): void {
      if (error instanceof IllegalStateException) {
        this.$context.snackbar.error('何か問題が発生しました');
        return;
      }

      for (const anError of error.errors) {
        if (anError.__typename === 'DuplicatedNameError') {
          this.$context.snackbar.error('同じ名前が存在します');
        }
      }
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.taskTypeFormPanel.updateDisplayedWidth(value);
    },
  },
});
