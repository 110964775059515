import { StoredMapperBase } from '~/framework/core/mapper';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';
import {
  AggregatedWasteTypeEntity,
  AggregatedWasteTypeEntityData,
} from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';
import { AggregatedWasteTypeStore } from '~/framework/domain/masters/waste-type/aggregatedWasteTypeStore';
import { JwnetWasteMasterStore } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterStore';
import { JwnetWasteMasterMapper } from '~/framework/domain/masters/jwnet-waste-master/jwnetWasteMasterMapper';

export class AggregatedWasteTypeMapper extends StoredMapperBase<
  AggregatedWasteTypeEntityData,
  AggregatedWasteTypeEntity
> {
  protected store: AggregatedWasteTypeStore;
  private jwnetWasteMasterMapper: JwnetWasteMasterMapper;
  private userMapper: UserMapper;

  constructor(store: AggregatedWasteTypeStore, jwnetWasteMasterStore: JwnetWasteMasterStore, userStore: UserStore) {
    super();
    this.store = store;
    this.jwnetWasteMasterMapper = new JwnetWasteMasterMapper(jwnetWasteMasterStore);
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: AggregatedWasteTypeEntityData): AggregatedWasteTypeEntity {
    const jwnetWasteMaster = data.jwnetWasteMaster
      ? this.jwnetWasteMasterMapper.mapSingle(data.jwnetWasteMaster)
      : undefined;
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    const updatedBy = this.userMapper.mapSingle(data.updatedBy);
    return new AggregatedWasteTypeEntity(
      data.id,
      data.category,
      data.code,
      data.name,
      data.status,
      data.isProminent,
      data.createdAt,
      data.updatedAt,
      jwnetWasteMaster,
      createdBy,
      updatedBy
    );
  }

  protected updateWithData(data: AggregatedWasteTypeEntityData, entity: AggregatedWasteTypeEntity) {
    entity.category = data.category;
    entity.code = data.code;
    entity.name = data.name;
    entity.status = data.status;
    entity.isProminent = data.isProminent;
    entity.createdAt = data.createdAt;
    entity.updatedAt = data.updatedAt;
    entity.updatedBy = {
      ...data.updatedBy,
      persistentId: data.updatedBy.id,
    };
  }
}
