import { DisposalEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/disposalEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { DisposalSiteEntity } from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { IOriginalDisposal } from '~/pages/schedule/originalDisposal';

export class Disposal extends DisposalEntity<IOriginalDisposal> {
  // reactive property を追加
  disposalSite: DisposalSiteEntity;

  constructor(
    original: IOriginalDisposal,
    id: string,
    index: number,
    disposalSiteId: PseudoId,
    disposalSiteArrivalTime: number,
    disposalSiteDepartureTime: number,
    hasRestBeforeDisposalSiteArrival: boolean,
    prioritizeAssignedDisposalSite: boolean,
    sequentialAssignedDisposalSite: boolean,
    disposalSite: DisposalSiteEntity
  ) {
    super(
      original,
      id,
      index,
      disposalSiteId,
      disposalSiteArrivalTime,
      disposalSiteDepartureTime,
      hasRestBeforeDisposalSiteArrival,
      prioritizeAssignedDisposalSite,
      sequentialAssignedDisposalSite
    );
    this.disposalSite = disposalSite;
  }

  clone(): Disposal {
    return new Disposal(
      this.original,
      this.id,
      this.index,
      this.disposalSiteId,
      this.disposalSiteArrivalTime,
      this.disposalSiteDepartureTime,
      this.hasRestBeforeDisposalSiteArrival,
      this.prioritizeAssignedDisposalSite,
      this.sequentialAssignedDisposalSite,
      this.disposalSite
    );
  }
}
