import { MapperBase } from '~/framework/core/mapper';
import {
  GenerationSiteEntity,
  GenerationSiteEntityData,
} from '~/framework/domain/masters/generation-site/generationSiteEntity';

export class GenerationSiteMapper extends MapperBase<GenerationSiteEntityData, GenerationSiteEntity> {
  protected instantiateWithData(data: GenerationSiteEntityData): GenerationSiteEntity {
    return new GenerationSiteEntity(
      data.id,
      data.clientId,
      data.code,
      data.name,
      data.nameRuby,
      data.zipCode,
      data.address1,
      data.address2,
      data.address3,
      data.address4,
      data.latitude,
      data.longitude,
      data.bannedDriverIds,
      data.defaultAssignedDriverId,
      data.defaultAssignedDisposalSiteId,
      data.defaultAssignableCarTypeIds,
      data.defaultAssignedCarId,
      data.defaultAvoidHighways,
      data.defaultDurationAtEntrance,
      data.restPeriodStart,
      data.restPeriodEnd,
      data.note,
      data.attachments ?? [],
      data.isAddressComplete,
      data.createdAt,
      data.status,
      data.defaultCollectablePeriodTemplateId,
      data.defaultCollectablePeriodStart,
      data.defaultCollectablePeriodEnd,
      data.defaultRouteCollectionAllowed,
      data.defaultPreloadStatus,
      data.defaultIsFixedArrivalTimeReportNeeded,
      data.defaultMarginTypeOfFixedArrivalTime,
      data.defaultMarginOfFixedArrivalTime,
      data.noteForOffice,
      data?.client
    );
  }
}
