import { SoftDeleteStatus, PreloadStatus, MarginType } from '~/framework/domain/typeAliases';
import { ReactiveProperty } from '~/framework/property';
import { Maybe } from '~/framework/typeAliases';
import { yomiganize } from '~/framework/services/string/string';
import { UploadedFile } from '~/graphql/graphQLServerApi';

export interface GenerationSiteEntityData {
  id: string;
  clientId: string;
  code: Maybe<string>;
  client?: {
    id: string;
    code: Maybe<string>;
    name: string;
  };
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  bannedDriverIds: string[];
  defaultAssignedDriverId: string | undefined;
  defaultAssignedDisposalSiteId: string | undefined;
  defaultAssignableCarTypeIds: string[];
  defaultAssignedCarId: string | undefined;
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  note: string | undefined;
  attachments: UploadedFile[] | undefined;
  isAddressComplete: boolean;
  createdAt: Date;
  status: SoftDeleteStatus;
  defaultCollectablePeriodTemplateId: string | undefined;
  defaultCollectablePeriodStart: number | undefined;
  defaultCollectablePeriodEnd: number | undefined;
  defaultRouteCollectionAllowed: boolean | undefined;
  defaultPreloadStatus: PreloadStatus | undefined;
  defaultIsFixedArrivalTimeReportNeeded: boolean | undefined;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: string | undefined;
}
export class GenerationSiteEntity {
  readonly id: string;
  readonly persistentId: string;
  clientId: string;
  code: Maybe<string>;
  client?: {
    id: string;
    code: Maybe<string>;
    name: string;
  };

  name: string;
  _nameRuby!: string;
  @ReactiveProperty(GenerationSiteEntity.prototype.updateAddress)
  zipCode: Maybe<string>;

  @ReactiveProperty(GenerationSiteEntity.prototype.updateAddress)
  address1: string;

  @ReactiveProperty(GenerationSiteEntity.prototype.updateAddress)
  address2: string;

  @ReactiveProperty(GenerationSiteEntity.prototype.updateAddress)
  address3: string;

  @ReactiveProperty(GenerationSiteEntity.prototype.updateAddress)
  address4: Maybe<string>;

  latitude: number;
  longitude: number;
  bannedDriverIds: string[];
  defaultAssignedDriverId: Maybe<string>;
  defaultAssignedDisposalSiteId: Maybe<string>;
  defaultAssignableCarTypeIds: string[];
  defaultAssignedCarId: Maybe<string>;
  defaultAvoidHighways: boolean;
  defaultDurationAtEntrance: number;
  @ReactiveProperty(GenerationSiteEntity.prototype.updateHasRestPeriod)
  restPeriodStart: Maybe<number>;

  @ReactiveProperty(GenerationSiteEntity.prototype.updateHasRestPeriod)
  restPeriodEnd: Maybe<number>;

  note: Maybe<string>;
  attachments: UploadedFile[];
  isAddressComplete: boolean;
  createdAt: Date;
  @ReactiveProperty(GenerationSiteEntity.prototype.updateStatus)
  status: SoftDeleteStatus;

  defaultCollectablePeriodTemplateId: Maybe<string>;
  defaultCollectablePeriodStart: Maybe<number>;
  defaultCollectablePeriodEnd: Maybe<number>;

  defaultRouteCollectionAllowed: Maybe<boolean>;
  defaultPreloadStatus: Maybe<PreloadStatus>;

  defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  noteForOffice: Maybe<string>;

  displayName: Maybe<string>;

  // computed property
  address!: string;
  addressWithoutZipCode!: string;
  hasRestPeriod!: boolean;
  isDeleted!: boolean;

  get nameRuby() {
    return this._nameRuby;
  }

  set nameRuby(value: string) {
    this._nameRuby = yomiganize(value)[0];
  }

  constructor(
    id: string,
    clientId: string,
    code: Maybe<string>,
    name: string,
    nameRuby: string,
    zipCode: Maybe<string>,
    address1: string,
    address2: string,
    address3: string,
    address4: Maybe<string>,
    latitude: number,
    longitude: number,
    bannedDriverIds: string[],
    defaultAssignedDriverId: Maybe<string>,
    defaultAssignedDisposalSiteId: Maybe<string>,
    defaultAssignableCarTypeIds: string[],
    defaultAssignedCarId: Maybe<string>,
    defaultAvoidHighways: boolean,
    defaultDurationAtEntrance: number,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    note: Maybe<string>,
    attachments: UploadedFile[],
    isAddressComplete: boolean,
    createdAt: Date,
    status: SoftDeleteStatus,
    defaultCollectablePeriodTemplateId: Maybe<string>,
    defaultCollectablePeriodStart: Maybe<number>,
    defaultCollectablePeriodEnd: Maybe<number>,
    defaultRouteCollectionAllowed: Maybe<boolean>,
    defaultPreloadStatus: Maybe<PreloadStatus>,
    defaultIsFixedArrivalTimeReportNeeded: Maybe<boolean>,
    defaultMarginTypeOfFixedArrivalTime: MarginType,
    defaultMarginOfFixedArrivalTime: number,
    noteForOffice: Maybe<string>,
    client?: { name: string; id: string; code: Maybe<string> }
  ) {
    this.id = id;
    this.persistentId = id;
    this.clientId = clientId;
    this.code = code;
    this.name = name;
    this.nameRuby = nameRuby;
    this.zipCode = zipCode;
    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.address4 = address4;
    this.latitude = latitude;
    this.longitude = longitude;
    this.bannedDriverIds = bannedDriverIds;
    this.defaultAssignedDriverId = defaultAssignedDriverId;
    this.defaultAssignedDisposalSiteId = defaultAssignedDisposalSiteId;
    this.defaultAssignableCarTypeIds = defaultAssignableCarTypeIds;
    this.defaultAssignedCarId = defaultAssignedCarId;
    this.defaultAvoidHighways = defaultAvoidHighways;
    this.defaultDurationAtEntrance = defaultDurationAtEntrance;
    this.restPeriodStart = restPeriodStart;
    this.restPeriodEnd = restPeriodEnd;
    this.note = note;
    this.attachments = attachments;
    this.isAddressComplete = isAddressComplete;
    this.createdAt = createdAt;
    this.status = status;
    this.defaultCollectablePeriodTemplateId = defaultCollectablePeriodTemplateId;
    this.defaultCollectablePeriodStart = defaultCollectablePeriodStart;
    this.defaultCollectablePeriodEnd = defaultCollectablePeriodEnd;
    this.defaultRouteCollectionAllowed = defaultRouteCollectionAllowed;
    this.defaultPreloadStatus = defaultPreloadStatus;
    this.noteForOffice = noteForOffice;
    this.defaultIsFixedArrivalTimeReportNeeded = defaultIsFixedArrivalTimeReportNeeded;
    this.defaultMarginTypeOfFixedArrivalTime = defaultMarginTypeOfFixedArrivalTime;
    this.defaultMarginOfFixedArrivalTime = defaultMarginOfFixedArrivalTime;
    this.updateAddress();
    this.updateHasRestPeriod();
    this.updateStatus();
    this.client = client;
  }

  private updateAddress(): void {
    const address = [];
    const addressWithoutZipCode = [];
    if (this.zipCode !== undefined && this.zipCode !== '') {
      address.push(`〒${this.zipCode}`);
    }
    if (this.address1 !== '') {
      address.push(this.address1);
      addressWithoutZipCode.push(this.address1);
    }
    if (this.address2 !== '') {
      address.push(this.address2);
      addressWithoutZipCode.push(this.address2);
    }
    if (this.address3 !== '') {
      address.push(this.address3);
      addressWithoutZipCode.push(this.address3);
    }
    if (this.address4 !== undefined && this.address4 !== '') {
      address.push(this.address4);
      addressWithoutZipCode.push(this.address4);
    }
    this.address = address.join(' ');
    this.addressWithoutZipCode = addressWithoutZipCode.join(' ');
  }

  private updateHasRestPeriod(): void {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    this.hasRestPeriod = this.restPeriodStart !== undefined && this.restPeriodEnd !== undefined;
  }

  private updateStatus(): void {
    this.isDeleted = this.status === SoftDeleteStatus.Deleted;
  }
}
