import { IUserData } from '~/framework/server-api/masters/user';

import { IDuplicatedNameError } from '~/framework/server-api/errors';

export const baseSite$createSymbol = Symbol('baseSite$createSymbol');

export type CreateResultTypes = (IBaseSiteDataType & IBaseSiteData) | IBaseSiteMutationError;

export const baseSite$getAllSymbol = Symbol('baseSite$getAll');

export const baseSite$updateSymbol = Symbol('baseSite$updateSymbol');

export type UpdateResultTypes = (IBaseSiteDataType & IBaseSiteData) | IBaseSiteMutationError;

export type BaseSite = {
  [baseSite$getAllSymbol]: IGetAllApi;
  [baseSite$createSymbol]: ICreateApi;
  [baseSite$updateSymbol]: IUpdateApi;
};

export interface IBaseSiteDataType {
  __typename: 'BaseSite';
}

export interface IBaseSiteData {
  id: string;
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  hasCars: boolean;
  hasContainers: boolean;
  createdAt: Date;
  createdBy: IUserData;
}

export interface ICreateApi {
  create(data: ICreateData): Promise<CreateResultTypes>;
}

export interface ICreateData {
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  hasCars: boolean;
  hasContainers: boolean;
}
export interface IGetAllApi {
  getAll(): Promise<IBaseSiteData[]>;
}

export interface IBaseSiteMutationError {
  __typename: 'BaseSiteMutationError';
  errors: BaseSiteMutationErrorTypes[];
}

export interface IHasCarsMustBeTrueError {
  __typename: 'HasCarsMustBeTrueError';
}

export interface IHasCarsOrHasContainersMustBeTrueError {
  __typename: 'HasCarsOrHasContainersMustBeTrueError';
}

export type BaseSiteMutationErrorTypes =
  | IDuplicatedNameError
  | IHasCarsMustBeTrueError
  | IHasCarsOrHasContainersMustBeTrueError;

export interface IUpdateApi {
  update(data: IUpdateData): Promise<UpdateResultTypes>;
}

export interface IUpdateData {
  id: string;
  name: string;
  nameRuby: string;
  zipCode: string | undefined;
  address1: string;
  address2: string;
  address3: string;
  address4: string | undefined;
  latitude: number;
  longitude: number;
  hasCars: boolean;
  hasContainers: boolean;
}
