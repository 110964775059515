import { ContainerTypeTaskTypeStatus, TaskType } from '~/framework/domain/typeAliases';

export interface PackingStyleTaskTypeDefaultEntityData extends PackingStyleTaskTypeDefaultEntity {}

export class PackingStyleTaskTypeDefaultEntity {
  constructor(
    public readonly id: string,
    public packingStyleId: string,
    public taskType: TaskType,
    public duration: number,
    public status: ContainerTypeTaskTypeStatus,
    public isProportionalToCount: boolean
  ) {}
}
