import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import * as List from '~/framework/application/masters/client/search';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import {
  client$updateSymbol,
  IClientUpdateData,
  client$getByKeywordsSymbol,
  ICondition,
  client$createSymbol,
  IClientCreateData,
  client$getByIdsSymbol,
  client$getAllSymbol,
  client$getWithGenerationSiteNumByIdsSymbol,
} from '~/framework/server-api/masters/client';

import { AggregatedClientMapper } from '~/framework/domain/masters/client/aggregatedClientMapper';

import * as Selection from '~/framework/application/masters/client/searchSelection';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';

export const clientSymbol = Symbol('client');

export class ClientApplicationService {
  private store: Store;
  private serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  // region search
  async search(first: number, after: Maybe<string>, condition: ICondition): Promise<List.IList> {
    const client$getByKeywordsApi = this.serverApis.get(client$getByKeywordsSymbol);
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );
    const result = await client$getByKeywordsApi.getWithOptionalsByKeywords(first, after, condition);
    const entities = clientMapper.map(result.edges.map((edge) => edge.node));
    const output: List.IList = {
      pageInfo: result.pageInfo,
      totalCount: result.totalCount,
      items: entities.map((entity, index) => {
        return {
          entity,
          generationSiteNum: result.edges[index].node.generationSiteNum,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region searchSelection
  async searchSelection(first: number, after: Maybe<string>, condition: ICondition): Promise<Selection.IList> {
    const client$getByKeywordsApi = this.serverApis.get(client$getByKeywordsSymbol);
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );
    const result = await client$getByKeywordsApi.getByKeywords(first, after, condition);
    const entities = clientMapper.map(result.edges.map((edge) => edge.node));
    const output: Selection.IList = {
      pageInfo: result.pageInfo,
      totalCount: result.totalCount,
      items: entities.map((entity, index) => {
        return {
          entity,
          cursor: result.edges[index].cursor,
        };
      }),
    };
    return output;
  }
  // endregion

  // region create
  async create(data: IClientCreateData): Promise<AggregatedClientEntity> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$createApi = this.serverApis.get(client$createSymbol);
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );
    const [createResult] = await client$createApi.create([data]);
    const entity = clientMapper.mapSingle({
      id: createResult.client.id,
      code: undefined, // 新規作成時はコード設定できないので undefined を入れる
      createdAt: createResult.client.createdAt,
      createdBy: createResult.client.createdBy,
      signInUrl: createResult.client.signInUrl,
      updatedAt: createResult.client.updatedAt,
      updatedBy: createResult.client.updatedBy,
      reservationSiteEnabled: createResult.client.reservationSiteEnabled,
      packingStyleReservationSettings: createResult.client.packingStyleReservationSettings,
      ...data,
    });
    return entity;
  }
  // endregion

  // region import
  async getAll(): Promise<AggregatedClientEntity[]> {
    const client$getAllApi = this.serverApis.get(client$getAllSymbol);
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );
    const result = await client$getAllApi.getAll();
    const entities = clientMapper.map(result);
    return entities;
  }

  async createMultiple(data: IClientCreateData[]): Promise<AggregatedClientEntity[]> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$createApi = this.serverApis.get(client$createSymbol);
    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );
    const createResults = await client$createApi.create(data);
    const getResults = await client$getByIdsApi.getByIds(createResults.map((item) => item.client.id));
    const entities = clientMapper.map(getResults);
    return entities;
  }
  // endregion

  // region update
  async update(data: IClientUpdateData): Promise<AggregatedClientEntity> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$updateApi = this.serverApis.get(client$updateSymbol);
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );
    const [updateResult] = await client$updateApi.update([data]);

    const entity = clientMapper.mapSingle({
      code: undefined, // コードが設定されている可能性はあるが、返却値を利用しないため undefined を入れておく。本来的には update では Entity を返却しないようにしたい
      createdAt: updateResult.client.createdAt,
      createdBy: updateResult.client.createdBy,
      updatedAt: updateResult.client.updatedAt,
      updatedBy: updateResult.client.updatedBy,
      signInUrl: updateResult.client.signInUrl,
      ...data,
      reservationSiteEnabled: updateResult.client.reservationSiteEnabled,
      packingStyleReservationSettings: updateResult.client.packingStyleReservationSettings,
    });
    return entity;
  }
  // endregion

  // region delete
  async delete(id: string): Promise<AggregatedClientEntity> {
    // ここでは createdBy を取得するためにもう一度 getByIds しているが、本来はこういう事をせずに
    // create の結果で返すべき。今はそういう API になっていないので致し方なくやっている。
    const client$updateApi = this.serverApis.get(client$updateSymbol);
    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );
    const [getResult] = await client$getByIdsApi.getByIds([id]);
    const updateData = {
      id: getResult.id,
      name: getResult.name,
      nameRuby: getResult.nameRuby,
      note: getResult.note,
      status: SoftDeleteStatus.Deleted,
      packingStyleReservationSettings: [],
    };
    await client$updateApi.update([updateData]);
    const entity = clientMapper.mapSingle({ ...getResult, ...updateData });
    return entity;
  }
  // endregion

  async getById(id: PersistentId): Promise<AggregatedClientEntity> {
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );

    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const [client] = await client$getByIdsApi.getByIds([id]);

    return clientMapper.mapSingle(client);
  }

  async getWithGenerationSiteNumById(
    id: PersistentId
  ): Promise<{ entity: AggregatedClientEntity; generationSiteNum: number }> {
    const clientMapper: AggregatedClientMapper = new AggregatedClientMapper(
      this.store.masters.aggregatedClient,
      this.store.masters.user
    );

    const client$getWithGenerationSiteNumByIdsApi = this.serverApis.get(client$getWithGenerationSiteNumByIdsSymbol);
    const [client] = await client$getWithGenerationSiteNumByIdsApi.getWithGenerationSiteNumByIds([id]);

    return {
      entity: clientMapper.mapSingle(client),
      generationSiteNum: client.generationSiteNum,
    };
  }
}
