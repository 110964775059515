import _ from 'lodash';
import {
  MarginType,
  OrderDisposalSiteAssignmentType,
  DriverAssignmentType,
  PreloadStatus,
  OrderSchedulingPriority,
} from '~/framework/domain/typeAliases';
import {
  AbstractEntityFormPanel,
  ICacheablePanelOption,
  IInitializablePanelOption,
  IEntitlablePanelOption,
} from '~/framework/view-models/panels/abstractEntityFormPanel';
import { AggregatedOrderEntity as IOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { IOpenPanelOption } from '~/framework/view-models/panels/panel';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { GenerationSiteTaskCategory } from '~/framework/view-models/generationSiteTaskCategory';
import {
  IIrregularTaskItem,
  IrregularTaskItemFactory,
} from '~/components/panels/schedule/r-order-form/irregularTaskItem';
import {
  GenerationSiteTaskItem,
  IGenerationSiteTaskItem,
} from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import {
  buildByOrderRecurringSettings,
  IRecurringOrderSettings,
} from '~/components/panels/schedule/r-order-form/r-recurring-order-settings-dialog/recurringOrderSettings';

import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';
import DateCollectablePeriodTypes from '~/components/panels/schedule/r-order-form/dateCollectablePeriodTypes';
import {
  IOrderAssignableDriver,
  isDriversCandidate,
  isHelpersCandidate,
  isOperatorsCandidate,
} from '~/framework/server-api/schedule/order/driver/assignableDriver';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { IOrderRoutingGroupItem } from '~/components/panels/schedule/r-order-form/routingGroup';
import { ErpOrderForm } from '~/components/common/r-erp-order-form/RErpOrderForm';
import { IErpOrderData } from '~/framework/server-api/schedule/order/erpOrder';
import { IErpClientData } from '~/framework/server-api/masters/erpClient';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';

export interface IOrderFormPanelOption
  extends IOpenPanelOption,
    ICacheablePanelOption,
    // TODO: InitialFormValues を過不足なく指定しなくてはならないが、
    // 一部省略可能にし、省略した場合はデフォルトで上書きされるようにする
    IInitializablePanelOption<IFormValues>,
    IEntitlablePanelOption {
  scrollTarget?: Maybe<OrderScrollTarget>;
}

export enum OrderScrollTarget {
  PreloadStatus = 'preload-status',
}

/**
 * Composite な OrderEntity を編集するためのもの
 */
export interface IOrderFormPanel extends IEntityFormPanel<IOrderEntity, IOrderFormPanelOption> {}

export class OrderFormPanel
  extends AbstractEntityFormPanel<IOrderEntity, IOrderFormPanelOption>
  implements IOrderFormPanel {}

/**
 * フォームの値
 */
export interface IFormValues {
  orderGroupId: Maybe<PersistentId>;
  dateCollectablePeriodType: DateCollectablePeriodTypes;
  dateCollectablePeriodItems: DateCollectablePeriodItem[];
  orderPlanCollectablePeriodItems: DateCollectablePeriodItem[];
  editOrderPlanCollectablePeriodItems: DateCollectablePeriodItem[];
  clientId: Maybe<PersistentId>;
  generationSiteId: Maybe<PersistentId>;
  generationSiteTaskCategory: GenerationSiteTaskCategory;
  irregularTask: IIrregularTaskItem;
  carNum: number;
  disposalSiteIds: PersistentId[];
  disposalSiteAssignmentType: OrderDisposalSiteAssignmentType;
  orderAssignedDisposalSites: IOrderAssignedDisposalSite[];
  generationSiteTasks: IGenerationSiteTaskItem[];
  durationAtGenerationSite: Maybe<number>;
  note: Maybe<string>;
  attachmentsToAdd: File[];
  attachmentsToRemove: string[];
  driverNum: number;
  avoidHighways: boolean;
  driverAssignmentType: DriverAssignmentType;
  isAssignableDriversCandidate: boolean;
  assignableDrivers: IOrderAssignableDriver[];
  assignableCarTypeIds: PersistentId[];
  assignedCarId: Maybe<PersistentId>;
  routeCollectionAllowed: boolean;
  routingGroup: Maybe<IOrderRoutingGroupItem>;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: Maybe<string>;
  schedulingPriority: OrderSchedulingPriority;
  preloadStatus: PreloadStatus;
  fixedArrivalTime: Maybe<number>;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  recurringSettings: Maybe<IRecurringOrderSettings>;
  createdBy: Maybe<UserEntity>;
  createdAt: Maybe<Date>;
  updatedBy: Maybe<UserEntity>;
  updatedAt: Maybe<Date>;
  erpOrderForm: Maybe<ErpOrderForm>;
}

/**
 * 既存の受注情報を与えて、フォームに初期値として表示するためのデータを取得する
 *
 * @param order 既存の受注情報
 */
export const getInitialFormValuesByOrder = (
  order: IOrderEntity,
  canUseErp?: boolean,
  erpOrder?: IErpOrderData
): IFormValues => {
  const generationSiteTasks: IGenerationSiteTaskItem[] = [];
  const erpOrderItemsMapByGenerationSiteId = new Map(
    erpOrder?.erpOrderItems.map((item) => [item.generationSiteTaskId, item])
  );

  for (const task of order.generationSiteTasks) {
    const erpOrderItem = erpOrderItemsMapByGenerationSiteId.get(task.id);
    generationSiteTasks.push(
      new GenerationSiteTaskItem(
        task.taskType,
        task.wasteTypeId,
        task.containerTypeId,
        task.containerNum,
        erpOrderItem?.apparentQuantity,
        erpOrderItem?.apparentQuantityUnit
      )
    );
  }
  const generationSiteTaskCategory =
    0 < order.generationSiteTasks.length
      ? GenerationSiteTaskCategory.TaskWithContainer
      : 0 < order.irregularTasks.length
      ? GenerationSiteTaskCategory.Irregular
      : GenerationSiteTaskCategory.TaskWithContainer;

  const irregularTask = _.first(order.irregularTasks);
  const irregularTaskItem =
    irregularTask === undefined
      ? IrregularTaskItemFactory.instantiateDefault()
      : IrregularTaskItemFactory.instantiateByEntity(irregularTask);

  let recurringSettings =
    order.recurringSettings === undefined ? undefined : buildByOrderRecurringSettings(order.recurringSettings);

  let dateCollectablePeriodItem: DateCollectablePeriodItem = new DateCollectablePeriodItem(
    order.date,
    order.collectablePeriodTemplateName,
    order.collectablePeriodStart,
    order.collectablePeriodEnd,
    order.unloadDate
  );

  // order.plan内でfixedかcandidateDatesのどちらかの値が存在する場合は初期値に入れる
  let orderPlanCollectablePeriodItems: DateCollectablePeriodItem[] = [];

  // 旧型データのみの場合はorder下のデータを入れる
  if (order.plan === undefined) {
    orderPlanCollectablePeriodItems = [dateCollectablePeriodItem];
  }

  // 単数データが入っている場合は配列にそのデータを1つ入れる
  if (order.plan?.fixed !== undefined) {
    orderPlanCollectablePeriodItems = [
      new DateCollectablePeriodItem(
        order.plan.fixed.date,
        order.plan.fixed.collectablePeriodTemplateName,
        order.plan.fixed.collectablePeriodStart,
        order.plan.fixed.collectablePeriodEnd,
        order.plan.fixed.unloadDate
      ),
    ];
    // 単数データを入れた場合deprecated側の項目にも同じものを入れる
    dateCollectablePeriodItem = new DateCollectablePeriodItem(
      order.plan.fixed.date,
      order.plan.fixed.collectablePeriodTemplateName,
      order.plan.fixed.collectablePeriodStart,
      order.plan.fixed.collectablePeriodEnd,
      order.plan.fixed.unloadDate
    );
  }

  // 複数データが入っている場合は配列にそのデータを入れる
  if (order.plan?.candidateDates !== undefined) {
    orderPlanCollectablePeriodItems = order.plan.candidateDates.map(
      (date) =>
        new DateCollectablePeriodItem(
          date.date,
          date.collectablePeriodTemplateName,
          date.collectablePeriodStart,
          date.collectablePeriodEnd,
          date.unloadDate
        )
    );
    // 複数データが入っている場合にはその配列内の最も若い日付をdeprecated側の項目に入れる
    const firstItem = orderPlanCollectablePeriodItems[0];
    dateCollectablePeriodItem = new DateCollectablePeriodItem(
      firstItem.date,
      firstItem.collectablePeriodTemplateName,
      firstItem.collectablePeriodStart,
      firstItem.collectablePeriodEnd,
      firstItem.unloadDate
    );
  }

  // 時間厳守の場合はsetCollectableDistinctTimeを呼ぶ
  dateCollectablePeriodItem.setCollectableDistinctTime();
  orderPlanCollectablePeriodItems.forEach((item) => {
    item.setCollectableDistinctTime();
  });

  // 基本はデフォルト
  let dateCollectablePeriodType = DateCollectablePeriodTypes.Default;
  // recurringSettingsがある場合はrecurring
  if (recurringSettings !== undefined) {
    dateCollectablePeriodType = DateCollectablePeriodTypes.Recurring;
    // 繰り返し受注の場合はのorderPlanCollectablePeriodItemsを空にする
    orderPlanCollectablePeriodItems = [];
  }
  // orderPlanCollectablePeriodItems2以上ある場合はplanにしてもしrecurringSettingsがある場合は消す
  if (orderPlanCollectablePeriodItems.length > 1) {
    dateCollectablePeriodType = DateCollectablePeriodTypes.Plan;
    recurringSettings = undefined;
  }

  let erpOrderForm: Maybe<ErpOrderForm>;

  if (canUseErp) {
    erpOrderForm = erpOrder ? getErpOrderFormValuesByErpOrder(erpOrder) : undefined;
  }

  return {
    orderGroupId: order.orderGroupId,
    dateCollectablePeriodType,
    dateCollectablePeriodItems: [dateCollectablePeriodItem],
    orderPlanCollectablePeriodItems,
    editOrderPlanCollectablePeriodItems: orderPlanCollectablePeriodItems,
    clientId: order.clientId,
    generationSiteId: order.generationSiteId,
    generationSiteTaskCategory,
    irregularTask: irregularTaskItem,
    // NOTE: 稼ぎ頭連携オンの場合は複数車台設定できないので、1台で初期化する
    carNum: canUseErp ? 1 : order.carNum,
    disposalSiteIds: order.orderAssignedDisposalSites.map(
      (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSiteId
    ),
    disposalSiteAssignmentType: order.disposalSiteAssignmentType,
    orderAssignedDisposalSites: order.orderAssignedDisposalSites,
    generationSiteTasks,
    durationAtGenerationSite: order.durationAtGenerationSite,
    note: order.note,
    attachmentsToAdd: [],
    attachmentsToRemove: [],
    driverNum: order.minAssignedDriverNum,
    avoidHighways: order.avoidHighways,
    driverAssignmentType: order.driverAssignmentType,
    isAssignableDriversCandidate:
      order.driverAssignmentType === DriverAssignmentType.NotDistinguished
        ? isDriversCandidate(order.minAssignedDriverNum, order.assignableDrivers)
        : isOperatorsCandidate(order.carNum, order.assignableDrivers) ||
          isHelpersCandidate(order.carNum, order.minAssignedDriverNum, order.assignableDrivers),
    assignableDrivers: order.assignableDrivers,
    assignableCarTypeIds: order.assignableCarTypeIds,
    assignedCarId: order.assignedCarId,
    routeCollectionAllowed: order.routeCollectionAllowed,
    routingGroup: order.routingGroup,
    fixedDisplayOnReservation: order.fixedDisplayOnReservation,
    fixedDisplayOnReservationName: order.fixedDisplayOnReservationName,
    schedulingPriority: order.schedulingPriority,
    preloadStatus: order.preloadStatus,
    fixedArrivalTime: order.fixedArrivalTime,
    isFixedArrivalTimeReportNeeded: order.isFixedArrivalTimeReportNeeded,
    marginTypeOfFixedArrivalTime: order.marginTypeOfFixedArrivalTime,
    marginOfFixedArrivalTime: order.marginOfFixedArrivalTime,
    recurringSettings,
    createdBy: order.createdBy,
    createdAt: order.createdAt,
    updatedBy: order.updatedBy,
    updatedAt: order.updatedAt,
    erpOrderForm,
  };
};

export const getErpOrderFormValuesByErpOrder = (erpOrder: IErpOrderData): ErpOrderForm => {
  return {
    id: erpOrder.id,
    orderId: erpOrder.orderId,
    transportationClient: erpOrder.transportationClient,
    withDisposalItemFromScheduling: erpOrder.withDisposalItemFromScheduling,
    note: erpOrder.note,
    erpOrderItems: erpOrder.erpOrderItems,
  };
};

export const getDefaultInitialErpOrderFormValues = (transportationClients: IErpClientData[]): ErpOrderForm => {
  return {
    id: undefined,
    orderId: undefined,
    // NOTE: transportationClients[0] は自社を指すという仕様
    transportationClient: transportationClients[0],
    withDisposalItemFromScheduling: true,
    note: '',
    erpOrderItems: [],
  };
};
