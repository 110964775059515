
import Vue from 'vue';
import { Maybe, ValidationRule } from '~/framework/typeAliases';
import { required, maxLength } from '~/framework/view-models/rules';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RSideformInstance } from '~/components/common/r-sideform/componentType';
import { ICloseEntityFormArgs, IOpenEntityFormArgs } from '~/framework/view-models/panels/entityFormPanel';
import { IllegalStateException } from '~/framework/core/exception';
import { FormErrors, IFormErrors } from '~/components/panels/masters/r-car-form/errors';
import { FormValues, IFormValues } from '~/components/panels/masters/r-car-form/formValues';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { carSymbol, CarApplicationService } from '~/framework/application/masters/car/carApplicationService';
import { BaseSiteEntity } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { ICarMutationError } from '~/framework/server-api/masters/car';
import { formatDateForField } from '~/framework/services/date/date';
import {
  AdditionalInfoKeys,
  FormModeParams,
  FormTargetParams,
  RinEventFormComponentParam,
  RinEventFormTargetTypes,
  RinEventNames,
  ShortcutKeyParams,
} from '~/framework/services/rin-events/rinEventParams';
import { carTypeSymbol } from '~/framework/application/masters/car-type/carTypeApplicationService';
import { baseSiteSymbol } from '~/framework/application/masters/base-site/baseSiteApplicationService';

enum FormMode {
  Register,
  Edit,
}

type Masters = {
  carTypes: CarTypeEntity[];
  baseSites: BaseSiteEntity[];
};

type DataType = {
  carApplicationService: CarApplicationService;
  isActive: boolean;
  isCloseConfirmDialogActive: boolean;
  isRegisterConfirmDialogActive: boolean;
  listenerDisposer: Maybe<() => void>;
  closeConfirmDialogResolver: Maybe<(value: boolean) => void>;
  registrationConfirmDialogResolver: Maybe<(value: boolean) => void>;
  FormMode: typeof FormMode;
  formMode: FormMode;
  title: string;
  isFormValid: boolean;
  formValues: Maybe<IFormValues>;
  masters: Maybe<Masters>;
  rules: { [key: string]: ValidationRule };
  initialFormValues: Maybe<IFormValues>;
  editingCar: Maybe<AggregatedCarEntity>;
  createdCar: Maybe<AggregatedCarEntity>;
  updatedCar: Maybe<AggregatedCarEntity>;
  isRegistering: boolean;
  formErrors: IFormErrors;
};

enum EventTypes {
  Change = 'change',
}

export default Vue.extend({
  name: 'RCarForm',
  model: {
    event: 'change',
    prop: 'isActive',
  },
  data(): DataType {
    const carApplicationService = this.$context.applications.get(carSymbol);
    return {
      carApplicationService,
      isActive: false,
      isCloseConfirmDialogActive: false,
      isRegisterConfirmDialogActive: false,
      listenerDisposer: undefined,
      closeConfirmDialogResolver: undefined,
      registrationConfirmDialogResolver: undefined,
      FormMode,
      formValues: undefined,
      masters: undefined,
      initialFormValues: undefined,
      title: '車の登録',
      formMode: FormMode.Register,
      isFormValid: true,
      editingCar: undefined,
      createdCar: undefined,
      updatedCar: undefined,
      isRegistering: false,
      rules: { required },
      formErrors: new FormErrors(),
    };
  },
  computed: {
    isRegisterButtonDisabled(): boolean {
      return !this.isFormValid || !this.canParkAtBaseSite;
    },
    isFormDirty(): boolean {
      return (
        this.initialFormValues?.name !== this.formValues?.name ||
        this.initialFormValues?.carTypeId !== this.formValues?.carTypeId ||
        this.initialFormValues?.baseSiteId !== this.formValues?.baseSiteId
      );
    },
    isEnabledOnDisabled(): boolean {
      return this.formMode === FormMode.Edit;
    },
    formTarget(): RinEventFormTargetTypes {
      return FormTargetParams.CAR;
    },
    // NOTE: baseSite が設定されていない or 取得できない場合はアラートを出さない
    canParkAtBaseSite(): boolean {
      if (this.formValues?.baseSiteId === undefined || this.masters?.baseSites === undefined) {
        return true;
      }

      const baseSiteId = this.formValues.baseSiteId;

      const baseSite = this.masters.baseSites.find((baseSite) => baseSite.id === baseSiteId);
      if (baseSite === undefined) {
        return true;
      }

      return baseSite.hasCars;
    },
  },
  mounted() {
    const openEventListenerDisposer = this.$context.panels.carFormPanel.openFormEvent.on(this.onOpen);
    this.$context.panels.carFormPanel.registerCloseHandler(this.onCloseForm);
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Panel
    );
    this.listenerDisposer = () => {
      openEventListenerDisposer.dispose();
      keyboardEventListenerDisposer.dispose();
    };
    this.carApplicationService.addCreatePresenter({
      create: (entity: AggregatedCarEntity) => this.onCreate(entity),
      errorOnCreate: (error: ICarMutationError) => this.onCreateError(error),
    });
    this.carApplicationService.addUpdatePresenter({
      update: (entity: AggregatedCarEntity) => this.onUpdate(entity),
      errorOnUpdate: (error: ICarMutationError) => this.onUpdateError(error),
    });
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    formatDateForField,
    maxLength,
    /**
     * @public
     */
    async open(car: Maybe<AggregatedCarEntity> = undefined): Promise<void> {
      if (this.isActive) return;
      await this.initializeForm(car);
      await (this.$refs.RSideform as RSideformInstance).open();
      this.isActive = true;
      this.$emit(EventTypes.Change, this.isActive);
    },
    /**
     * @public
     */
    async close(): Promise<void> {
      if (this.isActive === false) return;
      await (this.$refs.RSideform as RSideformInstance).close();
      this.isActive = false;
      this.$emit(EventTypes.Change, this.isActive);
      const closeArgs: ICloseEntityFormArgs<AggregatedCarEntity> = {
        entity: this.editingCar,
        createdEntity: this.createdCar,
        updatedEntity: this.updatedCar,
        removedEntity: undefined,
      };
      this.$context.panels.carFormPanel.closeFormEvent.emit(closeArgs);
    },
    async onOpen(args: IOpenEntityFormArgs<AggregatedCarEntity>): Promise<void> {
      await this.open(args.entity);
      await this.open();
    },
    async onCloseForm(): Promise<boolean> {
      if (this.formValues === undefined) throw new IllegalStateException(`Impossible`);
      if (this.initialFormValues === undefined) throw new IllegalStateException(`Impossible`);
      // 編集した状態であれば閉じてもよいかを確認し、閉じてよい場合のみ閉じる
      // 何も編集していない状態であれば閉じてもよい
      if (this.isFormDirty) {
        const closeConfirmDialogPromise = new Promise<boolean>((resolve) => {
          // ダイアログは画面全体を覆うのでこれが resolve されない事はない想定
          this.isCloseConfirmDialogActive = true;
          this.closeConfirmDialogResolver = resolve;
        });
        const isClosable = await closeConfirmDialogPromise;
        if (isClosable === false) return false;
      }
      await this.close();
      return true;
    },
    onConfirmClose(value: boolean): void {
      this.isCloseConfirmDialogActive = false;
      if (this.closeConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.closeConfirmDialogResolver(value);
    },
    onConfirmRegistrationClose(value: boolean): void {
      this.isRegisterConfirmDialogActive = false;
      if (this.registrationConfirmDialogResolver === undefined) throw new Error('Resolver has not been set!');
      this.registrationConfirmDialogResolver(value);
    },
    async initializeForm(car: Maybe<AggregatedCarEntity>): Promise<void> {
      const carTypeApplicationService = this.$context.applications.get(carTypeSymbol);
      const baseSiteApplicationService = this.$context.applications.get(baseSiteSymbol);
      const [carTypes, baseSites] = await Promise.all([
        carTypeApplicationService.getAll(),
        baseSiteApplicationService.getAll(),
      ]);
      this.masters = { carTypes, baseSites };
      this.editingCar = car;
      this.initialFormValues = this.getInitialFormValues(car);
      this.formValues = { ...this.initialFormValues };
      this.formErrors = new FormErrors();
      this.formMode = this.editingCar === undefined ? FormMode.Register : FormMode.Edit;
      this.title = this.formMode === FormMode.Register ? '車の登録' : '車の編集';
    },
    async onRegisterButtonClicked(): Promise<void> {
      const formValues = this.formValues;
      if (!formValues) throw new IllegalStateException(`Impossible!`);
      if (!formValues.name) throw new IllegalStateException(`Impossible!`);
      if (!formValues.carTypeId) throw new IllegalStateException(`Impossible!`);
      if (!formValues.baseSiteId) throw new IllegalStateException(`Impossible!`);

      this.isRegistering = true;
      this.formErrors.resetAllErrors();

      if (this.formMode === FormMode.Register) {
        await this.carApplicationService.create({
          name: formValues.name,
          carTypeId: formValues.carTypeId,
          baseSiteId: formValues.baseSiteId,
          enabledOn: formValues.enabledOn,
        });
      } else if (this.formMode === FormMode.Edit) {
        if (this.editingCar === undefined) throw new IllegalStateException(`Impossible!`);
        await this.carApplicationService.update({
          id: this.editingCar.persistentId,
          name: formValues.name,
          carTypeId: formValues.carTypeId,
          baseSiteId: formValues.baseSiteId,
        });
      }
      this.isRegistering = false;

      this.$rinGtm.push(RinEventNames.COMPLETE_INPUT, {
        [AdditionalInfoKeys.TARGET]: FormTargetParams.CAR,
        [AdditionalInfoKeys.MODE]: this.formMode === FormMode.Register ? FormModeParams.REGISTER : FormModeParams.EDIT,
      });
    },
    onCreate(entity: AggregatedCarEntity): void {
      this.createdCar = entity;
      this.$context.snackbar.success('車の登録完了');
      this.$nextTick(this.close);
    },
    onCreateError(error: ICarMutationError): void {
      this.handleFormError(error);
    },
    onUpdate(entity: AggregatedCarEntity): void {
      this.updatedCar = entity;
      this.$context.snackbar.success('車の編集完了');
      this.$nextTick(this.close);
    },
    onUpdateError(error: ICarMutationError): void {
      this.handleFormError(error);
    },
    handleFormError(error: ICarMutationError): void {
      for (const anError of error.errors) {
        if (anError.__typename === 'DuplicatedNameError') {
          this.formErrors.addError('name', '同じ名前が存在します。');
        }
      }
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.isActive === false) return;

      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventFormComponentParam);
        Vue.nextTick(this.onCloseForm);
        context.stop();
      }
    },
    onUpdateDisplayedWidth(value: number): void {
      this.$context.panels.carFormPanel.updateDisplayedWidth(value);
    },
    getInitialFormValues(car: Maybe<AggregatedCarEntity>): IFormValues {
      return car === undefined ? this.getDefaultInitialFormValues() : this.getInitialFormValuesByCar(car);
    },
    getDefaultInitialFormValues(): IFormValues {
      // `enabledOn` is initialized to client's current date.
      const date = new Date();
      const enabledOn = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      return new FormValues(undefined, undefined, undefined, enabledOn);
    },
    getInitialFormValuesByCar(car: AggregatedCarEntity): IFormValues {
      return new FormValues(car.name, car.carType.id, car.baseSite.id, car.enabledOn);
    },
  },
});
