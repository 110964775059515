import { IEntity } from '~/framework/core/entity';
import { Id, Maybe } from '~/framework/typeAliases';

export interface IEntityData {
  id: string;
}

export interface INamespace<Entity extends IEntity> {
  /**
   * ID 指定で複数取得する
   * @param ids
   */
  getByIds(ids: Id[]): Maybe<Entity>[];

  /**
   * ID 指定で取得する
   * @param id
   */
  getById(id: Id): Maybe<Entity>;

  /**
   * 複数で追加する
   */
  add(entities: Entity[]): void;

  /**
   * 単体で追加する
   * @param entity
   */
  add(entity: Entity): void;

  /**
   * 削除する
   * @param ids
   */
  delete(ids: Id[]): void;

  /**
   * 単体で削除する
   * @param id
   */
  delete(id: Id): void;

  /**
   * キャッシュされている ID とキャッシュされていない ID に分離する
   * @param ids
   */
  splitIds(ids: Id[]): [cached: Id[], nonCached: Id[]];
}

export abstract class NamespaceBase<Entity extends IEntity> implements INamespace<Entity> {
  protected map: Map<string, Entity>;

  constructor() {
    this.map = new Map<string, Entity>();
  }

  add(arg: Entity[] | Entity): void {
    if (arg instanceof Array) {
      this.adds(arg);
    } else {
      this.adds([arg]);
    }
  }

  delete(arg: Id[] | Id): void {
    if (arg instanceof Array) {
      this.deletes(arg);
    } else {
      this.deletes([arg]);
    }
  }

  getById(id: Id): Maybe<Entity> {
    return this.map.get(id);
  }

  getByIds(ids: Id[]): Maybe<Entity>[] {
    return ids.map((id) => this.map.get(id));
  }

  splitIds(ids: Id[]): [cached: Id[], nonCached: Id[]] {
    const cached: Id[] = [];
    const nonCached: Id[] = [];
    for (const id of ids) {
      if (this.map.has(id)) cached.push(id);
      else nonCached.push(id);
    }
    return [cached, nonCached];
  }

  private adds(entities: Entity[]): void {
    for (const entity of entities) {
      if (this.map.has(entity.id)) {
        throw new Error(`Entity id: ${entity.id} has already been registered!`);
      }
      this.map.set(entity.id, entity);
    }
  }

  private deletes(ids: Id[]): Entity[] {
    const deleted = [];
    for (const id of ids) {
      deleted.push(this.map.getOrError(id));
      this.map.delete(id);
    }
    return deleted;
  }
}
