import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedCarTypeContainerTypeEntity,
  AggregatedCarTypeContainerTypeEntityData,
} from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeEntity';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';

export class AggregatedCarTypeContainerTypeMapper extends StoredMapperBase<
  AggregatedCarTypeContainerTypeEntityData,
  AggregatedCarTypeContainerTypeEntity
> {
  protected store: AggregatedCarTypeContainerTypeStore;

  constructor(store: AggregatedCarTypeContainerTypeStore) {
    super();
    this.store = store;
  }

  protected instantiateWithData(data: AggregatedCarTypeContainerTypeEntityData): AggregatedCarTypeContainerTypeEntity {
    return new AggregatedCarTypeContainerTypeEntity(
      data.id,
      data.containerTypeId,
      data.emptyCount,
      data.emptyCountOccupation,
      data.lowerTierFullCount,
      data.upperTierFullCount,
      data.fullCountOccupation,
      data.isUpperTierAvailable,
      data.containerName,
      data.containerUnitName
    );
  }

  protected updateWithData(
    data: AggregatedCarTypeContainerTypeEntityData,
    entity: AggregatedCarTypeContainerTypeEntity
  ): void {
    entity.containerTypeId = data.containerTypeId;
    entity.emptyCount = data.emptyCount;
    entity.emptyCountOccupation = data.emptyCountOccupation;
    entity.lowerTierFullCount = data.lowerTierFullCount;
    entity.upperTierFullCount = data.upperTierFullCount;
    entity.fullCountOccupation = data.fullCountOccupation;
    entity.isUpperTierAvailable = data.isUpperTierAvailable;
    entity.containerName = data.containerName;
    entity.containerUnitName = data.containerUnitName;
  }
}
