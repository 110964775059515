import {
  DisposalSiteEntity,
  DisposalSiteEntityData,
} from '~/framework/domain/masters/disposal-site/disposalSiteEntity';
import { UserEntity, UserEntityData } from '~/framework/domain/masters/user/userEntity';
import { Maybe } from '~/framework/typeAliases';
import { ClientForDisposalSite } from '~/framework/server-api/masters/disposalSite';

export interface AggregatedDisposalSiteEntityData extends DisposalSiteEntityData {
  createdBy: UserEntityData;
}

export class AggregatedDisposalSiteEntity extends DisposalSiteEntity {
  createdBy: UserEntity;

  constructor(
    id: string,
    code: Maybe<string>,
    name: string,
    nameRuby: string,
    zipCode: Maybe<string>,
    address1: string,
    address2: string,
    address3: string,
    address4: Maybe<string>,
    latitude: number,
    longitude: number,
    disposablePeriodStart: number,
    disposablePeriodEnd: number,
    durationAtEntrance: number,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    acceptanceLimit: number,
    note: Maybe<string>,
    isAddressComplete: boolean,
    createdAt: Date,
    createdBy: UserEntity,
    client: Maybe<ClientForDisposalSite>,
    temporalAcceptanceInterval: Maybe<number>,
    temporalAcceptanceLimit: Maybe<number>
  ) {
    super(
      id,
      code,
      name,
      nameRuby,
      zipCode,
      address1,
      address2,
      address3,
      address4,
      latitude,
      longitude,
      disposablePeriodStart,
      disposablePeriodEnd,
      durationAtEntrance,
      restPeriodStart,
      restPeriodEnd,
      acceptanceLimit,
      note,
      isAddressComplete,
      createdAt,
      client,
      temporalAcceptanceInterval,
      temporalAcceptanceLimit
    );
    this.createdBy = createdBy;
  }
}
