import { EmploymentStatus, SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import employmentStatusMap from '~/assets/settings/employmentStatus.json';
import { ReactiveProperty } from '~/framework/property';

export interface DriverEntityData {
  id: string;
  name: string;
  nameRuby: string;
  employmentStatus: EmploymentStatus;
  defaultPrimaryCarId: Maybe<string>;
  licensedCarTypeIds: string[];
  defaultAttendanceTemplateId: Maybe<string>;
  status: SoftDeleteStatus;
  signInUrl: string;
}

export class DriverEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  nameRuby: string;
  employmentStatus: EmploymentStatus;
  defaultPrimaryCarId: Maybe<string>;
  licensedCarTypeIds: string[];
  defaultAttendanceTemplateId: Maybe<string>;
  @ReactiveProperty(DriverEntity.prototype.updateEmploymentStatusName)
  status: SoftDeleteStatus;

  signInUrl: string;

  employmentStatusName!: string;

  constructor(
    id: string,
    name: string,
    nameRuby: string,
    employmentStatus: EmploymentStatus,
    defaultPrimaryCarId: Maybe<string>,
    licensedCarTypeIds: string[],
    defaultAttendanceTemplateId: Maybe<string>,
    status: SoftDeleteStatus,
    signInUrl: string
  ) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.nameRuby = nameRuby;
    this.employmentStatus = employmentStatus;
    this.defaultPrimaryCarId = defaultPrimaryCarId;
    this.licensedCarTypeIds = licensedCarTypeIds;
    this.defaultAttendanceTemplateId = defaultAttendanceTemplateId;
    this.status = status;
    this.signInUrl = signInUrl;
    this.updateEmploymentStatusName();
  }

  private updateEmploymentStatusName() {
    this.employmentStatusName = employmentStatusMap[this.employmentStatus];
  }
}
