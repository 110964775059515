import { IContainerTypeData } from './containerType';
import { IPackingStyleData } from './packingStyle';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { IUserData } from '~/framework/server-api/masters/user';

import { ClientsByKeywordsOrder, ClientPackingStyleReservationSettingInput } from '~/framework/server-api/typeAliases';
import { IConnection } from '~/framework/server-api/connection';

import { Maybe } from '~/framework/typeAliases';

export const client$createSymbol = Symbol('client$create');

export const client$getAllSymbol = Symbol('client$getAll');

export const client$getByIdsSymbol = Symbol('client$getByIds');

export const client$getByKeywordsSymbol = Symbol('client$getByKeywords');

export const client$getWithGenerationSiteNumByIdsSymbol = Symbol('client$getWithGenerationSiteNumByIdsSymbol');

export const client$updateSymbol = Symbol('client$update');

export type Client = {
  [client$getAllSymbol]: IGetAllApi;
  [client$getByIdsSymbol]: IGetByIdsApi;
  [client$getByKeywordsSymbol]: IGetByKeywordsApi;
  [client$createSymbol]: ICreateApi;
  [client$updateSymbol]: IUpdateApi;
  [client$getWithGenerationSiteNumByIdsSymbol]: IGetWithGenerationSiteNumByIdsApi;
};

export interface IClientData {
  id: string;
  code: Maybe<string>;
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  createdAt: Date;
  createdBy: IUserData;
  status: SoftDeleteStatus;
  signInUrl: Maybe<string>;
  updatedAt: Date;
  updatedBy: IUserData;
  reservationSiteEnabled: boolean;
  packingStyleReservationSettings: IPackingStyleReservationSetting[];
}

export interface IPackingStyleReservationSetting {
  packingStyle: IPackingStyleData;
  visible: boolean;
  containerTypeReservationSettings: IClientContainerTypeReservationSetting[];
}

export interface IClientContainerTypeReservationSetting {
  containerType: IContainerTypeData;
  visible: Maybe<boolean>;
}

export interface IRichClientData extends IClientData {
  generationSiteNum: number;
}

export interface ICreateApi {
  create(data: IClientCreateData[]): Promise<IClientCreateResultData[]>;
}

export interface IClientCreateResultData {
  client: IClientData;
}

export interface IClientCreateData {
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  status: SoftDeleteStatus;
}

export interface IGetAllApi {
  getAll(): Promise<IClientData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<IClientData[]>;
}

export { ClientsByKeywordsOrder } from '~/framework/server-api/typeAliases';
export interface IGetByKeywordsApi {
  /**
   * 最低限の情報を取得する
   * @param first
   * @param after
   * @param condition
   */
  getByKeywords(first: number, after: Maybe<string>, condition: ICondition): Promise<IConnection<IClientData>>;

  /**
   * 排出場の数込みで取得する
   * @param first
   * @param after
   * @param condition
   */
  getWithOptionalsByKeywords(
    first: number,
    after: Maybe<string>,
    condition: ICondition
  ): Promise<IConnection<IRichClientData>>;
}

export interface ICondition {
  keywords: Maybe<string[]>;
  since: Maybe<Date>;
  orderBy: ClientsByKeywordsOrder;
}

export interface IGetWithGenerationSiteNumByIdsApi {
  getWithGenerationSiteNumByIds(ids: string[]): Promise<IRichClientData[]>;
}

export interface IUpdateApi {
  update(data: IClientUpdateData[]): Promise<IClientUpdateResultData[]>;
}

export interface IClientUpdateResultData {
  client: IClientData;
}

export interface IClientUpdateData {
  id: string;
  name: string;
  nameRuby: string;
  note: Maybe<string>;
  status: SoftDeleteStatus;
  packingStyleReservationSettings: Maybe<Array<ClientPackingStyleReservationSettingInput>>;
}
