import { AbstractEntityFormPanel } from '~/framework/view-models/panels/abstractEntityFormPanel';
import { IEntityFormPanel } from '~/framework/view-models/panels/entityFormPanel';
import { IRoutingRegulationEntity } from '~/framework/domain/masters/routing-regulation/routingRegulationEntity';

/**
 * RoutingRegulation を編集するためのもの
 */

export interface IRoutingRegulationFormPanel extends IEntityFormPanel<IRoutingRegulationEntity> {}

export class RoutingRegulationFormPanel
  extends AbstractEntityFormPanel<IRoutingRegulationEntity>
  implements IRoutingRegulationFormPanel {}
