
import Vue, { PropType } from 'vue';
import _ from 'lodash';
import RPlanCandidateDatesCollectablePeriodInput from './RPlanCandidateDatesCollectablePeriodInput.vue';
import { PreloadStatus } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { formatDateForField } from '~/framework/services/date/date';
import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';
import { IBusinessDaysService } from '~/framework/services/business-days/businessDaysService';
import { OrderForm } from '~/components/panels/schedule/r-order-form/ROrderForm';
import { NationalHolidayService } from '~/framework/domain/masters/holiday-rule/nationalHolidayService';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import RCollectablePeriodInput from '~/components/panels/schedule/r-order-form/RCollectablePeriodInput.vue';
import {
  DesignatedTimePeriodOption,
  DesignatedTimePeriodOptionId,
  DistinctTimeOption,
  DistinctTimeOptionId,
} from '~/framework/view-models/collectablePeriodTemplateOption';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';

const nationalHolidayService = new NationalHolidayService();

type DataType = {
  step: number;
  startDate: Date;
  endDate: Maybe<Date>;
  isCollectableTimeDistinct: boolean;
  collectablePeriodTemplateId: Maybe<string>;
  collectablePeriodTemplateName: Maybe<string>;
  collectableDistinctTime: Maybe<number>;
  collectablePeriodStart: Maybe<number>;
  collectablePeriodEnd: Maybe<number>;
  collectableDistinctTimeErrorMessages: string[];
  collectablePeriodStartErrorMessages: string[];
  collectablePeriodEndErrorMessages: string[];
  startDateErrorMessages: string[];
  endDateErrorMessages: string[];
  periodInputErrorMessages: string[];
  holidayCandidates: IHolidayCandidate[];
  dateCollectablePeriodItemValues: DateCollectablePeriodItem[];
  PreloadStatus: typeof PreloadStatus;
  // 入力できる複数候補日の最大数
  maxCandidateDatesCount: number;
  // 項目数操作中
  isUpdating: boolean;
};

const HolidayCandidatesTypes = {
  Monday: '月',
  Tuesday: '火',
  Wednesday: '水',
  Thursday: '木',
  Friday: '金',
  Saturday: '土',
  Sunday: '日',
  Holiday: '祝日',
} as const;

enum EventTypes {
  Input = 'input',
  ClickComplete = 'click:complete',
  UpdateDate = 'update:date',
  UpdateUnloadDate = 'update:unload-date',
  UpdateCollectablePeriodTemplateId = 'update:collectable-period-template-id',
  UpdateCollectablePeriodTemplateName = 'update:collectable-period-template-name',
  UpdateCollectableDistinctTime = 'update:collectable-distinct-time',
  UpdateCollectablePeriodStart = 'update:collectable-period-start',
  UpdateCollectablePeriodEnd = 'update:collectable-period-end',
}

type IHolidayCandidate = {
  dayType: string;
  isSelected: boolean;
};

export default Vue.extend({
  name: 'RPlanCandidateDatesPeriodSetting',
  components: {
    RPlanCandidateDatesCollectablePeriodInput,
    RCollectablePeriodInput,
  },
  props: {
    viewModel: {
      type: Object as PropType<OrderForm>,
      required: false,
      default: undefined,
    },
    holidayRules: {
      type: Object as PropType<HolidayRuleEntity>,
      required: true,
    },
    businessDaysService: {
      type: Object as PropType<IBusinessDaysService>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      step: 1,
      startDate: new Date(),
      endDate: undefined,
      isCollectableTimeDistinct: false,
      collectablePeriodTemplateId: undefined,
      collectablePeriodTemplateName: undefined,
      collectableDistinctTime: undefined,
      collectablePeriodStart: undefined,
      collectablePeriodEnd: undefined,
      collectableDistinctTimeErrorMessages: [],
      collectablePeriodStartErrorMessages: [],
      collectablePeriodEndErrorMessages: [],
      startDateErrorMessages: [],
      endDateErrorMessages: [],
      periodInputErrorMessages: [],
      holidayCandidates: [
        { dayType: HolidayCandidatesTypes.Monday, isSelected: false },
        { dayType: HolidayCandidatesTypes.Tuesday, isSelected: false },
        { dayType: HolidayCandidatesTypes.Wednesday, isSelected: false },
        { dayType: HolidayCandidatesTypes.Thursday, isSelected: false },
        { dayType: HolidayCandidatesTypes.Friday, isSelected: false },
        { dayType: HolidayCandidatesTypes.Saturday, isSelected: false },
        { dayType: HolidayCandidatesTypes.Holiday, isSelected: false },
        { dayType: HolidayCandidatesTypes.Sunday, isSelected: false },
      ],
      dateCollectablePeriodItemValues: [],
      PreloadStatus,
      // 日数生成上限
      maxCandidateDatesCount: 60,
      isUpdating: false,
    };
  },
  computed: {
    isNextButtonDisabled(): boolean {
      return false;
    },
    isCompleteButtonDisabled(): boolean {
      return this.viewModel.editOrderPlanCollectablePeriodItems.some((item: DateCollectablePeriodItem) => {
        // 時間厳守の場合は collectablePeriodStart のみをチェックする
        const isDistinctTime = item.collectablePeriodTemplateId === DistinctTimeOptionId;
        if (isDistinctTime) return item.date === undefined || item.collectablePeriodStart === undefined;

        return (
          item.date === undefined ||
          item.collectablePeriodTemplateId === undefined ||
          item.collectablePeriodStart === undefined ||
          item.collectablePeriodEnd === undefined
        );
      });
    },
    // 削除ボタンは1つの場合は非表示
    isDeleteButtonDisplayed(): boolean {
      return this.viewModel.editOrderPlanCollectablePeriodItems.length > 1;
    },
    selectedCollectablePeriodTemplateEntity(): Maybe<CollectablePeriodTemplateEntity> {
      if (this.collectablePeriodTemplateId === undefined) return;
      if (
        this.collectablePeriodTemplateId === DistinctTimeOptionId ||
        this.collectablePeriodTemplateId === DesignatedTimePeriodOptionId
      )
        return;

      return this.viewModel.collectablePeriodTemplateEntities.find(
        (template: CollectablePeriodTemplateEntity) => template.id === this.collectablePeriodTemplateId
      );
    },
  },
  mounted(): void {
    this.init();
  },
  methods: {
    formatDateForField,
    init(): void {
      // 開始日は翌日
      // 先頭のパラメータを設定の基本値として入力して、複数候補日の場合はStep2へ単数の場合は1へ
      // 編集アイテムが複数ある場合はその先頭のものをデフォルトに、ない場合はデフォルトのものを入れる
      const collectableItem =
        this.viewModel.editOrderPlanCollectablePeriodItems.length > 1
          ? this.viewModel.editOrderPlanCollectablePeriodItems[0]
          : this.viewModel.defaultDateCollectablePeriodItem;
      this.startDate = collectableItem.date ? collectableItem.date : this.nextBusinessDay(new Date());
      this.endDate = undefined;

      this.setTemplate(collectableItem);
      this.initErrorMessages();
      // init時にmasterのデフォルトを反映する
      this.holidayCandidates = [
        { dayType: HolidayCandidatesTypes.Monday, isSelected: this.holidayRules.monday },
        { dayType: HolidayCandidatesTypes.Tuesday, isSelected: this.holidayRules.tuesday },
        { dayType: HolidayCandidatesTypes.Wednesday, isSelected: this.holidayRules.wednesday },
        { dayType: HolidayCandidatesTypes.Thursday, isSelected: this.holidayRules.thursday },
        { dayType: HolidayCandidatesTypes.Friday, isSelected: this.holidayRules.friday },
        { dayType: HolidayCandidatesTypes.Saturday, isSelected: this.holidayRules.saturday },
        { dayType: HolidayCandidatesTypes.Sunday, isSelected: this.holidayRules.sunday },
        { dayType: HolidayCandidatesTypes.Holiday, isSelected: this.holidayRules.nationalHoliday },
      ];
      this.dateCollectablePeriodItemValues = [];

      if (this.viewModel.editOrderPlanCollectablePeriodItems.length > 1) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    },
    back(): void {
      const items = this.viewModel.editOrderPlanCollectablePeriodItems;

      // 戻る際に入力されている候補日の最も早い日を開始日、遅い日を終了日として設定する
      const firstItem = items[0];
      const lastItem = items[items.length - 1];
      if (firstItem?.date) {
        this.startDate = firstItem.date;
      }
      if (lastItem?.date) {
        this.endDate = lastItem.date;
      }
      this.setTemplate(firstItem);
      this.initErrorMessages();
      this.step = 1;
    },
    setTemplate(item: DateCollectablePeriodItem): void {
      // 最も早い日のテンプレートを適用する
      this.collectablePeriodTemplateName = item.collectablePeriodTemplateName;
      this.collectablePeriodTemplateId = item.collectablePeriodTemplateId;

      if (this.collectablePeriodTemplateId !== undefined && this.collectablePeriodTemplateName !== undefined) {
        if (this.collectablePeriodTemplateId === DistinctTimeOptionId) {
          // 時間厳守の場合
          this.isCollectableTimeDistinct = true;
          this.collectableDistinctTime = item.collectableDistinctTime;
        } else if (this.collectablePeriodTemplateId === DesignatedTimePeriodOptionId) {
          this.isCollectableTimeDistinct = false;
          this.collectablePeriodTemplateName = DesignatedTimePeriodOption.name;
          this.collectablePeriodStart = item.collectablePeriodStart;
          this.collectablePeriodEnd = item.collectablePeriodEnd;
        } else {
          // 時間厳守以外の普通のテンプレの場合
          this.isCollectableTimeDistinct = false;
          const collectablePeriodTemplate = this.viewModel.collectablePeriodTemplateEntities.find(
            (template: any) => template.id === this.collectablePeriodTemplateId
          );
          if (collectablePeriodTemplate === undefined) return;
          this.collectablePeriodStart = collectablePeriodTemplate.collectablePeriodStart;
          this.collectablePeriodEnd = collectablePeriodTemplate.collectablePeriodEnd;
        }
        // テンプレート名
        this.collectablePeriodTemplateName = item.collectablePeriodTemplateName;
      } else {
        // テンプレートがない場合
        this.isCollectableTimeDistinct = false;
        this.collectablePeriodStart = undefined;
        this.collectablePeriodEnd = undefined;
        this.collectablePeriodTemplateName = undefined;
      }
    },
    initErrorMessages(): void {
      this.collectableDistinctTimeErrorMessages = [];
      this.collectablePeriodStartErrorMessages = [];
      this.collectablePeriodEndErrorMessages = [];
      this.startDateErrorMessages = [];
      this.endDateErrorMessages = [];
      this.periodInputErrorMessages = [];
    },
    close(): void {
      this.$emit('close');
    },
    complete(): void {
      this.$emit('confirm');
      this.close();
    },
    onChangeStartDate(): void {
      this.startDateErrorMessages = [];
      if (this.startDate === undefined) {
        this.startDateErrorMessages.push('必須項目です');
        return;
      }
      if (this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
        this.startDateErrorMessages.push('終了日よりも後の日付は選択できません');
        return;
      }
      // endDateが入力されていてstartを変更して日数オーバーした場合
      if (this.endDate !== undefined) {
        const diff = this.endDate.getTime() - this.startDate.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        if (diffDays > this.maxCandidateDatesCount) {
          this.startDateErrorMessages.push(`候補日は${this.maxCandidateDatesCount}日以内で設定してください`);
          return;
        }
      }

      this.endDateErrorMessages = [];
    },
    onChangeEndDate(): void {
      this.endDateErrorMessages = [];
      if (this.endDate === undefined) {
        this.endDateErrorMessages.push('必須項目です');
        return;
      }
      if (this.startDate.getTime() > this.endDate.getTime()) {
        this.endDateErrorMessages.push('開始日よりも前の日付は選択できません');
        return;
      }
      const diff = this.endDate.getTime() - this.startDate.getTime();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays > this.maxCandidateDatesCount) {
        this.endDateErrorMessages.push(`候補日は${this.maxCandidateDatesCount}日以内で設定してください`);
        return;
      }
      this.startDateErrorMessages = [];
    },
    onChangeBaseCollectablePeriodTemplate(_args: any): void {
      const collectablePeriodTemplateId = this.collectablePeriodTemplateId;

      if (collectablePeriodTemplateId !== undefined) {
        if (collectablePeriodTemplateId === DistinctTimeOptionId) {
          // 時間厳守の場合
          this.isCollectableTimeDistinct = true;
          this.collectableDistinctTime = this.collectablePeriodStart;
          this.collectablePeriodTemplateName = DistinctTimeOption.name;
        } else if (collectablePeriodTemplateId === DesignatedTimePeriodOptionId) {
          this.isCollectableTimeDistinct = false;
          this.collectablePeriodTemplateName = DesignatedTimePeriodOption.name;
        } else {
          // 時間厳守以外の普通のテンプレの場合
          this.isCollectableTimeDistinct = false;
          const collectablePeriodTemplate = this.viewModel.collectablePeriodTemplateEntities.find(
            (template: any) => template.id === collectablePeriodTemplateId
          );
          if (collectablePeriodTemplate === undefined) return;
          this.collectablePeriodStart = collectablePeriodTemplate.collectablePeriodStart;
          this.collectablePeriodEnd = collectablePeriodTemplate.collectablePeriodEnd;
        }
        // テンプレート名を取得
        this.collectablePeriodTemplateName =
          this.viewModel.collectablePeriodTemplateMap.getOrError(collectablePeriodTemplateId).name;
      }
      // 入力が入った場合にエラーを消す
      this.periodInputErrorMessages = [];
    },
    onChangeCollectableDistinctTime(value: Maybe<number>): void {
      this.collectableDistinctTime = value;
    },
    onChangeCollectablePeriodStart(value: Maybe<number>): void {
      this.collectablePeriodStart = value;

      if (value === undefined) return;

      // NOTE: テンプレート設定中に値を変更せずにイベントが発火する場合はテンプレートを変更しない
      // 例: [午前中] 8:00 ~ 12:00 が設定されているときの time-picker にフォーカスして 8:00 を選択したときに値が変更されていないのにイベントが発火してしまうので、それをガードする
      if (
        this.selectedCollectablePeriodTemplateEntity !== undefined &&
        this.selectedCollectablePeriodTemplateEntity.collectablePeriodStart === value
      )
        return;

      // NOTE: 時間が変更されると、テンプレートを時間指定に変更する
      this.collectablePeriodTemplateId = DesignatedTimePeriodOptionId;
      this.collectablePeriodTemplateName = DesignatedTimePeriodOption.name;
    },
    onChangeCollectablePeriodEnd(value: Maybe<number>): void {
      this.collectablePeriodEnd = value;

      if (value === undefined) return;

      // NOTE: テンプレート設定中に値を変更せずにイベントが発火する場合はテンプレートを変更しない
      // 例: [午前中] 8:00 ~ 12:00 が設定されているときの time-picker にフォーカスして 8:00 を選択したときに値が変更されていないのにイベントが発火してしまうので、それをガードする
      if (
        this.selectedCollectablePeriodTemplateEntity !== undefined &&
        this.selectedCollectablePeriodTemplateEntity.collectablePeriodEnd === value
      )
        return;

      // NOTE: 時間が変更されると、テンプレートを時間指定に変更する
      this.collectablePeriodTemplateId = DesignatedTimePeriodOptionId;
      this.collectablePeriodTemplateName = DesignatedTimePeriodOption.name;
    },
    // 開始日と終了日を指定して、その間の日付の配列を返す
    getDateArray(startDate: Date, endDate: Date) {
      const dateArray = [];
      let countDay = Math.floor(startDate.getTime() / 86400000);
      const countDate = _.cloneDeep(startDate);
      const endDay = Math.floor(endDate.getTime() / 86400000);
      while (countDay <= endDay) {
        const isHoliday = nationalHolidayService.isHoliday(countDate, this.getEditHolidayRule());
        if (!isHoliday) {
          dateArray.push(_.cloneDeep(countDate));
        }
        countDate.setDate(countDate.getDate() + 1);
        countDay++;
      }

      return dateArray.slice(0, this.maxCandidateDatesCount);
    },
    getEditHolidayRule(): HolidayRuleEntity {
      // masterとは別のフォームで設定したholidayRuleを生成する
      const holidayRule = new HolidayRuleEntity(
        '-1',
        this.holidayCandidates[0].isSelected,
        this.holidayCandidates[1].isSelected,
        this.holidayCandidates[2].isSelected,
        this.holidayCandidates[3].isSelected,
        this.holidayCandidates[4].isSelected,
        this.holidayCandidates[5].isSelected,
        this.holidayCandidates[6].isSelected,
        this.holidayCandidates[7].isSelected
      );
      return holidayRule;
    },
    async onClickNextButton() {
      // startDateからendDateまでの日付の配列を取得する
      // バリデーション
      this.startDateErrorMessages = [];
      this.endDateErrorMessages = [];
      this.periodInputErrorMessages = [];

      if (this.endDate === undefined) {
        this.endDateErrorMessages.push('必須項目です');
        return;
      }

      if (this.startDate.getTime() > this.endDate.getTime()) {
        this.endDateErrorMessages.push('開始日よりも前の日付は選択できません');
        return;
      }

      // 時間厳守でない場合はテンプレート選択とStartとEndが必須
      if (!this.isCollectableTimeDistinct) {
        if (
          this.collectablePeriodTemplateId === undefined ||
          this.collectablePeriodStart === undefined ||
          this.collectablePeriodEnd === undefined
        ) {
          this.periodInputErrorMessages.push('必須項目です');
          return;
        }
      }
      // 時間厳守の場合はテンプレート選択とDistinctTimeが必須
      if (this.isCollectableTimeDistinct) {
        if (this.collectablePeriodTemplateId === undefined || this.collectableDistinctTime === undefined) {
          this.periodInputErrorMessages.push('必須項目です');
          return;
        }
      }

      // 開始日と終了日が最大日数以上離れている場合はエラー
      // dateArray計算前に弾く
      const diff = this.endDate.getTime() - this.startDate.getTime();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays > this.maxCandidateDatesCount) {
        this.endDateErrorMessages.push(`開始日と終了日の間は${this.maxCandidateDatesCount}日以内にしてください`);
        return;
      }

      const dateArray = this.getDateArray(this.startDate, this.endDate);

      // dateArrayが空ならアラート出してStep2に遷移しない
      if (dateArray.length === 0) {
        this.$context.snackbar.error('設定できる候補日が存在しません');
        return;
      }

      // 最大14個まで
      // 14個以上ならアラート出して14個まで減らしてStep2に遷移
      if (dateArray.length > this.maxCandidateDatesCount) {
        this.$context.snackbar.warning(`設定できる候補日は最大${this.maxCandidateDatesCount}日分です`);
      }

      // dateArrayの各要素に対して、dateCollectablePeriodItemValuesの各要素を適用する
      const orderPlanCollectablePeriodItems = dateArray.map((date) => {
        // PreloadStatus.Forcedの場合は宵積みが有効で、dateの翌営業日を入れる
        const item = new DateCollectablePeriodItem(
          date,
          this.collectablePeriodTemplateName,
          this.collectablePeriodStart,
          this.collectablePeriodEnd,
          this.viewModel.preloadStatus === PreloadStatus.Forced ? this.nextBusinessDay(date) : undefined,
          undefined,
          this.collectablePeriodTemplateId,
          this.collectableDistinctTime
        );
        return item;
      });

      this.isUpdating = true;
      this.viewModel.setEditOrderPlanCollectablePeriodItems(orderPlanCollectablePeriodItems);
      await this.$nextTick();
      this.isUpdating = false;

      this.step = 2;
    },
    async onAddItemButtonClicked() {
      // 項目数が最大数を超えていた場合はエラー通知を出して追加しない
      if (this.viewModel.editOrderPlanCollectablePeriodItems.length >= this.maxCandidateDatesCount) {
        this.$context.snackbar.error(`追加できる日数は最大${this.maxCandidateDatesCount}日分です`);
        return;
      }
      // 空のdateCollectablePeriodItemを生成する
      const lastItem = _.cloneDeep(
        this.viewModel.editOrderPlanCollectablePeriodItems[
          this.viewModel.editOrderPlanCollectablePeriodItems.length - 1
        ]
      );
      const currentDate = lastItem.date ?? new Date();

      const nextDate = this.nextBusinessDay(currentDate);

      const item = new DateCollectablePeriodItem(
        // 入力されている項目の次の日付で初期化する、入力されていない場合は今日の次の日付で初期化する
        nextDate,
        lastItem.collectablePeriodTemplateName,
        lastItem.collectablePeriodStart,
        lastItem.collectablePeriodEnd,
        this.viewModel.preloadStatus === PreloadStatus.Forced ? this.nextBusinessDay(nextDate) : undefined,
        undefined,
        lastItem.collectablePeriodTemplateId,
        lastItem.collectableDistinctTime
      );
      this.viewModel.addEditOrderPlanCollectablePeriodItem(item);

      // 一番下までスクロールする
      await this.$nextTick();
      const targetElement = document.getElementById('r-plan-candidate-dates-period-setting__scroll-target');
      const contentElement = document.getElementById('r-plan-candidate-dates-period-setting__scroll-content');

      if (targetElement && contentElement) {
        targetElement.scrollTo({ left: 0, top: contentElement.scrollHeight, behavior: 'smooth' });
      }
    },
    // 入力した日付に対して最も若い次の営業日を返す
    nextBusinessDay(baseDate: Date) {
      const date = _.cloneDeep(baseDate);
      date.setDate(date.getDate() + 1);
      while (nationalHolidayService.isHoliday(date, this.getEditHolidayRule())) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    },
    async onDeleteItemButtonClicked(index: number) {
      // 宵積みの日付のwatchに引っかかってアラートが大量に出るのでフラグ管理で回避する
      this.isUpdating = true;
      this.viewModel.deleteEditOrderPlanCollectablePeriodItem(index);
      await this.$nextTick();
      this.isUpdating = false;
    },
    onChangeUnloadDate(value: string) {
      this.$emit(EventTypes.UpdateUnloadDate, value);
    },
  },
});
