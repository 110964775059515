import { GenerationSitesByKeywordsCondition } from '~/framework/server-api/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { IConnection, LazySearchBase } from '~/components/common/r-lazy-searchable-pulldown/lazySearch';
import { generationSiteSymbol } from '~/framework/application/masters/generation-site/generationSiteApplicationService';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { IListItem } from '~/framework/application/masters/generation-site/selection';

export class GenerationSite extends LazySearchBase<GenerationSitesByKeywordsCondition, GenerationSiteEntity> {
  protected async loadConnection(
    first: number,
    after: Maybe<string>,
    condition: GenerationSitesByKeywordsCondition
  ): Promise<IConnection<GenerationSiteEntity>> {
    const service = this.systemContext.applications.get(generationSiteSymbol);
    const list = (await service.searchSelection(first, after, condition)) as any;
    return {
      items: list.items.map((item: IListItem) => {
        return { ...item.entity, displayName: `${item.entity.name} [${item.entity.client?.name}]` };
      }),
      hasNextPage: list.pageInfo.hasNextPage,
      endCursor: list.pageInfo.endCursor,
    };
  }
}
