import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { IOriginalDisposalEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/originalDisposalEntity';

export interface IDisposalEntityData {
  /**
   * 内部的な ID
   */
  id: string;

  /**
   * 排出場の順番
   */
  index: number;

  /**
   * 排出場の ID
   */
  disposalSiteId: PseudoId;

  /**
   * 処分場到着時間
   * この時間は再計算で勝手に変更される可能性がある
   */
  disposalSiteArrivalTime: number;

  /**
   * 処分場出発時間
   * この時間は再計算で勝手に変更される可能性がある
   */
  disposalSiteDepartureTime: number;

  /**
   * 処分場到着前に休憩があるかどうか
   */
  hasRestBeforeDisposalSiteArrival: boolean;

  /**
   * 処分場の複数候補指定のうち一つ選ばれたかどうか
   */
  prioritizeAssignedDisposalSite: boolean;

  /**
   * 処分場の順番指定されているかどうか
   */
  sequentialAssignedDisposalSite: boolean;
}

export interface IDisposalEntity<
  Original extends IOriginalDisposalEntity,
  Clone extends IDisposalEntity<Original, Clone>
> extends IDisposalEntityData {
  /**
   * 編集前の処分場情報
   */
  original: Original;

  /**
   * 処分場にいる時間
   */
  disposalSiteDuration: number;

  /**
   * このオブジェクトの clone を返す
   */
  clone(): Clone;
}

export class DisposalEntity<Original extends IOriginalDisposalEntity>
  implements IDisposalEntity<Original, DisposalEntity<Original>>
{
  original: Original;
  id: string;
  index: number;
  disposalSiteId: PseudoId;
  disposalSiteArrivalTime: number;
  disposalSiteDepartureTime: number;
  hasRestBeforeDisposalSiteArrival: boolean;
  prioritizeAssignedDisposalSite: boolean;
  sequentialAssignedDisposalSite: boolean;

  get disposalSiteDuration() {
    return this.disposalSiteDepartureTime - this.disposalSiteArrivalTime;
  }

  constructor(
    original: Original,
    id: string,
    index: number,
    disposalSiteId: PseudoId,
    disposalSiteArrivalTime: number,
    disposalSiteDepartureTime: number,
    hasRestBeforeDisposalSiteArrival: boolean,
    prioritizeAssignedDisposalSite: boolean,
    sequentialAssignedDisposalSite: boolean
  ) {
    this.original = original;
    this.id = id;
    this.index = index;
    this.disposalSiteId = disposalSiteId;
    this.disposalSiteArrivalTime = disposalSiteArrivalTime;
    this.disposalSiteDepartureTime = disposalSiteDepartureTime;
    this.hasRestBeforeDisposalSiteArrival = hasRestBeforeDisposalSiteArrival;
    this.prioritizeAssignedDisposalSite = prioritizeAssignedDisposalSite;
    this.sequentialAssignedDisposalSite = sequentialAssignedDisposalSite;
  }

  clone(): DisposalEntity<Original> {
    return new DisposalEntity(
      this.original,
      this.id,
      this.index,
      this.disposalSiteId,
      this.disposalSiteArrivalTime,
      this.disposalSiteDepartureTime,
      this.hasRestBeforeDisposalSiteArrival,
      this.prioritizeAssignedDisposalSite,
      this.sequentialAssignedDisposalSite
    );
  }
}
