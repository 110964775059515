import { MapperBase } from '~/framework/core/mapper';
import {
  IrregularTaskEntityData,
  IrregularTaskEntity,
} from '~/framework/domain/schedule/order/irregular-task/irregularTaskEntity';

/**
 * トラックする必要はないので Mapper は厳密には必要ないが、同じ作法にしておいた方が
 * 分かりやすそうなので用意している
 */

export class IrregularTaskMapper extends MapperBase<IrregularTaskEntityData, IrregularTaskEntity> {
  protected instantiateWithData(data: IrregularTaskEntityData): IrregularTaskEntity {
    return new IrregularTaskEntity(
      data.id,
      data.orderId,
      data.name,
      data.durationAtGenerationSite,
      data.durationAtDisposalSite,
      data.skipDisposalSite
    );
  }
}
