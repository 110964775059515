import { TaskType } from '~/framework/domain/typeAliases';
import {
  ReservationGenerationSiteTask,
  ReservationGenerationSiteTaskData,
} from '~/framework/domain/reservation/reservation/reservation-generation-site-task/reservationGenerationSiteTask';
import { Maybe } from '~/framework/typeAliases';
import {
  ContainerTypeEntity,
  ContainerTypeEntityData,
} from '~/framework/domain/masters/container-type/containerTypeEntity';
import {
  AggregatedWasteTypeEntity,
  AggregatedWasteTypeEntityData,
} from '~/framework/domain/masters/waste-type/aggregatedWasteTypeEntity';

export interface AggregatedReservationGenerationSiteTaskData extends ReservationGenerationSiteTaskData {
  wasteType: Maybe<AggregatedWasteTypeEntityData>;
  containerType: ContainerTypeEntityData;
}

export class AggregatedReservationGenerationSiteTask extends ReservationGenerationSiteTask {
  wasteType: Maybe<AggregatedWasteTypeEntity>;
  containerType: ContainerTypeEntity;

  constructor(
    taskType: TaskType,
    wasteTypeId: Maybe<string>,
    containerTypeId: string,
    containerNum: number,
    wasteType: Maybe<AggregatedWasteTypeEntity>,
    containerType: ContainerTypeEntity
  ) {
    super(taskType, wasteTypeId, containerTypeId, containerNum);
    this.wasteType = wasteType;
    this.containerType = containerType;
  }
}
