import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import {
  containerType$createSymbol,
  containerType$getAllSymbol,
  containerType$updateSymbol,
  ICreateData,
  IUpdateData,
} from '~/framework/server-api/masters/containerType';

import { containerTypeTaskType$getAllSymbol } from '~/framework/server-api/masters/containerTypeTaskType';
import { ContainerTypeTaskTypeMapper } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeMapper';

import { AggregatedContainerTypeMapper } from '~/framework/domain/masters/container-type/aggregatedContainerTypeMapper';
import { carType$getAllSymbol } from '~/framework/server-api/masters/carType';
import { CarTypeMapper } from '~/framework/domain/masters/car-type/carTypeMapper';

import { AggregatedContainerTypeEntity } from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { officeSetting$getSymbol } from '~/framework/server-api/masters/officeSetting';
import { OfficeSettingMapper } from '~/framework/domain/masters/office-setting/officeSettingMapper';
import { mapData } from '~/framework/core/mapper';
import { taskType$getAllSymbol } from '~/framework/server-api/masters/taskType';
import { TaskTypeMapper } from '~/framework/domain/masters/task-type/taskTypeMapper';
import { OfficeSettingEntity } from '~/framework/domain/masters/office-setting/officeSettingEntity';
import { TaskTypeEntity } from '~/framework/domain/masters/task-type/taskTypeEntity';

export const containerTypeSymbol = Symbol('containerType');

export interface IContainerTaskListData {
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
  containerTypes: AggregatedContainerTypeEntity[];
  officeSetting: OfficeSettingEntity;
  taskTypes: TaskTypeEntity[];
}

export class ContainerTypeApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async create(data: ICreateData): Promise<AggregatedContainerTypeEntity> {
    const containerTypeMapper: AggregatedContainerTypeMapper = new AggregatedContainerTypeMapper(
      this.store.masters.aggregatedContainerType,
      this.store.masters.packingStyle
    );
    const carTypeMapper: CarTypeMapper = new CarTypeMapper();
    const [containerTypeData, carTypeData] = await Promise.all([
      this.serverApis.get(containerType$createSymbol).create(data),
      this.serverApis.get(carType$getAllSymbol).getAll(),
    ]);
    const carTypeDataMap = mapData(carTypeData, 'id');
    const transformingCarTypeData = data.transformingCarTypeId
      ? carTypeDataMap.getOrError(data.transformingCarTypeId)
      : undefined;
    const transformingCarType = transformingCarTypeData
      ? carTypeMapper.mapSingle({ ...transformingCarTypeData, orderGroupId: transformingCarTypeData.orderGroup.id })
      : undefined;
    const entity = containerTypeMapper.mapSingle({
      ...containerTypeData,
      transformingCarType,
    });
    return entity;
  }

  async update(data: IUpdateData): Promise<AggregatedContainerTypeEntity> {
    const containerTypeMapper: AggregatedContainerTypeMapper = new AggregatedContainerTypeMapper(
      this.store.masters.aggregatedContainerType,
      this.store.masters.packingStyle
    );
    const carTypeMapper: CarTypeMapper = new CarTypeMapper();
    const result = await Promise.all([
      this.serverApis.get(containerType$updateSymbol).update(data),
      this.serverApis.get(carType$getAllSymbol).getAll(),
    ]);
    const [containerTypeData, carTypeData] = result;
    const carTypeDataMap = mapData(carTypeData, 'id');
    const transformingCarTypeData = data.transformingCarTypeId
      ? carTypeDataMap.getOrError(data.transformingCarTypeId)
      : undefined;
    const transformingCarType = transformingCarTypeData
      ? carTypeMapper.mapSingle({ ...transformingCarTypeData, orderGroupId: transformingCarTypeData.orderGroup.id })
      : undefined;
    const entity = containerTypeMapper.mapSingle({
      ...containerTypeData,
      transformingCarType,
    });
    return entity;
  }

  // region list
  async getAll(): Promise<AggregatedContainerTypeEntity[]> {
    const containerTypeMapper: AggregatedContainerTypeMapper = new AggregatedContainerTypeMapper(
      this.store.masters.aggregatedContainerType,
      this.store.masters.packingStyle
    );
    const carTypeMapper: CarTypeMapper = new CarTypeMapper();
    const [containerTypeData, carTypeData, taskTypeData] = await Promise.all([
      this.serverApis.get(containerType$getAllSymbol).getAll(),
      this.serverApis.get(carType$getAllSymbol).getAll(),
      this.serverApis.get(taskType$getAllSymbol).getAll(),
    ]);
    const carTypeDataMap = mapData(carTypeData, 'id');
    const entities = containerTypeMapper.map(
      containerTypeData.map((containerType) => {
        const transformingCarTypeData = containerType.transformingCarTypeId
          ? carTypeDataMap.getOrError(containerType.transformingCarTypeId)
          : undefined;
        const transformingCarType = transformingCarTypeData
          ? carTypeMapper.mapSingle({ ...transformingCarTypeData, orderGroupId: transformingCarTypeData.orderGroup.id })
          : undefined;
        return {
          ...containerType,
          transformingCarType,
          taskTypeData,
        };
      })
    );
    return entities;
  }
  // endregion

  // region ContainerTask
  async getAllContainerTask(): Promise<IContainerTaskListData> {
    const containerTypeTaskTypeMapper: ContainerTypeTaskTypeMapper = new ContainerTypeTaskTypeMapper(
      this.store.masters.containerTypeTaskType
    );
    const containerTypeMapper: AggregatedContainerTypeMapper = new AggregatedContainerTypeMapper(
      this.store.masters.aggregatedContainerType,
      this.store.masters.packingStyle
    );
    const carTypeMapper: CarTypeMapper = new CarTypeMapper();
    const officeSettingMapper: OfficeSettingMapper = new OfficeSettingMapper(this.store.masters.officeSetting);

    const [containerTypeTaskTypeData, containerTypeData, carTypeData, officeSettingData, taskTypeData] =
      await Promise.all([
        this.serverApis.get(containerTypeTaskType$getAllSymbol).getAll(),
        this.serverApis.get(containerType$getAllSymbol).getAll(),
        this.serverApis.get(carType$getAllSymbol).getAll(),
        this.serverApis.get(officeSetting$getSymbol).get(),
        this.serverApis.get(taskType$getAllSymbol).getAll(),
      ]);

    const containerTypeTaskTypes = containerTypeTaskTypeMapper.map(containerTypeTaskTypeData);
    const carTypeDataMap = mapData(carTypeData, 'id');
    const containerTypes = containerTypeMapper.map(
      containerTypeData.map((containerType) => {
        const transformingCarTypeData = containerType.transformingCarTypeId
          ? carTypeDataMap.getOrError(containerType.transformingCarTypeId)
          : undefined;
        const transformingCarType = transformingCarTypeData
          ? carTypeMapper.mapSingle({ ...transformingCarTypeData, orderGroupId: transformingCarTypeData.orderGroup.id })
          : undefined;
        return {
          ...containerType,
          transformingCarType,
        };
      })
    );
    const officeSetting = officeSettingMapper.mapSingle(officeSettingData);
    const taskTypes = new TaskTypeMapper().map(taskTypeData);

    return { containerTypeTaskTypes, containerTypes, officeSetting, taskTypes };
  }
  // endregion

  async getAllTaskTypes(): Promise<ContainerTypeTaskTypeEntity[]> {
    const containerTypeTaskTypeMapper: ContainerTypeTaskTypeMapper = new ContainerTypeTaskTypeMapper(
      this.store.masters.containerTypeTaskType
    );
    const result = await this.serverApis.get(containerTypeTaskType$getAllSymbol).getAll();
    return containerTypeTaskTypeMapper.map(result);
  }
}
