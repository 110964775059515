import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';
import { MarginType, PreloadStatus } from '~/framework/domain/typeAliases';

export interface OfficeSettingEntityData {
  id: string;
  defaultDurationAtBaseSite: number;
  defaultDurationAtGenerationSite: number;
  defaultDurationAtDisposalSite: number;
  defaultRestPeriodStartOfGenerationSite: number | undefined;
  defaultRestPeriodEndOfGenerationSite: number | undefined;
  defaultRestPeriodStartOfDisposalSite: number | undefined;
  defaultRestPeriodEndOfDisposalSite: number | undefined;
  defaultBannedDriverIdsAtGenerationSite: string[];
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: boolean;
  defaultAvoidHighways: boolean;
  isPackingPlacementManagementEnabled: boolean;
  isFixedArrivalTimeReportEnabled: boolean;
  defaultIsFixedArrivalTimeReportNeeded: boolean;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;
  isOnlineReservationEnabled: boolean;
  isPublishingStatusOfScheduleManagementEnabled: boolean;
  canPrintSchedules: boolean;
  canUseErp: boolean;
  useCsvImport: boolean;
  useCsvExport: boolean;
}

export class OfficeSettingEntity {
  readonly id: string;
  readonly persistentId: string;

  defaultDurationAtBaseSite: number;
  defaultDurationAtGenerationSite: number;
  defaultDurationAtDisposalSite: number;
  @ReactiveProperty(OfficeSettingEntity.prototype.updateHasDefaultRestPeriodOfGenerationSite)
  defaultRestPeriodStartOfGenerationSite: Maybe<number>;

  @ReactiveProperty(OfficeSettingEntity.prototype.updateHasDefaultRestPeriodOfGenerationSite)
  defaultRestPeriodEndOfGenerationSite: Maybe<number>;

  @ReactiveProperty(OfficeSettingEntity.prototype.updateHasDefaultRestPeriodOfDisposalSite)
  defaultRestPeriodStartOfDisposalSite: Maybe<number>;

  @ReactiveProperty(OfficeSettingEntity.prototype.updateHasDefaultRestPeriodOfDisposalSite)
  defaultRestPeriodEndOfDisposalSite: Maybe<number>;

  defaultBannedDriverIdsAtGenerationSite: string[];
  defaultPreloadStatus: PreloadStatus;
  defaultRouteCollectionAllowed: boolean;
  defaultAvoidHighways: boolean;
  isPackingPlacementManagementEnabled: boolean;
  isFixedArrivalTimeReportEnabled: boolean;
  defaultIsFixedArrivalTimeReportNeeded: boolean;
  defaultMarginTypeOfFixedArrivalTime: MarginType;
  defaultMarginOfFixedArrivalTime: number;

  hasDefaultRestPeriodOfGenerationSite!: boolean;
  hasDefaultRestPeriodOfDisposalSite!: boolean;
  isOnlineReservationEnabled!: boolean;
  isPublishingStatusOfScheduleManagementEnabled: boolean;
  canPrintSchedules: boolean;
  canUseErp: boolean;
  useCsvImport: boolean;
  useCsvExport: boolean;

  constructor(
    id: string,
    defaultDurationAtBaseSite: number,
    defaultDurationAtGenerationSite: number,
    defaultDurationAtDisposalSite: number,
    defaultRestPeriodStartOfGenerationSite: Maybe<number>,
    defaultRestPeriodEndOfGenerationSite: Maybe<number>,
    defaultRestPeriodStartOfDisposalSite: Maybe<number>,
    defaultRestPeriodEndOfDisposalSite: Maybe<number>,
    defaultBannedDriverIdsAtGenerationSite: string[],
    defaultPreloadStatus: PreloadStatus,
    defaultRouteCollectionAllowed: boolean,
    defaultAvoidHighways: boolean,
    isPackingPlacementManagementEnabled: boolean,
    isFixedArrivalTimeReportEnabled: boolean,
    defaultIsFixedArrivalTimeReportNeeded: boolean,
    defaultMarginTypeOfFixedArrivalTime: MarginType,
    defaultMarginOfFixedArrivalTime: number,
    isOnlineReservationEnabled: boolean,
    isPublishingStatusOfScheduleManagementEnabled: boolean,
    canPrintSchedules: boolean,
    canUseErp: boolean,
    useCsvImport: boolean,
    useCsvExport: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.defaultDurationAtBaseSite = defaultDurationAtBaseSite;
    this.defaultDurationAtGenerationSite = defaultDurationAtGenerationSite;
    this.defaultDurationAtDisposalSite = defaultDurationAtDisposalSite;
    this.defaultRestPeriodStartOfGenerationSite = defaultRestPeriodStartOfGenerationSite;
    this.defaultRestPeriodEndOfGenerationSite = defaultRestPeriodEndOfGenerationSite;
    this.defaultRestPeriodStartOfDisposalSite = defaultRestPeriodStartOfDisposalSite;
    this.defaultRestPeriodEndOfDisposalSite = defaultRestPeriodEndOfDisposalSite;
    this.defaultBannedDriverIdsAtGenerationSite = defaultBannedDriverIdsAtGenerationSite;
    this.defaultPreloadStatus = defaultPreloadStatus;
    this.defaultRouteCollectionAllowed = defaultRouteCollectionAllowed;
    this.defaultAvoidHighways = defaultAvoidHighways;
    this.isPackingPlacementManagementEnabled = isPackingPlacementManagementEnabled;
    this.isFixedArrivalTimeReportEnabled = isFixedArrivalTimeReportEnabled;
    this.defaultIsFixedArrivalTimeReportNeeded = defaultIsFixedArrivalTimeReportNeeded;
    this.defaultMarginTypeOfFixedArrivalTime = defaultMarginTypeOfFixedArrivalTime;
    this.defaultMarginOfFixedArrivalTime = defaultMarginOfFixedArrivalTime;
    this.isOnlineReservationEnabled = isOnlineReservationEnabled;
    this.isPublishingStatusOfScheduleManagementEnabled = isPublishingStatusOfScheduleManagementEnabled;
    this.canPrintSchedules = canPrintSchedules;
    this.canUseErp = canUseErp;
    this.useCsvImport = useCsvImport;
    this.useCsvExport = useCsvExport;
    this.updateHasDefaultRestPeriodOfGenerationSite();
    this.updateHasDefaultRestPeriodOfDisposalSite();
  }

  private updateHasDefaultRestPeriodOfGenerationSite(): void {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    this.hasDefaultRestPeriodOfGenerationSite =
      this.defaultRestPeriodStartOfGenerationSite !== undefined &&
      this.defaultRestPeriodEndOfGenerationSite !== undefined;
  }

  private updateHasDefaultRestPeriodOfDisposalSite(): void {
    // NOTE 現実的にはどちらかに値が入っていてどちらかに入っていないという状況はあり得ない
    // 両方入っているか両方 undefined かどちらかなのだが、念のため
    this.hasDefaultRestPeriodOfDisposalSite =
      this.defaultRestPeriodStartOfDisposalSite !== undefined && this.defaultRestPeriodEndOfDisposalSite !== undefined;
  }
}
