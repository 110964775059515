import { IOrderGroupData } from '~/framework/server-api/masters/orderGroup';

import { IDuplicatedNameError } from '~/framework/server-api/errors';

export const carType$createSymbol = Symbol('carType$createSymbol');

export const carType$getAllSymbol = Symbol('getAll');

export const carType$getByIdsSymbol = Symbol('getByIds');

export const carType$updateSymbol = Symbol('carType$updateSymbol');

export type CarType = {
  [carType$getAllSymbol]: IGetAllApi;
  [carType$getByIdsSymbol]: IGetByIdsApi;
  [carType$createSymbol]: ICreateApi;
  [carType$updateSymbol]: IUpdateApi;
};

export interface ICarTypeContainerTypeData {
  id: string;
  containerTypeId: string;
  emptyCount: number;
  emptyCountOccupation: number;
  lowerTierFullCount: number;
  upperTierFullCount: number;
  fullCountOccupation: number;
  isUpperTierAvailable: boolean;
}

export interface ICarTypeContainerTypeCreateData {
  containerTypeId: string;
  emptyCount: number;
  emptyCountOccupation: number;
  lowerTierFullCount: number;
  upperTierFullCount: number;
  fullCountOccupation: number;
  isUpperTierAvailable: boolean;
}

export interface ICarTypeDataType {
  /**
   * ここで GraphQL の実装に依存するのは微妙なのだが、これを回避しようとすると仕組みが不必要に複雑になるので
   * 致し方なくこうした。意見あれば welcome。
   */
  __typename: 'CarType';
}

export interface ICarTypeData {
  id: string;
  name: string;
  orderGroup: IOrderGroupData;
  drivingSpeedCoefficient: number;
  durationOfBoarding: number;
  durationOfAlighting: number;
  loadableContainerTypes: ICarTypeContainerTypeData[];
}

export interface ICreateApi {
  create(data: ICreateData): Promise<CreateResultTypes>;
}

export type CreateResultTypes = (ICarTypeDataType & ICarTypeData) | ICarTypeMutationError;

export interface ICreateData {
  name: string;
  orderGroupId: string;
  drivingSpeedCoefficient: number;
  durationOfBoarding: number;
  durationOfAlighting: number;
  loadableContainerTypes: ICarTypeContainerTypeCreateData[];
}

export interface IGetAllApi {
  getAll(): Promise<ICarTypeData[]>;
}

export interface IGetByIdsApi {
  getByIds(ids: string[]): Promise<ICarTypeData[]>;
}

export interface ICarTypeMutationError {
  __typename: 'CarTypeMutationError';
  errors: CarTypeMutationErrorTypes[];
}

export type CarTypeMutationErrorTypes = IDuplicatedNameError;

export interface IUpdateApi {
  update(data: IUpdateData): Promise<UpdateResultTypes>;
}

export type UpdateResultTypes = (ICarTypeDataType & ICarTypeData) | ICarTypeMutationError;

export interface IUpdateData {
  id: string;
  name: string;
  orderGroupId: string;
  drivingSpeedCoefficient: number;
  durationOfBoarding: number;
  durationOfAlighting: number;
  loadableContainerTypes: ICarTypeContainerTypeCreateData[];
}
