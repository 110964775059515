import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedDisposalSiteEntity,
  AggregatedDisposalSiteEntityData,
} from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { AggregatedDisposalSiteStore } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';
import { UserMapper } from '~/framework/domain/masters/user/userMapper';

export class AggregatedDisposalSiteMapper extends StoredMapperBase<
  AggregatedDisposalSiteEntityData,
  AggregatedDisposalSiteEntity
> {
  protected store: AggregatedDisposalSiteStore;
  private userMapper: UserMapper;

  constructor(store: AggregatedDisposalSiteStore, userStore: UserStore) {
    super();
    this.store = store;
    this.userMapper = new UserMapper(userStore);
  }

  protected instantiateWithData(data: AggregatedDisposalSiteEntityData): AggregatedDisposalSiteEntity {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    return new AggregatedDisposalSiteEntity(
      data.id,
      data.code,
      data.name,
      data.nameRuby,
      data.zipCode,
      data.address1,
      data.address2,
      data.address3,
      data.address4,
      data.latitude,
      data.longitude,
      data.disposablePeriodStart,
      data.disposablePeriodEnd,
      data.durationAtEntrance,
      data.restPeriodStart,
      data.restPeriodEnd,
      data.acceptanceLimit,
      data.note,
      data.isAddressComplete,
      data.createdAt,
      createdBy,
      data.client,
      data.temporalAcceptanceInterval,
      data.temporalAcceptanceLimit
    );
  }

  protected updateWithData(data: AggregatedDisposalSiteEntityData, entity: AggregatedDisposalSiteEntity): void {
    const createdBy = this.userMapper.mapSingle(data.createdBy);
    entity.name = data.name;
    entity.code = data.code;
    entity.nameRuby = data.nameRuby;
    entity.zipCode = data.zipCode;
    entity.address1 = data.address1;
    entity.address2 = data.address2;
    entity.address3 = data.address3;
    entity.address4 = data.address4;
    entity.latitude = data.latitude;
    entity.longitude = data.longitude;
    entity.disposablePeriodStart = data.disposablePeriodStart;
    entity.disposablePeriodEnd = data.disposablePeriodEnd;
    entity.durationAtEntrance = data.durationAtEntrance;
    entity.restPeriodStart = data.restPeriodStart;
    entity.restPeriodEnd = data.restPeriodEnd;
    entity.acceptanceLimit = data.acceptanceLimit;
    entity.note = data.note;
    entity.isAddressComplete = data.isAddressComplete;
    entity.createdAt = data.createdAt;
    entity.createdBy = createdBy;
    entity.client = data.client;
    entity.temporalAcceptanceInterval = data.temporalAcceptanceInterval;
    entity.temporalAcceptanceLimit = data.temporalAcceptanceLimit;
  }
}
