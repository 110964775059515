import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  carType$getAllSymbol,
  carType$createSymbol,
  ICreateData,
  carType$updateSymbol,
  IUpdateData,
  ICarTypeMutationError,
} from '~/framework/server-api/masters/carType';
import { mapData } from '~/framework/core/mapper';
import { AggregatedCarTypeMapper } from '~/framework/domain/masters/car-type/aggregatedCarTypeMapper';
import { containerType$getAllSymbol } from '~/framework/server-api/masters/containerType';
import { AggregatedCarTypeEntity } from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';
import { IDisposable } from '~/framework/core/disposable';

import { Ports } from '~/framework/core/ports';

export const carTypeSymbol = Symbol('carType');

interface ICreatePresenter {
  create(entity: AggregatedCarTypeEntity): void;
  errorOnCreate(error: ICarTypeMutationError): void;
}

interface IUpdatePresenter {
  update(entity: AggregatedCarTypeEntity): void;
  errorOnUpdate(error: ICarTypeMutationError): void;
}

export class CarTypeApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly createPort: Ports<ICreatePresenter>;
  private readonly updatePort: Ports<IUpdatePresenter>;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
    this.createPort = new Ports<ICreatePresenter>();
    this.updatePort = new Ports<IUpdatePresenter>();
  }

  addCreatePresenter(presenter: ICreatePresenter): IDisposable {
    return this.createPort.add(presenter);
  }

  async create(data: ICreateData): Promise<void> {
    const createApi = this.serverApis.get(carType$createSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);
    const [createResult, containerTypeData] = await Promise.all([
      createApi.create(data),
      containerType$getAllApi.getAll(),
    ]);
    // visitor にしてもいいが、数がないのでわざわざそこまでしなくてもよさそう
    if (createResult.__typename === 'CarType') {
      const carTypeMapper: AggregatedCarTypeMapper = new AggregatedCarTypeMapper(
        this.store.masters.aggregatedCarType,
        this.store.masters.orderGroup,
        this.store.masters.aggregatedCarTypeContainerType
      );
      const containerTypeDataMap = mapData(containerTypeData, 'id');
      const aggregatedCarTypeData = {
        ...createResult,
        orderGroupId: createResult.orderGroup.id,
        loadableContainerTypes: createResult.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };
      const entity = carTypeMapper.mapSingle(aggregatedCarTypeData);
      this.createPort.output('create', entity);
    } else if (createResult.__typename === 'CarTypeMutationError') {
      this.createPort.output('errorOnCreate', createResult);
    }
  }

  addUpdatePresenter(presenter: IUpdatePresenter): IDisposable {
    return this.updatePort.add(presenter);
  }

  async update(data: IUpdateData): Promise<void> {
    const updateApi = this.serverApis.get(carType$updateSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);
    const [updateResult, containerTypeData] = await Promise.all([
      updateApi.update(data),
      containerType$getAllApi.getAll(),
    ]);
    // visitor にしてもいいが、数がないのでわざわざそこまでしなくてもよさそう
    if (updateResult.__typename === 'CarType') {
      const carTypeMapper: AggregatedCarTypeMapper = new AggregatedCarTypeMapper(
        this.store.masters.aggregatedCarType,
        this.store.masters.orderGroup,
        this.store.masters.aggregatedCarTypeContainerType
      );
      const containerTypeDataMap = mapData(containerTypeData, 'id');
      const aggregatedCarTypeData = {
        ...updateResult,
        orderGroupId: updateResult.orderGroup.id,
        loadableContainerTypes: updateResult.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };
      const entity = carTypeMapper.mapSingle(aggregatedCarTypeData);
      this.updatePort.output('update', entity);
    } else if (updateResult.__typename === 'CarTypeMutationError') {
      this.updatePort.output('errorOnUpdate', updateResult);
    }
  }

  // endregion

  // region getAll
  async getAll(): Promise<AggregatedCarTypeEntity[]> {
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);
    const carTypeMapper: AggregatedCarTypeMapper = new AggregatedCarTypeMapper(
      this.store.masters.aggregatedCarType,
      this.store.masters.orderGroup,
      this.store.masters.aggregatedCarTypeContainerType
    );
    const [carTypeData, containerTypeData] = await Promise.all([
      carType$getAllApi.getAll(),
      containerType$getAllApi.getAll(),
    ]);
    const containerTypeDataMap = mapData(containerTypeData, 'id');
    const aggregatedCarTypeData = carTypeData.map((carType) => {
      return {
        ...carType,
        orderGroupId: carType.orderGroup.id,
        loadableContainerTypes: carType.loadableContainerTypes.map((container) => {
          return {
            ...container,
            containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
            containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
          };
        }),
      };
    });
    const entities = carTypeMapper.map(aggregatedCarTypeData);
    return entities;
  }
  // endregion
}
