import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedDriverAttendanceEntity,
  AggregatedDriverAttendanceEntityData,
} from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { AggregatedDriverAttendanceStore } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceStore';
import { AttendanceMapper } from '~/framework/domain/masters/attendance/attendanceMapper';
import { IAttendanceStore } from '~/framework/domain/masters/attendance/attendanceStore';
import { AggregatedDriverMapper } from '~/framework/domain/masters/driver/aggregatedDriverMapper';
import { AggregatedDriverStore } from '~/framework/domain/masters/driver/aggregatedDriverStore';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';

export class AggregatedDriverAttendanceMapper extends StoredMapperBase<
  AggregatedDriverAttendanceEntityData,
  AggregatedDriverAttendanceEntity
> {
  protected store: AggregatedDriverAttendanceStore;
  private attendanceMapper: AttendanceMapper;
  private driverMapper: AggregatedDriverMapper;
  private carMapper: AggregatedCarMapper;

  constructor(
    store: AggregatedDriverAttendanceStore,
    attendanceStore: IAttendanceStore,
    driverStore: AggregatedDriverStore,
    carStore: AggregatedCarStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    carTypeStore: AggregatedCarTypeStore,
    orderGroupStore: OrderGroupStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore,
    baseSiteStore: AggregatedBaseSiteStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.attendanceMapper = new AttendanceMapper(attendanceStore);
    this.driverMapper = new AggregatedDriverMapper(
      driverStore,
      carStore,
      carTypeStore,
      orderGroupStore,
      carTypeContainerTypeStore,
      baseSiteStore,
      driverAttendanceTemplateStore,
      userStore
    );
    this.carMapper = new AggregatedCarMapper(
      carStore,
      carTypeStore,
      orderGroupStore,
      carTypeContainerTypeStore,
      baseSiteStore,
      userStore
    );
  }

  protected instantiateWithData(data: AggregatedDriverAttendanceEntityData): AggregatedDriverAttendanceEntity {
    const attendance = this.attendanceMapper.mapSingle(data.attendance);
    const driver = this.driverMapper.mapSingle(data.driver);
    const primaryCar = this.carMapper.mapSingle(data.primaryCar);
    return new AggregatedDriverAttendanceEntity(
      data.id,
      data.attendanceId,
      data.driverId,
      data.primaryCarId,
      data.forceRidePrimaryCar,
      data.templateName,
      data.regularWorkPeriodStart,
      data.regularWorkPeriodEnd,
      data.restPeriodStart,
      data.restPeriodEnd,
      data.overtimeWorkType,
      data.overtimeWorkableDuration,
      attendance,
      driver,
      primaryCar
    );
  }

  protected updateWithData(data: AggregatedDriverAttendanceEntityData, entity: AggregatedDriverAttendanceEntity): void {
    const attendance = this.attendanceMapper.mapSingle(data.attendance);
    const driver = this.driverMapper.mapSingle(data.driver);
    const primaryCar = this.carMapper.mapSingle(data.primaryCar);
    entity.attendanceId = data.attendanceId;
    entity.driverId = data.driverId;
    entity.primaryCarId = data.primaryCarId;
    entity.forceRidePrimaryCar = data.forceRidePrimaryCar;
    entity.templateName = data.templateName;
    entity.regularWorkPeriodStart = data.regularWorkPeriodStart;
    entity.regularWorkPeriodEnd = data.regularWorkPeriodEnd;
    entity.restPeriodStart = data.restPeriodStart;
    entity.restPeriodEnd = data.restPeriodEnd;
    entity.overtimeWorkType = data.overtimeWorkType;
    entity.overtimeWorkableDuration = data.overtimeWorkableDuration;
    entity.attendance = attendance;
    entity.driver = driver;
    entity.primaryCar = primaryCar;
  }
}
