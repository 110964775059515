import {
  AggregatedBaseSiteEntity,
  AggregatedBaseSiteEntityData,
} from '~/framework/domain/masters/base-site/aggregatedBaseSiteEntity';
import {
  AggregatedCarTypeEntity,
  AggregatedCarTypeEntityData,
} from '~/framework/domain/masters/car-type/aggregatedCarTypeEntity';

export interface AggregatedCarEntityData {
  id: string;
  name: string;
  enabledOn: Date;
  carType: AggregatedCarTypeEntityData;
  baseSite: AggregatedBaseSiteEntityData;
}

export class AggregatedCarEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  enabledOn: Date;
  carType: AggregatedCarTypeEntity;
  baseSite: AggregatedBaseSiteEntity;
  displayName: string;

  constructor(
    id: string,
    name: string,
    carType: AggregatedCarTypeEntity,
    baseSite: AggregatedBaseSiteEntity,
    enabledOn: Date
  ) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.enabledOn = enabledOn;
    this.carType = carType;
    this.baseSite = baseSite;
    this.displayName = `${name} [${carType.name}]`;
  }
}
