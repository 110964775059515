export interface PackingPlacementEntityData {
  id: string;
  containerTypeId: string;
  num: number;
  lastAllocatedAt: Date;
}

export class PackingPlacementEntity {
  readonly id: string;
  readonly persistentId: string;
  containerTypeId: string;
  num: number;
  lastAllocatedAt: Date;

  constructor(id: string, containerTypeId: string, num: number, lastAllocatedAt: Date) {
    this.id = id;
    this.persistentId = id;
    this.containerTypeId = containerTypeId;
    this.num = num;
    this.lastAllocatedAt = lastAllocatedAt;
  }
}
