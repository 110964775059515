import { IEntity, IPersistentEntity } from '~/framework/core/entity';
import { Seconds } from '~/framework/typeAliases';

export interface CarTypeEntityData {
  id: string;
  name: string;
  orderGroupId: string;
  drivingSpeedCoefficient: number;
  durationOfBoarding: Seconds;
  durationOfAlighting: Seconds;
}

export class CarTypeEntity implements IEntity, IPersistentEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  orderGroupId: string;
  drivingSpeedCoefficient: number;
  durationOfBoarding: Seconds;
  durationOfAlighting: Seconds;

  constructor(
    id: string,
    name: string,
    orderGroupId: string,
    drivingSpeedCoefficient: number,
    durationOfBoarding: Seconds,
    durationOfAlighting: Seconds
  ) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.orderGroupId = orderGroupId;
    this.drivingSpeedCoefficient = drivingSpeedCoefficient;
    this.durationOfBoarding = durationOfBoarding;
    this.durationOfAlighting = durationOfAlighting;
  }
}
