import { TaskType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';

export interface GenerationSiteTaskEntityData {
  id: string;
  orderId: string;
  taskType: TaskType;
  wasteTypeId: string | undefined;
  containerTypeId: string | undefined;
  containerNum: number | undefined;
}

export class GenerationSiteTaskEntity implements GenerationSiteTaskEntity {
  readonly id: string;
  readonly persistentId: string;
  orderId: string;

  @ReactiveProperty(GenerationSiteTaskEntity.prototype.updateTaskTypeName)
  taskType: TaskType;

  wasteTypeId: Maybe<string>;
  containerTypeId: Maybe<string>;
  containerNum: Maybe<number>;
  taskTypeName!: string;

  constructor(
    id: string,
    orderId: string,
    taskType: TaskType,
    wasteTypeId: Maybe<string>,
    containerTypeId: Maybe<string>,
    containerNum: Maybe<number>
  ) {
    this.id = id;
    this.persistentId = id;
    this.orderId = orderId;
    this.taskType = taskType;
    this.wasteTypeId = wasteTypeId;
    this.containerTypeId = containerTypeId;
    this.containerNum = containerNum;
    this.updateTaskTypeName();
  }

  private updateTaskTypeName(): void {
    this.taskTypeName = this.taskType.name;
  }
}
