import { Maybe } from '~/framework/typeAliases';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { IInfeasibilityData } from '~/framework/domain/schedule/schedule/pseudo-entities/infeasibilityData';
import { IDriverReasonData } from '~/framework/domain/schedule/schedule/pseudo-entities/driverReasonData';
import {
  InfeasibilityCauses,
  InfeasibilityDriverReasons,
  InfeasibilityReasons,
  ScheduleInfeasibilityTypes,
} from '~/graphql/custom-scalars/scheduleJsonObjectTypes';

// 最適化のエラーには三種類ある
//
// 1. 固定まわりのエラー (inconsistencies)
// 2. 受注情報、マスター、勤怠まわりのエラー (infeasibilities)
// 3. 実際に最適化を走らせてみた場合のエラー (infeasibilities)
//
// 2 までは実際には最適化を走らせないで判定が行われる。なので2までが内部的に pre-opt と呼ばれている。
// 固定まわりのエラーはもともと配車表を作成できていたのにおかしな入れ替えや固定をしたから矛盾が発生しているもの。
// 固定まわりはドライバー・ルートに紐付いて不可能性が判定されるのに対してそれ以降のものはオーダー単位で判定が行われる。
// なので inconsistencies と infeasibilities は同じエラーではあるがやや概念が異なる。
// オーダー単位で不可能だったものは以下２つに分類される。
//
// 1. オーダーやマスターの情報に問題があるもの
// 2. ドライバーに割り当てようとした場合に問題があるもの（勤怠情報を参照する）
//
// 2 に関しては何故そのドライバーでは問題があったのかという情報が返される。なお、orderId に対して cause は高々一つで、
// orderId に対して複数の cause が割り当てられる（つまり複数の infeasibility が返ってくる）事はあり得ない。
//
// DriverReason をジェネリックにしている理由は、View レベルのクラスでも InfeasibilityEntity に
// 集約できる様にしたいため。こうしておく事で DriverReason に DriverEntity を集約している様な
// 状態を実現でき、View 側でそれらの Entity を取ってくる様な余計な手間を取らなくて済み、
// また Entity が fat になりすぎる事を避ける事ができる。

export interface IInfeasibilityEntity<D extends IDriverReasonEntity> extends IInfeasibilityData<D> {
  // 必要であれば追記
}

export interface IDriverReasonEntity extends IDriverReasonData {
  // 必要であれば追記
}

export class InfeasibilityEntity<D extends IDriverReasonEntity> implements IInfeasibilityEntity<D> {
  id: string;
  orderId: PseudoId;
  cause: InfeasibilityCauses;
  reasons: Maybe<InfeasibilityReasons[]>;
  driverReasons: Maybe<D[]>;
  assignedDriverId: Maybe<PseudoId>;
  assignedCarId: Maybe<PseudoId>;
  acceptableCarTypeIds: Maybe<PseudoId[]>;
  type: ScheduleInfeasibilityTypes;
  assignableCarTypeIds: Maybe<PseudoId[]>;
  releaseDriverAssignment: Maybe<boolean>;
  durationAtGenerationSite: Maybe<number>;
  durationAtDisposalSite: Maybe<number>;
  durationOfDriving: Maybe<number>;
  reducibleDurationByHighway: Maybe<number>;

  constructor(
    id: string,
    orderId: PseudoId,
    cause: InfeasibilityCauses,
    reasons: Maybe<InfeasibilityReasons[]>,
    driverReasons: Maybe<D[]>,
    assignedDriverId: Maybe<PseudoId>,
    assignedCarId: Maybe<PseudoId>,
    acceptableCarTypeIds: Maybe<PseudoId[]>,
    type: ScheduleInfeasibilityTypes,
    assignableCarTypeIds: Maybe<PseudoId[]>,
    releaseDriverAssignment: Maybe<boolean>,
    durationAtGenerationSite: Maybe<number>,
    durationAtDisposalSite: Maybe<number>,
    durationOfDriving: Maybe<number>,
    reducibleDurationByHighway: Maybe<number>
  ) {
    this.id = id;
    this.orderId = orderId;
    this.cause = cause;
    this.reasons = reasons;
    this.driverReasons = driverReasons;
    this.assignedDriverId = assignedDriverId;
    this.assignedCarId = assignedCarId;
    this.acceptableCarTypeIds = acceptableCarTypeIds;
    this.type = type;
    this.assignableCarTypeIds = assignableCarTypeIds;
    this.releaseDriverAssignment = releaseDriverAssignment;
    this.durationAtGenerationSite = durationAtGenerationSite;
    this.durationAtDisposalSite = durationAtDisposalSite;
    this.durationOfDriving = durationOfDriving;
    this.reducibleDurationByHighway = reducibleDurationByHighway;
  }
}

export class DriverReasonEntity implements IDriverReasonEntity {
  driverId: PseudoId;
  reasons: InfeasibilityDriverReasons[];

  constructor(driverId: PseudoId, reasons: InfeasibilityDriverReasons[]) {
    this.driverId = driverId;
    this.reasons = reasons;
  }
}
