import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedDriverEntity,
  AggregatedDriverEntityData,
} from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { AggregatedDriverStore } from '~/framework/domain/masters/driver/aggregatedDriverStore';
import { AggregatedCarStore } from '~/framework/domain/masters/car/aggregatedCarStore';
import { AggregatedCarMapper } from '~/framework/domain/masters/car/aggregatedCarMapper';
import { CarTypeMapper } from '~/framework/domain/masters/car-type/carTypeMapper';
import { DriverAttendanceTemplateMapper } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateMapper';
import { DriverAttendanceTemplateStore } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateStore';
import { AggregatedCarTypeStore } from '~/framework/domain/masters/car-type/aggregatedCarTypeStore';
import { OrderGroupStore } from '~/framework/domain/masters/order-group/orderGroupStore';
import { AggregatedCarTypeContainerTypeStore } from '~/framework/domain/masters/car-type/car-type-container-type/aggregatedCarTypeContainerTypeStore';
import { AggregatedBaseSiteStore } from '~/framework/domain/masters/base-site/aggregatedBaseSiteStore';
import { UserStore } from '~/framework/domain/masters/user/userStore';

export class AggregatedDriverMapper extends StoredMapperBase<AggregatedDriverEntityData, AggregatedDriverEntity> {
  protected store: AggregatedDriverStore;
  private carMapper: AggregatedCarMapper;
  private carTypeMapper: CarTypeMapper;
  private driverAttendanceTemplateMapper: DriverAttendanceTemplateMapper;

  constructor(
    store: AggregatedDriverStore,
    carStore: AggregatedCarStore,
    carTypeStore: AggregatedCarTypeStore,
    orderGroupStore: OrderGroupStore,
    carTypeContainerTypeStore: AggregatedCarTypeContainerTypeStore,
    baseSiteStore: AggregatedBaseSiteStore,
    driverAttendanceTemplateStore: DriverAttendanceTemplateStore,
    userStore: UserStore
  ) {
    super();
    this.store = store;
    this.carMapper = new AggregatedCarMapper(
      carStore,
      carTypeStore,
      orderGroupStore,
      carTypeContainerTypeStore,
      baseSiteStore,
      userStore
    );
    this.carTypeMapper = new CarTypeMapper();
    this.driverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(driverAttendanceTemplateStore);
  }

  protected instantiateWithData(data: AggregatedDriverEntityData): AggregatedDriverEntity {
    const defaultPrimaryCar = data.defaultPrimaryCar ? this.carMapper.mapSingle(data.defaultPrimaryCar) : undefined;
    const licensedCarTypes = this.carTypeMapper.map(data.licensedCarTypes);
    const defaultAttendanceTemplate = data.defaultAttendanceTemplate
      ? this.driverAttendanceTemplateMapper.mapSingle(data.defaultAttendanceTemplate)
      : undefined;

    return new AggregatedDriverEntity(
      data.id,
      data.name,
      data.nameRuby,
      data.employmentStatus,
      data.defaultPrimaryCarId,
      data.licensedCarTypeIds,
      data.defaultAttendanceTemplateId,
      data.status,
      data.signInUrl,
      defaultPrimaryCar,
      licensedCarTypes,
      defaultAttendanceTemplate
    );
  }

  protected updateWithData(data: AggregatedDriverEntityData, entity: AggregatedDriverEntity): void {
    const defaultPrimaryCar = data.defaultPrimaryCar ? this.carMapper.mapSingle(data.defaultPrimaryCar) : undefined;
    const licensedCarTypes = this.carTypeMapper.map(data.licensedCarTypes);
    const defaultAttendanceTemplate = data.defaultAttendanceTemplate
      ? this.driverAttendanceTemplateMapper.mapSingle(data.defaultAttendanceTemplate)
      : undefined;

    entity.name = data.name;
    entity.nameRuby = data.nameRuby;
    entity.employmentStatus = data.employmentStatus;
    entity.defaultPrimaryCarId = data.defaultPrimaryCarId;
    entity.licensedCarTypeIds = data.licensedCarTypeIds;
    entity.defaultAttendanceTemplateId = data.defaultAttendanceTemplateId;
    entity.status = data.status;
    entity.defaultPrimaryCar = defaultPrimaryCar;
    entity.licensedCarTypes = licensedCarTypes;
    entity.defaultAttendanceTemplate = defaultAttendanceTemplate;
  }
}
