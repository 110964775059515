export type RinEventNameTypes = (typeof RinEventNames)[keyof typeof RinEventNames];

export const RinEventNames = {
  VIEW: 'view',
  LOGIN: 'login',
  LOGOUT: 'logout',
  CREATE_SCHEDULE: 'create_schedule',
  ACCEPTANCE_CHECK: 'acceptance_check',
  PASS_ACCEPTANCE_CHECK: 'pass_acceptance_check',
  OPEN_ACCEPTANCE_CHECK_RESULT: 'open_acceptance_check_result',
  QUIT_ACCEPTANCE_CHECK: 'quit_acceptance_check',
  RESUME_ACCEPTANCE_CHECK: 'resume_acceptance_check',
  DOWNLOAD_SCHEDULE_CSV: 'download_schedule_csv',
  DOWNLOAD_SCHEDULE_ICS: 'download_schedule_ics',
  PUBLISH_SCHEDULE: 'publish_schedule',
  UNPUBLISH_SCHEDULE: 'unpublish_schedule',
  PRINT_SCHEDULE: 'print_schedule',
  LOCK: 'lock',
  UNLOCK: 'unlock',
  LOCK_ALL: 'lock_all',
  UNLOCK_ALL: 'unlock_all',
  MOVE_ORDER_ASSIGNMENT: 'move_order_assignment',
  REVERT: 'revert',
  MOVE_ROUTE: 'move_route',
  FREE_MOVE: 'free_move',
  FORCE_RESOLVE: 'force_resolve',
  LOCK_TIME: 'lock_time',
  TASK_CHECK_ON: 'task_check_on',
  TASK_CHECK_OFF: 'task_check_off',
  OPEN_FORM: 'open_form',
  OPEN_MAP: 'open_map',
  CHANGE_DISPLAY_SETTINGS: 'change_display_settings',
  CHANGE_VIEW_RANGE: 'change_view_range',
  CHANGE_DATE: 'change_date',
  CHANGE_WEEK: 'change_week',
  OPEN_CALENDAR: 'open_calendar',
  SORT_ORDERS: 'sort_orders',
  CANCEL_ORDER: 'cancel_order',
  CHANGE_ORDER_DATE: 'change_order_date',
  REVERT_CANCEL_ORDER: 'revert_cancel_order',
  DELETE: 'delete',
  CANCEL_RESERVATION: 'cancel_reservation',
  PUSH_BACK_RESERVATION: 'push_back_reservation',
  RESOLVE: 'resolve',
  CLOSE_FORM: 'close_form',
  COMPLETE_INPUT: 'complete_input',
  COMPLETE_INPUT_CONTINUE: 'complete_input_continue',
  DOWNLOAD_SEARCH_ORDERS_CSV: 'download_search_orders_csv',
  TRANSIT: 'transit',
  SEARCH_ORDERS: 'search_orders',
  SEARCH: 'search',
  SORT: 'sort',
  AUTO_COMPLETE_ADDRESS_FROM_ZIPCODE: 'auto_complete_address_from_zipcode',
  PASTE_ADDRESS: 'paste_address',
  FILTER: 'filter',
  COPY_URL: 'copy_url',
  DOWNLOAD_DRIVERS_CSV: 'download_drivers_csv',
  SORT_DRIVER_SEQUENCE: 'sort_driver_sequence',
  AUTO_FILL: 'auto_fill',
  DELETE_ALL: 'delete_all',
  CHANGE_MONTH: 'change_month',
  CHANGE_HOLIDAY_SETTING: 'change_holiday_setting',
  CHANGE_SCHEDULE_VIEW_SETTING: 'change_schedule_view_setting',
  SET_ALL_FIELD_WORKERS_TO_HELPER: 'set_all_field_workers_to_helper',
  DOWNLOAD_CSV_EXPORT_SCHEDULES: 'download_csv_export_schedules',
  SHOW_ANNOUNCEMENTS: 'show_announcements',
  SWAP_DRIVER: 'swap_driver',

  SHORTCUT: 'shortcut',
} as const;

export type RinEventPageNameTypes = (typeof PageNames)[keyof typeof PageNames];

export const PageNames = {
  SCHEDULE: 'schedule',
  ORDERS: 'orders',
  ERRORS: 'errors',
  GENERATION_SITE_IMPORT: 'generation_site_import',
  SEARCH_ORDERS: 'search_orders',
  MASTERS: 'masters',
  GENERATION_SITE: 'generation_site',
  CLIENT: 'client',
  ROUTING_REGULATION: 'routing_regulation',
  DRIVERS: 'drivers',
  DRIVER_ATTENDANCES: 'driver_attendances',
  DRIVER_ATTENDANCE_TEMPLATE: 'driver_attendance_template',
  COLLECTABLE_PERIOD_TEMPLATE: 'collectable_period_template',
  OFFICE_SETTING: 'office_setting',
  CONTAINER_TYPES: 'container_types',
  TASK_TYPE: 'task_type',
  CONTAINER_TASKS: 'container_tasks',
  CAR_TYPES: 'car_types',
  CARS: 'cars',
  CARS_ATTENDANCES: 'cars_attendances',
  WASTE_TYPE: 'waste_type',
  DISPOSAL_SITE: 'disposal_site',
  DISPOSAL_SITE_ATTENDANCES: 'disposal_site_attendances',
  DISPOSAL_SITE_IMPORT: 'disposal_site_import',
  BASE_SITE: 'base_site',
  EXECUTION: 'execution',
  TASK_RESULT_VIEWS: 'task_result_views',
  PACKING_PLACEMENTS: 'packing_placements',
  EXECUTION_CONTAINER_TYPES: 'execution_container_types',
  USER: 'user',
  RELEASE_NOTE: 'release_note',

  // 下記は page name ではないが referrer として利用するため追加
  ORDER_FORM: 'order_form',
  RESERVATION_FORM: 'reservation_form',
  CLIENT_FORM: 'client_form',
  GENERATION_SITE_FORM: 'generation_site_form',
  ROUTING_REGULATION_FORM: 'routing_regulation_form',
  ACCEPTANCE_CHECK_DIALOG: 'acceptance_check_dialog',
} as const;

export const RinEventDateFormat = 'yyyy-MM-dd' as const;

export type RinEventAdditionalInfoKeyTypes = (typeof AdditionalInfoKeys)[keyof typeof AdditionalInfoKeys];

export const AdditionalInfoKeys = {
  PAGE: 'page',
  DATE: 'date',
  ORDER_GROUP_ID: 'order_group_id',
  TARGET: 'target',
  REFERRER: 'referrer',
  MODE: 'mode',
  FROM: 'from',
  TO: 'to',

  CLIENT_ID: 'client_id',
  GENERATION_SITE_ID: 'generation_site_id',
  ORDER_ID: 'order_id',
  RESERVATION_ID: 'reservation_id',
  ROUTING_REGULATION_ID: 'routing_regulation_id',
  DRIVER_ID: 'driver_id',
  DRIVER_ATTENDANCE_TEMPLATE_ID: 'driver_attendance_template_id',
  COLLECTABLE_PERIOD_TEMPLATE_ID: 'collectable_period_template_id',
  CONTAINER_TYPE_ID: 'container_type_id',
  TASK_TYPE_ID: 'task_type_id',
  CAR_TYPE_ID: 'car_type_id',
  CAR_ID: 'car_id',
  WASTE_TYPE_ID: 'waste_type_id',
  DISPOSAL_SITE_ID: 'disposal_site_id',

  BASE_SITE_ID: 'base_site_id',

  SOURCE_ORDER_ID: 'source_order_id',
  SOURCE_GENERATION_SITE_ID: 'source_order_id',

  TASK_NAME: 'task_name',

  SCHEDULE_VIEW_SETTING: 'schedule_view_setting',
  VIEW_RANGE: 'view_range',
  SELECTED_DATE: 'selected_date',
  ERROR_COUNTS: 'error_counts',

  KEY: 'key',
  COMPONENT: 'component',

  // 受注検索のログで細かい設定値を送信するため追加
  START_AT: 'start_at',
  END_AT: 'end_at',
  CREATED_START_AT: 'created_start_at',
  CREATED_END_AT: 'created_end_at',
  INCLUDE_FOLLOWING_RECURRING_ORDER: 'include_following_recurring_order',
  ONLY_FIXED_DISPLAY_ON_RESERVATION: 'only_fixed_display_on_reservation',
} as const;

export type RinEventSortTargetTypes = (typeof SortTargetParams)[keyof typeof SortTargetParams];

export const SortTargetParams = {
  GENERATION_SITE: 'generation_site',
  CLIENT: 'client',
  COLLECTABLE_PERIOD: 'collectable_period',
  TASK_TYPE: 'task_type',
  WASTE_TYPE: 'waste_type',
  TASK_DURATION: 'task_duration',
  DISPOSAL_SITE: 'disposal_sites',
  UPDATED_AT: 'updated_at',
  UPDATED_BY: 'updated_by',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  DEFAULT: 'default',
} as const;

export type RinEventFormTargetTypes = (typeof FormTargetParams)[keyof typeof FormTargetParams];

export const FormTargetParams = {
  ORDER: 'order',
  RESERVATION: 'reservation',
  GENERATION_SITE: 'generation_site',
  CLIENT: 'client',
  ROUTING_REGULATION: 'routing_regulation',
  DRIVER: 'driver',
  DRIVER_ATTENDANCE: 'driver_attendance',
  DRIVER_ATTENDANCE_TEMPLATE: 'driver_attendance_template',
  COLLECTABLE_PERIOD_TEMPLATE: 'collectable_period_template',
  OFFICE_SETTING: 'office_setting',
  CONTAINER_TYPE: 'container_type',
  TASK_TYPE: 'task_type',
  CONTAINER_TASK: 'container_task',
  CAR_TYPE: 'car_type',
  CAR: 'car',
  CAR_ATTENDANCE: 'car_attendance',
  WASTE_TYPE: 'waste_type',
  DISPOSAL_SITE: 'disposal_site',
  DISPOSAL_SITE_ATTENDANCES: 'disposal_site_attendances',
  BASE_SITE: 'base_site',
  PACKING_PLACEMENT: 'packing_placement',
} as const;

export const RevertTargetParams = {
  ORDER_ASSIGNMENT: 'order_assignment',
  ROUTE: 'route',
  EXECUTE: 'execute',
} as const;

export const FormModeParams = {
  REGISTER: 'register',
  EDIT: 'edit',
} as const;

export type RinEventShortCutKeyTypes = (typeof ShortcutKeyParams)[keyof typeof ShortcutKeyParams];

export const ShortcutKeyParams = {
  C: 'c',
  G: 'g',
  M: 'm',
  S: 's',
  A: 'a',
  O: 'o',
  E: 'e',
  T: 't',

  LEFT: 'left',
  RIGHT: 'right',
  DOWN: 'down',
  UP: 'up',
  ENTER: 'enter',
  ESCAPE: 'escape',
} as const;

export const ComponentTypes = {
  DIALOG: 'dialog',
  FORM: 'form',
} as const;

export const RinEventDialogComponentParam = { [AdditionalInfoKeys.COMPONENT]: ComponentTypes.DIALOG } as const;
export const RinEventFormComponentParam = { [AdditionalInfoKeys.COMPONENT]: ComponentTypes.FORM } as const;
