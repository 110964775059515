import { ContainerTypeTaskTypeEntity } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeEntity';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  containerTypeTaskType$getAllSymbol,
  containerTypeTaskType$updateSymbol,
  IUpdateData as IContainerTypeTaskTypeUpdateData,
} from '~/framework/server-api/masters/containerTypeTaskType';
import { mapEntity } from '~/framework/core/mapper';
import { ContainerTypeTaskTypeMapper } from '~/framework/domain/masters/container-type/container-type-task-type/containerTypeTaskTypeMapper';
import { PackingStyleTaskTypeDefaultEntity } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultEntity';

export const containerTaskSymbol = Symbol('containerTask');

export interface IUpdateTasksResult {
  packingStyleTaskTypeDefaults: PackingStyleTaskTypeDefaultEntity[];
  containerTypeTaskTypes: ContainerTypeTaskTypeEntity[];
}

export class ContainerTaskApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async getAll(): Promise<ContainerTypeTaskTypeEntity[]> {
    const containerTypeTaskType$getAllApi = this.serverApis.get(containerTypeTaskType$getAllSymbol);
    const containerTypeTaskTypeMapper: ContainerTypeTaskTypeMapper = new ContainerTypeTaskTypeMapper(
      this.store.masters.containerTypeTaskType
    );

    const containerTypeTaskTypeData = await containerTypeTaskType$getAllApi.getAll();
    const containerTypeTaskTypes = containerTypeTaskTypeMapper.map(containerTypeTaskTypeData);

    return containerTypeTaskTypes;
  }

  async update(
    containerTypeTaskTypeUpdateData: IContainerTypeTaskTypeUpdateData[]
  ): Promise<ContainerTypeTaskTypeEntity[]> {
    const containerTypeTaskType$update = this.serverApis.get(containerTypeTaskType$updateSymbol);
    const containerTypeTaskType$getAllApi = this.serverApis.get(containerTypeTaskType$getAllSymbol);
    const containerTypeTaskTypeMapper: ContainerTypeTaskTypeMapper = new ContainerTypeTaskTypeMapper(
      this.store.masters.containerTypeTaskType
    );

    const containerTypeTaskTypeIds = await containerTypeTaskType$update.update(containerTypeTaskTypeUpdateData);
    const containerTypeTaskTypeData = await containerTypeTaskType$getAllApi.getAll();

    const containerTypeTaskTypes = containerTypeTaskTypeIds.mapValues(
      mapEntity(containerTypeTaskTypeMapper.map(containerTypeTaskTypeData))
    );

    return containerTypeTaskTypes;
  }
}
