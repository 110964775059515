import { WithoutId } from '~/framework/typeAliases';

import { OvertimeWorkType } from '~/framework/domain/typeAliases';

export const driverAttendance$createSymbol = Symbol('driverAttendance$createSymbol');
export const driverAttendance$createDefaultSymbol = Symbol('driverAttendance$createDefaultSymbol');
export const driverAttendance$deleteSymbol = Symbol('driverAttendance$deleteSymbol');
export const driverAttendance$getByDateRangeSymbol = Symbol('driverAttendance$getByDateRangeSymbol');
export const driverAttendance$getByDateRangeOfAllDriversSymbol = Symbol(
  'driverAttendance$getByDateRangeOfAllDriversSymbol'
);
export const driverAttendance$updateSymbol = Symbol('driverAttendance$updateSymbol');

export type DriverAttendance = {
  [driverAttendance$getByDateRangeSymbol]: IGetByDateRangeApi;
  [driverAttendance$getByDateRangeOfAllDriversSymbol]: IGetByDateRangeOfAllDriversApi;
  [driverAttendance$createSymbol]: ICreateApi;
  [driverAttendance$updateSymbol]: IUpdateApi;
  [driverAttendance$deleteSymbol]: IDeleteApi;
  [driverAttendance$createDefaultSymbol]: ICreateDefaultApi;
};

export interface ICreateApi {
  create(data: WithoutId<IDriverAttendanceData>[]): Promise<string[]>;
}

export interface ICreateDefaultApi {
  createDefault(driverId: string, start: Date, end: Date): Promise<IDriverAttendanceData[]>;
}

export interface IDeleteApi {
  delete(ids: string[]): Promise<string[]>;
}

export interface IDriverAttendanceData {
  id: string;
  attendanceId: string;
  driverId: string;
  primaryCarId: string;
  forceRidePrimaryCar: boolean;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
}

export interface IGetByDateRangeApi {
  getByDateRange(driverId: string, start: Date, end: Date): Promise<IDriverAttendanceData[]>;
}

export interface IGetByDateRangeOfAllDriversApi {
  getByDateRangeOfAllDrivers(start: Date, end: Date): Promise<IDriverAttendanceData[]>;
}

export interface IUpdateApi {
  update(data: IDriverAttendanceData[]): Promise<string[]>;
}
