import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';

export interface IDriverAssignment {
  driverType: DriverType;
  driverId: PseudoId;
}

// NOTE: 受注の指定乗務員にも enum DriverType は存在するが、こちらは配車表側の呼称に追従して運転者 = Driver となっている
export enum DriverType {
  // 運転者 (is_driver: true)
  Driver = 'Driver',
  // 補助員 (is_helper: true)
  Helper = 'Helper',
  // 指定なし (配車表 json に is_driver / is_helper が指定されていない)
  None = 'None',
}
