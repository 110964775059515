import _ from 'lodash';
import { DateString, Maybe, PersistentId } from '~/framework/typeAliases';
import { AggregatedDriverAttendanceEntity as IDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import {
  DriverAttendanceWrapper,
  IDriverAttendanceWrapper,
} from '~/components/common/r-driver-monthly-attendances-dialog/r-driver-attendance/driverAttendanceWrapper';
import { MonthDatesCalendar } from '~/framework/services/calendar/monthDatesCalendar';
import { formatDateToString } from '~/framework/services/date/date';
import { DriverAttendanceMap, IDriverAttendanceMap } from '~/framework/view-models/driverAttendanceMap';
import { DaysAWeek } from '~/framework/constants';
import {
  DefaultDriverAttendance,
  IDefaultDriverAttendance,
} from '~/components/common/r-driver-monthly-attendances-dialog/defaultDriverAttendance';
import { DriverAttendanceTemplateEntity } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import { AggregatedCarEntity as ICarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { AggregatedDriverEntity as IDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';
import { HolidayRuleEntity } from '~/framework/domain/masters/holiday-rule/holidayRuleEntity';
import { NationalHolidayService } from '~/framework/domain/masters/holiday-rule/nationalHolidayService';
import { IDriverAttendanceError } from '~/components/common/r-driver-monthly-attendances-dialog/driverAttendanceError';

export interface IDriverMonthlyAttendances {
  /**
   * 月のカレンダー
   */
  monthDatesCalendar: MonthDatesCalendar;

  /**
   * 対象のドライバー
   */
  driver: IDriverEntity;

  /**
   * よく乗る車が設定されているかどうか
   */
  hasDefaultPrimaryCar: boolean;

  /**
   * 勤怠ルールが設定されているかどうか
   */
  hasDefaultAttendanceTemplate: boolean;

  /**
   * vue 側で ?. の記述ができないのでこちら側に入れている
   */
  defaultPrimaryCarName: Maybe<string>;

  /**
   * vue 側で ?. の記述ができないのでこちら側に入れている
   */
  defaultPrimaryCarTypeName: Maybe<string>;

  /**
   * vue 側で ?. の記述ができないのでこちら側に入れている
   */
  defaultAttendanceTemplateName: Maybe<string>;

  /**
   * これが与えられている区間に関してはカブりの警告を出す事ができる
   */
  driverAttendances: IDriverAttendanceWrapper[][];

  /**
   * 乗務員の勤怠が追加された時に呼ばれる
   * @param driverAttendance
   */
  addDriverAttendances(...driverAttendances: IDriverAttendanceEntity[]): void;

  /**
   * できるだけカブらない primaryCarId を返すが、カブる可能性はある
   * 乗務員にデフォルトの車が設定されていたらそれを利用する
   * されていなかった場合はその日の勤怠から使われていない車を適当に割り当てる
   * 今後は defaultPrimaryCarTypeId が設定されていてそこから車を設定する様な事も考えられる
   */
  getPreferablePrimaryCarId(date: Date): PersistentId;

  /**
   * primaryCar が衝突している勤怠を取得する
   */
  getConflictedDriverAttendances(): IDriverAttendanceEntity[];

  /**
   * カレンダーがズレた状態で呼ばれる事は想定していないので注意
   * @param generatedDriverAttendances
   */
  getConflictedDefaultDriverAttendanceDates(
    start: Date,
    end: Date,
    generatedDriverAttendances: IDriverAttendanceEntity[]
  ): Date[];

  /**
   * 指定した日を自動生成できなかったものとして表示する
   * @param dates
   */
  setDatesNotGenerated(dates: Date[]): void;

  /**
   * 選択された月の勤怠を全て取得する
   */
  getDriverAttendancesOfSelectedMonth(): IDriverAttendanceEntity[];

  /**
   * 前の月ボタンが押された時に呼ばれる
   */
  onClickPrevMonthButton(): void;

  /**
   * 次の月ボタンが押されたら呼ばれる
   */
  onClickNextMonthButton(): void;

  /**
   * 勤怠マップが更新された時に呼ばれる
   * イベント経由にするとリークするので
   */
  onUpdateAllDriverAttendanceMap(): void;

  /**
   * 特定の勤怠が更新された時に呼ばれる
   */
  onUpdateDriverAttendance(driverAttendance: IDriverAttendanceEntity): void;

  /**
   * 特定の勤怠が削除された時に呼ばれる
   */
  onDeleteDriverAttendances(...driverAttendance: IDriverAttendanceEntity[]): void;
}

export class DriverMonthlyAttendances implements IDriverMonthlyAttendances {
  private readonly allDriverAttendanceMap: IDriverAttendanceMap;
  private readonly holidayRule: HolidayRuleEntity;
  private readonly cars: ICarEntity[];
  private readonly weekDates: Date[];
  private readonly driverAttendanceMap: Map<DateString, IDriverAttendanceWrapper>;
  private readonly driverAttendanceTemplates: DriverAttendanceTemplateEntity[];
  private readonly defaultDriverAttendance: IDefaultDriverAttendance;
  private readonly nationalHolidayService: NationalHolidayService;
  private readonly errors: Map<DateString, IDriverAttendanceError>;

  readonly monthDatesCalendar: MonthDatesCalendar;
  readonly driver: IDriverEntity;

  hasDefaultPrimaryCar: boolean;

  hasDefaultAttendanceTemplate: boolean;

  defaultPrimaryCarName: Maybe<string>;

  defaultPrimaryCarTypeName: Maybe<string>;

  defaultAttendanceTemplateName: Maybe<string>;

  driverAttendances!: IDriverAttendanceWrapper[][];

  constructor(
    holidayRule: HolidayRuleEntity,
    monthDatesCalendar: MonthDatesCalendar,
    driver: IDriverEntity,
    baseDate: Date,
    driverAttendanceTemplates: DriverAttendanceTemplateEntity[],
    cars: ICarEntity[],
    allDriverAttendances: Maybe<IDriverAttendanceEntity[]>,
    nationalHolidayService: NationalHolidayService,
    errors: Maybe<IDriverAttendanceError[]>
  ) {
    this.holidayRule = holidayRule;
    this.monthDatesCalendar = monthDatesCalendar;
    this.driver = driver;
    this.driverAttendanceTemplates = driverAttendanceTemplates;
    this.cars = cars;
    this.nationalHolidayService = nationalHolidayService;
    this.errors = new Map<DateString, IDriverAttendanceError>();
    for (const error of errors ?? []) {
      this.errors.set(this.getDateKey(error.date), error);
    }
    this.hasDefaultPrimaryCar = this.driver.defaultPrimaryCar !== undefined;
    this.hasDefaultAttendanceTemplate = this.driver.defaultAttendanceTemplate !== undefined;
    this.defaultPrimaryCarName = this.driver.defaultPrimaryCar?.name;
    this.defaultPrimaryCarTypeName = this.driver.defaultPrimaryCar?.carType.name;
    this.defaultAttendanceTemplateName = this.driver.defaultAttendanceTemplate?.name;
    this.weekDates = this.monthDatesCalendar.getWeekDatesOf(baseDate);
    this.allDriverAttendanceMap = new DriverAttendanceMap();
    if (allDriverAttendances) this.allDriverAttendanceMap.addDriverAttendances(...allDriverAttendances);
    this.defaultDriverAttendance = new DefaultDriverAttendance(
      holidayRule,
      driver,
      this.allDriverAttendanceMap,
      nationalHolidayService
    );
    this.driverAttendanceMap = new Map<DateString, IDriverAttendanceWrapper>();
    this.updateDriverAttendances();
    const driverAttendances = allDriverAttendances?.filter(
      (driverAttendance) => driverAttendance.driverId === driver.persistentId
    );
    if (driverAttendances !== undefined) {
      for (const driverAttendance of driverAttendances) {
        this.addDriverAttendances(driverAttendance);
      }
    }
  }

  addDriverAttendances(...driverAttendances: IDriverAttendanceEntity[]): void {
    this.allDriverAttendanceMap.addDriverAttendances(...driverAttendances);
    this.onUpdateAllDriverAttendanceMap();
    for (const driverAttendance of driverAttendances) {
      if (driverAttendance.driverId === this.driver.persistentId) {
        this.addToDriverAttendanceMap(driverAttendance);
      }
    }
  }

  getPreferablePrimaryCarId(date: Date): PersistentId {
    if (this.driver.defaultPrimaryCarId !== undefined) return this.driver.defaultPrimaryCarId;
    const carIds = this.cars.map((car) => car.persistentId);
    const usedCarIds = this.allDriverAttendanceMap.getUsedCarIdsOf(date);
    const usedCarIdSet = new Set<PersistentId>(usedCarIds);
    const nonUsedCarIds = carIds.filter((id) => !usedCarIdSet.has(id));
    if (0 < nonUsedCarIds.length) return _.first(nonUsedCarIds)!;
    else return _.first(carIds)!;
  }

  getConflictedDriverAttendances(): IDriverAttendanceEntity[] {
    const result = [];
    for (const driverAttendance of this.driverAttendanceMap.values()) {
      if (driverAttendance.driverAttendance !== undefined && driverAttendance.isPrimaryCarConflicted) {
        result.push(driverAttendance.driverAttendance);
      }
    }
    return result;
  }

  getConflictedDefaultDriverAttendanceDates(
    start: Date,
    end: Date,
    generatedDriverAttendances: IDriverAttendanceEntity[]
  ): Date[] {
    return this.defaultDriverAttendance.getConflictedDates(start, end, generatedDriverAttendances);
  }

  setDatesNotGenerated(dates: Date[]): void {
    for (const date of dates) {
      const wrapper = this.driverAttendanceMap.get(this.getDateKey(date));
      if (wrapper) wrapper.isDefaultDriverAttendanceNotGenerated = true;
    }
  }

  getDriverAttendancesOfSelectedMonth(): IDriverAttendanceEntity[] {
    const firstDateOfMonth = this.monthDatesCalendar.firstDateOfMonth;
    const driverAttendanceMap = this.allDriverAttendanceMap.getAttendanceMapOf(this.driver.persistentId);
    const driverAttendances = [];
    for (const driverAttendance of driverAttendanceMap.values()) {
      if (driverAttendance.attendance.date.getMonth() === firstDateOfMonth.getMonth()) {
        driverAttendances.push(driverAttendance);
      }
    }
    return driverAttendances;
  }

  onClickPrevMonthButton(): void {
    this.monthDatesCalendar.shiftToPreviousMonth();
    this.updateDriverAttendances();
  }

  onClickNextMonthButton(): void {
    this.monthDatesCalendar.shiftToNextMonth();
    this.updateDriverAttendances();
  }

  onUpdateAllDriverAttendanceMap(): void {
    this.driverAttendances.forEach((week) => {
      week.forEach((day) => day.onUpdateAllDriverAttendanceMap());
    });
  }

  onUpdateDriverAttendance(driverAttendance: IDriverAttendanceEntity): void {
    this.addToDriverAttendanceMap(driverAttendance);
  }

  onDeleteDriverAttendances(...driverAttendances: IDriverAttendanceEntity[]): void {
    this.allDriverAttendanceMap.removeDriverAttendances(...driverAttendances);
    for (const driverAttendance of driverAttendances) {
      const wrapper = this.getWrapperOf(driverAttendance);
      if (wrapper) wrapper.driverAttendance = undefined;
    }
    this.onUpdateAllDriverAttendanceMap();
  }

  private addToDriverAttendanceMap(driverAttendance: IDriverAttendanceEntity): void {
    const wrapper = this.getWrapperOf(driverAttendance);
    if (wrapper) wrapper.driverAttendance = driverAttendance;
  }

  private getWrapperOf(driverAttendance: IDriverAttendanceEntity): Maybe<IDriverAttendanceWrapper> {
    const key = this.getDateKey(driverAttendance.attendance.date);
    return this.driverAttendanceMap.get(key);
  }

  private updateDriverAttendances() {
    this.driverAttendances = [];
    let week = [];
    for (const date of this.monthDatesCalendar.monthDates) {
      // すでにラッパーが存在するならそれを使うが、なければ追加
      const key = this.getDateKey(date);
      const wrapper = this.driverAttendanceMap.get(key);
      if (wrapper !== undefined) {
        week.push(wrapper);
      } else {
        const wrapper = new DriverAttendanceWrapper(
          date,
          this.monthDatesCalendar.isDateOfSelectedMonth(date),
          this.nationalHolidayService.isHoliday(date, this.holidayRule),
          this.isSelectedDate(date),
          this.driver,
          this.driverAttendanceTemplates,
          this.cars,
          this.allDriverAttendanceMap,
          this.errors.get(this.getDateKey(date))
        );
        week.push(wrapper);
        this.driverAttendanceMap.set(this.getDateKey(date), wrapper);
      }
      if (week.length === DaysAWeek) {
        this.driverAttendances.push(week);
        week = [];
      }
    }
  }

  private isSelectedDate(date: Date): boolean {
    const first = _.first(this.weekDates)!;
    const last = _.last(this.weekDates)!;
    return first.getTime() <= date.getTime() && date.getTime() <= last.getTime();
  }

  private getDateKey(date: Date): DateString {
    return formatDateToString(date);
  }
}
