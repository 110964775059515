import { OvertimeWorkType } from '~/framework/domain/typeAliases';
import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';

export interface DriverAttendanceEntityData {
  id: string;
  attendanceId: string;
  driverId: string;
  primaryCarId: string;
  forceRidePrimaryCar: boolean;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
}

export class DriverAttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  attendanceId: string;
  driverId: string;
  primaryCarId: string;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  @ReactiveProperty(DriverAttendanceEntity.prototype.updateHasRestPeriod)
  restPeriodStart: Maybe<number>;

  @ReactiveProperty(DriverAttendanceEntity.prototype.updateHasRestPeriod)
  restPeriodEnd: Maybe<number>;

  @ReactiveProperty(DriverAttendanceEntity.prototype.updateIsOvertimeWorkable)
  overtimeWorkType: OvertimeWorkType;

  overtimeWorkableDuration: number;

  hasRestPeriod!: boolean;
  isOvertimeWorkable!: boolean;

  constructor(
    id: string,
    attendanceId: string,
    driverId: string,
    primaryCarId: string,
    public forceRidePrimaryCar: boolean,
    templateName: string,
    regularWorkPeriodStart: number,
    regularWorkPeriodEnd: number,
    restPeriodStart: Maybe<number>,
    restPeriodEnd: Maybe<number>,
    overtimeWorkType: OvertimeWorkType,
    overtimeWorkableDuration: number
  ) {
    this.id = id;
    this.persistentId = id;
    this.attendanceId = attendanceId;
    this.driverId = driverId;
    this.primaryCarId = primaryCarId;
    this.templateName = templateName;
    this.regularWorkPeriodStart = regularWorkPeriodStart;
    this.regularWorkPeriodEnd = regularWorkPeriodEnd;
    this.restPeriodStart = restPeriodStart;
    this.restPeriodEnd = restPeriodEnd;
    this.overtimeWorkType = overtimeWorkType;
    this.overtimeWorkableDuration = overtimeWorkableDuration;
    this.updateHasRestPeriod();
    this.updateIsOvertimeWorkable();
  }

  private updateIsOvertimeWorkable(): void {
    this.isOvertimeWorkable = this.overtimeWorkType !== OvertimeWorkType.None;
  }

  private updateHasRestPeriod(): void {
    this.hasRestPeriod = this.restPeriodStart !== undefined && this.restPeriodEnd !== undefined;
  }
}
