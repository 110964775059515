import { AggregatedDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { PersistentId } from '~/framework/typeAliases';
import {
  driverAttendance$getByDateRangeSymbol,
  driverAttendance$getByDateRangeOfAllDriversSymbol,
  driverAttendance$deleteSymbol,
  driverAttendance$createDefaultSymbol,
  driverAttendance$createSymbol,
  driverAttendance$updateSymbol,
} from '~/framework/server-api/masters/driverAttendance';
import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { carType$getAllSymbol } from '~/framework/server-api/masters/carType';
import { car$getAllSymbol } from '~/framework/server-api/masters/car';
import { driverAttendanceTemplate$getAllSymbol } from '~/framework/server-api/masters/driverAttendanceTemplate';
import { driver$getAllSymbol } from '~/framework/server-api/masters/driver';
import { attendance$getByDateRangeSymbol, attendance$createSymbol } from '~/framework/server-api/masters/attendance';
import { containerType$getAllSymbol } from '~/framework/server-api/masters/containerType';
import { IDriverAttendanceFactory } from '~/framework/factories/masters/driverAttendanceFactory';
import { OvertimeWorkType } from '~/framework/domain/typeAliases';

export const driverAttendanceSymbol = Symbol('driverAttendance');

export interface IDriverAttendanceCreateData {
  driverId: string;
  primaryCarId: string;
  forceRidePrimaryCar: boolean;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
}

export interface IDriverAttendanceUpdateData {
  id: string;
  attendanceId: string;
  driverId: string;
  primaryCarId: string;
  forceRidePrimaryCar: boolean;
  templateName: string;
  regularWorkPeriodStart: number;
  regularWorkPeriodEnd: number;
  restPeriodStart: number | undefined;
  restPeriodEnd: number | undefined;
  overtimeWorkType: OvertimeWorkType;
  overtimeWorkableDuration: number;
}

export class DriverAttendanceApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly driverAttendanceFactory: IDriverAttendanceFactory;

  constructor(store: Store, serverApis: ServerApiManager, driverAttendanceFactory: IDriverAttendanceFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.driverAttendanceFactory = driverAttendanceFactory;
  }

  async getByDates(start: Date, end: Date): Promise<AggregatedDriverAttendanceEntity[]> {
    const driverAttendance$getByDateRangeOfAllDrivers = this.serverApis.get(
      driverAttendance$getByDateRangeOfAllDriversSymbol
    );
    const car$getAllApi = this.serverApis.get(car$getAllSymbol);
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const driverAttendanceTemplate$getAllApi = this.serverApis.get(driverAttendanceTemplate$getAllSymbol);
    const driver$getAllApi = this.serverApis.get(driver$getAllSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);

    const [
      driverAttendanceData,
      allDriversData,
      carData,
      carTypeData,
      driverAttendanceTemplateData,
      containerTypesData,
    ] = await Promise.all([
      driverAttendance$getByDateRangeOfAllDrivers.getByDateRangeOfAllDrivers(start, end),
      driver$getAllApi.getAll(),
      car$getAllApi.getAll(),
      carType$getAllApi.getAll(),
      driverAttendanceTemplate$getAllApi.getAll(),
      containerType$getAllApi.getAll(),
    ]);

    const driverAttendances = await this.driverAttendanceFactory.buildByData(driverAttendanceData, {
      driverData: allDriversData,
      carData,
      carTypeData,
      driverAttendanceTemplateData,
      containerTypesData,
    });

    return driverAttendances;
  }

  async getListOfDriver(driverId: PersistentId, start: Date, end: Date): Promise<AggregatedDriverAttendanceEntity[]> {
    const driverAttendance$getByDateRangeApi = this.serverApis.get(driverAttendance$getByDateRangeSymbol);
    const driverAttendanceData = await driverAttendance$getByDateRangeApi.getByDateRange(driverId, start, end);
    return this.driverAttendanceFactory.buildByData(driverAttendanceData);
  }

  async deleteDriverAttendances(ids: PersistentId[]): Promise<string[]> {
    const api = this.serverApis.get(driverAttendance$deleteSymbol);
    const result = await api.delete(ids);
    this.store.masters.aggregatedDriverAttendance.delete(result);
    return result;
  }

  async createDefaultDriverAttendances(
    driverId: PersistentId,
    start: Date,
    end: Date
  ): Promise<AggregatedDriverAttendanceEntity[]> {
    const driverAttendance$createDefaultApi = this.serverApis.get(driverAttendance$createDefaultSymbol);
    const driverAttendanceData = await driverAttendance$createDefaultApi.createDefault(driverId, start, end);
    return this.driverAttendanceFactory.buildByData(driverAttendanceData);
  }

  async create(date: Date, data: IDriverAttendanceCreateData): Promise<AggregatedDriverAttendanceEntity> {
    const attendance$getByDateApi = this.serverApis.get(attendance$getByDateRangeSymbol);
    const attendance$createApi = this.serverApis.get(attendance$createSymbol);
    const driverAttendance$createApi = this.serverApis.get(driverAttendance$createSymbol);
    const [attendance] = await attendance$getByDateApi.getByDateRange(date, date);
    const attendanceId = attendance ? attendance.id : (await attendance$createApi.create([{ date }]))[0];
    const driverAttendanceData = { ...data, attendanceId };
    const [driverAttendanceId] = await driverAttendance$createApi.create([driverAttendanceData]);
    const [entity] = await this.driverAttendanceFactory.buildByData([
      {
        ...driverAttendanceData,
        id: driverAttendanceId,
      },
    ]);
    return entity;
  }

  async update(data: IDriverAttendanceUpdateData): Promise<AggregatedDriverAttendanceEntity> {
    const driverAttendance$updateApi = this.serverApis.get(driverAttendance$updateSymbol);
    await driverAttendance$updateApi.update([data]);
    const [entity] = await this.driverAttendanceFactory.buildByData([data]);
    return entity;
  }
}
