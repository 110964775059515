import { Maybe } from '~/framework/typeAliases';
import { ReactiveProperty } from '~/framework/property';

export interface JwnetWasteMasterEntityData {
  id: string;
  firstCategoryName: string;
  secondCategoryName: Maybe<string>;
  thirdCategoryName: Maybe<string>;
  code: string;
  isUsable: boolean;
}

export class JwnetWasteMasterEntity {
  readonly id: string;
  readonly persistentId: string;
  firstCategoryName: string;
  secondCategoryName: Maybe<string>;
  thirdCategoryName: Maybe<string>;

  @ReactiveProperty(JwnetWasteMasterEntity.prototype.updateCategoryCodes)
  code: string;

  isUsable: boolean;

  firstCategoryCode!: string;
  secondCategoryCode!: string;
  thirdCategoryCode!: string;
  fourthCategoryCode!: string;

  constructor(
    id: string,
    firstCategoryName: string,
    secondCategoryName: Maybe<string>,
    thirdCategoryName: Maybe<string>,
    code: string,
    isUsable: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.firstCategoryName = firstCategoryName;
    this.secondCategoryName = secondCategoryName;
    this.thirdCategoryName = thirdCategoryName;
    this.code = code;
    this.isUsable = isUsable;
    this.updateCategoryCodes();
  }

  private updateCategoryCodes(): void {
    this.firstCategoryCode = this.code.slice(0, 2);
    this.secondCategoryCode = this.code.slice(2, 3);
    this.thirdCategoryCode = this.code.slice(3, 4);
    this.fourthCategoryCode = this.code.slice(4, 7);
  }
}
