import {
  BooleanValue,
  TaskType,
  IntegerValue,
  ContainerTypeTaskTypeStatusValue,
  ContainerTypeTaskTypeStatus,
} from '~/framework/domain/typeAliases';

import { Maybe } from '~/framework/typeAliases';
export const containerTypeTaskType$getAllSymbol = Symbol('containerTypeTaskType$getAllSymbol');
export const containerTypeTaskType$updateSymbol = Symbol('containerTypeTaskType$updateSymbol');

export type ContainerTypeTaskType = {
  [containerTypeTaskType$getAllSymbol]: IGetAll;
  [containerTypeTaskType$updateSymbol]: IUpdate;
};

export interface IContainerTypeTaskTypeData {
  id: string;
  containerTypeId: string;
  taskType: TaskType;
  duration: IntegerValue;
  status: ContainerTypeTaskTypeStatusValue;
  isProportionalToCount: BooleanValue;
}

export interface IGetAll {
  getAll(): Promise<IContainerTypeTaskTypeData[]>;
}

export interface IUpdate {
  update(data: IUpdateData[]): Promise<string[]>;
}

export interface IUpdateData {
  id: string;
  values: {
    duration: Maybe<number>;
    status: Maybe<ContainerTypeTaskTypeStatus>;
    isProportionalToCount: Maybe<boolean>;
  };
}
