import { AggregatedReservationEntity } from '~/framework/domain/reservation/reservation/aggregatedReservationEntity';
import { IReservationData } from '~/framework/server-api/reservation/reservation';
import { AggregatedReservationMapper } from '~/framework/domain/reservation/reservation/aggregatedReservationMapper';
import { Store } from '~/framework/domain/store';
import { mapData } from '~/framework/core/mapper';
import { wasteType$getAllSymbol } from '~/framework/server-api/masters/wasteType';
import { containerType$getAllSymbol } from '~/framework/server-api/masters/containerType';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { client$getByIdsSymbol } from '~/framework/server-api/masters/client';
import { orderGroup$getAllSymbol } from '~/framework/server-api/masters/orderGroup';
import { generationSite$getByIdsSymbol } from '~/framework/server-api/masters/generationSite';
import { disposalSite$getAllSymbol } from '~/framework/server-api/masters/disposalSite';
import { driver$getByIdsSymbol } from '~/framework/server-api/masters/driver';
import { carType$getAllSymbol } from '~/framework/server-api/masters/carType';
import { car$getByIdsSymbol } from '~/framework/server-api/masters/car';
import { PersistentId } from '~/framework/typeAliases';
import { IOrderAssignedDisposalSite } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';

export interface IReservationFactory {
  buildByData(data: IReservationData[]): Promise<AggregatedReservationEntity[]>;
}

export class ReservationFactory implements IReservationFactory {
  private readonly reservationMapper: AggregatedReservationMapper;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.reservationMapper = new AggregatedReservationMapper(
      store.reservation.aggregatedReservation,
      store.masters.aggregatedClient,
      store.masters.user,
      store.masters.orderGroup,
      store.masters.aggregatedDisposalSite,
      store.masters.aggregatedWasteType,
      store.masters.aggregatedCar,
      store.masters.aggregatedCarType,
      store.masters.aggregatedCarTypeContainerType,
      store.masters.aggregatedBaseSite,
      store.masters.jwnetWasteMaster
    );
    this.serverApis = serverApis;
  }

  async buildByData(data: IReservationData[]): Promise<AggregatedReservationEntity[]> {
    const client$getByIdsApi = this.serverApis.get(client$getByIdsSymbol);
    const orderGroup$getAllApi = this.serverApis.get(orderGroup$getAllSymbol);
    const generationSite$getByIdsApi = this.serverApis.get(generationSite$getByIdsSymbol);
    const disposalSite$getAllApi = this.serverApis.get(disposalSite$getAllSymbol);
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const carType$getAllApi = this.serverApis.get(carType$getAllSymbol);
    const car$getByIdsApi = this.serverApis.get(car$getByIdsSymbol);
    const containerType$getAllApi = this.serverApis.get(containerType$getAllSymbol);
    const wasteType$getAllApi = this.serverApis.get(wasteType$getAllSymbol);

    const orderGroupIdSet = new Set<PersistentId>();
    const clientIdSet = new Set<PersistentId>();
    const generationSiteIdSet = new Set<PersistentId>();
    const disposalSiteIdSet = new Set<PersistentId>();
    const driverIdSet = new Set<PersistentId>();
    const carTypeIdSet = new Set<PersistentId>();
    const carIdSet = new Set<PersistentId>();
    for (const aData of data) {
      const assignedDisposalSiteIds = aData.orderAssignedDisposalSites.map(
        (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSite.id
      );
      orderGroupIdSet.add(aData.orderGroupId);
      clientIdSet.add(aData.clientId);
      generationSiteIdSet.add(aData.generationSiteId);
      disposalSiteIdSet.addValues(...assignedDisposalSiteIds);
      carTypeIdSet.addValues(...aData.assignableCarTypeIds);
      if (aData.assignableDrivers !== undefined && aData.assignableDrivers.length !== 0) {
        aData.assignableDrivers.forEach((assignableDriver) => driverIdSet.add(assignableDriver.driverId));
      }
      if (aData.assignedCarId !== undefined) carIdSet.add(aData.assignedCarId);
    }

    const [
      orderGroupData,
      clientData,
      generationSiteData,
      disposalSiteData,
      wasteTypeData,
      driverData,
      carTypeData,
      carData,
      containerTypeData,
    ] = await Promise.all([
      orderGroup$getAllApi.getAll(),
      client$getByIdsApi.getByIds(clientIdSet.toArray()),
      generationSite$getByIdsApi.getByIds(generationSiteIdSet.toArray()),
      disposalSite$getAllApi.getAll(),
      wasteType$getAllApi.getAll(),
      driver$getByIdsApi.getByIds(driverIdSet.toArray()),
      carType$getAllApi.getAll(),
      car$getByIdsApi.getByIds(carIdSet.toArray()),
      containerType$getAllApi.getAll(),
    ]);

    const orderGroupDataMap = mapData(orderGroupData, 'id');
    const clientDataMap = mapData(clientData, 'id');
    const generationSiteDataMap = mapData(generationSiteData, 'id');
    const disposalSiteDataMap = mapData(disposalSiteData, 'id');
    const wasteTypeDataMap = mapData(wasteTypeData, 'id');
    const carTypeDataMap = mapData(
      carTypeData.map((carType) => {
        return {
          ...carType,
          orderGroupId: carType.orderGroup.id,
        };
      }),
      'id'
    );
    const containerTypeDataMap = mapData(containerTypeData, 'id');
    const carDataMap = mapData(
      carData.map((car) => {
        const carTypeEntityData = {
          ...car.carType,
          orderGroupId: car.carType.orderGroup.id,
          loadableContainerTypes: car.carType.loadableContainerTypes.map((container) => {
            return {
              ...container,
              containerName: containerTypeDataMap.getOrError(container.containerTypeId).name,
              containerUnitName: containerTypeDataMap.getOrError(container.containerTypeId).unitName,
            };
          }),
        };

        return { ...car, carType: carTypeEntityData };
      }),
      'id'
    );

    const reservationEntityData = data.map((aData) => {
      const generationSiteTasks = aData.generationSiteTasks.map((generationSiteTask) => {
        return {
          ...generationSiteTask,
          wasteType: generationSiteTask.wasteTypeId?.map(wasteTypeDataMap),
          containerType: generationSiteTask.containerTypeId.map(containerTypeDataMap)!,
        };
      });

      // 受注候補日を古い → 新しい順に並び変える
      aData.candidateDates.sort((a, b) => {
        return (
          a.date.getTime() - b.date.getTime() ||
          a.collectablePeriodStart - b.collectablePeriodStart ||
          a.collectablePeriodEnd - b.collectablePeriodEnd
        );
      });

      // TODO: assignableDriverEntities は 受注一覧と配車表の受注詳細の指定乗務員の情報を表示するために追加しているが、配車表取得最適化タスクで不要になる想定のため暫定対応 [HGB-1206]
      const assignableDriverEntities = driverData.filter((driver) =>
        aData.assignableDrivers.map((assignableDriver) => assignableDriver.driverId).includes(driver.id)
      );

      const orderAssignedDisposalSites: IOrderAssignedDisposalSite[] = aData.orderAssignedDisposalSites.map(
        (orderAssignedDisposalSite) => {
          const defaultDurationAtEntrance = disposalSiteDataMap.getOrError(
            orderAssignedDisposalSite.disposalSite.id
          ).durationAtEntrance;
          return {
            disposalSiteId: orderAssignedDisposalSite.disposalSite.id,
            defaultDurationAtEntrance,
            durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance || defaultDurationAtEntrance,
            priority: orderAssignedDisposalSite.priority,
          };
        }
      );

      const assignedDisposalSiteIds = aData.orderAssignedDisposalSites.map(
        (orderAssignedDisposalSite) => orderAssignedDisposalSite.disposalSite.id
      );

      return {
        ...aData,
        client: clientDataMap.getOrError(aData.clientId),
        orderGroup: orderGroupDataMap.getOrError(aData.orderGroupId),
        generationSite: generationSiteDataMap.getOrError(aData.generationSiteId),
        assignedDisposalSites: assignedDisposalSiteIds.mapValues(disposalSiteDataMap),
        orderAssignedDisposalSites,
        assignableDriverEntities,
        assignedCar: aData.assignedCarId?.map(carDataMap),
        assignableCarTypes: aData.assignableCarTypeIds.mapValues(carTypeDataMap),
        generationSiteTasks,
        driverAssignmentType: aData.driverAssignmentType,
        // NOTE: remove __typeName
        assignableDrivers: aData.assignableDrivers.map((assignableDriver) => {
          return {
            driverType: assignableDriver.driverType,
            driverId: assignableDriver.driverId,
          };
        }),
      };
    });
    return this.reservationMapper.map(reservationEntityData);
  }
}
