/**
 * 乗務員の勤怠の日ごとのマップを扱うためのインターフェース
 * 週なり月なりの他の乗務員の勤怠を保持しておき自分の勤怠を追加した時に primaryCar がカブるかどうかというのは
 * 似たような処理になるので一覧側でも月の編集画面側でも同じものを利用している
 * 内部的には allDriverAttendanceMap に日ごとに全ての乗務員の勤怠を保持している
 */
import { AggregatedDriverAttendanceEntity as IDriverAttendanceEntity } from '~/framework/domain/masters/driver-attendance/aggregatedDriverAttendanceEntity';
import { DateString, PersistentId } from '~/framework/typeAliases';
import { formatDateToString } from '~/framework/services/date/date';

export interface IDriverAttendanceMap {
  /**
   * 勤怠を追加する
   * @param driverAttendances
   */
  addDriverAttendances(...driverAttendances: IDriverAttendanceEntity[]): void;

  /**
   * 勤怠を削除する
   * @param driverAttendances
   */
  removeDriverAttendances(...driverAttendances: IDriverAttendanceEntity[]): void;

  /**
   * 勤怠を与えて primaryCar がコンフリクトしているかどうかを取得する
   * @param driverAttendance
   */
  isPrimaryCarConflicted(driverAttendance: IDriverAttendanceEntity): boolean;

  /**
   * 特定の日の存在する勤怠を全て取得する
   * @param date
   */
  getAllDriverAttendanceOf(date: Date): IDriverAttendanceEntity[];

  /**
   * 既に使われている ID のリスト
   */
  getUsedCarIdsOf(date: Date): PersistentId[];

  /**
   * 特定ドライバーの勤怠マップを取得する
   * @param driverId
   */
  getAttendanceMapOf(driverId: PersistentId): Map<DateString, IDriverAttendanceEntity>;
}

export class DriverAttendanceMap implements IDriverAttendanceMap {
  private readonly allDriverAttendanceMap: Map<DateString, IDriverAttendanceEntity[]>;

  constructor() {
    this.allDriverAttendanceMap = new Map<DateString, IDriverAttendanceEntity[]>();
  }

  addDriverAttendances(...driverAttendances: IDriverAttendanceEntity[]) {
    for (const driverAttendance of driverAttendances) {
      const dayAttendances = this.getAllDriverAttendanceOf(driverAttendance.attendance.date);
      const index = dayAttendances.findIndex((dayAttendance) => dayAttendance.driverId === driverAttendance.driverId);
      if (index < 0) {
        dayAttendances.push(driverAttendance);
      } else {
        dayAttendances.splice(index, 1, driverAttendance);
      }
    }
  }

  removeDriverAttendances(...driverAttendances: IDriverAttendanceEntity[]): void {
    for (const dayAttendances of this.allDriverAttendanceMap.values()) {
      const newDayAttendances = dayAttendances.filter(
        (dayAttendance) =>
          !driverAttendances.some((itemToRemove) => dayAttendance.persistentId === itemToRemove.persistentId)
      );
      dayAttendances.splice(0, dayAttendances.length, ...newDayAttendances);
    }
  }

  isPrimaryCarConflicted(driverAttendance: IDriverAttendanceEntity): boolean {
    // 自分以外の勤怠で同じ車に乗っている人がいるか
    const dayAttendances = this.getAllDriverAttendanceOf(driverAttendance.attendance.date);
    return dayAttendances.some(
      (dayAttendance) =>
        dayAttendance.driverId !== driverAttendance.driverId &&
        dayAttendance.primaryCarId === driverAttendance.primaryCarId
    );
  }

  getAllDriverAttendanceOf(date: Date): IDriverAttendanceEntity[] {
    const key = this.getDateKey(date);
    if (!this.allDriverAttendanceMap.has(key)) this.allDriverAttendanceMap.set(key, []);
    return this.allDriverAttendanceMap.getOrError(key);
  }

  getUsedCarIdsOf(date: Date): PersistentId[] {
    const dayAttendances = this.getAllDriverAttendanceOf(date);
    return dayAttendances.map((dayAttendance) => dayAttendance.primaryCarId);
  }

  getAttendanceMapOf(driverId: PersistentId): Map<DateString, IDriverAttendanceEntity> {
    const map = new Map<DateString, IDriverAttendanceEntity>();
    for (const [date, driverAttendances] of this.allDriverAttendanceMap) {
      const filtered = driverAttendances.find((driverAttendance) => driverAttendance.driverId === driverId);
      if (filtered !== undefined) map.set(date, filtered);
    }
    return map;
  }

  private getDateKey(date: Date): DateString {
    return formatDateToString(date);
  }
}
