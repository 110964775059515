import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import {
  IInconsistentRouteInfoData,
  InconsistentReasonType,
} from '~/framework/domain/schedule/schedule/pseudo-entities/inconsistentRouteInfoData';

export interface IInconsistentRouteInfo<Clone extends IInconsistentRouteInfo<Clone>>
  extends IInconsistentRouteInfoData {
  /**
   * このオブジェクトの clone を返す
   */
  clone(): Clone;
}

export class InconsistentRouteInfo implements IInconsistentRouteInfo<InconsistentRouteInfo> {
  index: number;
  driverId: PseudoId;
  type: InconsistentReasonType;

  constructor(index: number, driverId: PseudoId, type: InconsistentReasonType) {
    this.index = index;
    this.driverId = driverId;
    this.type = type;
  }

  clone(): InconsistentRouteInfo {
    return new InconsistentRouteInfo(this.index, this.driverId, this.type);
  }
}
