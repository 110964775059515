import { IPersistentEntity } from '~/framework/core/entity';
import { Maybe } from '~/framework/typeAliases';
import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { PackingStyleEntityData } from '~/framework/domain/masters/packing-style/packingStyleEntity';

type PackingStyle = { packingStyle: PackingStyleEntityData };

//  こっちのほうが元ネタだけどEntityからEntityにしかないものを除外したほうが早い
export interface ContainerTypeEntityData
  extends Omit<ContainerTypeEntity, 'packingStyleId' | 'persistentId'>,
    PackingStyle {}

export class ContainerTypeEntity implements IPersistentEntity {
  persistentId: string;
  constructor(
    public readonly id: string,
    public name: string,
    public packingStyleId: string,
    public unitName: string,
    public transformingCarTypeId: Maybe<string>,
    public status: SoftDeleteStatus,
    public reservationSiteEnabled: boolean
  ) {
    this.persistentId = id;
  }
}
