
import Vue, { PropType } from 'vue';
import RResultDialog from './RResultDialog.vue';
import { IInconsistency } from '~/pages/schedule/inconsistency';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

enum EventTypes {
  ClickClose = 'click:close',
  ClickCheckSchedule = 'click:check-schedule',
}

type DataType = {
  listenerDisposer: Maybe<() => void>;
};

export default Vue.extend({
  name: 'RInconsistentDialog',
  components: {
    RResultDialog,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    inconsistencies: {
      type: Array as PropType<Maybe<IInconsistency[]>>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      listenerDisposer: undefined,
    };
  },
  computed: {
    driverNames(): Maybe<string[]> {
      if (this.inconsistencies === undefined) return undefined;
      return this.inconsistencies.map((inconsistency) => inconsistency.driver.name);
    },
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    onClickCloseButton(): void {
      this.$emit(EventTypes.ClickClose);
    },
    onClickCheckScheduleButton(): void {
      this.$emit(EventTypes.ClickCheckSchedule);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.$emit(EventTypes.ClickClose);
      }
      context.stop();
    },
  },
});
