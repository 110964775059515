import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import {
  driver$createSymbol,
  driver$getAllSymbol,
  driver$getByIdsSymbol,
  driver$updateSymbol,
  IDriverDataForCreate,
  IDriverDataForUpdate,
  IUpdateDriverSequenceData,
} from '~/framework/server-api/masters/driver';

import { Id, PersistentId } from '~/framework/typeAliases';
import { AggregatedDriverEntity } from '~/framework/domain/masters/driver/aggregatedDriverEntity';

import { SoftDeleteStatus } from '~/framework/domain/typeAliases';
import { IDriverFactory } from '~/framework/factories/masters/driverFactory';

export const driverSymbol = Symbol('driver');

export class DriverApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private readonly driverFactory: IDriverFactory;

  constructor(store: Store, serverApis: ServerApiManager, driverFactory: IDriverFactory) {
    this.store = store;
    this.serverApis = serverApis;
    this.driverFactory = driverFactory;
  }

  async create(data: IDriverDataForCreate): Promise<AggregatedDriverEntity> {
    const driver$createApi = this.serverApis.get(driver$createSymbol);
    const [id] = await driver$createApi.create([data]);
    const driver$getApi = this.serverApis.get(driver$getByIdsSymbol);
    const [createdDriverData] = await driver$getApi.getByIds([id]);
    const [driver] = await this.driverFactory.buildByData([createdDriverData]);
    return driver;
  }

  async update(data: IDriverDataForUpdate): Promise<AggregatedDriverEntity> {
    const driver$updateApi = this.serverApis.get(driver$updateSymbol);
    const [id] = await driver$updateApi.update([data]);
    const driver$getApi = this.serverApis.get(driver$getByIdsSymbol);
    const [updatedDriverData] = await driver$getApi.getByIds([id]);
    const [driver] = await this.driverFactory.buildByData([updatedDriverData]);
    return driver;
  }

  async updateDriverSequence(data: IUpdateDriverSequenceData): Promise<Id[]> {
    const driver$updateApi = this.serverApis.get(driver$updateSymbol);
    const res = await driver$updateApi.updateDriverSequence(data);
    return res;
  }

  // 通信が多重になっているが微妙だが今後 API の構成をユースケースごとに変えればどうにでもなる
  async delete(id: PersistentId): Promise<AggregatedDriverEntity> {
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const driver$updateApi = this.serverApis.get(driver$updateSymbol);
    const [data] = await driver$getByIdsApi.getByIds([id]);
    const updateData: IDriverDataForUpdate = {
      id: data.id,
      name: data.name,
      nameRuby: data.nameRuby,
      employmentStatus: data.employmentStatus,
      defaultPrimaryCarId: data.defaultPrimaryCarId,
      licensedCarTypeIds: data.licensedCarTypeIds,
      defaultAttendanceTemplateId: data.defaultAttendanceTemplateId,
      status: SoftDeleteStatus.Deleted, // NOTE これが大事
    };
    await driver$updateApi.update([updateData]);
    const [driver] = await this.driverFactory.buildByData([{ ...updateData, signInUrl: data.signInUrl }]);
    return driver;
  }

  async getAll(): Promise<AggregatedDriverEntity[]> {
    const driver$getAllApi = this.serverApis.get(driver$getAllSymbol);
    const data = await driver$getAllApi.getAll();
    const drivers = await this.driverFactory.buildByData(data);
    return drivers;
  }

  async getByIds(ids: string[]): Promise<AggregatedDriverEntity[]> {
    const driver$getByIdsApi = this.serverApis.get(driver$getByIdsSymbol);
    const data = await driver$getByIdsApi.getByIds(ids);
    const drivers = this.driverFactory.buildByData(data);
    return drivers;
  }
}
