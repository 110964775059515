import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';

import { PackingStyleTaskTypeDefaultEntity } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultEntity';
import {
  packingStyleTaskTypeDefault$getAllSymbol,
  packingStyleTaskTypeDefault$updateSymbol,
  IUpdateData as IPackingStyleTaskTypeDefaultUpdateData,
} from '~/framework/server-api/masters/packingStyleTaskTypeDefault';

import { PackingStyleTaskTypeDefaultMapper } from '~/framework/domain/masters/packing-style/packing-style-task-type-default/packingStyleTaskTypeDefaultMapper';

export const packingStyleTaskTypeDefaultSymbol = Symbol('packingStyleTaskTypeDefault');

export class PackingStyleTaskTypeDefaultApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async getAll(): Promise<PackingStyleTaskTypeDefaultEntity[]> {
    const packingStyleTaskTypeDefault$getAllApi = this.serverApis.get(packingStyleTaskTypeDefault$getAllSymbol);
    const packingStyleTaskTypeDefaultData = await packingStyleTaskTypeDefault$getAllApi.getAll();
    const packingStyleTaskTypeDefaultMapper: PackingStyleTaskTypeDefaultMapper = new PackingStyleTaskTypeDefaultMapper(
      this.store.masters.packingStyleTaskTypeDefault
    );
    const packingStyleTaskTypeDefaults = packingStyleTaskTypeDefaultMapper.map(packingStyleTaskTypeDefaultData);
    return packingStyleTaskTypeDefaults;
  }

  async update(
    packingStyleTaskTypeDefaultUpdateData: IPackingStyleTaskTypeDefaultUpdateData[]
  ): Promise<PackingStyleTaskTypeDefaultEntity[]> {
    const packingStyleTaskTypeDefault$update = this.serverApis.get(packingStyleTaskTypeDefault$updateSymbol);

    const packingStyleTaskTypeDefaultMapper: PackingStyleTaskTypeDefaultMapper = new PackingStyleTaskTypeDefaultMapper(
      this.store.masters.packingStyleTaskTypeDefault
    );
    const packingStyleTaskTypeDefaultData = await packingStyleTaskTypeDefault$update.update(
      packingStyleTaskTypeDefaultUpdateData
    );
    const packingStyleTaskTypeDefaults = packingStyleTaskTypeDefaultMapper.map(packingStyleTaskTypeDefaultData);

    return packingStyleTaskTypeDefaults;
  }
}
