import { IRouteEntity, RouteEntity } from '~/framework/domain/schedule/schedule/pseudo-entities/routeEntity';
import { Collection } from '~/pages/schedule/collection';
import { Disposal } from '~/pages/schedule/disposal';
import { InconsistentRouteInfo } from '~/pages/schedule/inconsistentRouteInfo';

import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';
import { Maybe } from '~/framework/typeAliases';
import { makePropertyReactive } from '~/framework/property';
import { IRScheduleRouteGanttItem } from '~/components/pages/schedule/r-schedule-route-gantt/rScheduleRouteGanttItem';
import { CarUsage } from '~/pages/schedule/carUsage';
import { CarTypeEntity } from '~/framework/domain/masters/car-type/carTypeEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { DriverEntity } from '~/framework/domain/masters/driver/driverEntity';
import { BaseSiteEntity } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { IOriginalRoute } from '~/pages/schedule/originalRoute';

export interface IRoute
  extends IRouteEntity<IOriginalRoute, Collection, Disposal, InconsistentRouteInfo, IRoute>,
    IRScheduleRouteGanttItem<Collection> {
  /**
   * これは最終的に本当にドラッグ対象になっているかどうかであって、間接的に
   * ドラッグ開始時のルートと同じドライバーかどうか、固定されているかどうか、
   * に影響される
   */
  isDraggable: boolean;

  /**
   * 順番を入れ替えられた際のアニメーションを有効にするかどうか
   */
  isSortedAnimationEnabled: boolean;

  /**
   * 順番が固定された際のアニメーションを有効にするかどうか
   */
  isFixedAnimationEnabled: boolean;

  /**
   * 何かしらのルートがドラッグされている時にそのドライバーと同じルートであるかどうか
   * 同じドライバーでしかドラッグできない
   */
  isSameDriverAsDraggingRoute: Maybe<boolean>;

  /**
   * 収集をドラッグ中かどうか
   */
  isDraggingCollection: boolean;

  /**
   * 開始時間が編集されているかどうか
   */
  isStartTimeEdited: boolean;

  /**
   * 終了時間が編集されているかどうか
   */
  isEndTimeEdited: boolean;

  /**
   * Warningを持ったまま作成完了したかどうか
   */
  hasWarning: boolean;

  /**
   * CSS 上の z-index
   */
  zIndex: number;

  /**
   * 収集が入る div の ID
   */
  collectionsElementId: string;

  /**
   * ドライバー
   */
  driver: DriverEntity;

  /**
   * 車
   */
  car: Maybe<AggregatedCarEntity>;

  /**
   * 車種
   */
  carType: Maybe<CarTypeEntity>;

  /**
   * 拠点
   */
  baseSite: Maybe<BaseSiteEntity>;

  /**
   * ルートに対して割り当てられた車
   */
  routeCarAssignment: Maybe<CarUsage>;

  /**
   * 補助があった場合に運転者になっているかどうか
   * 車が不定になっている場合には undefined
   */
  isDriver: Maybe<boolean>;

  /**
   * 補助があった場合に補助員になっているかどうか
   * 車が不定になっている場合には undefined
   */
  isHelper: Maybe<boolean>;

  /**
   * クライアントに到着予定時刻を報告済みの受注があるかどうか
   */
  hasFixedArrivalTimeReportedOrders: boolean;

  /**
   * クライアントに到着予定時刻を報告済みの受注が、前のルートにあるかどうか
   */
  hasNextRouteFixedArrivalTimeReportedOrders: boolean;

  /**
   * 回収の移動が終わった時に呼ばれる
   */
  onReassignCollection(): void;

  /**
   * ルートがドラッグ可能かどうかを更新する
   *
   * @description
   * - 回収が一つしかないのであればドラッグ不可
   * - 回収が一つでも固定されていればドラッグ不可
   * - ルートが固定されていたら問答無用でドラッグ不可
   * - ルートが固定されていない場合にドラッグ中ではないかもしくはドラッグ中であっても同じドライバーであればドラッグ可能
   * - それ以外はドラッグ不可
   */
  updateIsDraggable(): void;
}

export class Route extends RouteEntity<IOriginalRoute, Collection, Disposal, InconsistentRouteInfo> implements IRoute {
  nextRoute: Maybe<Route>;
  previousRoute: Maybe<Route>;

  isDraggable: boolean;
  isSortedAnimationEnabled: boolean;
  isFixedAnimationEnabled: boolean;
  _isSameDriverAsDraggingRoute: Maybe<boolean>;
  _isDraggingCollection: boolean;
  timelineStart: number = 60 * 60 * 8;
  timelineEnd: number = 60 * 60 * 18;
  isStartTimeEdited: boolean;
  isEndTimeEdited: boolean;
  collectionsElementId: string;
  zIndex: number;
  driver: DriverEntity;
  car: Maybe<AggregatedCarEntity>;
  carType: Maybe<CarTypeEntity>;
  baseSite: Maybe<BaseSiteEntity>;
  routeCarAssignment: Maybe<CarUsage>;
  isDriver: Maybe<boolean>;
  isHelper: Maybe<boolean>;

  get isSameDriverAsDraggingRoute(): Maybe<boolean> {
    return this._isSameDriverAsDraggingRoute;
  }

  set isSameDriverAsDraggingRoute(value: Maybe<boolean>) {
    this._isSameDriverAsDraggingRoute = value;
    this.updateIsDraggable();
  }

  get isDraggingCollection(): boolean {
    return this._isDraggingCollection;
  }

  set isDraggingCollection(value: boolean) {
    this._isDraggingCollection = value;
    this.updateIsDraggable();
  }

  get hasWarning(): boolean {
    return this.inconsistentLoadingRouteInfos.length > 0 || this.inconsistentTimeRouteInfos.length > 0;
  }

  get hasFixedArrivalTimeReportedOrders(): boolean {
    return this.collections.some((collection) => collection.order.fixedArrivalTime !== undefined);
  }

  get hasNextRouteFixedArrivalTimeReportedOrders(): boolean {
    if (this.nextRoute === undefined || !this.nextRoute.driverId.equals(this.driverId)) return false;
    return this.nextRoute.collections.some((collection) => collection.order.fixedArrivalTime !== undefined);
  }

  constructor(
    original: Maybe<IOriginalRoute>,
    id: string,
    index: number,
    carId: Maybe<PseudoId>,
    carIndex: Maybe<number>,
    driverId: PseudoId,
    isDriver: Maybe<boolean>,
    isHelper: Maybe<boolean>,
    startTime: number,
    endTime: number,
    garageSiteDepartureId: Maybe<PseudoId>,
    garageSiteDepartureTime: Maybe<number>,
    garageSiteArrivalId: Maybe<PseudoId>,
    garageSiteArrivalTime: Maybe<number>,
    hasRestBeforeGarageSiteArrival: Maybe<boolean>,
    hasChangeCar: Maybe<boolean>,
    baseSiteId: Maybe<PseudoId>,
    baseSiteArrivalTime: Maybe<number>,
    baseSiteDepartureTime: Maybe<number>,
    hasRestBeforeBaseSiteArrival: Maybe<boolean>,
    hasRestAfterGenerationSiteDeparture: Maybe<boolean>,
    hasRestAfterDisposalSiteDeparture: Maybe<boolean>,
    isFixedAssignment: boolean,
    isFixedSchedule: boolean,
    collections: Collection[],
    disposals: Disposal[],
    inconsistentLoadingRouteInfos: InconsistentRouteInfo[],
    inconsistentTimeRouteInfos: InconsistentRouteInfo[],
    routeCarAssignment: Maybe<CarUsage>,
    driver: DriverEntity,
    car: Maybe<AggregatedCarEntity>,
    carType: Maybe<CarTypeEntity>,
    baseSite: Maybe<BaseSiteEntity>
  ) {
    super(
      original,
      id,
      index,
      carId,
      carIndex,
      driverId,
      isDriver,
      isHelper,
      startTime,
      endTime,
      garageSiteDepartureId,
      garageSiteDepartureTime,
      garageSiteArrivalId,
      garageSiteArrivalTime,
      hasRestBeforeGarageSiteArrival,
      hasChangeCar,
      baseSiteId,
      baseSiteArrivalTime,
      baseSiteDepartureTime,
      hasRestBeforeBaseSiteArrival,
      hasRestAfterGenerationSiteDeparture,
      hasRestAfterDisposalSiteDeparture,
      isFixedAssignment,
      isFixedSchedule,
      collections,
      disposals,
      inconsistentLoadingRouteInfos,
      inconsistentTimeRouteInfos
    );
    this.driver = driver;
    this.car = car;
    this.carType = carType;
    this.routeCarAssignment = routeCarAssignment;
    this.baseSite = baseSite;
    this.isDriver = this.routeCarAssignment?.isDriver(this.driverId.value);
    this.isHelper = this.routeCarAssignment?.isHelper(this.driverId.value);
    this._isSameDriverAsDraggingRoute = undefined;
    this._isDraggingCollection = false;
    this.isDraggable = true;
    this.isSortedAnimationEnabled = false;
    this.isFixedAnimationEnabled = false;
    this.isStartTimeEdited = isFixedSchedule && this.startTime !== this.original?.startTime;
    this.isEndTimeEdited = isFixedSchedule && this.endTime !== this.original?.endTime;
    this.collectionsElementId = `r-schedule-route-gantt__collections-${this.id}`;
    this.zIndex = 0;

    // RouteEntity 側のプロパティが更新された時にそのままではこちら側では検知できないので、
    // リアクティブにし、setIsFixedAssignment を呼ぶ事で isFixed を更新している
    makePropertyReactive(this, 'isFixedAssignment', {
      preSetter: this.preSetIsFixedAssignment,
      postSetter: this.postSetIsFixedAssignment,
    });
    // startTime, endTime も同様
    makePropertyReactive(this, 'fixedStartTime', { postSetter: this.setFixedStartTime });
    makePropertyReactive(this, 'fixedEndTime', { postSetter: this.setFixedEndTime });
    // isFirstOfDriver, isLastOfDriver も同様
    makePropertyReactive(this, 'isFirstOfDriver', { postSetter: this.setIsFirstOfDriver });
    makePropertyReactive(this, 'isLastOfDriver', { postSetter: this.setIsLastOfDriver });
  }

  onReassignCollection(): void {
    this.updateIsDraggable();
    for (const collection of this.collections) {
      collection.onReassignCollection();
    }
  }

  clone(): Route {
    const collections = this.collections.map((collection) => collection.clone());
    const disposals = this.disposals.map((disposal) => disposal.clone());

    const inconsistentLoadingRouteInfos = this.inconsistentLoadingRouteInfos.map((inconsistentLoadingRouteInfo) =>
      inconsistentLoadingRouteInfo.clone()
    );
    const inconsistentTimeRouteInfos = this.inconsistentTimeRouteInfos.map((inconsistentTimeRouteInfo) =>
      inconsistentTimeRouteInfo.clone()
    );

    const clone = new Route(
      this.original,
      this.id,
      this.index,
      this.carId,
      this.carIndex,
      this.driverId,
      this.isDriver,
      this.isHelper,
      this._startTime,
      this._endTime,
      this.garageSiteDepartureId,
      this.garageSiteDepartureTime,
      this.garageSiteArrivalId,
      this.garageSiteArrivalTime,
      this.hasRestBeforeGarageSiteArrival,
      this.hasChangeCar,
      this.baseSiteId,
      this.baseSiteArrivalTime,
      this.baseSiteDepartureTime,
      this.hasRestBeforeBaseSiteArrival,
      this.hasRestAfterGenerationSiteDeparture,
      this.hasRestAfterDisposalSiteDeparture,
      this.isFixedAssignment,
      this.isFixedSchedule,
      collections,
      disposals,
      // 以下のinconsistentLoadingRouteInfos、inconsistentTimeRouteInfosの配列が、
      // 変更される可能性は無いが、念の為参照を切っている。
      inconsistentLoadingRouteInfos,
      inconsistentTimeRouteInfos,
      this.routeCarAssignment,
      this.driver,
      this.car,
      this.carType,
      this.baseSite
    );

    // 面倒だが内部状態をきちんとコピーする必要がある
    // RouteEntity
    clone.nextRoute = this.nextRoute;
    clone.previousRoute = this.previousRoute;
    clone._fixedStartTime = this._fixedStartTime;
    clone._fixedEndTime = this._fixedEndTime;
    clone._lastGenerationSiteDepartureTime = this._lastGenerationSiteDepartureTime;
    clone._lastGarageSiteArrivalTime = this._lastGarageSiteArrivalTime;
    clone._lastDisposalSiteDepartureTime = this._lastDisposalSiteDepartureTime;
    clone._lastRestAfterGenerationSiteDepartureEndTime = this._lastRestAfterGenerationSiteDepartureEndTime;
    clone._lastRestAfterDisposalSiteDepartureEndTime = this._lastRestAfterDisposalSiteDepartureEndTime;
    clone.isFirstOfDriver = this.isFirstOfDriver;
    clone.isLastOfDriver = this.isLastOfDriver;
    clone.isTimetableUnstable = this.isTimetableUnstable;
    clone.isCollectionEdited = this.isCollectionEdited;
    // Route
    // いくつかは this の内容をそのまま取ってくると復元した時に UI が壊れるので直接値を指定している
    clone.driver = this.driver;
    clone.car = this.car;
    clone.carType = this.carType;
    clone.routeCarAssignment = this.routeCarAssignment;
    clone.baseSite = this.baseSite;
    clone.isDriver = this.isDriver;
    clone.isHelper = this.isHelper;
    clone._isSameDriverAsDraggingRoute = undefined;
    clone._isDraggingCollection = false;
    clone.isDraggable = true;
    clone.isSortedAnimationEnabled = this.isSortedAnimationEnabled;
    clone.isFixedAnimationEnabled = this.isFixedAnimationEnabled;
    clone.isStartTimeEdited = this.isStartTimeEdited;
    clone.isEndTimeEdited = this.isEndTimeEdited;
    clone.hasChangeCar = this.hasChangeCar;
    clone.collectionsElementId = this.collectionsElementId;
    clone.zIndex = this.zIndex;
    clone.updateIsDraggable();

    return clone;
  }

  /**
   * @param isUnstable
   * @protected
   * @override
   */
  protected setIsCarUnstable(isUnstable: boolean) {
    super.setIsCarUnstable(isUnstable);
    if (isUnstable) {
      this.car = undefined;
      this.carType = undefined;
    } else {
      this.car = this.original?.car;
      this.carType = this.original?.carType;
    }
  }

  private preSetIsFixedAssignment(value: boolean): boolean {
    // undefined になると鬱陶しいので
    return !!value;
  }

  private postSetIsFixedAssignment(value: boolean): boolean {
    this.updateIsDraggable();
    if (value) this.isFixedAnimationEnabled = true;
    return value;
  }

  private setFixedStartTime(value: Maybe<number>): Maybe<number> {
    this.isStartTimeEdited = value !== undefined;

    return value;
  }

  private setFixedEndTime(value: Maybe<number>): Maybe<number> {
    this.isEndTimeEdited = value !== undefined;

    return value;
  }

  private setIsFirstOfDriver(_value: boolean): void {
    this.updateIsDraggable();
  }

  private setIsLastOfDriver(_value: boolean): void {
    this.updateIsDraggable();
  }

  updateIsDraggable() {
    if (this.isFirstOfDriver && this.isLastOfDriver) {
      this.isDraggable = false;
    } else if (this.isFixedAssignmentInAnyOfCollections || this.isDraggingCollection) {
      this.isDraggable = false;
    } else if (this.isSameDriverAsDraggingRoute === undefined || this.isSameDriverAsDraggingRoute) {
      this.isDraggable = true;
    } else {
      this.isDraggable = false;
    }
    // ルートに紐づく全ての回収も更新
    this.collections.forEach((collection) => collection.updateIsDraggable());
  }
}
