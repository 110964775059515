import { IEntity, IPersistentEntity } from '~/framework/core/entity';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { GenerationSiteEntity } from '~/framework/domain/masters/generation-site/generationSiteEntity';
import { IUserData } from '~/framework/server-api/masters/user';
import { IGenerationSiteData } from '~/framework/server-api/masters/generationSite';
import { GenerationSiteMapper } from '~/framework/domain/masters/generation-site/generationSiteMapper';

export interface IRoutingRegulationEntityData {
  id: string;
  name: string;
  createdBy: IUserData;
  updatedBy: IUserData;
  createdAt: Date;
  updatedAt: Date;
  generationSites: IGenerationSiteData[];
  optionalGenerationSites: IGenerationSiteData[];
  preserveGenerationSiteSequence: boolean;
}

export interface IRoutingRegulationEntity extends IEntity, IPersistentEntity {
  id: string;
  name: string;
  createdBy: UserEntity;
  updatedBy: UserEntity;
  createdAt: Date;
  updatedAt: Date;
  generationSites: GenerationSiteEntity[];
  optionalGenerationSites: GenerationSiteEntity[];
  preserveGenerationSiteSequence: boolean;
  generationSiteMapper: GenerationSiteMapper;
}

export class RoutingRegulationEntity implements IRoutingRegulationEntity {
  readonly id: string;
  readonly persistentId: string;
  name: string;
  createdBy: UserEntity;
  updatedBy: UserEntity;
  createdAt: Date;
  updatedAt: Date;
  generationSites: GenerationSiteEntity[];
  optionalGenerationSites: GenerationSiteEntity[];
  preserveGenerationSiteSequence: boolean;
  generationSiteMapper: GenerationSiteMapper = new GenerationSiteMapper();

  constructor(
    id: string,
    name: string,
    createdBy: IUserData,
    updatedBy: IUserData,
    createdAt: Date,
    updatedAt: Date,
    generationSites: IGenerationSiteData[],
    optionalGenerationSites: IGenerationSiteData[],
    preserveGenerationSiteSequence: boolean
  ) {
    this.id = id;
    this.persistentId = id;
    this.name = name;
    this.createdBy = new UserEntity(createdBy.id, createdBy.name);
    this.updatedBy = new UserEntity(updatedBy.id, updatedBy.name);
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.generationSites = this.generationSiteMapper.map(generationSites).map((generationSiteEntity) => {
      return {
        ...generationSiteEntity,
        displayName:
          generationSiteEntity.client === undefined
            ? `${generationSiteEntity.name}`
            : `${generationSiteEntity.name} [${generationSiteEntity.client.name}] `,
      } as GenerationSiteEntity;
    });

    this.optionalGenerationSites = this.generationSiteMapper.map(optionalGenerationSites).map((generationSite) => {
      return {
        ...generationSite,
        displayName:
          generationSite.client === undefined
            ? `${generationSite.name}`
            : `${generationSite.name} [${generationSite.client.name}] `,
      } as GenerationSiteEntity;
    });

    this.preserveGenerationSiteSequence = preserveGenerationSiteSequence;
  }
}
