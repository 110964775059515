import { BaseSiteEntity, BaseSiteEntityData } from '~/framework/domain/masters/base-site/baseSiteEntity';
import { UserEntity, UserEntityData } from '~/framework/domain/masters/user/userEntity';
import { Maybe } from '~/framework/typeAliases';

export interface AggregatedBaseSiteEntityData extends BaseSiteEntityData {
  createdBy: UserEntityData;
}

export class AggregatedBaseSiteEntity extends BaseSiteEntity {
  createdBy: UserEntity;

  constructor(
    id: string,
    name: string,
    nameRuby: string,
    zipCode: Maybe<string>,
    address1: string,
    address2: string,
    address3: string,
    address4: Maybe<string>,
    latitude: number,
    longitude: number,
    hasCars: boolean,
    hasContainers: boolean,
    createdAt: Date,
    createdBy: UserEntity
  ) {
    super(
      id,
      name,
      nameRuby,
      zipCode,
      address1,
      address2,
      address3,
      address4,
      latitude,
      longitude,
      hasCars,
      hasContainers,
      createdAt
    );

    this.createdBy = createdBy;
  }
}
