import { MapperBase } from '~/framework/core/mapper';
import {
  ContainerTypeEntity,
  ContainerTypeEntityData,
} from '~/framework/domain/masters/container-type/containerTypeEntity';

export class ContainerTypeMapper extends MapperBase<ContainerTypeEntityData, ContainerTypeEntity> {
  protected instantiateWithData(data: ContainerTypeEntityData): ContainerTypeEntity {
    return new ContainerTypeEntity(
      data.id,
      data.name,
      data.packingStyle.id,
      data.unitName,
      data.transformingCarTypeId,
      data.status,
      data.reservationSiteEnabled
    );
  }
}
