import _ from 'lodash';
import { Maybe } from '~/framework/typeAliases';

/**
 * 外部から値を設定する時に先ず呼ばれる
 * 返した値が元の setter に渡される
 */
export type ReactivePreSetter<T> = (value: T) => T;

/**
 * 外部から値を取得する時に呼ばれる
 * 先ず元の getter の値が取得された後で呼ばれる
 * 返した値が返り値になる
 */
export type ReactiveGetter<T> = (originalValue: T) => T;

/**
 * オリジナルの setter が呼ばれた後に呼ばれる
 * 確定した値を扱いたいのであればこれを利用する
 */
export type ReactivePostSetter<T> = (postValue: T) => void;

/**
 * makePropertyReactive を呼ぶ際のオプション
 */
export type ReactivePropertyOptions<T> = {
  /**
   * object の set が呼ばれた時に呼ばれて欲しい関数
   */
  preSetter?: Maybe<ReactivePreSetter<T>>;
  /**
   * object の get が呼ばれた時に呼ばれて欲しい関数
   */
  getter?: Maybe<ReactiveGetter<T>>;
  /**
   * object のオリジナルの set が呼ばれた後に呼ばれて欲しい関数
   */
  postSetter?: Maybe<ReactivePostSetter<T>>;
};

// https://stackoverflow.com/questions/37643254/check-if-typescript-class-has-setter-getter
export const getPropertyDescriptor = (object: any, property: PropertyKey): Maybe<PropertyDescriptor> => {
  let desc;
  do {
    desc = Object.getOwnPropertyDescriptor(object, property);
  } while (!desc && (object = Object.getPrototypeOf(object)));
  return desc;
};

/**
 * リアクティブなプロパティを定義するためのもの。
 * 特定のオブジェクトに設定されている getter, setter をラップする事ができる。
 *
 * @param object 対象のオブジェクト
 * @param property 対象のプロパティ
 * @param options 差し込みたい getter, setter のリスト
 */
export const makePropertyReactive = <O, T>(object: O, property: keyof O, options: ReactivePropertyOptions<T>) => {
  if (object === undefined || object === null) {
    throw new Error(`object cannot be null or undefined!`);
  }

  let { preSetter: reactivePreSetter, getter: reactiveGetter, postSetter: reactivePostSetter } = options;

  // 関数ポインタはそのまま使うと this がバインドされていない状態になってしまうので
  // ここで object にバインドしておく
  if (reactivePreSetter !== undefined) reactivePreSetter = reactivePreSetter.bind(object);
  if (reactivePostSetter !== undefined) reactivePostSetter = reactivePostSetter.bind(object);
  if (reactiveGetter !== undefined) reactiveGetter = reactiveGetter.bind(object);

  const propertyDescriptor = getPropertyDescriptor(object, property);
  if (propertyDescriptor && propertyDescriptor.configurable === false) {
    throw new Error(`Cannot find configurable property: ${property.toString()}`);
  }

  // 既に存在している getter と setter をラップする
  // dataObject の値を現時点での正とするので、getter の値は取らず、
  // setter がもし存在するのであればそれを呼んでおく
  const getter = propertyDescriptor && propertyDescriptor.get && propertyDescriptor.get.bind(object);
  const setter = propertyDescriptor && propertyDescriptor.set && propertyDescriptor.set.bind(object);
  let objectValue = getter === undefined ? object[property] : getter();

  Object.defineProperty(object, property, {
    enumerable: true,
    configurable: true,
    get: function getterFunction(): T {
      // getter が存在すればそこから、それがなければ object から直接値を取り出す
      // その後、reactiveGetter があればそれを通して最終的な値を取得し、それを返す
      let value = getter !== undefined ? getter() : objectValue;
      value = reactiveGetter !== undefined ? reactiveGetter(value) : value;
      return value;
    },
    set: function setterFunction(newValue: T): void {
      // もし reactiveSetter がある場合はそれを呼んで値を取り出す
      // その後、その値を元設定してあった setter を通して、もしくは直接設定する
      const value = reactivePreSetter !== undefined ? reactivePreSetter(newValue) : newValue;
      if (setter !== undefined) setter(value);
      else objectValue = value;
      const fixedValue = object[property] as any;
      if (reactivePostSetter !== undefined) reactivePostSetter(fixedValue);
    },
  });
};

/**
 * あるオブジェクトのプロパティを別オブジェクトのプロパティにマップする。
 * 例えばオブジェクト A のプロパティ a をオブジェクト B のプロパティ a として読み書きできる様にする。
 *
 * @param target オブジェクトを移植したい先
 * @param object プロパティを取り出す元となるオブジェクト
 * @param properties マップしたいプロパティのリスト
 */
export const mapProperties = <O>(target: any, object: O, properties: (keyof O)[]) => {
  if (target === undefined) {
    throw new Error(`target cannot be undefined!`);
  }
  if (object === undefined || object === null) {
    throw new Error(`object cannot be null or undefined!`);
  }

  for (const property of properties) {
    Object.defineProperty(target, property, {
      enumerable: true,
      configurable: true,
      get: function getterFunction() {
        return object[property];
      },
      set: function setterFunction(newValue): void {
        object[property] = newValue;
      },
    });
  }
};

/**
 * オブジェクトに設定されている null を全て undefined に置き換える。
 * GraphQL から返ってくる値は null である可能性があるが、TypeScript では null ではなく undefined が推奨されているため、
 * rin 内部では null を undefined に変換して利用する。特に string | undefined のような型のフィールドに null が入って
 * くると意味不明な挙動になるため注意する事。typeof が object でかつ null でないものに対応。
 *
 * @param nullableObject
 */
export const convertNullToUndefined = (nullableObject: any): any => {
  if (nullableObject === null || nullableObject === undefined) return undefined;
  if (_.isObjectLike(nullableObject)) {
    for (const property of Object.keys(nullableObject)) {
      nullableObject[property] = convertNullToUndefined(nullableObject[property]);
    }
  }
  return nullableObject;
};

/**
 * convertNullToUndefined の逆
 * @param undefinableObject
 */
export const convertUndefinedToNull = (undefinableObject: any): any => {
  if (undefinableObject === undefined || undefinableObject === null) return null;
  if (_.isObjectLike(undefinableObject)) {
    for (const property of Object.keys(undefinableObject)) {
      undefinableObject[property] = convertUndefinedToNull(undefinableObject[property]);
    }
  }
  return undefinableObject;
};

/**
 * プロパティをリアクティブにし、set した時に何かのメソッドを呼び出したい時に使う
 *
 * @param reactiveFunction プロパティが更新された時に呼び出したいメソッド、オブジェクトに bind されて利用されるので注意
 */
export const ReactiveProperty = (reactiveFunction: () => void) => {
  return function (prototype: any, propertyKey: string) {
    const propertySymbol = Symbol(`Property symbol for ${propertyKey}`);
    prototype[propertySymbol] = prototype[propertyKey];
    Object.defineProperty(prototype, propertyKey, {
      enumerable: true,
      configurable: true,
      set: function setterFunction(newValue: any) {
        this[propertySymbol] = newValue;
        reactiveFunction.bind(this)();
      },
      get: function getterFunction() {
        return this[propertySymbol];
      },
    });
  };
};
