import { UserEntity } from '../user/userEntity';
import { GenerationSiteMapper } from '../generation-site/generationSiteMapper';
import { IMapper, MapperBase } from '~/framework/core/mapper';
import {
  IRoutingRegulationEntity,
  IRoutingRegulationEntityData,
  RoutingRegulationEntity,
} from '~/framework/domain/masters/routing-regulation/routingRegulationEntity';

export interface IRoutingRegulationMapper extends IMapper<IRoutingRegulationEntityData, IRoutingRegulationEntity> {
  //
}

export class RoutingRegulationMapper
  extends MapperBase<IRoutingRegulationEntityData, IRoutingRegulationEntity>
  implements IRoutingRegulationMapper
{
  private generationSiteMapper: GenerationSiteMapper;

  constructor() {
    super();
    this.generationSiteMapper = new GenerationSiteMapper();
  }

  protected instantiateWithData(data: IRoutingRegulationEntityData): IRoutingRegulationEntity {
    return new RoutingRegulationEntity(
      data.id,
      data.name,
      data.createdBy,
      data.updatedBy,
      data.createdAt,
      data.updatedAt,
      data.generationSites,
      data.optionalGenerationSites,
      data.preserveGenerationSiteSequence
    );
  }

  protected updateWithData(data: IRoutingRegulationEntityData, entity: IRoutingRegulationEntity): void {
    entity.name = data.name;
    entity.createdBy = new UserEntity(data.createdBy.id, data.createdBy.name);
    entity.updatedBy = new UserEntity(data.updatedBy.id, data.updatedBy.name);
    entity.createdAt = data.createdAt;
    entity.updatedAt = data.updatedAt;
    entity.generationSites = this.generationSiteMapper.map(data.generationSites);
    entity.optionalGenerationSites = this.generationSiteMapper.map(data.optionalGenerationSites);
    entity.preserveGenerationSiteSequence = data.preserveGenerationSiteSequence;
  }
}
