import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { WithoutId } from '~/framework/typeAliases';
import {
  DriverAttendanceTemplateEntity,
  DriverAttendanceTemplateEntityData,
} from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateEntity';
import {
  driverAttendanceTemplate$getAllSymbol,
  driverAttendanceTemplate$getByIdsSymbol,
  driverAttendanceTemplate$createSymbol,
  driverAttendanceTemplate$updateSymbol,
} from '~/framework/server-api/masters/driverAttendanceTemplate';
import { DriverAttendanceTemplateMapper } from '~/framework/domain/masters/driver-attendance-template/driverAttendanceTemplateMapper';

export const driverAttendanceTemplateSymbol = Symbol('driverAttendanceTemplate');

export class DriverAttendanceTemplateApplicationService {
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
  }

  async getAll(): Promise<DriverAttendanceTemplateEntity[]> {
    const api = this.serverApis.get(driverAttendanceTemplate$getAllSymbol);
    const mapper: DriverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(
      this.store.masters.driverAttendanceTemplate
    );
    const result = await api.getAll();
    return mapper.map(result);
  }

  async getByIds(ids: string[]): Promise<DriverAttendanceTemplateEntity[]> {
    const api = this.serverApis.get(driverAttendanceTemplate$getByIdsSymbol);
    const mapper: DriverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(
      this.store.masters.driverAttendanceTemplate
    );
    const result = await api.getByIds(ids);
    const entities = mapper.map(result);
    return entities;
  }

  async create(data: WithoutId<DriverAttendanceTemplateEntityData>): Promise<DriverAttendanceTemplateEntity> {
    const api = this.serverApis.get(driverAttendanceTemplate$createSymbol);
    const mapper: DriverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(
      this.store.masters.driverAttendanceTemplate
    );
    const ids = await api.create([data]);
    const entityData = { ...data, id: ids[0] };
    const entity = mapper.mapSingle(entityData);
    return entity;
  }

  async update(data: DriverAttendanceTemplateEntityData): Promise<DriverAttendanceTemplateEntity> {
    const api = this.serverApis.get(driverAttendanceTemplate$updateSymbol);
    const mapper: DriverAttendanceTemplateMapper = new DriverAttendanceTemplateMapper(
      this.store.masters.driverAttendanceTemplate
    );
    await api.update([data]);
    const entity = mapper.mapSingle(data);
    return entity;
  }
}
