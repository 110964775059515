import { ICreateData } from '~/framework/server-api/schedule/order/createOrders';
import { RawScheduleJsonObject } from '~/framework/server-api/schedule/schedule/schedule';
import { RawRouteJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';

export const order$validateOrdersSymbol = Symbol('order$validateOrdersSymbol');

export interface IValidateOrdersApi {
  [order$validateOrdersSymbol]: void;
  validateOrders(data: ICreateData[]): Promise<RawScheduleJsonObject<RawRouteJsonObject>[]>;
}
