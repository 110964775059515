import { StoredMapperBase } from '~/framework/core/mapper';
import {
  AggregatedContainerTypeEntity,
  AggregatedContainerTypeEntityData,
} from '~/framework/domain/masters/container-type/aggregatedContainerTypeEntity';
import { AggregatedContainerTypeStore } from '~/framework/domain/masters/container-type/aggregatedContainerTypeStore';
import { CarTypeMapper } from '~/framework/domain/masters/car-type/carTypeMapper';
import { PackingStyleStore } from '~/framework/domain/masters/packing-style/packingStyleStore';
import { PackingStyleMapper } from '~/framework/domain/masters/packing-style/packingStyleMapper';

export class AggregatedContainerTypeMapper extends StoredMapperBase<
  AggregatedContainerTypeEntityData,
  AggregatedContainerTypeEntity
> {
  protected store: AggregatedContainerTypeStore;
  private carTypeMapper: CarTypeMapper;
  private packingStyleMapper: PackingStyleMapper;

  constructor(store: AggregatedContainerTypeStore, packingStyleStore: PackingStyleStore) {
    super();
    this.store = store;
    this.carTypeMapper = new CarTypeMapper();
    this.packingStyleMapper = new PackingStyleMapper(packingStyleStore);
  }

  protected instantiateWithData(data: AggregatedContainerTypeEntityData): AggregatedContainerTypeEntity {
    const transformingCarType = data.transformingCarType
      ? this.carTypeMapper.mapSingle(data.transformingCarType)
      : undefined;
    const packingStyle = this.packingStyleMapper.mapSingle(data.packingStyle);
    return new AggregatedContainerTypeEntity(
      data.id,
      data.name,
      data.unitName,
      data.transformingCarTypeId,
      data.status,
      data.reservationSiteEnabled,
      transformingCarType,
      packingStyle
    );
  }

  protected updateWithData(data: AggregatedContainerTypeEntityData, entity: AggregatedContainerTypeEntity): void {
    const transformingCarType = data.transformingCarType
      ? this.carTypeMapper.mapSingle(data.transformingCarType)
      : undefined;
    const packingStyle = this.packingStyleMapper.mapSingle(data.packingStyle);
    entity.name = data.name;
    entity.unitName = data.unitName;
    entity.transformingCarTypeId = data.transformingCarTypeId;
    entity.status = data.status;
    entity.transformingCarType = transformingCarType;
    entity.packingStyle = packingStyle;
    entity.reservationSiteEnabled = data.reservationSiteEnabled;
  }
}
