import { Maybe, PersistentId, Seconds } from '~/framework/typeAliases';
import { CarAttendanceType } from '~/framework/domain/typeAliases';
import { AttendanceEntity, AttendanceEntityData } from '~/framework/domain/masters/attendance/attendanceEntity';

export interface AggregatedCarAttendanceEntityData {
  id: PersistentId;
  attendance: AttendanceEntityData;
  carId: PersistentId;
  carAttendanceType: CarAttendanceType;
  periodStart: Maybe<Seconds>;
  periodEnd: Maybe<Seconds>;
  note: Maybe<string>;
}

export class AggregatedCarAttendanceEntity {
  readonly id: string;
  readonly persistentId: string;
  attendance: AttendanceEntity;
  carId: PersistentId;
  carAttendanceType: CarAttendanceType;
  periodStart: Maybe<Seconds>;
  periodEnd: Maybe<Seconds>;
  note: Maybe<string>;

  constructor(
    id: PersistentId,
    attendance: AttendanceEntity,
    carId: PersistentId,
    carAttendanceType: CarAttendanceType,
    periodStart: Maybe<Seconds>,
    periodEnd: Maybe<Seconds>,
    note: Maybe<string>
  ) {
    this.id = id;
    this.persistentId = id;
    this.attendance = attendance;
    this.carId = carId;
    this.carAttendanceType = carAttendanceType;
    this.periodStart = periodStart;
    this.periodEnd = periodEnd;
    this.note = note;
  }
}
