
import Vue, { PropType } from 'vue';
import RResultDialog from './RResultDialog.vue';
import { IDriverReason, IInfeasibility } from '~/components/pages/schedule/r-schedule-errors/infeasibility';
import { Maybe } from '~/framework/typeAliases';
import { UIKeyboardEvent, KeyboardEventCode, KeyboardEventPriority } from '~/framework/uiEventManager';
import { ITypedEventContext } from '~/framework/events/typedEventContext';
import { RinEventDialogComponentParam, ShortcutKeyParams } from '~/framework/services/rin-events/rinEventParams';

enum EventTypes {
  ClickClose = 'click:close',
  ClickRegisterOrder = 'click:register-order',
  ClickEditAttendance = 'click:edit-attendance',
  ClickRinLink = 'click:rin-link',
}

type DataType = {
  listenerDisposer: Maybe<() => void>;
};

export default Vue.extend({
  name: 'RAttendanceDialog',
  components: {
    RResultDialog,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    infeasibilities: {
      type: Array as PropType<Maybe<IInfeasibility<IDriverReason>[]>>,
      required: false,
      default: undefined,
    },
  },
  data(): DataType {
    return {
      listenerDisposer: undefined,
    };
  },
  mounted() {
    const keyboardEventListenerDisposer = this.$context.uiEvents.keyboardEvent.on(
      this.onKeydown,
      KeyboardEventPriority.Dialog
    );
    this.listenerDisposer = () => {
      keyboardEventListenerDisposer.dispose();
    };
  },
  beforeDestroy() {
    if (this.listenerDisposer !== undefined) this.listenerDisposer();
  },
  methods: {
    onClickCloseButton(): void {
      this.$emit(EventTypes.ClickClose);
    },
    onClickRegisterOrder(): void {
      this.$emit(EventTypes.ClickRegisterOrder);
    },
    onClickEditAttendance(): void {
      this.$emit(EventTypes.ClickEditAttendance);
    },
    onClickRinLink(infeasibility: IInfeasibility<IDriverReason>, path: string): void {
      this.$emit(EventTypes.ClickRinLink, infeasibility, path);
    },
    onKeydown(e: UIKeyboardEvent, context: ITypedEventContext): void {
      if (this.value === false) return;
      if (e.isCodeWithoutModifiers(KeyboardEventCode.Escape)) {
        this.$rinGtm.shortcut(ShortcutKeyParams.ESCAPE, RinEventDialogComponentParam);
        this.$emit(EventTypes.ClickClose);
      }
      context.stop();
    },
  },
});
