import { IDisposalEntityData } from '~/framework/domain/schedule/schedule/pseudo-entities/disposalEntity';
import { PseudoId } from '~/framework/domain/schedule/schedule/pseudo-entities/pseudoId';

export interface IOriginalDisposalEntity extends IDisposalEntityData {
  /**
   * 処分場にいる時間
   */
  disposalSiteDuration: number;
}

export class OriginalDisposalEntity implements IOriginalDisposalEntity {
  id: string;
  index: number;
  disposalSiteId: PseudoId;
  disposalSiteArrivalTime: number;
  disposalSiteDepartureTime: number;
  hasRestBeforeDisposalSiteArrival: boolean;
  prioritizeAssignedDisposalSite: boolean;
  sequentialAssignedDisposalSite: boolean;

  get disposalSiteDuration() {
    return this.disposalSiteDepartureTime - this.disposalSiteArrivalTime;
  }

  constructor(
    id: string,
    index: number,
    disposalSiteId: PseudoId,
    disposalSiteArrivalTime: number,
    disposalSiteDepartureTime: number,
    hasRestBeforeDisposalSiteArrival: boolean,
    prioritizeAssignedDisposalSite: boolean,
    sequentialAssignedDisposalSite: boolean
  ) {
    this.id = id;
    this.index = index;
    this.disposalSiteId = disposalSiteId;
    this.disposalSiteArrivalTime = disposalSiteArrivalTime;
    this.disposalSiteDepartureTime = disposalSiteDepartureTime;
    this.hasRestBeforeDisposalSiteArrival = hasRestBeforeDisposalSiteArrival;
    this.prioritizeAssignedDisposalSite = prioritizeAssignedDisposalSite;
    this.sequentialAssignedDisposalSite = sequentialAssignedDisposalSite;
  }
}
